<template>
  <div>
    <div v-if="user.tournaments_grouped.length" class="card card-blue-700 mb-4">
      <div class="card-header">
        Registrations
      </div>
      <div class="card-body p-1 p-sm-2 p-md-3">
        <user-tournament-item
          v-for="tournament in user.tournaments_grouped" :key="tournament.id"
          :tournament="tournament"/>
      </div>
    </div>
    <div v-else class="flex my-4">
      <div class="mr-4 mt-2">
        <i class="far fa-3x fa-fw fa-bullseye-arrow"></i>
      </div>
      <div class="flex-grow flex flex-col">
        <div class="text-2xl font-sans">
          Interested in competing?
          <video-tip :link="'https://www.youtube.com/embed/f2MKvNR2GeU'" :title="'Registering For A Tournament'"/>
        </div>
        <a href="/tournaments" class="text-blue-400 text-xl cursor-pointer">Find Tournaments here.</a>
      </div>
    </div>
  </div>
</template>

<script>
import UserTournamentItem from "./UserTournamentItem.vue";
import VideoTip from "../partials/VideoTip.vue";

export default {
  name: "UserTournaments",
  components: {VideoTip, UserTournamentItem},
  props: ['user'],
}
</script>

<style scoped>

</style>
