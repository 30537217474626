<script setup>
import NavigationLink from "./NavigationLink.vue";
import UserNavigation from "./UserNavigation.vue";
import NavigationTournament from "./NavigationTournament.vue";
import {computed} from "vue";
import {useStore} from "vuex";

const props = defineProps(['user']);
const store = useStore();

const tournament = computed(() => {
  return store.getters['tournament/getTournament'];
})
</script>

<template>
  <div class="topPadding flex flex-col">
    <div class="mr-2 text-stone-400 font-semibold">
      <navigation-link icon="fa-regular fa-house" label="Home" link="dashboard"/>
      <navigation-link icon="fa-regular fa-bullseye-arrow" label="Scores" link="results"/>
      <navigation-link icon="fa-regular fa-bow-arrow" label="Tournaments" link="tournaments"/>
      <navigation-link icon="fa-regular fa-user-group" label="Clubs" link="clubs"/>
      <navigation-link icon="fa-kit fa-archer" label="Archers" link="archers"/>
    </div>
    <user-navigation v-if="props.user != null" :user="props.user"/>
    <navigation-tournament v-else-if="tournament" :tournament="tournament" :user="props.user"/>
  </div>
</template>

<style scoped>
.topPadding {
  padding-top: 40px;
  width: 243px;
}
</style>