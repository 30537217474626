<template>
  <div class="flex-grow sm:m-2 md:mx-auto max-w-7xl">
    <template v-if="online">
      <tournament-form
        v-if="onlineTournament"
        :inc_tournament="onlineTournament"
        :club="onlineTournament.club"
        :sport-list="onlineTournament.sportList"
        :target-list="onlineTournament.targetList"/>
      <div v-else class="text-center">
        <h1 class="mt-4">Loading Tournament Details</h1>
        <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
      </div>
    </template>
    
    <online-feature v-else/>
  </div>
</template>

<script>
import CloneTournament from "../partials/CloneTournament.vue";
import DeleteTournament from "../partials/DeleteTournament.vue";
import OnlineFeature from "../partials/OnlineFeature.vue";
import TournamentForm from "./form/TournamentForm.vue";

export default {
  name: "TournamentEdit",
  components: {OnlineFeature, DeleteTournament, CloneTournament, TournamentForm},
  props: ['tournamentIn'],
  data() {
    return {
      onlineTournament: null,
    }
  },
  computed: {
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    tournament() {
      return this.tournamentIn;
    }
  },
  mounted() {
    this.getTournament();
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('refresh');
    next()
  },
  methods: {
    getTournament() {
      this.$axios
      .get('/tournaments/' + this.tournament.slug + '/edit')
      .then(({data}) => {
        this.onlineTournament = data.tournament;
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>
