<template>
  <div v-if="onlineTournament" class="flex flex-wrap w-full">
    <div class="w-full m-5 flex justify-center">
      {{ noNotifications }}
    </div>
    <div class="p-1 w-full" ref="bad_assignments">
      <bad-assignments @updated="badUpdated" :tournament="onlineTournament"/>
    </div>
    <div v-if="onlineTournament.payments.processing_fee" class="p-1 w-full md:w-1/2" ref="fees">
      <tournament-fees :tournament="onlineTournament"/>
    </div>
    <div class="p-1 w-full md:w-1/2" ref="withdraw">
      <withdraw-requests :tournament="onlineTournament"/>
    </div>
  </div>
</template>

<script>
import TournamentFees from "./TournamentFees.vue";
import WithdrawRequests from "./WithdrawRequests.vue";
import BadAssignments from "../../godmode/BadAssignments.vue";

export default {
  name: "TournamentNotifications",
  components: {BadAssignments, WithdrawRequests, TournamentFees},
  props: ['tournamentIn'],
  data() {
    return {
      onlineTournament: null,
      noNotifications: null,
      hasBad: false,
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    withdrawCount() {
      if (this.$refs.withdraw != null) {
        return this.$refs.withdraw.children.length;
      }
      return 0;
    },
    feesCount() {
      if (this.$refs.fees != null) {
        return this.$refs.fees.children.length;
      }
      return 0;
    }
  },
  methods: {
    badUpdated(value) {
      this.hasBad = value;
      this.setNone();
    },
    setNone() {
      let exists = this.withdrawCount || this.feesCount || this.hasBad;
      this.noNotifications = exists ? null : 'There are no notifications at this time';
    }
  },
  mounted() {
    this.$axios
    .get('/tournaments/' + this.tournament.slug + '/notifications')
    .then(({data}) => {
      this.onlineTournament = data.tournament;
      this.$nextTick(() => {
        this.setNone();
      });
    })
    .catch(({response}) => {
      console.log(response);
    });
  },
}
</script>

<style scoped>

</style>
