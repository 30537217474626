<template>
    <div class="card card-body pb-1 pt-1 mb-1">
        <div class="flex flex-col">
            <div class="flex flex-col">
                <a :href="'/tournaments/' + tournament.slug" class="text-blue-600 text-xl sm:text-2xl">{{tournament.name}}</a>
                <div class="text-blue-500 sm:text-xl">{{tournament.sport.name}}</div>
                <div class="sm:text-lg">{{start}} - {{end}}</div>
                <span v-if="location" class="sm:text-xl">{{location}}</span>

            </div>

            <div :class="statusClass(status)" class="flex flex-col justify-between sm:text-xl">
                <div>
                    {{statusMessage(status)}}
                    <div v-if="status === 'open'" class="text-center">
                        <span v-if="space" class="text-blue-500">-Space Available-</span>
                        <span v-else class="text-red-600">--FULL--</span>
                    </div>
                </div>

                <div>
                    <a :href="'/tournaments/' + tournament.slug + '#/competitor'" v-if="status === 'open' && space"
                       class="btn-outline-blue-600 btn-sm mt-2 float-right">Register</a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "TournamentListSmall",
        props: ['tournament'],
        data() {
            return {
                showDescription: this.tournament.description && this.tournament.description.length > 200,
            }

        },
        computed: {
            start() {
                return moment(this.tournament.start_time).format('DD MMM')
            },
            end() {
                return moment(this.tournament.end_time).format('DD MMM YYYY')
            },
            location() {
                let loc = '';
                if (this.tournament.address) {
                    if (this.tournament.address.city) {
                        loc += this.tournament.address.city;
                        if (this.tournament.address.state) {
                            loc += ' - ';
                        }
                    }
                    loc += this.tournament.address.state ? this.tournament.address.state : '';
                }
                return loc;
            },
            status() {
                let now = new Date();
                let status = '';
                let open = moment(this.tournament.reg_open);
                let close = moment(this.tournament.reg_close);
                if (open > now) {
                    status = 'opening';
                } else if (close < now) {
                    status = 'closed';
                } else {
                    status = 'open';
                }
                return status;
            },
            space() {
                return this.tournament.competitors_count < this.tournament.max_competitors
            }
        },
        methods: {
            formatTime(time) {
                return moment(time).format('D MMM hh:mm A')
            },
            statusMessage(status) {
                let message = "";
                if (status === 'opening') {
                    message += 'Registration opens ';
                    message += moment(this.tournament.reg_open).format('MMMM D');
                } else if (status === 'closed') {
                    message += 'Registration closed ';
                    message += moment(this.tournament.reg_close).format('MMMM D');
                } else {
                    message += 'Registration closes ';
                    message += moment(this.tournament.reg_close).format('MMMM D');
                }
                return message;
            },
            statusClass(status) {
                let color = ''
                if (status === 'opening') {
                    color = 'text-blue-500'
                } else if (status === 'closed') {
                    color = 'text-orange-700'
                } else {
                    color = 'text-blue-700'
                }
                return color;
            }
        }
    }
</script>

<style scoped>
    .largeDescription {
        overflow: hidden;
        max-height: 100px;
    }
</style>