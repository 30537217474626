<template>
    <div class="flex text-gray-700">
        <div class="text-lg">
        {{name}}:
        </div>
        <div class="flex flex-col justify-end ml-2">
            {{value}}
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TournamentDetailRow",
        props:['name','value']
    }
</script>

<style scoped>

</style>
