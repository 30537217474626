<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {Field} from "vee-validate";

const store = useCompetitorStore();
</script>

<template>
  <div v-if="store.times.length > 1" class="bs-card">
    <div class="bs-section-header">Line Times</div>
    <div v-for="archer of store.selectedArchers" class="flex mb-2">
      <div class="bs-input-box flex-2 mr-4">{{ archer.name }}</div>
      <Field as="select"
             v-model="store.groupTimes[archer.id]"
             :name="`${archer.name} (Archer ID:${archer.id}) Time`"
             class="bs-input-box flex-2">
        <option v-for="time of store.times"
                :value="time.id"
                :selected="store.groupTimes[archer.id] === time.id">
          {{ time.time }}
        </option>
      </Field>
    </div>
  </div>
</template>

<style scoped>

</style>