<template>
  <div class="text-lg flex flex-col justify-center">
    <image-upload
      :title="'Optional Target Image (200x200 .jpg)'"
      :option="'target'"
      :type="'target'"
      :height="200"
      :width="200"
      :multiplier="1"
      :file_id="fileId"
      :inc_url="incomingUrl"
      @update_image="updateImage(roundItem.id, end, competitorId, $event)"
      @remove_image="removeImage(roundItem.id, end, competitorId, $event)">
      <template v-slot:open-modal>
        <span v-if="hasImage">
          <img alt="camera icon" :src="imageSource" width="50" height="50">
        </span>
        <span v-else class="text-gray-600 cursor-pointer text-4xl"><i class="fas fa-camera"></i></span>
      </template>
    </image-upload>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import ImageUpload from "../../partials/ImageUpload.vue";
import {mapState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "ScoreImage",
  components: {ImageUpload},
  props: ['competitorId'],
  mixins: [mixin],
  computed: {
    roundItem() {
      return this.ladder == null ? this.round : this.ladder;
    },
    ...mapState(useDisplayStore, ['end', 'ladder']),
    imageList() {
      /** @namespace this.tournament.scoreImages **/
      return this.tournament.scoreImages;
    },
    incomingUrl() {
      if (this.hasImage) {
        return this.imageList[this.competitorId][this.roundItem.id][this.end]['url'];
      }
      return null;
    },
    imageSource() {
      //Don't === this line.
      if (this.imageList[this.competitorId][this.roundItem.id][this.end]['enabled'] == true) {
        return this.imageList[this.competitorId][this.roundItem.id][this.end]['url']
      }
      return '/img/imagePending.jpg';
    },
    hasImage() {
      if (this.imageList) {
        if (this.imageList[this.competitorId]) {
          if (this.imageList[this.competitorId][this.roundItem.id]) {
            if (this.imageList[this.competitorId][this.roundItem.id][this.end]) {
              return true;
            }
          }
        }
      }
      return false;
    },
    fileId() {
      return `${this.competitorId}__${this.tournament.id}__${this.roundItem.id}__${this.end}`;
    }
  },
  methods: {
    updateImage(round, end, competitor, details) {
      const [url, enabled, _] = details;
      if (this.imageList[competitor] === undefined) {
        this.imageList[competitor] = [];
      }
      if (this.imageList[competitor][round] === undefined) {
        this.imageList[competitor][round] = [];
      }
      if (this.imageList[competitor][round][end] === undefined) {
        this.imageList[competitor][round][end] = {};
      }
      this.imageList[competitor][round][end]['url'] = url;
      this.imageList[competitor][round][end]['enabled'] = enabled;
    },
    removeImage(round, end, competitor) {
      let slug = `${competitor}__${this.tournament.id}__${round}__${end}`
      this.$axios({
        method: 'post',
        url: '/image/target/delete/' + slug,
      }).then(() => {
        delete this.imageList[competitor][round][end];
      })
    },
  }
}
</script>

<style scoped>

</style>
