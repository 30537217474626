<script setup>

import {useTournamentStore} from "../../stores/TournamentStore";
import {useStore} from "vuex";
import {computed} from "vue";


const store = useTournamentStore();

const props = defineProps(['icon', 'label', 'link']);

const vuexStore = useStore();

const selectedTournament = computed(() => {
  return vuexStore.getters['tournament/getTournament'];
})

const active = computed(() => {
  if (selectedTournament.value != null) return false;
  return store.activeLink === props.link;
})
</script>

<template>
  <a :href="`/${link}`" class="flex navigationLink"
     :class="active ? 'activeLink' : ''">
    <div class="navigationIcon flex justify-center"><i :class="icon"></i></div>
    <div class="pl-2">{{ label }}</div>
  </a>
</template>

<style scoped>
.navigationLink:hover {
  @apply text-black;
}

.navigationLink {
  padding-left: 19px;
  padding-right: 20px;
  font-size: 16px;
  letter-spacing: .32px;
  line-height: 19px;
  height: 36px;
  max-width: 280px;
}

@media (min-width: 1100px) {
  .navigationLink {
    @apply py-2 mb-2;
    padding-left: 61px;
  }
}

.navigationIcon {
  width: 26px;
}

.activeLink {
  @apply bg-white text-black font-extrabold;
}
</style>