<template>
  <div class="card card-blue-700 mb-3">
    <div class="card-header">
      Club Breakdown {{ breakdown === null ? '' : ` (${breakdown.length})` }}
    </div>
    <div class="card-body">
      <div v-if="breakdown !== null">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Club</th>
              <th class="table-header">Email</th>
              <th class="table-header">Admin</th>
              <th class="table-header">Active</th>
              <th class="table-header">Upcoming</th>
              <th class="table-header">Ended</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="data in breakdown">
              <td><a :href="`/clubs/${data['Slug']}`">{{ data['Club'] }}</a></td>
              <td class="whitespace-nowrap overflow-hidden" style="max-width:175px;">{{ data['Email'] }}</td>
              <td>
                <span class="flex flex-col">
                  <span v-for="admin in data['Admin']">{{ admin }}</span>
                </span>
              </td>
              <td>{{ data['Active Count'] }}</td>
              <td class="whitespace-nowrap">{{ upcoming(data) }}</td>
              <td class="whitespace-nowrap">{{ end(data) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="flex justify-center">
        <div class="flex flex-col">
          <div>Loading Breakdown</div>
          <div class="flex justify-center">
            <i class="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "ClubBreakdown",
  props: [],
  data() {
    return {
      breakdown: null,
    }
  },
  methods: {
    end(breakdown) {
      let count = breakdown['Ended Count'];
      let string = `${count}`;
      if (count > 0) {
        let time = moment(breakdown['Ended Date']).format('MM/DD/YY');
        string += `(${time})`;
      }
      return string;
    },
    upcoming(breakdown) {
      let count = breakdown['Upcoming Count'];
      let string = `${count}`;
      if (count > 0) {
        let time = moment(breakdown['Upcoming Date']).format('MM/DD/YY');
        string += `(${time})`;
      }
      return string;
    }
  },
  created() {
    this.$axios.get('/godmode/club_breakdown')
    .then(({data}) => {
      if (data) {
        this.breakdown = data.data;
      }
    });
  }
}
</script>

<style scoped>

</style>
