<template>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
      <tr v-if="selectedRound">
        <th class="table-header text-left">Name</th>
        <th class="table-header "> {{ rounds.length > 1 ? 'Round' : 'Total' }} Score</th>
        <th v-for="breaker in tieBreakers" class="table-header"> {{ breakerHeader(breaker) }}</th>
        <th class="table-header" v-for="end in selectedRound.ends_count">{{ endHeader(end) }}</th>
      </tr>
      <tr v-else>
        <th class="table-header text-left">Name</th>
        <th v-for="option in Object.keys(breakdownArray)" class="table-header">
          {{ `${option[0].toUpperCase()}${option.slice(1)}` }}
        </th>
        <th class="table-header">Total Score</th>
        <th v-for="breaker in tieBreakers" class="table-header">Total {{ breaker }}'s</th>
        <th v-if="useHandicap" class="table-header">Handicap</th>
        <th class="table-header " v-if="rounds.length > 1" v-for="round in rounds">{{ round.name }}</th>
      </tr>
      </thead>
      <tbody>
      <competitor-score
        v-for="(competitor, index) in breakdownCompetitors"
        :competitor="competitors[competitor]"
        :scores="tournamentIn.scoreList[competitor]"
        :round="selectedRound ? selectedRound : {}"
        :place="index + 1"
        :key="(selectedRound ? selectedRound.id : 'overall')+'breakdown_' + competitor"
        :filter_list="Object.keys(breakdownArray)"
        :breakdown="!selectedRound"
        :images="tournamentIn.scoreImages[competitor]"
        :duplicates="store.competitorDuplicates(competitor)"/>
      <tr v-if="!breakdownCompetitors.length" class="text-center">
        <td colspan="120" class="table-empty ">No matching results</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import CompetitorScore from "./CompetitorScore.vue";
import {useResultsStore} from "../../../stores/ResultsStore";
import {mapState} from "pinia";

export default {
  name: "BreakdownResultsTable",
  components: {CompetitorScore},
  props: ['breakdownCompetitors', 'competitors', 'tournamentIn'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, [
      'breakdownArray',
      'rounds',
      'tieBreakers',
      'useHandicap',
      'selectedRound'
    ]),
  },
  methods: {
    breakerHeader(breaker) {
      let text = this.rounds.length > 1 ? 'Round' : 'Total';
      return `${text} ${breaker}'s`;
    },
    endHeader(end) {
      let endText = this.$trans.choice('search.end', 1);
      let text = `${endText} ${end}`;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
  }
  
}
</script>
<style scoped>

</style>