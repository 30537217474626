<script setup>
const navigateTo = ((site) => {
  let fbLink = 'https://facebook.com/bowscore';
  let instaLink = 'https://instagram.com/bowscore';
  window.open(site === 'facebook' ? fbLink : instaLink);
})
</script>

<template>
  <div class="mx-4 flex flex-col justify-between h-full ">
    <div class="flex flex-col centered">
      <div class="centered lg:hidden">
        <img class="logo" src="/img/Bowscore Logo.svg" alt="Bowscore logo">
      </div>
      <img class="hidden lg:block logo" src="/img/Bowscore Logo.svg" alt="Bowscore logo">
      <div class="title">We're here to help.</div>
      <div class="flex">
        <img src="/img/bowscore_customer_support2x.png" width="148" alt="customer support image" class="pic"/>
        <div class="flex flex-col justify-center">
          <div class="header">Email us</div>
          <div class="value"><a href="mailto:support@bowscore.com">support@bowscore.com</a></div>
        </div>
      </div>
      <div class="text">Your tournament success is important to us. Contact us with any questions and we’ll get you an
        answer within the hour (during business hours).
      </div>
      <div class="header">Connect with us on social</div>
      <div class="flex mt-4">
        <img @click="navigateTo('facebook')" src="/img/Facebook_Logo_Primary.png" width="48" class="linkButton">
        <img @click="navigateTo('instagram')" src="/img/IG_logo.png" width="48" class="linkButton">
      </div>
    </div>
    <div class="footer w-full centered">
      <i class="fa-regular fa-sm fa-copyright"></i> Scoring Tech 2024
    </div>
  </div>

</template>

<style scoped>
.footer {
  @apply text-stone-400 font-semibold;
  letter-spacing: .02rem;
  margin-bottom: 40px;
}

.linkButton {
  @apply mr-8;
  cursor: pointer;
}

.pic {
  @apply mr-12;
  border-radius: 6px;
}

.title {
  @apply text-stone-600 font-extrabold;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 56px;
}

.header {
  @apply text-stone-600 font-bold;
  font-size: 32px;
  line-height: 38px;
}

.value {
  @apply font-semibold;
  color: #FB5202;
  font-size: 32px;
  line-height: 38px;
}

.text {
  @apply text-stone-600 my-14;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 32px;
}

.centered {
  @apply mx-auto;
  max-width: 600px;
  min-width: 200px;
  
}

.logo {
  @apply my-16;
  width: 240px;
}

@media (max-width: 1100px) {
  .value {
    font-size: 18px;
    line-height: 22px;
  }
  
  .footer {
    margin-bottom: 16px;
  }
  
  .logo {
    @apply mx-auto mb-8;
    width: 120px;
    margin-top: 26px;
  }
  
  .linkButton {
    @apply mr-6;
    width: 24px;
    
  }
  
  .header {
    font-size: 16px;
    line-height: 19px;
  }
  
  .text {
    margin-top: 24px;
    font-size: 18px;
    line-height: 24px;
  }
  
  .pic {
    @apply mr-6;
    width: 88px;
  }
  
  .title {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 24px;
  }
}
</style>