<script setup>

import SideNavigation from "../navigation/SideNavigation.vue";
import SimpleFooter from "./SimpleFooter.vue";

const props = defineProps({
  user: Object,
})

</script>

<template>
  <div class="flex flex-col h-full">
    <div class="block lg:flex flex-grow">
      <SideNavigation :user="props.user" class="hidden lg:flex flex-1 left"/>
      <div class="middle flex justify-center">
        <div class="main flex flex-col h-full min-w-0 w-full">
          <div class="block lg:flex flex-grow justify-center">
            <slot class="flex-grow w-full"/>
          </div>
        </div>
      </div>
    </div>
    <simple-footer/>
    <!--    <div class="flex">-->
    <!--      <div class="hidden lg:flex left">&nbsp;</div>-->
    <!--      <div class="middle">-->
    <!--        <simple-footer class="flex-shrink"/>-->
    <!--      </div>-->
    <!--      <div class="hidden lg:flex right">&nbsp;</div>-->
    <!--    </div>-->
  
  </div>

</template>

<style scoped>
.main {
  width: 100%;
}

.left {
  max-width: 280px;
  width: 280px;
  min-width: 280px;
}

.middle {
  @apply flex-grow;
}

.right {
  max-width: 280px;
  width: 280px;
  min-width: 280px;
}

@media (min-width: 1100px) {
  .main {
    max-width: 820px;
    width: 820px;
  }
}

@media (min-width: 1380px) {
  .main {
    max-width: 1080px;
    width: 1080px;
  }
}

@media (min-width: 1800px) {
  .main {
    max-width: 1480px;
    width: 1480px;
  }
}


</style>