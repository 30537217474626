<template>
  <div class="max-w-md m-2 bg-gray-100 text-blue-700">
    <a href="https://bowscore.com" target="_blank">
      <img style="width:100%" src="/img/Orange_Bowscore_Logo.png" alt="Bowscore">
    </a>
    <div class="text-center text-2xl">View the live results!</div>
    <div class="flex flex-col">
      <div class="bs-form-box flex">
        <span><i class="fas fa-search"></i></span>
        <input type="text" class="flex-grow pl-2" id="search" :placeholder="'Search by tournament name'"
               v-model="search">
        <span v-if="search.length" @click="search = ''" class="cursor-pointer"><i
          class="fas fa-times"></i></span>
      </div>
      <div class="p-2">
        <div class="text-gray-600 border-b border-gray-300">
          Select a Tournament or search for one not shown.
        </div>
        <div class="border-b border-gray-300 py-2 cursor-pointer" v-for="tournament in tournaments">
          <a :href="`/tournaments/${tournament.slug}`" target="_blank">
            <div class="uppercase font-bold">{{ tournament.name }}</div>
            <div class="font-bold">{{ formattedDate(tournament) }}</div>
          </a>
        </div>
        
        <div v-if="!tournaments.length">
          No tournaments match the filter
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SportWidget",
  props: ['inc_tournaments'],
  data() {
    return {
      tournaments: this.inc_tournaments,
      search: '',
    }
  },
  watch: {
    search(newVal) {
      if (newVal.length > 2) {
        this.$axios.get('widget/search', {params: {search: newVal}}).then(({data}) => {
          this.tournaments = data.tournaments;
        })
      }
      if (!newVal.length) {
        this.tournaments = this.inc_tournaments;
      }
    }
  },
  methods: {
    formattedDate(tournament) {
      let start = moment(tournament.start_time);
      let end = moment(tournament.end_time);
      let dateString = '';
      if (end.year() !== start.year()) {
        dateString += start.format('MMM D, YYYY - ');
      } else if (start.day() !== end.day()) {
        dateString += start.format('MMM D-');
      } else {
        dateString += start.format('MMM D');
      }
      if (end.month() !== start.month()) {
        dateString += end.format('MMM D, YYYY');
      } else if (start.day() !== end.day()) {
        dateString += end.format('D, YYYY');
      } else {
        dateString += end.format(', YYYY');
      }
      return dateString;
    }
  }
}
</script>

<style scoped>
#search:focus {
  outline: none;
}
</style>
