<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Rounds</div>
    <div class="bs-section-header">Number of rounds</div>
    <div class="flex flex-wrap">
      <div v-for="int of [...Array(12)].keys().map(x => ++x)" class="mr-2 mb-2">
        <button @click.prevent="store.editRoundCount(int)" class="bs-input-box"
                :class="store.rounds.length === int ? 'bg-black text-white': ''">{{ int }}
        </button>
      </div>
      <button class="linkText" @click.prevent="store.navigateRounds()">
        <i class="fa-regular fa-lg fa-bullseye"></i><span class="ml-1">Edit the date, ends, and arrows for each
        round</span>
      </button>
    </div>
  
  </div>
</template>

<style scoped>
.linkText {
  @apply text-black font-bold mt-8;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .018rem;
}
</style>