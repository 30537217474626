<template>
  <div>
    <div class="p-2 w-full flex justify-between md:justify-around">
      <button @click="changeModel('tournaments')" class="btn btn-outline-blue-600 flex-1 mx-1"
              :class="model === 'tournaments' ? 'active' : ''">Tournaments
      </button>
      <button @click="changeModel('clubs')" class="btn btn-outline-blue-600 flex-1 mx-1"
              :class="model === 'clubs' ? 'active' : ''">Clubs
      </button>
      <button @click="changeModel('competitors')" class="btn btn-outline-blue-600 flex-1 mx-1"
              :class="model === 'competitors' ? 'active' : ''">Competitors
      </button>
      <button @click="changeModel('profiles')" class="btn btn-outline-blue-600 flex-1 mx-1"
              :class="model === 'profiles' ? 'active' : ''">Profiles
      </button>
      <button @click="changeModel('teams')" class="btn btn-outline-blue-600 flex-1 mx-1"
              :class="model === 'teams' ? 'active' : ''">Teams
      </button>
    </div>
    <div class="bs-form-box p-0 my-3 flex rounded-lg w-full md:w-1/2 mx-auto">
      <div class="h-center text-gray-600 p-2 mr-2">
        <i class="fa-2x fas fa-search"></i>
      </div>
      <input class="flex-grow text-xl sm:text-3xl focus:outline-none" :placeholder="' Search ' + model"
             v-model="search">
      <button v-if="search.length" @click="clearSearch"
              class="h-center text-gray-600 p-2 mr-2">
        <i class="fa-2x fas fa-times"></i>
      </button>
    </div>
    
    
    <div v-if="Object.keys(resultList).length" class="w-full sm:w-1/2 mx-auto">
      <div v-for="result in resultList" class="mx-3">
        <a v-if="model === 'tournaments'" class="text-blue-600 flex justify-between"
           :href="'/tournaments/' + result.slug">
          <div>{{ result.name }}</div>
          <div>{{ result.address.city }}, {{ result.address.state }}</div>
        </a>
        <a v-if="model === 'clubs'" class="text-blue-600 flex justify-between"
           :href="'/clubs/' + result.slug">
          <div>{{ result.name }}</div>
          <div v-if="result.range_address">{{ result.range_address.city }}, {{ result.range_address.state }}
          </div>
          <div v-else-if="result.mailing_address">{{ result.mailing_address.city }},
            {{ result.mailing_address.state }}
          </div>
        </a>
        <a v-if="model === 'competitors'" class="text-blue-600 flex justify-between"
           :href="'/tournaments/' + result.tournament.slug + '#/results'">
          <div>{{ result.name }}</div>
          <div>{{ result.tournament.name }}</div>
        </a>
        <a v-if="model === 'profiles' && result.slug" class="text-blue-600"
           :href="'/archers/' + result.slug">
          <div>{{ result.full_name }}</div>
        </a>
        <a v-if="model === 'teams'" class="text-blue-600 flex justify-between"
           :href="'/tournaments/' + result.tournament.slug + '#/results?team=' + encodeURI(result.team)">
          <div>{{ result.name }}</div>
          <div>{{ result.team }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FullSearch",
  props: ['inc_model', 'inc_results'],
  data() {
    return {
      search: '',
      resultList: this.inc_results,
      model: this.inc_model,
    }
  },
  watch: {
    search(text) {
      if (!text.length) {
        this.resultList = [];
      } else if (text.length > 2 || Object.keys(this.resultList).length) {
        this.submitSearch();
      }
    },
    resultList(newList, oldList) {
    
    }
  },
  methods: {
    changeModel(model) {
      this.model = model;
      this.resultList = [];
      this.submitSearch();
    },
    clearSearch() {
      this.search = '';
      this.resultList = [];
    },
    submitSearch() {
      this.$axios
      .get('/search/' + this.model, {
        params: {
          'search': this.search,
          'update': true,
        }
      })
      .then(({data}) => {
        this.resultList = data.results;
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    let searchValue = urlParams.get('search');
    if (searchValue) {
      this.search = searchValue;
    }
  }
}
</script>

<style scoped>

</style>