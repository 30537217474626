<template>
  <div v-if="options.length > 1" class="w-full md:w-1/2 p-2">
    <div class="text-2xl mb-3">Allowed {{ capitalizedHeader }}: {{ selectedString }}</div>
    <VueMultiselect
      @update:modelValue="setUpdate"
      :modelValue="allowed"
      :options="options"
      :searchable="true"
      :hideSelected="true"
      :multiple="true"
      :showLabels="label != null"
      :label="label"
      :track-by="trackBy"
      :placeholder="`Select ${capitalizedHeader}`"/>
    <span>
      <small class="text-black-50">
        Leaving this selection empty will allow all {{ capitalizedHeader }} to be scored
      </small>
    </span>
  </div>
</template>

<script>
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "LockType",
  props: ['header', 'allowed', 'options', 'label', 'trackBy', 'mutation', 'type'],
  computed: {
    ...mapWritableState(useDisplayStore, [
      'localBales',
      'localCompetitors',
      'localLocations',
      'localRounds',
      'localTimes',
      'localLadders',
    ]),
    selectedString() {
      if (this.allowed != null && this.allowed.length) {
        let list = [];
        if (this.label === 'time') {
          list = this.allowed.map(f => f.time);
        } else if (this.label === 'name') {
          list = this.allowed.map(f => f.name);
        } else if (this.label === 'id') {
          list = this.allowed.map(f => f.id);
        } else {
          list = this.allowed;
        }
        return list.join(', ')
      }
      return 'No Restriction'
    },
    capitalizedHeader() {
      let value = this.header.toString();
      let splitValue = value.split(' ');
      let altered = splitValue.map(w => w.charAt(0).toUpperCase() + w.slice(1));
      return altered.join(' ');
    },
  },
  
  methods: {
    setUpdate(value) {
      this[`local${this.type}`] = value;
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>
