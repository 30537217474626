<template>
  <div v-if="tournament" class="flex-grow overflow-x-hidden m-1 sm:m-2 md:p-5">
    <Form>
      <div class="bg-blue-200 text-blue-600 p-2 flex flex-col md:flex-row justify-between rounded">
        <div class="hidden w-1/6 md:flex flex-col justify-center text-center">
          <div class="flex justify-center"><i class="fas fa-bow-arrow fa-3x"></i></div>
        </div>
        <div class="flex flex-col flex-grow text-center mx-4">
          <div class="text-3xl">Practice Tournament</div>
          <div>
            These tournaments are intended for casual use.
            They can be a great tool to help learn the scoring interface.
            If you want something more than just a casual tournament between buddies,
            find a tournament <a href="/tournaments" class="text-blue-500">here</a>.
          </div>
        </div>
        <div class=" md:w-1/6 text-center flex flex-col justify-center">
          <div class="flex justify-center">
            <div>
              <div class="text-lg border-b border-solid border-blue-700 px-6">Expires</div>
              <div>1 week after created</div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-2xl mt-3">Tournament Setup</div>
      <hr class="">
      <div class="flex flex-wrap">
        <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-2 mb-4">
          <label class="bs-box-label" for="name">Name</label>
          <Field class="bs-form-box h-10 w-full" name="name" placeholder="Weekend Practice Tournament"
                 v-model="tournament.name" rules="required|clean"/>
          <span class="hintText">Name this tournament, or just put a simple description.</span>
          <ErrorMessage class="errorText" name="name"/>
        </div>
        <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-2 mb-4">
          <label class="bs-box-label" for="sport_id">Sport</label>
          <Field as="select" @change="handleExisting" name="sport_id" class="bs-form-box h-10 w-full"
                 v-model="tournament.sport_id" rules="required">
            <option v-for="sport in sports" :selected="tournament && tournament.sport_id === sport.id"
                    name="sport_id" :value="parseInt(sport.id)">{{ sport.name }}
            </option>
          </Field>
          <span class="hintText">Select your sport.</span>
          <ErrorMessage class="errorText" name="sport_id"/>
        </div>
        <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-2 mb-4">
          <label class="bs-box-label" for="target">Available Targets</label>
          <VueMultiselect
            v-model="tournament.targets"
            :options="sportTargets"
            :searchable="true"
            :show-labels="false"
            :hideSelected="true"
            :multiple="true"
            :placeholder="'Select Targets'"
            track-by="id"
            label="name"
            name="available_targets"
            class="multiselect_blue"/>
          <span class="text-sm text-gray-600">Pick all of the targets you will use this tournament.</span>
        </div>
        <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-2">
          <label class="bs-box-label" for="target_style">Stakes or Bales?</label>
          <div class="flex flex-col lg:flex-row">
            <label class="container">Stakes
              <input @change="updateStyle('Stakes')" type="radio" value="Stakes" v-model="tournament.target_style">
            </label>
            <label class="container">Bales
              <input @change="updateStyle('Bales')" type="radio" value="Bales" v-model="tournament.target_style">
            </label>
          </div>
        </div>
        <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-2">
          <label class="bs-box-label" for="bale_competitors">Participants per {{ baleWord }}</label>
          <Field type="number" min="1" max="6" name="bale_competitors" class="bs-form-box"
                 rules="required|min_value:1|max_value:6" required
                 v-model="tournament.bale_competitors"/>
        </div>
        <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-2">
          <label class="bs-box-label" :for="'round_'+0+'_ends'"># of {{ endsWord }}</label>
          <Field class="bs-form-box" type="number" max="20" min="1"
                 v-model="tournament.rounds[0].ends_count"
                 rules="required|min_value:1|max_value:20" :name="'round_'+0+'_ends'"/>
        </div>
        <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-2">
          <label class="bs-box-label" :for="'round_'+0+'_arrows'"># of Arrows</label>
          <Field class="bs-form-box" type="number" max="10" min="1"
                 :name="'round_'+0+'_arrows'"
                 rules="required|min_value:1|max_value:10"
                 v-model="tournament.rounds[0].arrows_count"/>
        </div>
      </div>
      
      <div v-if="tournament.name && tournament.targets.length">
        <div class="text-2xl">Add Competitors</div>
        <hr class="my-0">
        
        <div class="mt-3 border border-gray-400 rounded p-2">
          
          <div class="w-full flex flex-col">
            <div class="w-full hidden lg:flex mb-0">
              <div class="flex w-full flex-wrap flex-grow">
                <div class="flex flex-col w-full md:w-3/12 mt-2 md:my-0 px-2">
                  <label class="bs-box-label" for="name">Name</label>
                </div>
                <div class="flex flex-col w-full md:w-2/12 mt-2 md:my-0 px-2">
                  <label class="bs-box-label" for="target">Target</label>
                </div>
                <div class="flex flex-col w-full md:w-1/12 mt-2 md:my-0 px-2">
                  <label class="bs-box-label" for="gender">Gender</label>
                </div>
                <div class="flex flex-col w-full md:w-1/4 mt-2 md:my-0 px-2">
                  <label class="bs-box-label" for="division">Division</label>
                </div>
                <div class="flex flex-col w-full md:w-1/4 mt-2 md:my-0 px-2">
                  <label class="bs-box-label" for="class">Class</label>
                </div>
              </div>
              <div class="w-8"></div>
            </div>
            <div class="mb-3 ">
              <div class="w-full flex flex-col lg:flex-row">
                <div class="flex w-full flex-wrap flex-grow">
                  <div class="flex flex-col w-full sm:w-6/12 lg:w-3/12  md:mt-0 px-2">
                    <label class="lg:hidden bs-box-label" for="competitor">Name</label>
                    <Field type="input" name="competitor" class="bs-form-box"
                           rules="required|clean"
                           v-model="competitor"/>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/4 lg:w-2/12 md:mt-0 px-2">
                    <label class="lg:hidden bs-box-label" for="target_id">Target</label>
                    <select name="target_id" class="bs-form-box  w-full" v-model="target_id">
                      <option v-for="target in tournament.targets" :value="target.id">{{ target.name }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/4 lg:w-1/12  md:mt-0 px-2">
                    <label class="lg:hidden bs-box-label" for="gender">Gender</label>
                    <select name="gender" class="bs-form-box  w-full" v-model="gender">
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 md:mt-0 px-2">
                    <label class="lg:hidden bs-box-label" for="division">Division</label>
                    <select name="division" class="bs-form-box  w-full" v-model="division">
                      <option>None</option>
                      <option v-for="(div, index) in sportDivisions">{{ div }}</option>
                    </select>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 md:mt-0 px-2">
                    <label class="lg:hidden bs-box-label" for="selectedClass">Class</label>
                    <select name="selectedClass" class="bs-form-box  w-full" v-model="selectedClass">
                      <option>None</option>
                      <option v-for="(selectedClass, index) in sportClasses">{{ selectedClass }}</option>
                    </select>
                  </div>
                </div>
                <div class="xs:p-1 lg:p-0" id="competitor">
                  <button v-if="competitor?.length && target_id != null" @click="addParticipant"
                          class="btn btn-outline-green-500 h-full h-center">
                    <span class="lg:hidden flex pr-1">Add Archer </span><i
                    class="fas fa-plus  xs:ml-1 xs:mt-1 lg:m-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-2" v-for="(competitor, pIndex) in tournament.competitors">
          <div class="w-full flex flex-col lg:flex-row">
            <div class="flex w-full flex-wrap flex-grow">
              <div class="flex flex-col w-full sm:w-6/12 lg:w-3/12 mt-2 md:mt-0 px-2">
                <Field type="input" class="bs-form-box"
                       rules="required|clean"
                       :name="`${pIndex}_name`"
                       v-model="tournament.competitors[pIndex].name"/>
                <ErrorMessage class="errorText" :name="`${pIndex}_name`"/>
              </div>
              <div class="flex flex-col w-full sm:w-1/4 lg:w-2/12 mt-2 md:mt-0 px-2">
                <select :name="pIndex + '_target'" class="bs-form-box dropdown w-full"
                        v-model="tournament.competitors[pIndex].target_id">
                  <option v-for="target in tournament.targets" :value="target.id">{{ target.name }}</option>
                </select>
              </div>
              <div class="flex flex-col w-full sm:w-1/4 lg:w-1/12 mt-2 md:mt-0 px-2">
                <select class="bs-form-box  w-full" v-model="tournament.competitors[pIndex].gender"
                        :name="pIndex + '_gender'">
                  <option>Male</option>
                  <option>Female</option>
                </select>
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 mt-2 md:mt-0 px-2">
                <select class="bs-form-box  w-full" v-model="tournament.competitors[pIndex].division"
                        :name="pIndex + '_division'">
                  <option>None</option>
                  <option v-for="(div, index) in sportDivisions">{{ div }}</option>
                </select>
              </div>
              <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 mt-2 md:mt-0 px-2">
                <select class="bs-form-box  w-full" v-model="tournament.competitors[pIndex].class"
                        :name="pIndex + '_class'">
                  <option>None</option>
                  <option v-for="(selectedClass, index) in sportClasses">{{ selectedClass }}</option>
                </select>
              </div>
            </div>
            <div class="xs:p-1 lg:p-0">
              <button @click="removeParticipant(pIndex)" class="btn btn-outline-gray-600 h-full h-center">
                <span class="lg:hidden flex pr-1">Delete {{ tournament.competitors[pIndex].name }} </span>
                <i class="fas fa-trash-alt xs:ml-1 xs:mt-1 lg:m-0"></i>
              </button>
            </div>
          </div>
        </div>
        
        <div v-if="tournament.competitors.length" class="text-center m-2">
          <button @click="createMatch" class="btn btn-blue-600">
            {{ tournament.id ? 'Update' : 'Create' }}
            Practice Tournament <span v-show="loading"><i class="fas fa-spin fa-spinner"></i></span></button>
        </div>
      </div>
    
    </Form>
  </div>
</template>

<script>

import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import {Form, ErrorMessage, Field} from "vee-validate";
import Swal from "sweetalert2";

export default {
  name: "PracticeMatch",
  components: {Form, Field, ErrorMessage, VueMultiselect},
  props: ['inc_tournament', 'sports'],
  data() {
    return {
      competitor: '',
      division: '',
      selectedClass: '',
      gender: '',
      target_id: null,
      sport: null,
      loading: false,
      tournament: this.inc_tournament,
      trans: this.$trans,
    }
  },
  computed: {
    sportClasses() {
      return this.sports.find(f => f.id === this.tournament.sport_id).Class
    },
    sportDivisions() {
      return this.sports.find(f => f.id === this.tournament.sport_id).Division
    },
    sportTargets() {
      return this.sports.find(f => f.id === this.tournament.sport_id).targets
    },
    baleWord() {
      let word = this.trans.choice('search.bale', 1);
      return this.capitalize(word);
    },
    endsWord() {
      let word = this.trans.choice('search.end', 2);
      return this.capitalize(word);
    },
  },
  
  methods: {
    
    capitalize(text) {
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    updateStyle(type) {
      let default_locale = type === 'Bales' ? 'bow_bale' : 'bow_stake';
      this.trans.locale = default_locale;
    },
    addParticipant() {
      let vm = this;
      let competitor = {
        name: vm.competitor,
        target_id: vm.target_id,
        gender: vm.gender,
        division: vm.division,
        class: vm.selectedClass
      };
      vm.tournament.competitors.unshift(competitor);
      vm.competitor = '';
      let container = vm.$el.querySelector("#competitor");
      container.scrollIntoView({behavior: "smooth"});
      
    },
    removeParticipant(index) {
      delete this.tournament.competitors[index];
    },
    handleExisting(change) {
      this.sport = change.target.value;
      let sport = this.sports.find(f => f.id === parseInt(change.target.value));
      this.tournament.targets = this.tournament.targets.filter(function (target) {
        let exists = false;
        sport.targets.forEach(function (sportTarget) {
          if (sportTarget.id === target.id) {
            exists = true;
          }
        });
        return exists;
      });
    },
    submitCall() {
      this.$axios
      .post('/practice/', {tournament: this.tournament})
      .then(({data}) => {
        if (data.success) {
          window.location = '/practice/' + data.slug + '#/practice/score';
        } else {
          this.loading = false;
        }
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    createMatch() {
      let vm = this;
      vm.loading = true;
      if (vm.competitor?.length && vm.target_id != null) {
        Swal.fire({
          titleText: 'Would you like to add the competitor ' + vm.competitor + ' to the tournament?',
          showCancelButton: true,
          cancelButtonText: "Create Without " + vm.competitor,
          confirmButtonText: 'Yes',
          reverseButtons: true,
          showCloseButton: true,
        }).then(value => {
          if (value.dismiss) {
            if (value.dismiss === 'cancel') {
              vm.submitCall();
            } else {
              vm.loading = false;
              return false;
            }
          } else {
            let competitor = {
              name: vm.competitor,
              target_id: vm.target_id,
              gender: vm.gender,
              division: vm.division,
              class: vm.selectedClass
            };
            vm.tournament.competitors.unshift(competitor);
            vm.competitor = '';
            vm.submitCall();
          }
        })
      } else {
        vm.submitCall();
      }
    },
  },
  mounted() {
    if (this.inc_tournament.target_style) {
      let default_locale = this.inc_tournament.target_style === 'Bales' ? 'bow_bale' : 'bow_stake';
      this.trans.locale = default_locale;
    }
  }
}
</script>

<style scoped>

</style>
