<template>
  <tr>
    <td>{{ competitorName }}</td>
    <td v-for="count in ladder.start_ladder - 1">{{ cellValue(count - 1) }}</td>
  </tr>
</template>
<script>
export default {
  name: "CompetitorShootOutRow",
  props: ['tournamentIn', 'competitorIndex', 'ladder', 'orderedCompetitors'],
  computed: {
    competitorId() {
      return this.orderedCompetitors[this.competitorIndex];
    },
    competitorName() {
      if (this.competitorId == null) return '';
      return this.tournamentIn.competitorList[this.competitorId].name;
    },
    scores() {
      if (this.competitorId == null) return null;
      return this.tournamentIn.scoreList[this.competitorId].ladders;
    },
  },
  methods: {
    cellValue(step) {
      let index = `${this.ladder.id}_${step}`;
      if (this.scores != null && this.scores[index] != null && this.scores[index].complete > 0) {
        let score = this.scores[index].points;
        if (this.scores[index]['X'] > 0) {
          score += `-${this.scores[index]['X']}x`;
        }
        return score;
      }
      return '';
    }
  }
}
</script>
<style scoped>

</style>