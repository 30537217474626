<template>
  <div>
    <div v-for="(competitorId,position) in bale" class="flex-col m-3" :ref="'score_block_' + competitorId"
         :class="competitor ? (competitor.id === competitorId ? 'flex my-40 ' : 'hidden') : 'flex'">
      <div class="flex flex-col" v-if="competitorId">
        <competitor-header
          :competitor-id="competitorId"
          :bale-number="baleNumber"
          :position="position"/>
        <arrow-selection
          @openScore="openScore"
          :competitor-id="competitorId"
          :bale-number="baleNumber"
          :position="position"/>
        <score-grid
          @openScore="openScore"
          @closeScore="closeScore"
          @forceUpdate="$emit('forceUpdate')"
          :competitor-id="competitorId"
          :bale-number="baleNumber"/>
        <div class="flex mb-8" style="height:46px;">
          <score-accept
            :competitor-id="competitorId"
            :bale-number="baleNumber"
            :accept-type="'deny'"
            :styling="'mr-3'"/>
          <score-accept
            :competitor-id="competitorId"
            :bale-number="baleNumber"
            :accept-type="'accept'"
            :styling="'ml-3'"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import ScoreImage from "./ScoreImage.vue";
import CompetitorHeader from "./CompetitorHeader.vue";
import ArrowSelection from "./ArrowSelection.vue";
import ScoreGrid from "./ScoreGrid.vue";
import ScoreAccept from "./ScoreAccept.vue";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";


export default {
  name: "ScorePanel",
  props: ['bale', 'baleNumber'],
  components: {
    ScoreAccept,
    ScoreGrid,
    ArrowSelection,
    CompetitorHeader,
    ScoreImage,
  },
  mixins: [mixin],
  computed: {
    ...mapWritableState(useDisplayStore, ['target', 'competitor']),
  },
  methods: {
    
    openScore(competitor, arrow) {
      if (this.competitor && this.competitor.id === competitor && this.arrow === arrow) {
        this.closeScore();
      } else {
        let newCompetitor = this.tournament.competitorList[competitor];
        this.store.$patch({
          competitor: newCompetitor,
          arrow: arrow,
        })
        this.setTarget();
        this.scrollToScore(this.competitor.id);
      }
    },
    
    closeScore() {
      let id = this.competitor.id;
      this.store.$patch({
        competitor: null,
        arrow: null,
      })
      this.scrollToScore(id);
      if (!this.tournament.accept_button) {
        this.checkEndCompletion();
      }
      
    },
    scrollToScore(id) {
      let scrollTo = 'score_block_' + id;
      let container = this.$refs[scrollTo][0];
      this.$nextTick(() => {
        container.scrollIntoView();
        window.scrollBy(0, -55);
      });
    },
    
    setTarget() {
      let targetId = this.roundItem.target ? this.roundItem.target.id : (this.competitor ? this.competitor.target_id : this.tournament.targets[0].id);
      let target = this.tournament.targetValues.find(f => f.id === parseInt(targetId));
      if (!target) {
        target = this.tournament.targetValues.find(f => f.id === parseInt(this.tournament.targets[0].id))
      }
      this.target = target;
    },
  }
}
</script>

<style scoped>

</style>
