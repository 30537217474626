<template>
  <div class="w-full m-2">
    <div class="flex justify-between">
      <span class="text-3xl">Reshoot Equipment Failures</span>
      <router-link to="/score">
        <button class="btn btn-blue-600">Return to Scoring</button>
      </router-link>
    </div>
    <hr>
    <div v-if="tournament.reshoots.length">
      <div class="w-full sm:w-1/2 md:w-2/5 lg:w-1/3 xl:w-1/4 mx-auto mt-2 px-2 pb-4">
        <div>Filter by name</div>
        <div class="bs-form-box flex">
          <div>
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <input type="text" class="px-3 flex-grow focus:outline-none" v-model="search">
          <div v-if="search !== ''" @click="clearSearch">
            <span><i class="fas fa-times"></i></span>
          </div>
        </div>
      </div>
      <div v-for="(reshoot, index) in filteredCompetitors">
        <div class="text-blue-600 cursor-pointer"
             :class="scoring === index ? 'text-3xl text-center pt-4' : 'text-xl'"
             @click="scoring ===index ? scoring = null : scoring = index">
          {{ detailLabel(reshoot) }}
        </div>
        <div v-if="scoring === index" class="lg:w-3/4 mx-auto my-3 flex-grow justify-center">
          <div v-if="reshoot.target_id" class="flex flex-wrap p-3">
            <div class="w-1/3 p-0"
                 v-for="t in target_values[reshoot.target_id]">
              <div @click="updateScore(t, reshoot, index)"
                   class="scoreButton text-4xl xs:text-5xl sm:text-6xl md:text-7xl">
                {{ t.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="text-center">
        There are no scores needing a reshoot.
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "ScoreReshoots",
  props: ['tournamentIn', 'user'],
  data() {
    return {
      roundsList: {},
      target_values: {},
      scoring: null,
      search: '',
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    filteredCompetitors() {
      let competitors = null;
      if (this.search.length) {
        let search = this.search.toUpperCase();
        competitors = this.tournament.reshoots.filter((reshoot) => {
          let name = this.getCompetitor(reshoot.competitor_id).name.toUpperCase();
          return name.includes(search);
        });
      }
      return competitors ? competitors : this.tournament.reshoots;
    }
  },
  created() {
    this.setRoundsList();
    this.setTargetValues();
  },
  methods: {
    clearSearch() {
      this.search = '';
    },
    detailLabel(reshoot) {
      let competitor = this.getCompetitor(reshoot.competitor_id);
      let name = competitor != null ? competitor.name : 'Reshoot';
      return `${name} - Round: ${this.roundsList[reshoot.round]} End: ${reshoot.end} Arrow: ${reshoot.arrow}`
    },
    getCompetitor(competitor_id) {
      return this.tournament.competitorList[competitor_id];
    },
    getScore(competitor, round, end, arrow) {
    
    },
    setRoundsList() {
      this.tournament.rounds.forEach((round) => {
        this.roundsList[round.id] = round.name;
      })
    },
    setTargetValues() {
      this.tournament.targetValues.forEach((target) => {
        this.target_values[target.id] = target.values;
      })
    },
    updateScore(hit, reshoot, index) {
      Swal.fire({
        title: `Update Score to ${hit.value}?`,
        text: this.detailLabel(reshoot),
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
      }).then(({dismiss}) => {
        if (!dismiss) {
          let newScore = {
            tournament_id: this.tournament.id,
            competitor_id: reshoot.competitor_id,
            device: this.$store.getters['device/getDeviceId'],
            authorized: 1,
            uploader_id: this.user ? this.user.id : null,
            arrow: reshoot.arrow,
            target_id: reshoot.target_id,
            target_hit: hit.name,
            score: hit.value,
            round: reshoot.round,
            end: reshoot.end,
            created_at: moment().format(),
          };
          
          let score = newScore;
          let shortScore = {
            arrow: score['arrow'],
            target_hit: score['target_hit'],
            target_id: score['target_id'],
            score: score['score']
          };
          let oldScore = this.tournament.scoreList[score['competitor_id']]
            .rounds[score['round']]
            .ends[score['end']]
            .arrows[score['arrow']];
          let pointsChange = score['score'] ? score['score'] : 0;
          let completeChange = score['target_hit'] ? 1 : 0;
          
          pointsChange -= oldScore.score;
          completeChange -= oldScore.target_hit ? 1 : 0;
          
          this.tournament.tieBreakers.forEach(breaker => {
            let change = score['target_hit'] === breaker ? 1 : 0;
            change -= oldScore.target_hit === breaker ? 1 : 0;
            this.tournament.scoreList[score['competitor_id']][breaker] += change;
            this.tournament.scoreList[score['competitor_id']].rounds[score['round']][breaker] += change;
            this.tournament.scoreList[score['competitor_id']].rounds[score['round']].ends[score['end']][breaker] += change;
          });
          
          this.tournament.scoreList[score['competitor_id']].points += pointsChange;
          this.tournament.scoreList[score['competitor_id']].rounds[score['round']].points += pointsChange;
          this.tournament.scoreList[score['competitor_id']].rounds[score['round']].ends[score['end']].points += pointsChange;
          this.tournament.scoreList[score['competitor_id']].complete += completeChange;
          this.tournament.scoreList[score['competitor_id']].rounds[score['round']].complete += completeChange;
          this.tournament.scoreList[score['competitor_id']].rounds[score['round']].ends[score['end']].complete += completeChange;
          
          this.tournament.scoreList[score['competitor_id']]
            .rounds[score['round']]
            .ends[score['end']]
            .arrows[score['arrow']] = shortScore;
          
          this.tournament.reshoots.splice(index, 1);
          this.scoring = null;
          this.$store.dispatch('tournament/updateScore', newScore);
        }
      });
    },
  }
}
</script>

<style scoped>
.scoreButton.scoreSelected,
.scoreButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.scoreButton {
  @apply border-blue-800 border text-blue-800 w-full font-black text-center align-middle;
}
</style>
