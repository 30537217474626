<template>
  <div>
    <scoring-disabled v-if="tournament.lock_scoring" :manage="authManage"/>
    <div v-else class="w-full px-3 bg-white">
      <scoring-unauthorized v-if="!verified && !incCompetitor"/>
      <div v-if="readyToScore">
        <div v-if="showLock">
          <lock-device :user="user" :use-ladder="true"/>
          <setup-finder/>
        </div>
        <div v-else>
          <div class="flex justify-between flex-wrap p-2">
            <i @click="lockClicked" class="order-1 text-gray-700 text-5xl fas fa-lock-alt"></i>
            <restrict-scoring :tournament="tournament"/>
            <sync-button/>
          </div>
          <div v-if="authManage || verified" class="flex flex-col lg:w-3/4 lg:mx-auto ">
            <ladder-end-display/>
            <div v-for="(bale, baleNumber) in filteredCompetitors" :class="bale.length ? 'mb-10' : ''"
                 class="md:mx-auto md:w-3/4" :ref="'bale_' + baleNumber">
              <a @click.prevent="addBale(baleNumber)" href=""
                 v-if="Object.values(bale).length && !competitor"
                 class="text-blue-600 cursor-pointer flex justify-end text-xl border-b">
                {{ this.$trans.choice('search.bale', 1) }} {{ baleNumber }}
              </a>
              <score-panel
                @forceUpdate="$emit('forceUpdate')"
                :bale="bale"
                :bale-number="baleNumber"
                :key="`${baleNumber}_ladder_score_panel`"/>
            </div>
            <div v-if="filteredCompetitors.length === 0" class="text-center">
              <p>No Assigned Competitors</p>
            </div>
          </div>
          <div v-else>
            Scoring for this round is not publicly available.
          </div>
        </div>
      </div>
      <div v-else class="text-center py-32">
        <h1 class="mt-4">Loading Scores</h1>
        <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
import QuickRegister from "../tournaments/QuickRegister.vue";
import LockDevice from "./lock/LockDevice.vue";
import SetupFinder from "../SetupFinder.vue";
import ScoringUnauthorized from "./ScoringUnauthorized.vue";
import RestrictScoring from "./restrict/RestrictScoring.vue";
import SyncButton from "./SyncButton.vue";
import mixin from "../../mixins/scoring_mixins";
import ScorePanel from "./panel/ScorePanel.vue";
import ScoringDisabled from "./ScoringDisabled.vue";
import LadderEndDisplay from "./LadderEndDisplay.vue";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";

export default {
  name: "ScoringLadder",
  mixins: [mixin],
  components: {
    LadderEndDisplay,
    LockDevice,
    QuickRegister,
    RestrictScoring,
    ScorePanel,
    ScoringDisabled,
    ScoringUnauthorized,
    SetupFinder,
    SyncButton,
  },
  props: ['tournamentIn', 'user', 'incCompetitor'],
  mounted() {
    let vm = this;
    window.addEventListener('beforeunload', function (event) {
      if (vm.notAccepted.length) {
        event.preventDefault();
        event.returnValue = Swal.fire({
          titleText: 'Some scores have not been accepted!',
          html: 'Please either Accept or Deny any scores before leaving this page'
        }).then(() => {
          return false;
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.notAccepted.length) {
      Swal.fire({
        titleText: 'Some scores have not been accepted!',
        html: 'You must either Accept or Deny any scores before leaving this page'
      }).then(() => {
        next(false)
      })
    } else {
      if (this.locked && to.path !== '/complete') {
        if (this.tournament.club_id && this.tournament.lock_code) {
          Swal.fire({
            titleText: 'This device is locked for scoring!',
            html: 'You must unlock it before leaving this page.',
            icon: 'info',
            input: 'text',
            showConfirmButton: true,
            confirmButtonText: 'Unlock',
            showCancelButton: true,
            reverseButtons: true,
            inputValidator: (value) => {
              if (value !== this.tournament.lock_code) {
                return 'Incorrect Code'
              }
              this.unlockDevice();
              return next();
            }
          }).then(({dismiss}) => {
            if (dismiss) {
              return next(false)
            }
          })
        } else {
          this.unlockDevice();
          return next();
        }
      } else {
        return next();
      }
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'round',
      'lineTime',
      'location',
      'shownBales',
      'hideShown',
      'restrictedBales',
      'restrictedLocations',
      'restrictedTimes',
      'restrictedRounds',
      'restrictedCompetitors',
      'restrictedLadders',
      'showLock',
      'locked',
      'verified',
      'device',
      'tournaments',
      'clubs',
      'competitor',
      'ladder',
      'step',
      'end'
    ]),
    ladderCompetitors() {
      let list = {};
      for (let competitor of this.competitors) {
        if (!(competitor.bale in list)) list[competitor.bale] = {};
        list[competitor.bale][competitor.bale_position] = competitor.competitor_id;
      }
      return list;
    },
    authManage() {
      /** @namespace this.user.auth_manage **/
      return this.user && this.user.auth_manage;
    },
    
    readyToScore() {
      if (!this.tournamentLoaded) return false;
      if (!this.ladder) return false;
      return this.scoreList;
    },
    tournamentLoaded() {
      return this.tournament && this.tournament.hasOwnProperty('unassignedCompetitors')
    },
  },
  methods: {
    async setDisabledCode() {
      let code = await this.$localForage.getItem(`${this.tournament.id}_code`);
      if (code != null) {
        if (code.disabled) {
          this.code = code.code;
          return this.disabled = true;
        }
      }
      this.disabled = false
    },
    addBale(bale) {
      let exists = this.shownBales.indexOf(bale);
      if (exists === -1) {
        let copy = [...this.shownBales];
        copy.push(bale);
        this.shownBales = copy;
        this.hideShown = false;
        this.$nextTick(() => {
          let scrollTo = 'bale_' + bale;
          let container = this.$refs[scrollTo][0];
          container.scrollIntoView();
          window.scrollBy(0, -65);
        });
      }
    },
    addRestricted(item, list, mutation) {
      let exists = list.indexOf(item);
      if (exists === -1) {
        list.push(item);
        this[`restricted${mutation}`] = list;
      }
    },
    
    filteredCount() {
      return Object.values(this.filteredCompetitors).reduce(function (a, c) {
        return a + Object.values(c).length;
      }, 0);
    },
    getRounds() {
      let rounds = this.restrictedRounds;
      if (rounds.length) {
        let filtered = this.tournamentIn.rounds.filter(function (r) {
          return rounds.includes(r.id);
        });
        if (filtered.length) return filtered;
        return rounds;
      }
      return this.tournamentIn.rounds;
    },
    goToCompetitor(competitor) {
      this.location = this.tournament.locations.find(f => f.name === competitor.location);
      this.shownBales.push(competitor.bale);
    },
    handleIncomingCompetitor() {
      this.clearRestricted();
      if (!this.inc_competitor) return;
      this.unlockDevice();
      this.restrictedCompetitors = [this.inc_competitor.id];
      let firstLoop = true;
      for (let assignment of this.inc_competitor.assignments) {
        this.addRestricted(assignment.bale, this.restrictedBales, 'Bales');
        let location = this.locationFromAssignment(assignment);
        if (location) this.addRestricted(location, this.restrictedLocations, 'Locations');
        let time = this.timeFromAssignment(assignment);
        if (time) this.addRestricted(time, this.restrictedTimes, 'Times');
        this.addRestricted(assignment.round_id, this.restrictedRounds, 'Rounds');
        if (firstLoop) {
          this.round = this.tournament.rounds[0];
          let lineTime = this.round.line_times.find(f => f.id === parseInt(assignment.line_time));
          let location = this.round.locations.find(f => f.name === assignment.location);
          this.lineTime = this.store.$patch({
            lineTime: lineTime,
            location: location,
          })
          firstLoop = false;
        }
      }
    },
    locationFromAssignment(assignment) {
      return this.tournament.locations.find(f => f.name === assignment.location);
    },
    lockClicked() {
      if (!this.tournament.club_id || !(this.tournament.lock_code && this.tournament.lock_code.length)) {
        return this.showLock = true;
      }
      Swal.fire({
        title: 'Enter Lock Code',
        input: 'text',
        showCancelButton: true,
        reverseButtons: true,
        inputValidator: (value) => {
          if (value.toLowerCase() !== this.tournament.lock_code.toLowerCase()) {
            return 'Incorrect Code'
          }
          this.showLock = true;
        }
      })
    },
    async setExisting() {
      let key = this.tournament.id + '_locked_';
      for (const name of ['Rounds', 'Times', 'Locations', 'Bales', 'Competitors', 'Ladders']) {
        let value = await this.$localForage.getItem(`${key}${name.toLowerCase()}`);
        if (value && value.length) this.setRestricted(name, value);
      }
    },
    
    clearRestricted() {
      this.store.$patch({
        restrictedBales: [],
        restrictedCompetitors: [],
        restrictedLocations: [],
        restrictedTimes: [],
        restrictedRounds: [],
        restrictedLadders: [],
      })
    },
    
    setRestricted(name, list) {
      this[`restricted${name}`] = list && list.length ? list : [];
    },
    
    async setTournamentLocked() {
      let locked = await this.$localForage.getItem(this.tournament.id + '_lock');
      if (locked) this.locked = true;
    },
    verify(tournamentId, clubId, allowClub) {
      if (this.device == null) return false;
      let tournament = this.tournaments.indexOf(tournamentId) !== -1;
      let club = this.clubs.indexOf(clubId) !== -1;
      if (allowClub) {
        return tournament || club;
      }
      return tournament;
    },
    setVerified() {
      if (this.authManage || !this.tournamentIn.club_id) {
        this.verified = true;
      } else {
        let tId = this.tournamentIn.id;
        let cId = this.tournamentIn.club_id;
        let devices = this.tournamentIn.club_devices;
        let exists = this.$store.getters['device/verify'](tId, cId, devices);
        if (!exists) {
          this.$store.dispatch('device/updateAuthorizations').then(() => {
            exists = this.$store.getters['device/verify'](tId, cId, devices);
            this.verified = exists;
          });
        } else {
          this.verified = exists;
        }
        // let exists = this.verify(tId, cId, devices);
        // if (!exists) {
        //   this.updateAuthorizations().then(() => {
        //     exists = this.verify(tId, cId, devices);
        //     this.verified = exists;
        //   })
        // } else {
        //   this.verified = true;
        // }
      }
    },
    async updateAuthorizations() {
      if (this.device == null) return;
      await this.$axios.get('/device', {
        params: {
          'device_id': this.device.device_id,
        }
      }).then(async ({data}) => {
        this.device = data.device;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    timeFromAssignment(assignment) {
      return this.tournament.line_times.find(f => f.id === parseInt(assignment.line_time));
    }
  },
  
  async created() {
    await this.setDisabledCode();
    let vm = this;
    await this.setTournamentLocked();
    await this.setExisting().then(function (_) {
      let ladders = vm.restrictedLadders;
      let ladder = vm.ladder;
      let step = vm.step;
      if (ladders && ladders.length) {
        if (ladder == null || !(ladder in ladders)) {
          ladder = ladders[0];
          step = 0;
        }
      } else {
        ladder = vm.ladder !== null ? vm.ladder : vm.tournament.ladders[0];
      }
      let bales = vm.restrictedBales;
      if (bales) {
        vm.store.$patch({
          hideShown: false,
          shownBales: [...bales],
        })
      }
      vm.store.$patch({
        user: vm.user,
        end: 1,
        ladder: ladder,
        step: step,
        round: null,
      })
    })
    this.setVerified();
  },
  
}
</script>

<style scoped>
</style>
