<template>
  <div>
    <dropdown v-if="ladders.length > 1">
      <template v-slot:trigger>
        <div id="ladderSelection"
             class="text-xl sm:text-2xl md:text-3xl lg:text-4xl dropdown-toggle text-gray-800"
             aria-haspopup="true" aria-expanded="false">
          Ladder: {{ ladderName(ladder) }}
          <i class="fas fa-caret-down"></i>
        </div>
      </template>
      <span v-for="ladder in ladders" class="w-full">
        <span @click="updateLadder(ladder);"
              class="dropdown-item flex justify-between text-xl">
          <span class="max-w-16 md:max-w-md overflow-hidden">{{ ladder.name }}</span>
          <span class="ml-2 text-center font-bold"
                v-if="!competitor"
                :class="getColorFromPercentage(getLadderCompletion(ladder.id))">
            {{ getLadderCompletion(ladder.id) }}%
          </span>
        </span>
      </span>
    </dropdown>
    <div v-else class="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
      Ladder: {{ ladderName(ladder) }}
    </div>
  </div>

</template>

<script>

import mixin from "../../../mixins/scoring_mixins";
import Dropdown from "../../partials/Dropdown.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "RestrictLadder",
  mixins: [mixin],
  components: {Dropdown},
  methods: {
    ladderName(ladder) {
      let text = ladder.name;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
  },
  computed: {
    ...mapState(useDisplayStore, ['incCompetitor', 'restrictedLadders']),
    competitor() {
      return this.incCompetitor;
    },
    ladders() {
      return this.restrictedLadders.length ? this.restrictedLadders : this.tournament.ladders;
    },
    
  }
}
</script>

<style scoped>

</style>
