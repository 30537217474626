<template>
  <div class="bs-right-margin">
    <div class="flex justify-center">
      <div class="bs-form-box p-0 flex rounded-lg">
        <div class="flex flex-col justify-center text-grey-light p-2 mr-2">
          <i class="fa-2x fas fa-search"></i>
        </div>
        <input @keyup.enter="submitSearch" class="flex-grow text-xl sm:text-3xl focus:outline-none"
               :placeholder="' Enter Archer Name'" v-model="search">
        <button v-if="search.length" @click="clearSearch"
                class="flex flex-col justify-center text-grey-light p-2 mr-2">
          <i class="fa-2x fas fa-times"></i>
        </button>
        <div v-else>&nbsp;</div>
      </div>
    </div>
    <div class="flex flex-wrap justify-center">
      <div v-for="archer in filteredArchers">
        <a v-if="archer.slug" :href="'/archers/' + archer.slug" class="m-2 p-2 flex flex-col">
          <div class="flex justify-center">
            <img :src="archer.image && archer.image_enabled ? archer.image : '/img/defaultProfileImage.jpg'" width="100"
                 height="100">
          </div>
          <div class="text-center text-grey-darkest">
            <div>{{ archer.full_name }}</div>
          </div>
        </a>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "ProfileList",
  props: ['archers'],
  data() {
    return {
      search: '',
      filteredArchers: '',
    }
  },
  watch: {
    search(text) {
      if (!text.length) {
        this.filteredArchers = '';
      } else if (text.length > 2) {
        this.submitSearch();
      }
    }
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.filteredArchers = this.archers;
    },
    submitSearch() {
      this.$axios
      .get('/archers/', {
        params: {
          'search': this.search
        }
      })
      .then(({data}) => {
        this.filteredArchers = data.archers;
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>