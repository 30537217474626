<template>
  <div class="mx-auto">
    <div class="card card-blue-700">
      <div class="card-header">
        Auto {{ baleWord }} Assignment Settings
      </div>
      <div class="card-body">
        <div class="flex flex-col">
          <div><span class="float-right text-sm text-gray-600 mr-2">
            <i class="fal fa-info-circle"></i>
            Competitors matching the selected options will be assigned to that location if space is
            available. If there is no match they will be placed in the next available position. If competitors are
            allowed to choose a starting line time, they will only be shown valid times for their form options. If their
            selected time is full, they will be put on the waiting list.
          </span></div>
          <div v-for="(round, roundIndex) in tournament.rounds" class="flex-1 bg-gray-200 m-2 flex flex-col">
            <div class="flex p-2">
              <button @click="expand(roundIndex.toString())">
                <span v-if="!shown(roundIndex)">+</span>
                <span v-if="shown(roundIndex)">-</span>
                Round: {{ round.name }}
              </button>
            </div>
            <div v-show="roundIndex === 0 && missingOptions.length" class="errorText px-2">
              Adding restrictions to the first round can prevent people from registering if not all the options are
              available. If an unrestricted line time exists you can ignore this warning.
              <div>
                Missing: {{ missingOptions }}
              </div>
            </div>
            <div
              v-for="(line_time,timeIndex) in round.line_times"
              v-if="shown(roundIndex)"
              class="flex-1 bg-blue-200 m-2 flex flex-col">
              <div class="flex p-2">
                <button @click="expand(roundIndex + '_' + timeIndex)">
                  <span v-show="!shown(roundIndex, timeIndex)">+</span>
                  <span v-show="shown(roundIndex, timeIndex)">-</span>
                  Line Time: {{ line_time.time }}
                </button>
              </div>
              <div v-if="shown(roundIndex, timeIndex)"
                   v-for="(location,locIndex) in round.locations" class="flex-1 bg-gray-200 m-2 flex-col">
                <div class="flex p-2 items-start">
                  <button @click="expand(roundIndex + '_' + timeIndex + '_' + locIndex)">
                    <span v-show="!shown(roundIndex, timeIndex, locIndex)">+</span>
                    <span v-show="shown(roundIndex, timeIndex, locIndex)">-</span>
                    Location: {{ location.name }}
                  </button>
                  <div class="flex flex-col">
                    <two-choice-selector
                      v-for="position in positions(`${roundIndex}_${timeIndex}_${locIndex}`)"
                      :key="`${roundIndex}_${timeIndex}_${locIndex}_${position}`"
                      class="ml-2 pt-1"
                      :style="zIndex(roundIndex,timeIndex,locIndex)"
                      :position="position"
                      @selected="selected"
                      @clearSelection="clearSelection"
                      :nestedOptions="optionDetails"
                      :settings="settings"
                      :identifier="`${roundIndex}_${timeIndex}_${locIndex}`"/>
                  </div>
                </div>
                <div v-if="shown(roundIndex, timeIndex, locIndex)"
                     class="flex flex-wrap justify-between">
                  <div v-for="(bale, baleIndex) in Object.values(location.bales)"
                       class="bg-blue-200 m-2 flex-grow">
                    <div class="flex justify-center">
                      <div class="text-center">
                        {{ baleWord }}: {{ baleIndex + 1 }}
                        <two-choice-selector
                          v-for="position in positions(`${roundIndex}_${timeIndex}_${locIndex}_${baleIndex}`)"
                          :key="`${roundIndex}_${timeIndex}_${locIndex}_${baleIndex}_${position}`"
                          class="ml-2"
                          :style="zIndex(roundIndex,timeIndex,locIndex,baleIndex)"
                          :position="position"
                          @selected="selected"
                          @clearSelection="clearSelection"
                          :nestedOptions="optionDetails"
                          :settings="settings"
                          :identifier="`${roundIndex}_${timeIndex}_${locIndex}_${baleIndex}`"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end">
          <button @click="save" class="mr-2 btn-sm btn-gray-600">
            Save Settings <span v-show="loading"><i class="fas fa-spinner fa-spin"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TwoChoiceSelector from "../../partials/TwoChoiceSelector.vue";

export default {
  name: "AutoSettings",
  components: {TwoChoiceSelector},
  props: ['tournamentIn'],
  data() {
    return {
      expanded: [],
      settings: this.tournamentIn.assignment_settings ? this.tournamentIn.assignment_settings : [],
      loading: false,
    }
  },
  computed: {
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    missingOptions() {
      let list = '';
      if (this.settings.length) {
        let matches = [];
        let times = [];
        let filtered = this.settings.filter(f => f.identifier.startsWith('0'));
        filtered.forEach(function (item) {
          if (!(item.choice_1 in matches)) {
            matches[item.choice_1] = [];
          }
          times.push(item.identifier.substring(0, 3));
          matches[item.choice_1].push(item.choice_2);
        });
        let unique = [...new Set(times)];
        if (unique.length < this.tournamentIn.rounds[0].line_times.length) return list;
        let form = this.tournamentIn.regform;
        for (name in matches) {
          let option = form.find(function (item) {
            return item.name === name;
          })
          if (option) {
            let options = option.multiple.map(function (item) {
              return item.label;
            });
            let difference = options.filter(x => !matches[name].includes(x));
            if (difference.length) {
              list += `${name} - ${difference.join(', ')}.`;
            }
          }
        }
      }
      return list;
    },
    tournament() {
      return this.tournamentIn;
    },
    registrationOptions() {
      return this.tournament.regform.filter(f => f.multiple !== null);
    },
    optionDetails() {
      let details = [];
      this.registrationOptions.forEach(function (option) {
        let multiple = option.multiple.map(m => m.label);
        details.push({'name': option.name, 'options': multiple})
      });
      let targets = this.tournament.targets.map(m => m.name);
      details.push({'name': 'Target', 'options': targets});
      return details;
    }
  },
  methods: {
    positions(identifier) {
      let list = this.settings.filter(function (f) {
        return f.identifier === identifier
      })
      if (list.length) {
        let returnValue = [];
        list.forEach(function (s) {
          returnValue.push(s.position);
        })
        returnValue.sort();
        let next = returnValue[returnValue.length - 1] + 1;
        returnValue.push(next)
        return returnValue;
      } else {
        return [0];
      }
      
    },
    shown(round, time, loc) {
      let string = round.toString();
      if (time !== undefined) string += `_${time}`;
      if (loc !== undefined) string += `_${loc}`;
      let isShown = this.expanded.indexOf(string) === -1;
      return isShown;
    },
    zIndex(round, time, loc, bale) {
      let amount = this.tournament.rounds.length * 1000;
      let r = (round + 1) * 100;
      let t = (time + 1) * 10;
      let l = (loc + 1) * 5;
      let b = bale + 1;
      amount = amount - (isNaN(r) ? 0 : r) - (isNaN(t) ? 0 : t) - (isNaN(l) ? 0 : l) - (isNaN(b) ? 0 : b);
      return 'z-index:' + amount + ';';
    },
    selected(identifier, choice_1, choice_2, position) {
      let index = this.settings.findIndex(f => f.identifier === identifier && f.position === position);
      if (index !== -1) {
        this.settings.splice(index, 1);
      }
      this.settings.push({identifier: identifier, choice_1: choice_1, choice_2: choice_2, position: position})
    },
    clearSelection(identifier, position) {
      let index = this.settings.findIndex(f => f.identifier === identifier && f.position === position);
      if (index !== -1) {
        this.settings.splice(index, 1);
      }
    },
    expand(group) {
      let index = this.expanded.indexOf(group);
      if (index !== -1) {
        this.expanded.splice(index, 1);
      } else {
        this.expanded.push(group);
      }
    },
    save() {
      this.loading = true;
      let vm = this;
      this.$axios.post(`/tournaments/` + this.tournament.slug + '/assignment/settings', {
        'settings': this.settings
      })
      .then(({data}) => {
        if (data['success']) {
          vm.loading = false;
          this.$axios
          .get('/tournaments/' + this.tournament.slug + '/suite/tournament')
          .then(({data}) => {
            this.$store.commit('tournament/SET_TOURNAMENT', {
              'tournament': data.tournament
            });
            window.location = '/tournaments/' + data.tournament.slug
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        vm.loading = false;
      });
    }
    
  },
}
</script>

<style scoped>

</style>
