<template>
  <div v-if="postal" class="w-full h-full">
    <div class="m-2">
      <div class="text-3xl">{{ postal.name }} Master Dashboard</div>
      <hr>
      <div class="flex flex-wrap">
        <div class="p-1 w-full lg:w-1/2">
          <div class="card card-blue-700">
            <div class="card-header">
              Approve Host
            </div>
            <div class="card-body">
              <div v-if="!postal.host_requests.length">
                No tournament hosts awaiting approval
              </div>
              <div v-else class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="table-header">Tournament</th>
                    <th class="table-header">Club</th>
                    <th class="table-header">Approve</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="child in postal.host_requests">
                    <td><a :href="'/tournaments/' + child.slug" target="_blank">{{ child.name }}</a>
                    </td>
                    <td><a :href="'/clubs/' + child.club.slug"
                           target="_blank">{{ child.club.name }}</a>
                    </td>
                    <td>
                      <button @click="acceptHost(child.slug)"
                              class="btn-sm btn-outline-green-500">
                        Accept
                      </button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="p-1 w-full xl:w-1/2">
          <div class="card card-blue-700">
            <div class="card-header">
              Host List
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th class="table-header">Tournament</th>
                    <th class="table-header">Club</th>
                    <th class="table-header">Competitors</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="child in postal.children">
                    <td><a :href="'/tournaments/' + child.slug" target="_blank">{{ child.name }}</a>
                    </td>
                    <td><a :href="'/clubs/' + child.club.slug"
                           target="_blank">{{ child.club.name }}</a>
                    </td>
                    <td>
                      {{ child.competitors_count }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "PostalDashboard",
  props: ['tournamentIn'],
  data() {
    return {
      postal: null,
    }
  },
  methods: {
    acceptHost(childSlug) {
      this.$axios.post('/tournaments/' + childSlug + '/approve_host')
      .then(({data}) => {
        if (data['success']) {
          this.postal.host_requests = data['hosts'];
        }
      });
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
  },
  created() {
    this.$axios.get('/tournaments/' + this.tournament.slug + '/postal')
    .then(({data}) => {
      this.postal = data.postal;
    }).catch((error) => {
    });
  }
}
</script>

<style scoped>

</style>
