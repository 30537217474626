<template>
    <div>
        <div v-if="!club.stripe" class="bg-blue-100 p-3">
            <div class="text-center text-xl"><a class="text-blue-500" href="https://stripe.com">Stripe</a> is the preferred method for taking online payments.</div>
            <ul class="list-disc ml-5">
                <li>Stripe has better <a href="https://stripe.com/pricing" target="_blank" class="text-blue-400">rates</a> than Paypal, Authorize.net and other payment methods.</li>
                <li>You are going to have to setup a Stripe account if you don't have one already.</li>
                <li>This setup only needs to be done once per club.</li>
                <li>You will need basic bank information like account numbers and tax ID.</li>
                <li>Setup is very fast and easy, just click the link below!</li>
            </ul>
            <div class="flex justify-center">
                <a :href="'https://connect.stripe.com/oauth/authorize?response_type=code&client_id=' + client_id +
                '&scope=read_write' + '&state=' + stateValue" target="_blank">
                    <div class="mt-4">
                        <img class="img"
                             style="max-width: 190px;"
                             src="/img/stripe_dark.png"
                             alt="stripe_connect">
                    </div>
                </a>
            </div>

        </div>
        <p v-else class="text-center bg-blue-lightest p-3">
            This Club is all set up to use Stripe payments.
        </p>
    </div>

</template>

<script>
    export default {
        name: "StripeConnect",
        props: ['club','user','client_id'],
        computed: {
            stateValue(){
                let value = {'club_slug':this.club.slug, 'user_slug':this.user.slug};
                return encodeURIComponent(JSON.stringify(value))

            }
        }
    }
</script>

<style scoped>

</style>
