<template>
    <tr>
        <td>
            <div class="custom-control custom-switch">
                <input @change="updateDevice" type="checkbox" class="custom-control-input" :id="'active_' + device.id"
                       v-model="active">
                <label class="custom-control-label" :for="'active_' + device.id">
                    {{active ? 'Yes' : 'No'}}
                </label>
            </div>
        </td>
        <td>
            <span @click="changeName = !changeName" class="cursor-pointer">{{device_name}}</span>
            <div v-show="changeName" class="input-group input-group-sm">
                <input type="text" class="form-control" v-model="device_name">
                <div class="input-group-append">
                    <button @click.prevent="updateName" class="btn btn-outline-secondary">
                        <span v-show="!nameSpinner"><i class="far fa-check"></i></span>
                        <span v-show="nameSpinner"><i class="far fa-spinner fa-spin"></i></span>
                    </button>
                </div>
            </div>
        </td>
        <td>
            <span v-show="!expiration" class="text-grey-light">not active</span>
            <template v-if="expiration">
                <span @click="expInput = !expInput">{{formatDateTime(expiration)}}</span>
                <flat-pickr
                        v-show="expInput"
                        class="form-control"
                        v-model="expiration"
                        :config="expirationOptions"
                        @on-close="updateExpiration"
                ></flat-pickr>
            </template>

        </td>
    </tr>
</template>

<script>
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';

    export default {
        name: "ClubDeviceDetails",
        props: ['device'],
        data() {
            return {
                device_name: this.device.name ? this.device.name : this.device.device_id,
                expiration: this.formatTime(this.device.expiration),
                changeName: false,
                expInput: false,
                expireTimeOptions: {},
                nameSpinner: false,
                active: this.checkActive(),
                expirationOptions: {
                    dateFormat: 'd M Y h:i K',
                    enableTime: true,
                    minuteIncrement: 15,
                    minDate: new Date(),
                    allowInput: true,
                    plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})]
                }
            }
        },
        methods: {
            updateName() {
                this.changeName = false;
                this.updateDevice();
            },
            updateExpiration() {
                this.updateDevice();
            },
            updateDevice() {
                let vm = this;
                this.idSpinner = true;
                this.$axios.post('/device/update', {
                    'device': {
                        name: this.device_name,
                        id: this.device.id,
                        active: this.active,
                        device_id: this.device.device_id,
                        expiration: this.expiration
                    },
                })
                    .then(({data}) => {
                        if (data['error']) {
                            swal.fire(data['error'])
                        }
                        if (data['success']) {
                            vm.idSpinner = false;
                            vm.changeId = false;
                            vm.expInput = false;
                            vm.expiration = this.formatTime(data['exp']);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            formatDateTime(date) {
                return date ? moment(date).format('D MMM YYYY h:mm A') : null;
            },
            formatTime(time) {
                return time ? moment(time).format('D MMM YYYY h:mm A') : null;
            },
            setExpireTimeOptions() {
                this.expireTimeOptions = {
                    format: 'D MMM YYYY hh:mm A',
                    stepping: 15,
                    showClose: true,
                    showClear: true,
                    minDate: new Date(),
                    maxDate: moment().add(100, 'y'),
                    date: moment(this.expiration)
                };
            },
            checkActive() {
                return this.device.active && (moment(this.device.expiration) > moment())
            },
        },
        mounted() {
            this.setExpireTimeOptions();
        }
    }
</script>

<style scoped>
    td {
        white-space: nowrap;
    }
</style>