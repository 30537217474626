<template>
  <div class="mt-2">
    <div class="card-blue-700">
      <div class="card-header">User Scoring</div>
    </div>
    <div class="card-body">
      <form-switch
        @toggled="toggleScoring"
        :model="scoring"
        :id="'toggleScoring'"
        :label="'Allow User Scoring'"
        :sub-label="'Any user that is logged in to the account used to register for this tournament can score\n'+
                    'themselves.'"/>
      <div v-if="scoring" class="flex flex-col">
        <label class="text-lg sm:w-1/2 md:w-2/3 lg:w-1/2">
          Time Limit (in minutes)
          <input @blur="updateTimeLimit" class="bs-form-box block" type="number" v-model="timeLimit"
                 :max="maxLimit">
        </label>
        <span class="text-gray-600 text-sm">
          Leave this field empty if there is no time limit. Before a user begins scoring they will be warned
          that a timer will start. Scores entered by the user will not be counted after the limit is up.
          Scores entered on an approved device or by a tournament admin will still be valid.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "UserScoring",
  components: {FormSwitch},
  props: ['tournament'],
  data() {
    return {
      scoring: this.tournament.user_scoring,
      timeLimit: this.tournament.time_limit,
      maxLimit: 60 * 24 * 3,
    }
  },
  methods: {
    toggleScoring() {
      this.scoring = !this.scoring;
      this.$axios.post(`/tournaments/${this.tournament.slug}/user_scoring`, {scoring: this.scoring})
    },
    updateTimeLimit() {
      if (this.timeLimit <= this.maxLimit) {
        this.$axios.post(`/tournaments/${this.tournament.slug}/user_scoring`, {timeLimit: this.timeLimit})
      }
    }
  }
}
</script>

<style scoped>

</style>
