<template>
  <div class="m-1 p-1 pb-4 mb-4 b-b-1">
    <div class="flex flex-col">
      <div>
        <a @click.prevent="copy" href="" class="float-right text-gray-600 ml-3" title="Copy table">Copy <i
          class="far fa-paperclip"></i></a>
        <a @click.prevent="print" href="" class="float-right text-gray-600 ml-3" title="Print invoice">Print <i
          class="far fa-print"></i></a>
      </div>
      <div id="printArea" class="flex flex-col">
        <div class="flex flex-col sm:flex-row justify-between">
          <h4><strong>
            <a style="text-decoration-line: none;" :href="'/tournaments/' + tournament.slug"
               class="text-uppercase text-gray-600 text-2xl">
              {{ tournament.name }} <small
              class="ml-4 d-none d-sm-inline">{{ smallDate(tournament.start_time) }}</small>
            </a>
          </strong></h4>
          <h4 class="hidden sm:block"><strong>
            <a style="text-decoration-line: none;" :href="'/clubs/' + tournament.club.slug"
               class="text-uppercase text-gray-600 text-2xl">
              {{ tournament.club.name }}</a>
          </strong></h4>
        </div>
        <div class="flex flex-col p-3">
          <div class="hidden sm:flex fakeTableRow b-b-1">
            <div style="flex:3">Competitor Link</div>
            <div class="text-center">Paid</div>
            <div style="flex:3">Round</div>
            <div class="hidden sm:block text-center">Approved</div>
            <div class="block sm:hidden" title="Approved">A</div>
            <div style="flex:2" class="text-right">Line Time</div>
            <div style="flex:3" class="text-right">Location</div>
            <div class="text-right">Bale</div>
          </div>
          <div v-show="Object.keys(tournament.competitor_list).length > 1" class="block sm:hidden b-b-1">Competitor
            Links
          </div>
          <div v-for="(competitor, index) in tournament.competitor_list"
               class="fakeTableRow mb-2 rowLink">
            <div v-if="index < limit">
              <a :href="'/tournaments/' + tournament.slug + '/competitors/' + competitor.uuid"
                 class="block sm:hidden text-gray-600">
                <span v-show="Object.keys(tournament.competitor_list).length <= 1">Competitor Link: </span>
                {{ competitor.name }}
              </a>
              <a :href="'/tournaments/' + tournament.slug + '#/competitors/' + competitor.uuid"
                 class="hidden sm:flex text-gray-600">
                <div style="flex:3" class="text-left">
                  {{ competitor.name }}
                </div>
                <div class="text-center">
                  <span v-show="competitor.paid"><i class="fas fa-check "></i></span>
                  <span v-show="!competitor.paid"><i class="fas fa-ban "></i></span>
                </div>
                
                <div style="flex:3">
                  <div v-for="round in competitor.rounds">{{ round.name }}</div>
                </div>
                <div class="text-center">
                  <div v-for="round in competitor.rounds">
                    <span v-show="round.pivot.approved"><i class="fas fa-check "></i></span>
                    <span v-show="!round.pivot.approved"><i class="fas fa-ban "></i></span>
                  </div>
                </div>
                <div style="flex:2" class="text-right">
                  <div v-for="round in competitor.rounds">
                    {{
                      round.pivot.line_time ? smallTime(round.line_times.find(t => t.id ===
                        parseInt(round.pivot.line_time)).time) : 'n/a'
                    }}
                  </div>
                </div>
                <div style="flex:3" class="text-right">
                  <div v-for="round in competitor.rounds">
                    {{ round.pivot.location ? round.pivot.location : 'n/a' }}
                  </div>
                </div>
                <div class="text-right">
                  <div v-for="round in competitor.rounds">
                    {{ round.pivot.bale ? round.pivot.bale : 'n/a' }}
                  </div>
                </div>
              </a>
            </div>
          </div>
          
          <div v-if="tournament.competitor_list.length > 5">
            <a @click.prevent="limit = 9999" href="" v-if="limit === 5"> Show All
              {{ tournament.competitor_list.length }} Competitors</a>
            <a @click.prevent="limit = 5" href="" v-else> Limit Shown</a>
          </div>
        
        </div>
      </div>
    
    </div>
  
  </div>

</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "UserTournamentItem",
  props: ['tournament'],
  data() {
    return {
      limit: 5,
    }
  },
  computed: {
    tableData() {
      let string = '"Name","Paid","Round","Approved","Line Time","Location","Bale"\n';
      this.tournament.competitor_list.forEach(function (competitor) {
        let paid = '';
        if (competitor.paid) {
          paid = 'yes';
        } else {
          if (competitor.partial_paid) {
            paid = 'partial';
          } else {
            paid = 'no';
          }
        }
        competitor.rounds.forEach(function (round) {
          string += '' + competitor.name + ',' +
            '' + paid + ',' +
            '' + round.name + ',' +
            '' + (round.pivot.approved ? 'yes' : 'no') + ',' +
            '' + (round.pivot.line_time ? round.line_times.find(t => t.id === parseInt(round.pivot.line_time)).time : '') + ',' +
            '' + round.pivot.location + ',' +
            '' + round.pivot.bale + '\n';
        });
      });
      return string
    },
  },
  methods: {
    smallTime(time) {
      let dateTime = new Date(time);
      return moment(dateTime).format('DD-MMM H:mm')
    },
    smallDate(time) {
      let dateTime = new Date(time);
      return moment(dateTime).format('DD MMMM, YYYY')
    },
    print() {
      this.limit = 999;
      document.body.innerHTML = document.getElementById('printArea').innerHTML;
      window.print();
      window.location.reload()
    },
    
    copy() {
      let el = document.createElement('textarea');
      // Set value (string to be copied)
      el.value = this.tableData;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute('readonly', '');
      el.style = {position: 'absolute', left: '-9999px'};
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand('copy');
      // Remove temporary element
      document.body.removeChild(el);
      Swal.fire('Table copied to clipboard')
    }
  }
}
</script>

<style scoped>
.fakeTableRow div {
  flex: 1;
}

.rowLink :hover {
  background-color: #f7f7f7;
}

.rowLink a {
  text-decoration: none;
}
</style>
<style type="text/css" media="print">
@page {
  size: landscape;
}
</style>
