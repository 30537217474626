const state = {
    sponsor: null,
};

const getters = {
    getSponsor(state) {
        return state.sponsor;
    },
};
const actions = {};
const mutations = {
    SET(state, data) {
        state.sponsor = data.sponsor;
    },
    REMOVE_SPONSOR(state) {
        state.sponsor = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
