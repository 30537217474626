<template>
  <div v-if="tournament">
    <div v-if="!tournament.id" class="flex justify-between">
      <a @click.prevent="cloneExistingTournament"
         class="text-blue-600 cursor-pointer">Clone an Existing Tournament</a>
      <button @click.prevent="clearForm" class="btn-sm btn-outline-blue-600">Clear Form</button>
    </div>
    <form v-on:submit.prevent.once="submitForm" :method="!tournament.id ? 'POST' : 'PUT'">
      <!--      <form-wizard-->
      <!--        @on-complete="submitForm"-->
      <!--        @on-validate="handleValidation"-->
      <!--        :start-index="startIndex"-->
      <!--        :stepSize="'xs'"-->
      <!--        :validateOnBack="true"-->
      <!--        ref="tournament_form"-->
      <!--        :color="'#2779bd'"-->
      <!--        :errorColor="'#ef5753'"-->
      <!--        class="px-0 sm:px-2 lg:px-3"-->
      <!--        :title="(!tournament.id ? 'Create a Tournament' : 'Edit ' + tournament.name)"-->
      <!--        :subtitle="''">-->
      <!--        <tab-content-->
      <!--          title="Sport"-->
      <!--          :before-change="()=>validateStep('tournament-sport')">-->
      <!--          <tournament-sport-->
      <!--            :inc_tournament="tournament"-->
      <!--            :sportList="sportList"-->
      <!--            :sportTargets="sportTargets"-->
      <!--            ref="tournament-sport"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Info" :before-change="()=>validateStep('tournament-info')">-->
      <!--          <tournament-info-->
      <!--            :tournament="tournament"-->
      <!--            :club="club"-->
      <!--            ref="tournament-info"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Time" :before-change="()=>validateStep('tournament-time')">-->
      <!--          <tournament-time-->
      <!--            @onStartChange="onStartChange"-->
      <!--            @onEndChange="onEndChange"-->
      <!--            @onOpenChange="onOpenChange"-->
      <!--            @updateRoundTimes="updateRoundTimes"-->
      <!--            @removeTime="removeTime"-->
      <!--            @addTime="addTime"-->
      <!--            :tournament="tournament"-->
      <!--            :lineTimeOptions="lineTimeOptions"-->
      <!--            :startTimeOptions="startTimeOptions"-->
      <!--            :endTimeOptions="endTimeOptions"-->
      <!--            :regOpenOptions="regOpenOptions"-->
      <!--            :regCloseOptions="regCloseOptions"-->
      <!--            :timezones="timezones"-->
      <!--            ref="tournament-time"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content :title="'Location'" :before-change="()=>validateStep('tournament-location')">-->
      <!--          <tournament-location-->
      <!--            :tournament="tournament"-->
      <!--            @addLocation="addLocation"-->
      <!--            @updateRoundLocations="updateRoundLocations"-->
      <!--            @removeLocation="removeLocation"-->
      <!--            ref="tournament-location"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Setup" :before-change="()=>validateStep('tournament-setup')">-->
      <!--          <tournament-setup-->
      <!--            :tournament="tournament"-->
      <!--            @addRoundTime="addRoundTime"-->
      <!--            @removeRoundTime="removeRoundTime"-->
      <!--            @addRoundLocation="addRoundLocation"-->
      <!--            @removeRoundLocation="removeRoundLocation"-->
      <!--            @addRound="addRound"-->
      <!--            @removeRound="removeRound"-->
      <!--            @generateHelperRounds="generateHelperRounds"-->
      <!--            ref="tournament-setup"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Registration" :before-change="()=>validateStep('tournament-registration')">-->
      <!--          <registration-form-->
      <!--            :tournament="tournament"-->
      <!--            :regform="regform"-->
      <!--            ref="tournament-registration"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Payment" :before-change="()=>validateStep('tournament-payment')">-->
      <!--          <tournament-payment-->
      <!--            :tournament="tournament"-->
      <!--            :setupPayments="setupPayments"-->
      <!--            :total="total"-->
      <!--            :club="club"-->
      <!--            @toggleOfflinePayments="toggleOfflinePayments"-->
      <!--            @toggleFullPay="toggleFullPay"-->
      <!--            @toggleDownPay="toggleDownPay"-->
      <!--            ref="tournament-payment"/>-->
      <!--        </tab-content>-->
      <!--        <template slot="footer" slot-scope="props">-->
      <!--          <div class=wizard-footer-left>-->
      <!--            <wizard-button-->
      <!--              v-if="props.activeTabIndex > 0"-->
      <!--              @click.native="props.prevTab()"-->
      <!--              :style="props.fillButtonStyle">-->
      <!--              Previous-->
      <!--            </wizard-button>-->
      <!--          </div>-->
      <!--          <div class="wizard-footer-right">-->
      <!--            <wizard-button-->
      <!--              v-if="!props.isLastStep" @click.native="nextTab(props, props.activeTabIndex)"-->
      <!--              :style="props.fillButtonStyle"-->
      <!--              :class="errors.items.length ? 'disabled' : ''"-->
      <!--              class="wizard-footer-right">-->
      <!--              <span>Next</span>-->
      <!--            </wizard-button>-->
      <!--            <div class="float-left mr-3">-->
      <!--              <span v-if="fixErrors && errors.items.length" class="text-sm text-red-600 mr-2">You must-->
      <!--                fix all errors to continue.</span>-->
      <!--              <wizard-button-->
      <!--                v-if="!props.isLastStep && tournament.id"-->
      <!--                @click.native="submitValid(props.activeTabIndex)"-->
      <!--                class="wizard-footer-right"-->
      <!--                :style="props.fillButtonStyle">-->
      <!--                Update Tournament-->
      <!--                <span v-show="loading" class="ml-2">-->
      <!--                  <i class="fas fa-spinner fa-spin"></i>-->
      <!--                </span>-->
      <!--              </wizard-button>-->
      <!--            </div>-->
      <!--            <wizard-button-->
      <!--              v-if="props.isLastStep"-->
      <!--              @click.native="submitValid(props.activeTabIndex)"-->
      <!--              class="wizard-footer-right"-->
      <!--              :style="props.fillButtonStyle">-->
      <!--              {{ !tournament.id ? 'Create Tournament' : 'Update Tournament' }}-->
      <!--              <span v-show="loading" class="ml-2">-->
      <!--                <i class="fas fa-spinner fa-spin"></i>-->
      <!--              </span>-->
      <!--            </wizard-button>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <input type="hidden" :value="peopleToUnassign" name="peopleToUnassign"/>-->
      <!--      </form-wizard>-->
    </form>
  </div>
</template>

<script>
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import TournamentSportOld from "./TournamentSportOld.vue";
import TournamentInfo from "./TournamentInfo.vue";
import TournamentPayment from "./TournamentPayment.vue";
import RegistrationForm from "../RegistrationForm.vue";
import TournamentSetup from "./TournamentSetup.vue";
import TournamentLocation from "./TournamentLocation.vue";
import TournamentTime from "./TournamentTime.vue";
import moment from "moment-timezone";
import Swal from "sweetalert2";

export default {
  
  name: "TournamentFormOld",
  components: {
    TournamentTime,
    TournamentLocation,
    TournamentSetup, RegistrationForm, TournamentPayment, TournamentInfo, TournamentSportOld
  },
  props: {
    inc_tournament: Object,
    inc_user: Object,
    club: Object,
    sportList: Object,
    targetList: Object,
  },
  data() {
    return {
      tournament: this.inc_tournament,
      currentTime: new Date(),
      lineTimeOptions: {},
      startTimeOptions: {},
      endTimeOptions: {},
      regOpenOptions: {},
      regCloseOptions: {},
      timezones: {},
      loading: false,
      targets: [],
      saved: false,
      finalModel: {},
      fixErrors: false,
      peopleToUnassign: [],
    }
  },
  computed: {
    baleWord() {
      return this.lang.choice('search.bale', 1);
    },
    startIndex() {
      let query = this.$route.query;
      if (query && query.tab && this.tournament.id) {
        let tabs = ['sport', 'info', 'time', 'location', 'setup', 'registration', 'payment'];
        let index = tabs.indexOf(query.tab);
        return index ? index : 0;
      }
      return 0;
    },
    regform() {
      return this.tournament.regform ? this.tournament.regform : null;
    },
    saveData() {
      let exp = moment().add(5, 'minutes');
      exp = new Date(exp).toUTCString();
      return 'tournamentdata = ' + JSON.stringify(this.tournament) + '; expires=' + exp + ';';
    },
    sportTargets() {
      let returnArray = [];
      let targets = this.targetList[this.tournament.sport_id ? this.tournament.sport_id : 'all'];
      let clubTargets = this.targetList['club'];
      targets = {...targets, ...clubTargets};
      Object.keys(targets).forEach(function (id) {
        let target = {};
        target['id'] = id;
        target['name'] = targets[id];
        returnArray.push(target);
      });
      return returnArray;
    },
    bales_count() {
      if (!this.tournament.bales_count) {
        this.calculateCounts();
      }
      return this.tournament.bales_count
    },
    total() {
      let price = this.tournament.payments.price;
      let fee = this.tournament.payments.processing_fee;
      let amount = parseInt(price) + parseInt(fee);
      return amount;
    },
    setupPayments() {
      return !(this.club.stripe || this.club.auth_net_key);
    }
  },
  methods: {
    clearForm() {
      Swal.fire({
        title: 'Clear all form data for this tournament?',
        html: 'This will clear all data found on each step of the create tournament process',
        icon: 'warning',
      }).then(({dismiss}) => {
        if (!dismiss) {
          let newTournament = {
            'club_id': this.tournament.club_id,
            'timezone': this.tournament.timezone,
            'start_time': moment().minutes(0).seconds(0).format('D MMM YYYY h:mm A'),
            'end_time': moment().add(3, 'd').minutes(0).seconds(0).format('D MMM YYYY h:mm A'),
            'reg_open': moment().minutes(0).seconds(0).format('D MMM YYYY h:mm A'),
            'reg_close': moment().add(3, 'd').minutes(0).seconds(0).format('D MMM YYYY h:mm A'),
            'address': {
              'address1': null,
              'address2': null,
              'city': null,
              'state': null,
              'zip': null,
              'country': null,
            },
            'line_times': [{
              id: 1, time: moment().minutes(0).seconds(0).format('D MMM YYYY h:mm A')
            }],
            'locations': [{
              id: 1, bales: 5, name: 'Location 1'
            }],
            'bale_competitors': null,
            'rounds': [{
              'name': 'Round 1',
              'locations': [{
                id: 1, bales: 5, name: 'Location 1'
              }],
              'line_times': [{
                id: 1, time: moment().minutes(0).seconds(0).format('D MMM YYYY h:mm A')
              }],
              'target': null,
              'open': 1,
            }],
            'description': '',
            'auto_assignment': true,
            'club_devices': true,
            'payments': {
              'processing_fee': this.tournament.processing_fee,
              'price': 10,
              'pricing_extras': [],
              'allow_offline_payments': 1,
            },
            'lock_code': this.tournament.lock_code,
            'timed_prices': [],
            'hide_targets': true,
          };
          this.tournament = newTournament;
          this.$refs['tournament_form'].reset();
          this.$nextTick(() => {
            this.$validator.reset();
            this.errors.items = [];
          });
          console.log('FORM DELETED')
        }
      })
    },
    cloneExistingTournament() {
      let user;
      let inputOptions = {};
      let cloneCompetitors = false;
      let slug = '';
      this.$axios.get('/get_user').then(({data}) => {
        if (data['error']) {
          Swal.fire(data['error'])
        }
        if (data['success']) {
          user = data['user'];
          for (let index in user.managed_tournaments) {
            let tournament = user.managed_tournaments[index];
            if (!(tournament.is_master_postal || tournament.master_postal_id)) {
              inputOptions[tournament.slug] = tournament.name;
            }
          }
          
          if (Object.keys(inputOptions).length === 0) {
            inputOptions[false] = "You do not have any existing tournaments";
          }
          
          Swal.fire({
            title: 'Which tournament would you like to clone?',
            icon: 'question',
            confirmButtonText: 'Clone',
            showCancelButton: true,
            reverseButtons: true,
            input: 'select',
            inputOptions: inputOptions,
            inputValidator: (value) => {
              if (!value) {
                return 'Please choose a tournament to clone!'
              }
            }
          }).then((tournament) => {
            if (tournament.value) {
              slug = tournament.value;
              let inputOptions2 = {
                true: 'Clone with competitors',
                false: 'Just clone the details'
              };
              Swal.fire({
                title: 'Would you like to include all the competitors, or just the tournament details?',
                icon: 'question',
                confirmButtonText: 'Clone',
                showCancelButton: true,
                reverseButtons: true,
                input: 'radio',
                inputOptions: inputOptions2,
                animation: false,
                inputValidator: (value) => {
                  if (!value) {
                    return 'You need to choose a cloning option!'
                  }
                }
              }).then((result) => {
                if (result.value) {
                  cloneCompetitors = result.value;
                  Swal.fire({
                    title: 'What would you like to name the new tournament?',
                    icon: 'question',
                    confirmButtonText: 'Clone',
                    showCancelButton: true,
                    reverseButtons: true,
                    input: 'text',
                    animation: false,
                    inputValue: inputOptions[slug] + " (Clone)",
                    footer: 'This process may take a while on large tournaments.',
                    inputValidator: (nameValue) => {
                      if (nameValue == "") {
                        return 'Please enter a valid tournament name.'
                      }
                    },
                    showLoaderOnConfirm: true,
                    preConfirm: (tournamentName) => {
                      if (tournamentName) {
                        return this.$axios.post('/tournaments/' + slug + '/clone', {
                          'cloneCompetitors': cloneCompetitors,
                          'newTournamentName': tournamentName,
                        }).then(({data}) => {
                          if (data['error']) {
                            return Swal.fire(data['error'])
                          }
                          if (data['success']) {
                            window.location.href = '/tournaments/' + data['slug'];
                            return Swal.fire({
                              icon: 'success',
                              html: "Successfully cloned the tournament! Don't forget to change the dates and any other important details!",
                              showConfirmButton: false,
                            })
                          }
                        }).catch(function (error) {
                          console.log(error);
                        });
                      }
                    },
                  })
                }
              })
            }
          })
          
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getForm() {
      this.$axios
      .get('/regform/' + this.tournament.sport_id, {params: {'tournament': this.tournament.id ? this.tournament.id : null}})
      .then(({data}) => {
        this.tournament.regform = data.regform;
      })
      .catch(({response}) => {
        console.log(response);
      });
    },
    submitValid(index) {
      this.fixErrors = false;
      let refs = ['sport', 'info', 'time', 'location', 'setup', 'registration', 'payment'];
      let baleWord = this.baleWord;
      if (refs[index] === 'location') {
        this.checkBaleassignments()
        .then((resolve) => {
          if (resolve) {
            this.validateStep('tournament-' + refs[index]).then(valid => {
              if (valid) {
                this.submitForm();
              } else {
                this.fixErrors = true;
                this.focusError()
              }
            })
          } else {
            Swal.fire('Please update ' + baleWord + ' counts back to previous value.');
          }
        });
      } else {
        this.validateStep('tournament-' + refs[index]).then(valid => {
          if (valid && (!this.fixErrors && !this.errors.items.length)) {
            this.submitForm();
          } else {
            this.fixErrors = true;
            this.focusError()
          }
        })
      }
    },
    checkBaleassignments() {
      return new Promise((resolve, reject) => {
        this.peopleToUnassign = [];
        this.$axios.get('/tournaments/' + this.tournament.slug + '/assignments')
        .then(({data}) => {
          if (data['error']) {
            Swal.fire(data['error'].message)
          } else {
            let tournament = data['tournament'];
            if (tournament) {
              data['assignments'].forEach((assignment) => {
                this.tournament.locations.forEach((loc) => {
                  if (assignment.location === loc.name && parseInt(assignment.bale) > parseInt(loc.bales)) {
                    this.peopleToUnassign.push(assignment);
                  }
                })
              });
              if (this.peopleToUnassign.length > 0) {
                let shooter = " shooters";
                let has = " have";
                if (this.peopleToUnassign.length === 1) {
                  shooter = " shooter";
                  has = " has";
                }
                let baleWord = this.baleWord;
                Swal.fire({
                  titleText: this.peopleToUnassign.length + shooter + has + ' already been assigned to a removed ' + baleWord + '!',
                  showCancelButton: true,
                  reverseButtons: true,
                  confirmButtonText: 'Unassign' + shooter,
                  cancelButtonText: 'Cancel, leave ' + this.lang.choice('search.bale', 3),
                  footer: 'If you remove' + shooter + ' from ' + baleWord + ' you will need to manually assign them to a new ' + baleWord + '.',
                  width: 600
                }).then((result) => {
                  if (result.value) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                })
              } else {
                resolve(true);
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      });
    },
    nextTab(props, index) {
      let refs = ['sport', 'info', 'time', 'location', 'setup', 'payment'];
      if (refs[index] === 'location' && this.tournament.id) {
        this.checkBaleassignments()
        .then((resolve) => {
          if (resolve) {
            props.nextTab();
          } else {
            Swal.fire('Please update ' + this.baleWord + ' counts back to previous values.');
          }
        });
      } else {
        props.nextTab()
      }
    },
    focusError() {
      let firstError = this.$el.querySelector("[aria-invalid=true]");
      if (firstError) {
        let scroll = new Promise(function () {
          firstError.scrollIntoView({behavior: 'smooth', block: 'center'});
        });
        scroll.then(function () {
          firstError.focus();
        });
      }
    },
    handleValidation(isValid, tabIndex) {
      if (!isValid) {
        this.focusError()
      } else {
        if (!this.tournament.regform || (this.tournament.regform && !this.tournament.regform.length)) {
          this.getForm();
        }
      }
    },
    validateStep(name) {
      let refToValidate = this.$refs[name];
      if (refToValidate) {
        return refToValidate.validate();
      } else {
        return false;
      }
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    
    toggleOfflinePayments() {
      if (this.tournament.payments.allow_offline_payments) {
        this.tournament.payments.require_full_pay_at_register = false;
        this.tournament.payments.require_down_pay_at_register = false;
      }
    },
    toggleFullPay() {
      this.tournament.payments.allow_offline_payments = false;
      this.tournament.payments.require_down_pay_at_register = !this.tournament.payments.require_full_pay_at_register;
    },
    toggleDownPay() {
      this.tournament.payments.allow_offline_payments = false;
      this.tournament.payments.require_full_pay_at_register = !this.tournament.payments.require_down_pay_at_register;
    },
    updateRoundLocations(location) {
      let tournament = this.tournament;
      tournament.rounds.forEach(function (round, roundIndex) {
        round.locations.forEach(function (loc, locIndex) {
          if (loc.id === location.id) {
            tournament.rounds[roundIndex].locations[locIndex] = location;
          }
        })
      });
    },
    updateRoundTimes(time) {
      let tournament = this.tournament;
      tournament.rounds.forEach(function (round, roundIndex) {
        round.line_times.forEach(function (roundTime, timeIndex) {
          if (roundTime.id === time.id) {
            tournament.rounds[roundIndex].line_times[timeIndex] = time;
          }
        })
      });
    },
    addTime() {
      let newTime = new Date();
      newTime.setMinutes(0);
      let formatted = this.formatTime(newTime);
      if (formatted < this.tournament.start_time || formatted > this.tournament.end_time) {
        formatted = this.tournament.start_time;
      }
      let id = Math.max.apply(Math, this.tournament.line_times.map(function (t) {
        return t.id;
      }));
      let time = {'id': (id ? id + 1 : 1), 'time': formatted};
      this.tournament.line_times.push(time)
    },
    removeTime(index) {
      this.tournament.line_times.splice(index, 1)
    },
    capitalize(value) {
      if (!value) return '';
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    addLocation() {
      let id = Math.max.apply(Math, this.tournament.locations.map(function (loc) {
        return loc.id;
      }));
      let name = this.tournament.target_style === 'Bales' ? this.tournament.locations.length + 1 : String.fromCharCode(this.tournament.locations.length + 65);
      let newLocation = {
        'id': (id ? id + 1 : 1),
        'name': this.capitalize(this.$trans.choice('search.location', 1)) + ' ' + name,
        'bales': this.tournament.locations[0].bales
      };
      this.tournament.locations.push(newLocation)
    },
    removeLocation(index) {
      let removeLocation = this.tournament.locations[index];
      this.$delete(this.tournament.locations, index);
      this.tournament.rounds.forEach(function (round) {
        round.locations.forEach(function (location, i) {
          if (location.id === removeLocation.id) {
            round.locations.splice(i, 1);
          }
        })
      });
    },
    addRound() {
      let round = {
        'name': 'Round ' + (this.tournament.rounds.length + 1),
        'locations': [this.tournament.locations[0]],
        'line_times': [this.tournament.line_times[0]],
        'ends_count': '',
        'arrows_count': '',
        'open': 1
      };
      this.tournament.rounds.push(round)
    },
    generateHelperRounds(list) {
      this.tournament.rounds = list;
    },
    removeRound(index) {
      this.tournament.rounds.splice(index, 1)
    },
    calculateCounts() {
      let timeCount = this.tournament.line_times.length;
      let locationCount = this.tournament.locations.length;
      let bale_competitors = this.tournament.bale_competitors ? this.tournament.bale_competitors : 4;
      let total = timeCount * locationCount * bale_competitors;
      if (total < this.tournament.max_competitors) {
        this.tournament.bales_count = Math.ceil(this.tournament.max_competitors / total);
      }
    },
    formatTime(time) {
      return moment(time).format('D MMM YYYY h:mm A');
    },
    addRoundTime(selected, id) {
      let index = parseInt(id.replace('time', '').replace('Round', '').replace(' ', '')) - 1;
      this.tournament.rounds[index].line_times.push(selected);
      this.$set(this.lineTimeOptions, 'disable', this.tournament.line_times);
    },
    removeRoundTime(index, roundIndex) {
      this.tournament.rounds[roundIndex].line_times.splice(index, 1);
    },
    removeRoundLocation(index, roundIndex) {
      this.tournament.rounds[roundIndex].locations.splice(index, 1);
      
    },
    addRoundLocation(selected, id) {
      let index = parseInt(id.replace('location', '').replace('Round', '').replace(' ', '')) - 1;
      this.tournament.rounds[index].locations.push(selected);
    },
    submitForm() {
      let url = !this.tournament.id ? '/tournaments' : '/tournaments/' + this.tournament.slug;
      let method = !this.tournament.id ? 'POST' : 'PUT';
      this.loading = true;
      let tournament = this.tournament;
      this.$axios({
        method: method,
        url: url,
        data: {'tournament': tournament, 'peopleToUnassign': this.peopleToUnassign}
      }).then(({data}) => {
        this.saved = true;
        if (this.tournament.id) {
          this.loading = false;
        } else {
          this.$axios
          .get('/tournaments/' + data.slug + '/suite/tournament')
          .then(({data}) => {
            this.$store.commit('tournament/SET_TOURNAMENT', {
              'tournament': data.tournament
            });
            window.location = '/tournaments/' + data.tournament.slug
          })
        }
      }).catch(({response}) => {
        this.loading = false;
        console.log(response);
      });
    },
    onStartChange(time) {
      this.endTimeOptions['minDate'] = time;
      this.lineTimeOptions['minDate'] = time;
    },
    onEndChange(time) {
      this.lineTimeOptions['maxDate'] = time;
    },
    onOpenChange(time) {
      this.regCloseOptions['minDate'] = time;
    },
    setTimeOptions() {
      this.lineTimeOptions = {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        minDate: this.tournament.start_time,
        maxDate: this.tournament.end_time,
        disable: this.tournament.line_times,
        static: true,
      };
      this.startTimeOptions = {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        static: true,
      };
      this.regOpenOptions = {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        static: true,
      };
      this.endTimeOptions = {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        minDate: this.tournament.start_time,
        static: true,
      };
      this.regCloseOptions = {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        minDate: this.tournament.reg_open,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        static: true,
      };
      this.timezones = moment.tz.names;
    },
    savePartial() {
      if (!this.saved) {
        document.cookie = this.saveData;
      }
    }
  },
  beforeMount() {
    this.setTimeOptions();
  },
  created() {
    let vm = this;
    window.addEventListener('beforeunload', function () {
      vm.savePartial();
    });
  },
  mounted() {
    if (this.tournament.id) {
      // this.$refs.tournament_form.activateAll();
    }
  }
}
</script>

<style scoped>
.multiselect_blue :deep(.multiselect__tag),
.multiselect_blue :deep(.multiselect__option--highlight),
.multiselect_blue :deep(.multiselect__tag-icon:hover) {
  @apply bg-blue-600;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 0;
}

</style>
