<template>
  <div class="w-full lg:w-1/2 xl:w-1/4">
    <div class="card card-body p-1 m-1">
      <div class="flex flex-col md:flex-row">
        <div class="flex-1 flex flex-col sm:flex-row justify-between">
          <div class="flex flex-col">
            <a :href="'/clubs/' + club.slug" class="text-blue-600 text-xl sm:text-2xl">{{ club.name }}</a>
            <div class="text-lg text-gray-800">
              {{ location }}
            </div>
          </div>
        
        </div>
        <div class="flex-1">
          <div class="sm:w-3/4 md:w-56 sm:mx-auto lg:mx-0">
            <club-map :club="club" :zoom="4"/>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ClubMap from "./ClubMap.vue";

export default {
  name: "ClubListItem",
  components: {ClubMap},
  props: ['club'],
  computed: {
    location() {
      let address = '';
      if (this.club.range_address && this.club.range_address.state) {
        address += (this.club.range_address.city ? this.club.range_address.city + ', ' : '') +
          this.club.range_address.state;
      } else if (this.club.mailing_address && this.club.range_address.state) {
        address += (this.club.mailing_address.city ? this.club.mailing_address.city + ', ' : '') +
          this.club.mailing_address.state;
      }
      return address;
    },
  },
}
</script>

<style scoped>

</style>
