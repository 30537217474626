<template>
  <div>
    <div @click="deleteTournament" v-if="useSlot">
      <slot></slot>
    </div>
    <button v-else @click="deleteTournament" class="m-2 btn btn-red-600">
      DELETE {{ tournament.name.toUpperCase() }}
    </button>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: "DeleteTournament",
  props: ['tournament', 'useSlot'],
  methods: {
    deleteTournament() {
      let clubSlug = this.tournament.club.slug;
      let deleteText = 'This will completely erase the tournament, competitors, and scores.';
      let feeText = "Any outstanding fees will not be deleted.";
      Swal.fire({
        titleText: 'Are you sure you want to delete this tournament?',
        html: `${deleteText} ${feeText}`,
        icon: 'error',
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: 'Delete Tournament',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: () => {
          return this.$axios.delete(`/tournaments/${this.tournament.slug}`, {})
          .catch(function (error) {
            return Swal.fire(error);
          });
        },
      }).then(value => {
        if (value.value) {
          Swal.fire('The Tournament has been Deleted!');
          window.location.href = '/clubs/' + clubSlug;
        }
      })
    }
  }
}
</script>
<style scoped>

</style>