<template>
  <div v-if="isJeff">
    <div class="mb-2">
      <a href="/template_editor" class="btn btn-outline-blue-600">Template Editor</a>
    </div>
    <bad-assignments/>
  
  </div>
</template>
<script>
import BadAssignments from "./BadAssignments.vue";

export default {
  name: "JeffData",
  components: {BadAssignments},
  props: ['user'],
  computed: {
    isJeff() {
      return this.user.id === 1;
    }
  }
}
</script>
<style scoped>

</style>