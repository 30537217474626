<template>
  <div class="text-2xl flex justify-center"> This feature is only available online.</div>
</template>
<script>
export default {
  name: "OnlineFeature"
}
</script>
<style scoped>

</style>