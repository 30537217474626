<script setup>
import {ErrorMessage, Field} from "vee-validate";
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

</script>

<template>
  <div class="bs-card">
    <div class="mb-4">
      <div class="bs-section-header">Title</div>
      <Field v-model="store.name" class="bs-input-box" name="title" rules="required|clean|min:2" type="text"
             placeholder="State Indoor Championship"/>
      <ErrorMessage name="title" class="errorText"/>
    </div>
    <div>
      <div class="bs-section-header">Description</div>
      <Field class="bs-input-box w-full h-28" name="description" rules="clean|min:2" as="textarea" v-model="store.description"
             placeholder="(Instructions, details, policies, etc)"/>
      <ErrorMessage name="description" class="errorText"/>
    </div>
  
  </div>
</template>

<style scoped>

</style>