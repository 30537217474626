<template>
  <div class="w-full m-2 flex flex-wrap">
    <div class="w-full flex flex-col border-b pb-2 mb-2" v-if="clubTargets.length">
      <div class="text-2xl border-b mb-2">Custom Club Targets</div>
      <div class="flex flex-wrap">
        <div class="h-center w-1/5 mb-2">
          <button class="btn btn-blue-600 w-20 h-20 mx-auto"
                  @click="edit({name: 'Custom', values: [{name: 'M', value: 0}]})">
            Create New Target
          </button>
        </div>
        <div v-for="clubTarget in clubTargets" class="w-1/5 mb-2 bg-white">
          <div class='flex flex-col mx-1 p-1 h-full'
               :class="tournamentTargets.findIndex(f => f.id === clubTarget.id) === -1 ? '' : 'bg-blue-100'">
            <div class="flex justify-between flex-wrap mb-1">
              <button class="btn-xs px-1 btn-outline-gray-600" @click="edit(clubTarget)">edit</button>
              {{ clubTarget.name }}
              <button class="btn-xs px-1 btn-outline-blue-600" @click="update('add',clubTarget)"
                      v-if="tournamentTargets.findIndex(f => f.id === clubTarget.id) === -1">
                add
              </button>
              <button class="btn-xs px-1 btn-outline-red-600" @click="update('remove',clubTarget)" v-else>
                remove
              </button>
            </div>
            <div class="flex flex-wrap">
              <div class="w-1/3" v-for="zone in clubTarget.values">
                <div class="scoreButton text-2xl">
                  {{ zone.name }}
                  <div class="text-lg">
                    <div>v:{{ zone.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="target" :key="'key_'+targetKey" class="w-full flex flex-wrap">
      <div class='md:w-1/3 flex flex-col' :key="'key2_'+targetKey">
        <Form @submit="addValues">
          <div class="flex flex-col mb-2">
            <label for="target_name">Target Name</label>
            <Field type="input" v-model="target.name" name="target_name" ref="target_name"
                   class="bs-form-box w-full" rules="clean|json"/>
          </div>
          <div class="flex flex-col mb-2">
            <label>Zone Name</label>
            <Field @keyup="required = ''" type="text" v-model="name" name="name" ref="name"
                   class="bs-form-box w-full" maxlength="3" rules="clean|json"/>
          </div>
          <div class="flex flex-col mb-2">
            <label>Zone Value</label>
            <Field @keyup="required = ''" type="number" v-model="value" name="value" class="bs-form-box w-full"
                   required/>
          </div>
          <span class="errorText">{{ required }}</span>
          <ErrorMessage class="text-sm text-red-600" name="target_name"/>
          <ErrorMessage class="text-sm text-red-600" name="name"/>
          <ErrorMessage class="text-sm text-red-600" name="value"/>
          
          <div class="flex justify-end mb-2">
            <button type="submit" class="btn btn-green-500">
              Add Zone
            </button>
          </div>
          <hr>
          <button v-if="!target.id" @click="save" class="btn btn-blue-600 mt-2">Save Custom Target</button>
          <div v-else>
            <button @click="update('update',target)" class="btn btn-blue-600 mt-2">
              Update {{ target.name }}
            </button>
            <button @click="save" class="btn btn-blue-600 mt-2">Save As New Target</button>
          </div>
        </Form>
      </div>
      <div class='md:w-2/3 flex flex-col px-12'>
        <div class="text-center text-lg mb-3">Target Name: {{ target.name }}</div>
        <div class="flex flex-wrap">
          <div class="w-1/3 bg-white" v-for="zone in target.values">
            <div class="scoreButton text-4xl xs:text-5xl sm:text-6xl md:text-7xl">
              {{ zone.name }}
              <div class="text-lg mx-2 mb-1 flex justify-between">
                <div>value:{{ zone.value }}</div>
                <button @click="remove(zone.name)" class="text-red-600">
                  <i class="far fa-times-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, ErrorMessage, Field} from "vee-validate";
import Swal from "sweetalert2";

export default {
  name: "CustomTargets",
  components: {Form, ErrorMessage, Field},
  props: ['tournamentIn'],
  data() {
    return {
      name: '',
      value: null,
      target: this.tournamentIn.club.targets.length ? null : {name: 'Custom', values: [{name: 'M', value: 0}]},
      targetKey: 0,
      takenNames: [],
      required: '',
      tournamentTargets: this.tournamentIn.targets,
    }
  },
  created() {
    this.$axios.get('/targets/taken', {params: {club_id: this.tournament.club_id}})
    .then(({data}) => {
      this.takenNames = data.targets;
    })
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    clubTargets() {
      return this.tournament.club.targets
    },
  },
  methods: {
    edit(target) {
      this.target = {id: target.id ? target.id : null, name: target.name, values: target.values};
      this.targetKey++;
      this.$nextTick(() => {
        this.$refs.target_name.$el.focus();
      });
      
    },
    addValues() {
      if (this.name && this.value) {
        this.remove(this.name);
        this.target.values.push({name: this.name, value: this.value});
        this.target.values.sort((a, b) => ((parseInt(a.value) < parseInt(b.value)) ? 1 : -1));
        this.name = '';
        this.value = null;
        this.$nextTick(() => {
          this.$refs.name.$el.focus();
        });
      } else {
        this.required = 'Missing required fields'
      }
    },
    remove(name) {
      let exists = this.target.values.findIndex(f => f.name === name);
      if (exists !== -1) {
        this.target.values.splice(exists, 1);
      }
    },
    checkName() {
      return this.takenNames.includes(this.target.name);
    },
    save() {
      if (!this.target.name) {
        return Swal.fire('Target name is required');
      }
      let taken = this.checkName();
      if (taken) {
        Swal.fire({
          title: 'Name Taken',
          html: 'The new target must be uniquely named to prevent confusion.'
        })
      } else {
        this.$axios.post('/targets/store', {target: this.target, tournament: this.tournament.id})
        .then(({data}) => {
          this.tournament.club.targets = data.targets;
          this.takenNames.push(data.target.name);
          this.tournamentTargets.push(data.target);
          this.updateLocalVariables();
        })
      }
    },
    update(type, target) {
      this.$axios.post(`/targets/${type}`, {target: target, tournament: this.tournament.id})
      .then(({data}) => {
        if (type === 'update') {
          this.tournament.club.targets = data.targets;
        } else {
          this.tournamentTargets = data.targets;
        }
        this.updateLocalVariables();
      })
    },
    updateLocalVariables() {
      this.targetKey++
      this.target = null;
      this.name = '';
      this.value = null;
      this.required = '';
    }
  }
}
</script>

<style scoped>
.scoreButton.scoreSelected,
.scoreButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.scoreButton {
  @apply border-blue-800 border text-blue-800 w-full font-black text-center align-middle;
}
</style>
