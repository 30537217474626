<script setup>
import AppCard from "./AppCard.vue";
import {computed, onMounted} from "vue";
import ProfileCard from "./ProfileCard.vue";
import RegisterCard from "./RegisterCard.vue";
import TournamentCard from "./TournamentCard.vue";
import CoachCard from "./CoachCard.vue";
import {useDashboardStore} from "../../../stores/DashboardStore";
import RecommendedCard from "./RecommendedCard.vue";
import RecentCard from "./RecentCard.vue";
import UpcomingCard from "./UpcomingCard.vue";
import TournamentsCard from "./TournamentsCard.vue";
import ClubsCard from "./ClubsCard.vue";
import NewTournamentButton from "../../partials/NewTournamentButton.vue";
import ArchersCard from "./ArchersCard.vue";

const props = defineProps(['user'])

const roles = computed(() => {
  let user = props.user;
  let list = []
  if (user.competitors.length) list.push('competitor');
  if (user.clubs.length) list.push('admin');
  if (user.managed_archers.length) list.push('coach');
  return list;
})

const store = useDashboardStore();

onMounted(() => {
  store.setup(props.user);
})

const isGod = computed(() => {
  return props.user.roles?.find((f) => f.name === 'god');
})
</script>

<template>
  <div class="pageStyle" @click="store.toggled = null">
    <div v-if="user.managed_clubs.length || isGod" class="flex justify-between">
      <NewTournamentButton v-if="user.managed_clubs.length" :custom-button="true" :user="user" style="width:184px;">
        <div class="bs-shadow-card button cursor-pointer"><i class="fa-solid fa-plus mr-2"></i> New tournament</div>
      </NewTournamentButton>
      <div>&nbsp;</div>
      <a v-if="isGod" href="/godmode/dashboard" class="bs-shadow-card block button orange"> View godmode home</a>
    </div>
    <ProfileCard :class="store.displayClass('profile')" name="profile"/>
    <RecentCard :class="store.displayClass('recent')"/>
    <UpcomingCard :class="store.displayClass('upcoming')"/>
    <RecommendedCard :class="store.displayClass('recommended')"/>
    <RegisterCard :class="store.displayClass('register')" name="register"/>
    <TournamentCard :class="store.displayClass('tournament')" name="tournament"/>
    <TournamentsCard :class="store.displayClass('tournaments')"/>
    <ClubsCard :class="store.displayClass('clubs')"/>
    <CoachCard :class="store.displayClass('coach')" :user="user" name="coach"/>
    <archers-card :class="store.displayClass('archers')" :user="user"/>
    <AppCard :class="store.displayClass('app')" :roles="roles" name="app"/>
  </div>
</template>

<style scoped>
.button {
  @apply font-bold mb-4;
  padding-left: .938rem;
  padding-right: .938rem;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .018rem;
  width: 184px;
}

.orange {
  @apply text-white px-0;
  text-align: center;
  background-color: #FB5202;
}

.pageStyle {
  @apply flex flex-col;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (max-width: 1100px) {
  .pageStyle {
    @apply mt-0;
  }
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}
</style>