import localforage from 'localforage';
import moment from "moment";
import axios from "axios";

const state = {
  tournament: null,
  localServerIp: '192.168.86.75',
  offlineAll: {},
  locked: [],
  online: true,
  reloaded: false,
};

const getters = {
  getReloaded(state) {
    return state.reloaded;
  },
  getTournament(state) {
    return state.tournament;
  },
  getCompetitorsForTeam: (state) => (team) => {
    return Object.values(state.tournament.competitorList).filter(function (c) {
      let hasTeam = c.regform.find(f => f.option === 'team/club-name' || f.option === 'team_club_name');
      return hasTeam && hasTeam.name === team;
    })
  },
  getTeams(state) {
    let teamList = [];
    if (state.tournament && state.tournament.sport_id === 1 && state.tournament.competitorList && Object.keys(state.tournament.competitorList).length) {
      Object.values(state.tournament.competitorList).forEach(function (c) {
        let hasTeam = c.regform.find(f => f.option === 'team/club-name' || f.option === 'team_club_name');
        if (hasTeam && hasTeam !== 'undefined' && !teamList.includes(hasTeam.name)) {
          teamList.push(hasTeam.name);
        }
        let hasType = c.regform.find(f => f.option === 'team/club' || f.option === 'team_club');
        c.team = hasTeam && hasTeam !== 'undefined' ? hasTeam.name : null;
        c.teamType = hasType && hasType !== 'undefined' ? hasType.name : null;
      })
    }
    return teamList;
  },
  getOfflineScores(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      return state.offlineAll[state.tournament.id].offlineScores;
    }
    return [];
  },
  getOfflineCompetitors(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      return state.offlineAll[state.tournament.id].offlineCompetitors;
    }
    return [];
  },
  getOfflineAssignments(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      return state.offlineAll[state.tournament.id].offlineAssignments;
    }
    return [];
  },
  getLocked: (state) => (slug) => {
    return state.locked.includes(slug);
  },
  getOnline(state) {
    return state.online;
  },
  getTieBreakers(state) {
    return state.tournament.tieBreakers;
  },
  getAssignments(state) {
    return state.tournament.assignments;
  }
};

const actions = {
  quickAdd({state, commit}, competitor) {
    commit('ADD_COMPETITOR_TO_SCORELIST', competitor);
    commit('ADD_COMPETITOR_TO_ASSIGNMENTS', competitor);
  },
  addCompetitor({state, commit}, competitor) {
    commit('ADD_COMPETITOR', competitor)
    commit('ADD_COMPETITOR_TO_SCORELIST', competitor);
  },

  async updateScore({state, commit}, score) {
    if (score.target_hit === 'EF') {
      commit('ADD_TO_RESHOOTS', score);
    }
    if (state.online) {
      let fullCode = await localforage.getItem(`${state.tournament.id}_code`);
      let code;
      if (fullCode) code = fullCode.id;
      let unsavedChanges = getOfflineAssignments(state).length + getOfflineScores(state).length + getOfflineCompetitors(state).length;
      if (unsavedChanges) { //check for unlinked scores;
        commit('UPDATE_OFFLINE_SCORE', score); //add score to any other unlinked scores
        axios.post('/tournaments/' + state.tournament.slug + '/sync', {
          'scores': getOfflineScores(state),
          'assignments': getOfflineAssignments(state),
          'competitors': getOfflineCompetitors(state),
          'code': code,
        }).then((response) => {
          if (response.data.success) {
            commit("CLEAR_OFFLINE_SCORES");
            commit("CLEAR_OFFLINE_COMPETITORS");
            commit("CLEAR_OFFLINE_ASSIGNMENT");
          }
          if (response.data.disabled) {
            fullCode.disabled = true;
            localforage.setItem(`${state.tournament.id}_code`, fullCode);
          }
        }).catch(({response}) => {
          console.log(response);
        });
      } else { //no unlinked scores
        axios.post('/tournaments/' + state.tournament.slug + '/score', {scores: [score], code: code,})
        .then((response) => {
          if (response.data.success) {
            if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
              let offline = state.offlineAll[state.tournament.id].offlineScores;
              if (offline.length) {
                commit("CLEAR_OFFLINE_SCORES");
              }
            }
            if (response.data.disabled) {
              fullCode.disabled = true;
              localforage.setItem(`${state.tournament.id}_code`, fullCode);
            }
          } else {
            //failed to update score, adding it to offline scores for later;
            commit('UPDATE_OFFLINE_SCORE', score);
            axios.get('/online').then((data) => {
              commit('SET_ONLINE', true);
            }).catch(() => {
              commit('SET_ONLINE', false);
            });
          }
        })
        .catch(({response}) => {
          //failed to update score, adding it to offline scores for later;
          commit('UPDATE_OFFLINE_SCORE', score);
          axios.get('/online').then((data) => {
            commit('SET_ONLINE', true);
          }).catch(() => {
            commit('SET_ONLINE', false);
          });
        });
      }
    } else { //offline
      commit('UPDATE_OFFLINE_SCORE', score);
    }
  },
  updateLocalScore({state, commit}, score) {
    commit('UPDATE_LOCAL_SCORE', score);
  },
  updateOfflineAssignments({state, commit}, details) {
    commit('UPDATE_OFFLINE_ASSIGNMENTS', details);
  },
  resetOffline({state, commit}) {
    commit('RESET_OFFLINE_SCORES');
    commit('RESET_OFFLINE_COMPETITORS');
    commit('RESET_OFFLINE_ASSIGNMENTS');
  },
  setLocked({commit}, slug) {
    commit('SET_LOCKED', slug);
  },
  setUnlocked({commit}, slug) {
    commit('SET_UNLOCKED', slug);
  },
  clearTournament({commit}) {
    commit('CLEAR_TOURNAMENT')
  },
  async setOnline({state, commit}, on) {
    commit('SET_ONLINE', on);
    if (on) {
      let unsavedChanges = getOfflineAssignments(state).length + getOfflineScores(state).length + getOfflineCompetitors(state).length;
      if (unsavedChanges) { //check for unlinked scores;
        let code;
        let fullCode = await localforage.getItem(`${state.tournament.id}_code`);
        if (fullCode) code = fullCode.id;
        axios.post('/tournaments/' + state.tournament.slug + '/sync', {
          'scores': getOfflineScores(state),
          'assignments': getOfflineAssignments(state),
          'competitors': getOfflineCompetitors(state),
          'code': code,
        }).then((response) => {
          if (response.data.success) {
            commit("CLEAR_OFFLINE_SCORES");
            commit("CLEAR_OFFLINE_COMPETITORS");
            commit("CLEAR_OFFLINE_ASSIGNMENT");
          }
          if (response.data.disabled) {
            fullCode.disabled = true;
            localforage.setItem(`${state.tournament.id}_code`, fullCode);
          }
          if (response.data.new_ids) {
            axios.get('/tournaments/' + state.tournament.slug + '/suite/tournament')
            .then(({data}) => {
              state.tournament = data.tournament;
            }).catch((error) => {
              console.log("axios error", error);
            });
          }
        }).catch(({response}) => {
          console.log(response);
        });
      }
    }
  }

};

const mutations = {
  ADD_TO_RESHOOTS(state, score) {
    state.tournament.reshoots.push(score);
  },
  UPDATE_ASSIGNMENT(state, details) {
    let old = Object.values(state.tournament.assignments).findIndex((f) => {
      return f.competitor_id === details.competitor && f.round_id === details.round;
    });
    state.tournament.assignments[old]['line_time'] = details.details['time'];
    state.tournament.assignments[old]['location'] = details.details['location'];
    state.tournament.assignments[old]['bale'] = details.details['bale'];
    state.tournament.assignments[old]['position'] = details.details['position'];

  },
  UPDATE_OFFLINE_SCORE(state, score) {
    if (!state.offlineAll.hasOwnProperty(state.tournament.id)) {
      state.offlineAll[state.tournament.id] = {
        tournament: state.tournament.id,
        offlineCompetitors: [],
        offlineScores: [],
        offlineAssignments: []
      };
    }
    state.offlineAll[state.tournament.id]['offlineScores'].push(score);
  },
  REMOVE_OFFLINE_SCORE(state, score) {
    let exists = state.offlineAll[state.tournament.id]['offlineScores'].findIndex(function (f) {
      return f.competitor_id === score.competitor_id && f.round === score.round && f.arrow === score.arrow && f.end === score.end
    });
    if (exists !== -1) {
      delete state.offlineAll[state.tournament.id]['offlineScores'][exists];
    }
  },
  SET_LOCKED(state, slug) {
    let index = state.locked.indexOf(slug);
    if (index === -1) {
      state.locked.push(slug);
    }
  },
  SET_UNLOCKED(state, slug) {
    let index = state.locked.indexOf(slug);
    if (index !== -1) {
      state.locked.splice(index, 1);
    }
  },
  SET_TOURNAMENT(state, data) {
    const val = {...data.tournament}
    Object.freeze(val);
    state['tournament'] = val;
    localforage.getItem('allTournaments').then(async (allTournaments) => {
      if (!allTournaments) {
        allTournaments = [];
      }
      //remove old tournaments from localforage
      allTournaments = allTournaments.filter(f => moment(new Date(f.end_time)) > moment().subtract(5, 'days'));
      let existing = allTournaments.findIndex(f => f.id === state.tournament.id);
      if (existing !== -1) {
        allTournaments.splice(existing, 1);
      }
      let value = JSON.parse(JSON.stringify(state.tournament));
      allTournaments.push(value);
      await localforage.setItem('allTournaments', allTournaments)
    });

    if (!state.offlineAll.hasOwnProperty(val.id)) {
      Object.keys(state.offlineAll).forEach(function (id) {
        let tournament = state.offlineAll[id];
        if (!tournament.offlineCompetitors.length && !tournament.offlineScores.length && !tournament.offlineAssignments.length) {
          delete state.offlineAll[id];
        }
      });
      state.offlineAll[val.id] = {
        tournament: val.id,
        offlineCompetitors: [],
        offlineScores: [],
        offlineAssignments: []
      };
    }
    state.reloaded = !state.reloaded;
  },
  ADD_COMPETITOR(state, competitor) {
    getOfflineCompetitors(state).push(competitor);
    state.tournament.rounds.forEach(function (round) {
      if (round.open) {
        let time = round.line_times.find(t => t.id === competitor.line_time) ? competitor.line_time : 'noTime';
        state.tournament.unassignedCompetitors.rounds[round.id].line_times[time].competitors.push(competitor.id);
        state.tournament.unassignedCompetitors.rounds[round.id].line_times[time].competitors_count++;
        state.tournament.unassignedCompetitors.rounds[round.id].competitors_count++;
        state.tournament.unassignedCompetitors.competitors_count++;
      }
    });
  },
  ADD_COMPETITOR_TO_SCORELIST(state, competitor) {
    state.tournament.competitorList[competitor.id] = competitor;

    let scoreArray = {'points': 0, 'complete': 0, 'rounds': []};
    state.tournament.tieBreakers.forEach(breaker => {
      scoreArray[breaker] = 0;
    });
    //create empty nested array of all possible score options
    let roundData = {};
    state.tournament.rounds.forEach(function (round) {
      let endData = {};
      for (let end = 1; end <= round.ends_count; end++) {
        let arrowData = {};
        for (let arrow = 1; arrow <= round.arrows_count; arrow++) {
          arrowData[arrow] = {'arrow': arrow, 'score': 0, 'target_hit': null, 'target_id': null};
        }
        endData[end] = {'end': end, 'points': 0, 'complete': 0, 'arrows': arrowData};
        state.tournament.tieBreakers.forEach(breaker => {
          endData[end][breaker] = 0;
        });
      }
      roundData[round.id] = {'round_id': round.id, 'points': 0, 'complete': 0, 'ends': endData};
      state.tournament.tieBreakers.forEach(breaker => {
        roundData[round.id][breaker] = 0;
      });
    });
    scoreArray['rounds'] = roundData;
    state.tournament.scoreList[competitor.id] = scoreArray;
  },
  ADD_COMPETITOR_TO_ASSIGNMENTS(state, competitor) {
    competitor.assignments.forEach(assignment => {
      let loc = state.tournament.locations.find(f => f.name === assignment.location);
      state.tournament.assignedCompetitors.rounds[assignment.round_id].line_times[assignment.line_time].locations[loc.id].bales[assignment.bale].competitors.push(competitor.id);
    });
  },

  UPDATE_COMPETITOR(state, data) {
    state.tournament.assignedCompetitors
      .rounds[data.score.round]
      .line_times[data.lineTime.id]
      .locations[data.location.id]
      .bales[data.bale]
    .competitors.splice(data.competitorIndex, 1, data.competitor);
  },

  UPDATE_LOCAL_SCORE(state, score) {
    let roundWord = score['round'] === 0 ? 'ladders' : 'rounds';
    let roundId = score['round'] === 0 ? `${score['ladder']}_${score['step']}` : score['round'];
    let arrowString = score['arrow'].toString();
    let shortScore = {
      arrow: score['arrow'],
      target_hit: score['target_hit'],
      target_id: score['target_id'],
      score: score['score']
    };
    let competitorScores = state.tournament.scoreList[score['competitor_id']];
    let oldScore = {score: 0, target_hit: null};
    if (competitorScores[roundWord][roundId].ends[score['end']]) {
      if (Object.keys(competitorScores[roundWord][roundId].ends[score['end']].arrows).includes(arrowString)) {
        oldScore = competitorScores[roundWord][roundId].ends[score['end']].arrows[arrowString];
      } else {
        oldScore = competitorScores[roundWord][roundId].ends[score['end']].extras[arrowString];
      }
    }

    let pointsChange = score['score'] ? score['score'] : 0;
    let completeChange = score['target_hit'] ? 1 : 0;

    pointsChange -= oldScore.score;
    completeChange -= oldScore.target_hit ? 1 : 0;

    state.tournament.tieBreakers.forEach(breaker => {
      let change = score['target_hit'] === breaker ? 1 : 0;
      change -= oldScore.target_hit === breaker ? 1 : 0;
      competitorScores[breaker] += change;
      competitorScores[roundWord][roundId][breaker] += change;
      competitorScores[roundWord][roundId].ends[score['end']][breaker] += change;
    });

    competitorScores.points += pointsChange;
    competitorScores[roundWord][roundId].points += pointsChange;
    competitorScores[roundWord][roundId].ends[score['end']].points += pointsChange;
    competitorScores.complete += completeChange;
    competitorScores[roundWord][roundId].complete += completeChange;
    competitorScores[roundWord][roundId].ends[score['end']].complete += completeChange;
    if (Object.keys(competitorScores[roundWord][roundId].ends[score['end']].arrows).includes(arrowString)) {
      competitorScores[roundWord][roundId].ends[score['end']].arrows[arrowString] = shortScore;
    } else {
      competitorScores[roundWord][roundId].ends[score['end']].extras[arrowString] = shortScore;
    }
  },
  UPDATE_OFFLINE_ASSIGNMENTS(state, details) {
    let existing = getOfflineAssignments(state).findIndex(g => g.competitor === details.competitor && g.round === details.round);
    if (existing !== -1) {
      getOfflineAssignments(state).splice(existing, 1);
    }
    getOfflineAssignments(state).push(details);
  },
  RESET_OFFLINE_SCORES(state) {
    clearOfflineScores(state)

  },
  RESET_OFFLINE_COMPETITORS(state) {
    clearOfflineCompetitors(state)

  },
  RESET_OFFLINE_ASSIGNMENTS(state) {
    clearOfflineAssignments(state)

  },
  CLEAR_TOURNAMENT(state) {
    if (state.tournament) {
      state.tournament = null;
    }
  },
  CLEAR_OFFLINE_SCORES(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      let offline = state.offlineAll[state.tournament.id].offlineScores;
      if (offline.length) {
        offline.splice(0);
      }
    }
  },
  CLEAR_OFFLINE_COMPETITORS(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      let offline = state.offlineAll[state.tournament.id].offlineCompetitors;
      if (offline.length) {
        offline.splice(0);
      }
    }
  },
  CLEAR_OFFLINE_ASSIGNMENT(state) {
    if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
      let offline = state.offlineAll[state.tournament.id].offlineAssignments;
      if (offline.length) {
        offline.splice(0);
      }
    }
  },
  SET_ONLINE(state, on) {
    state.online = on;
  }
};

function getOfflineScores(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    return state.offlineAll[state.tournament.id].offlineScores;
  }
  return []
}

function getOfflineCompetitors(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    return state.offlineAll[state.tournament.id].offlineCompetitors;
  }
  return []
}

function getOfflineAssignments(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    return state.offlineAll[state.tournament.id].offlineAssignments;
  }
  return []
}

function clearOfflineScores(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    state.offlineAll[state.tournament.id].offlineScores.length = 0;
  }
}

function clearOfflineCompetitors(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    state.offlineAll[state.tournament.id].offlineCompetitors.length = 0;
  }
}

function clearOfflineAssignments(state) {
  if (state.tournament && state.offlineAll.hasOwnProperty(state.tournament.id)) {
    state.offlineAll[state.tournament.id].offlineAssignments.length = 0;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
