<template>
  <div class="btn-lg xs:text-2xl text-3xl btn-outline-gray-600">
    <select name="selected_target " class="selectButton"
            v-model="target" @change="changeTarget">
      <option v-for="t in tournament.targetValues" :value="t">
        {{ t.name }}
      </option>
    </select>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "TargetSelect",
  mixins: [mixin],
  computed: {
    ...mapWritableState(useDisplayStore, ['competitor', 'target']),
  },
  methods: {
    changeTarget() {
      this.competitor.target_id = this.target.id;
    },
  }
}
</script>

<style scoped>

.selectButton:hover {
  @apply bg-gray-600 text-white;
}

</style>
