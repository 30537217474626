<script setup>

import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field, Form} from "vee-validate";

const store = useTournamentStore();

</script>

<template>
  <div>
    <div v-if="store.registrationExtras.length === 0">
      <button @click.prevent="store.addOption(1)" class="addOption">
        <i class="fa-regular fa-plus"></i> Add another option
      </button>
    </div>
    <div v-else>
      <Form>
        <div class="bs-card">
          <div class="bs-section-header">Form options</div>
          <div v-for="(option, index) in store.registrationExtras" class="formOption">
            <Field :name="`option ${index + 1} name`" class="bs-input-box" v-model="option.name"
                   rules="required|clean"/>
            <ErrorMessage :name="`option ${index + 1} name`" class="errorText"/>
            <div class="mt-2 ml-6">
              <div v-for="(subOption, i) in option.multiple" class="">
                <div class="flex extraOption">
                  <Field :name="`option ${index + 1}-${i + 1}`" class="bs-input-box"
                         v-model="option.multiple[i]['label']"
                         rules="required|clean"/>
                  <span v-if="option.multiple.length > 2" @click="store.removeSubOption(index, i)" class="trash">
                    <i class="fa-regular fa-trash-can"></i>
                  </span>
                </div>
                <ErrorMessage :name="`option ${index + 1}-${i + 1}`" class="errorText"/>
              </div>
              <Field :name="`subOption_${index}_next`" class="bs-input-box" placeholder="Add another value"
                     v-model="store.extrasNext[index]" rules="required|clean"
                     @blur="store.addNextToExtras(index, option.multiple.length)"/>
            </div>
            <button @click.prevent="store.removeOption(index)" class="btn btn-white">Delete</button>
          </div>
          <button @click.prevent="store.addOption(store.registrationExtras.length + 1)" class="addOption">
            <i class="fa-regular fa-plus"></i> Add another option
          </button>
        </div>
      </Form>
    </div>
  
  </div>
</template>

<style scoped>
.extraOption {
  @apply mb-1;
  position: relative;
}

.trash {
  position: absolute;
  top: 7px;
  right: 20px;
}

.formOption {
  @apply border border-stone-100;
  padding: 16px;
  margin-bottom: 16px;
}

.addOption {
  @apply ml-3 text-black font-medium;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 16px;
}

.btn {
  @apply text-black font-semibold border border-stone-100;
  box-shadow: 0px 3px 6px #00000029;
  width: 92px;
  height: 32px;
  margin-top: 16px;
  letter-spacing: .28px;
  line-height: 17px;
}
</style>