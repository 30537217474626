<template>
  <div class="m-2 w-full">
    <div class="text-3xl">{{ baleWord }} Settings</div>
    <hr>
    <div class="flex flex-col my-2">
      <div class="text-2xl"> Positions:</div>
      <div class="flex">
        <Field
          v-for="(position, index) in positions"
          v-model="positions[index]"
          rules="required|clean|json"
          :name="`position ${index + 1}`"
          :id="`position ${index + 1}`"
          class="bs-form-box w-16 mx-1"/>
        <button class="btn btn-blue-600" @click="renamePositions">
          Save Position Name Changes
          <span v-if="loading ==='positions'"><i class="fas fa-spin fa-spinner"></i></span>
        </button>
      </div>
    </div>
    <div class="text-2xl mt-2">Names:</div>
    <div class="w-full flex flex-wrap">
      <div v-for="location in tournament.locations" class="my-2 w-full md:w-1/2 lg:w-1/3 px-2">
        <div v-if="Object.values(tournament.locations).length > 1" class="text-xl">
          Location: {{ location.name }}
        </div>
        <div class="text-center">
          <a href="" class="text-blue-600 cursor-pointer" @click.prevent="updateStart(location.id)">
            Increment {{ $trans.choice('search.bale', 1) }} names from a specified number
          </a>
          <div v-for="bale in bales[location.id]" class="mb-1">
            <label class="mr-2">{{ baleWord }} {{ bale.number }}</label>
            <Field
              v-model="bale.name"
              rules="required|clean|json"
              :name="`location ${location.id} bale ${bale.number}`"
              :id="`location ${location.id} bale ${bale.number}`"
              class="bs-form-box w-24"/>
          </div>
        </div>
        
        <div class="flex justify-end mt-2">
          <button class="btn btn-blue-600" @click="renameBales(location.id)">
            Save Name Changes
            <span v-if="loading === location.id"><i class="fas fa-spin fa-spinner"></i></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";

export default {
  name: "BaleSettings",
  components: {Field},
  props: ['tournamentIn'],
  data() {
    return {
      positions: this.tournamentIn.bales[0].positions,
      bales: null,
      loading: '',
    }
  },
  created() {
    this.setBales();
  },
  computed: {
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    tournament() {
      return this.tournamentIn;
    },
  },
  methods: {
    setBales() {
      let list = {};
      Object.values(this.tournament.locations).forEach(location => {
        let bales = this.tournament.bales.filter(f => f.location_id === location.id);
        let baleList = {};
        Object.values(bales).forEach(bale => {
          baleList[bale.number] = bale;
        });
        list[location.id] = baleList;
      });
      this.bales = list;
    },
    updateStart(location) {
      Swal.fire({
        titleText: 'Enter the starting number',
        input: 'number',
        animation: false,
        showCancelButton: true,
        reverseButtons: true,
        inputValidator(inputValue) {
          return new Promise((resolve) => {
            if (parseInt(inputValue) < 1) {
              resolve('Value must be greater than zero.');
            }
            resolve();
          })
        },
      }).then(({value}) => {
        if (value) {
          Object.values(this.bales[location]).forEach(bale => {
            bale.name = parseInt(bale.number) + parseInt(value) - 1;
          })
        }
      });
      
    },
    renameBales(location) {
      this.loading = location;
      this.$axios.post(`/tournaments/${this.tournament.slug}/bale_names`, {
        location: location,
        bales: this.bales[location]
      })
      .then(() => {
        this.loading = '';
      })
      
    },
    renamePositions() {
      this.loading = 'positions';
      this.$axios.post(`/tournaments/${this.tournament.slug}/bale_positions`, {positions: this.positions})
      .then(() => {
        this.loading = '';
      })
    }
  }
  
}
</script>

<style scoped>

</style>
