<template>
  <div class="sm:w-3/4 md:w-1/2 mx-auto">
    <div class="text-xl">Select the Archers you wish to register</div>
    <div class="text-xs">
      Visit
      <a :href="'/profile/'+user.slug+'/archers/'" class="text-blue-600">managed archers</a>
      to adjust who you see on this list
    </div>
    <div>
      <div class="flex justify-end">
        <button @click="toggleAll" class="btn btn-outline-gray-600">
          {{ all ? 'Select None' : 'Select All' }}
        </button>
      </div>
      <div v-for="archer in user.managed_archers">
        <input
          type="checkbox"
          :id="'managed_' + archer.id"
          :value="archer"
          @change="populateSelected"
          v-model="checkedNames"
        >
        <label :for="'managed_' + archer.id">{{ archer.name }}</label>
      </div>
    </div>
  </div>
</template>
<script>

/**
 * @typedef {object} user
 * @property {array} managed_archers
 */
export default {
  name: "MemberSelection",
  props: ['tournament', 'user'],
  data() {
    return {
      all: false,
      checkedNames: [],
    }
  },
  methods: {
    archerValue(archer, detail) {
      if (detail.name === 'Name') {
        let name = archer.defaults.find(f => f.option === 'name');
        if (name) return name.value;
        return archer.name;
      } else if (detail.name === 'Email') {
        let email = archer.defaults.find(f => f.option === 'email');
        if (email) return email.value;
        return archer.email ? archer.email : '';
      } else {
        let archerDefault = archer.defaults.find(f => {
          let validSport = f.sport_id === null || f.sport_id === this.tournament.sport_id;
          return f.option === detail.name && validSport;
        });
        if (archerDefault) return archerDefault.value
        return detail.option === 'checkbox-field' ? [] : null
      }
    },
    populateSelected() {
      for (let archer of this.checkedNames) {
        if (archer.registration !== undefined) continue;
        let selectedDetails = {};
        for (let detail of this.tournament.regform) {
          selectedDetails[detail.option] = {
            option: detail.option,
            value: this.archerValue(archer, detail),
          };
        }
        let round = this.tournament.rounds[0];
        let time = round.line_times.length === 1 ? round.line_times[0].id : null;
        selectedDetails['line_time'] = {
          option: 'line_time',
          value: time,
        };
        let target = this.tournament.targets.length === 1 ? this.tournament.targets[0].id : null;
        selectedDetails['target'] = {
          option: 'target',
          value: target,
        };
        archer.registration = selectedDetails;
      }
      this.$emit('setCheckedNames', this.checkedNames);
    },
    toggleAll() {
      this.all = !this.all;
      if (this.all) {
        this.checkedNames = this.user.managed_archers;
        this.populateSelected();
      } else {
        this.checkedNames = [];
        this.$emit('setCheckedNames', this.checkedNames);
      }
    },
  }
}
</script>

<style scoped>

</style>
