<script setup>

</script>

<template>
  <div class="flex">
    <div class="hidden lg:block left"></div>
    <div class="middle">
      <div class="flex flex-col md:flex-row justify-between footerWidth">
        <div class="linkText order-2 md:order-1"><i class="fa-regular fa-xs fa-copyright"></i> Scoring Tech 2024</div>
        <div class="order-1 md:order-2 flex flex-col md:flex-row">
          <a href="/terms_of_service" target="_blank" class="linkText">Terms and Conditions</a>
          <a href="/privacy_policy" target="_blank" class="linkText padLeft">Privacy Policy</a>
        </div>
      </div>
    </div>
    <div class="hidden lg:block right"></div>
  </div>

</template>

<style scoped>

.left {
  min-width: 280px;
  max-width: 280px;
  width: 280px;
}

.middle {
  @apply flex-grow flex justify-center;
}

.right {
  min-width: 280px;
  max-width: 280px;
}

.footerWidth {
  @apply flex-grow px-4;
  min-width: 100px;
  max-width: 1000px;
}

@media (max-width: 1100px) {
  .footerWidth {
    @apply mx-4;
    width: 100%;
  }
}


@media (min-width: 768px) {
  .padLeft {
    margin-left: 45px;
  }
}

.linkText {
  @apply text-stone-400 font-semibold;
  font-size: 16px;
  letter-spacing: .32px;
  line-height: 19px;
  margin-bottom: 16px;
}
</style>