<template>
  <div class="alert bg-yellow-200 text-yellow-900 p-2 text-xl text-center" role="alert">
    <span v-if="disabled">
      The code "{{ code }}" has expired. This device is no longer authorized to score this tournament.
    </span>
    <span v-else>
      This device has not been authorized to score this tournament.
    </span>
    Please contact the tournament director or use an
    <a href="/authorize" class="cursor-pointer text-blue-600">Authorization Code </a> to gain access.
  </div>
</template>

<script>
export default {
  name: "ScoringUnauthorized.vue",
  props: ['disabled', 'tournament', 'code'],
}
</script>

<style scoped>

</style>
