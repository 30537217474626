<template>
  <div class="w-full md:w-1/2 mb-2 pl-0 md:pl-2">
    <div class="card card-blue-700">
      <div class="card-header">
        God Mode Users
      </div>
      <div class="card-body">
        <!--        <div class="btn btn-blue-600" @click="testMethod()">-->
        <!--          test-->
        <!--        </div>-->
        <div v-if="users.length">
          <div v-for="user in users" class="flex my-2">
            <div class="flex flex-col justify-center">
              <div v-if="user.image">
                <img alt="camera icon" :src="user.image" width="50" height="50">
              </div>
              <div v-else>
                <div style="width:50px; height:50px;" class="bg-gray-600 text-white">
                  <div class="flex justify-center">No</div>
                  <div class="flex justify-center">Image</div>
                </div>
              </div>
            </div>
            
            <div class="flex flex-col justify-center mx-2 text-lg">
              <div>{{ user.full_name }}</div>
              <div>{{ user.email }}</div>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center">
          <div class="flex flex-col">
            <div>Loading User List</div>
            <div class="flex justify-center">
              <i class="fas fa-spinner fa-spin fa-3x"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GodUsers",
  data() {
    return {
      search: '',
      users: [],
    }
  },
  created() {
    this.$axios.get('/godmode/users').then((data) => {
      if (data.data) {
        this.users = data.data.list;
      }
    })
  },
  methods: {
    testMethod() {
    }
  }
  
}
</script>

<style scoped>
td, th {
  @apply mr-3
}

</style>
