<template>
  <div class="detailItem flex flex-col px-2 border border-gray-400" :class="{'editing': show}"
       :id="'form_detail_' + identifier" :ref="'form_detail_' + identifier">
    <div class="w-full text-center handleHolder mb-2"><i class="handle fas fa-grip-lines"></i></div>
    <div class="flex flex-wrap">
      <label class="w-full sm:w-1/2 order-2 sm:order-1 mt-1 flex flex-row sm:flex-col"
             :class="detail.type === 'header' ? 'text-2xl text-bold sm:w-3/4' : 'sm:w-1/4'">
        <div class="mr-2">{{ detail.name === 'Name' ? 'Competitor ' : '' }}{{ detail.name }}</div>
        <div v-if="detail.required" class="text-red-600">
          Required
        </div>
      </label>
      <div v-if="detail.type === 'waiver'" class="w-full sm:w-1/2 order-2 sm:order-1">
                <pre class="border rounded border-gray-500 p-2 whitespace-pre-line min-h-0 max-h-xs overflow-y-auto"
                     style="font-family: 'Roboto', sans-serif;"
                     v-if="detail.placeholder">{{ detail.placeholder }}</pre>
        <div>
          <input type="checkbox" disabled>
          <label>I have read and understand the waiver. I agree to be bound by its terms.</label>
          <div class="text-xs ml-2 text-gray-500">{{ detail.description }}</div>
        </div>
      </div>
      <div v-else-if="detail.multiple" class="w-full sm:w-1/2 order-3 sm:order-2 mt-1 mb-3">
        <small class="ml-0 text-gray-500">{{ detail.description }}</small>
        <div v-if="detail.type === 'selection'">
          <select class="bs-form-box w-full" disabled>
            <option selected>Select One...</option>
            <option :selected="option.selected" v-for="option in detail.multiple" :value="option.label">
              {{ option.label }}
            </option>
          </select>
        </div>
        <div v-else v-for="option in detail.multiple" class="pl-5">
          <input :type="detail.type" name="radioOptions" class="" :checked="option.selected" disabled>
          <label>{{ option.label }} </label>
        </div>
      </div>
      <div v-else-if="detail.type === 'textarea'" class="w-full sm:w-1/2 order-2 sm:order-1">
                <textarea type="text" class="bs-form-box w-full bg-gray-100" v-bind:placeholder="detail.placeholder"
                          disabled></textarea>
        <small class="ml-2 text-gray-500">{{ detail.description }}</small>
      </div>
      <div v-else-if="detail.type === 'image'" class="w-full sm:w-1/2 order-2 sm:order-1">
        <small class="ml-2 text-gray-500">{{ detail.description }}</small>
        <div class="text-center my-2">
          <button class="btn btn-outline-blue-600" disabled>Upload Image</button>
        </div>
      </div>
      <div v-else-if="detail.type === 'date'" class="w-full sm:w-1/2 order-2 sm:order-1">
        <div class="bs-form-box w-full bg-gray-100 flex justify-end" disabled>
          <i class="far fa-calendar text-xl text-gray-500"></i>
        </div>
        <small class="ml-2 text-gray-500">{{ detail.description }}</small>
      </div>
      <div v-else-if="detail.type !== 'header'" class="w-full sm:w-1/2 order-2 sm:order-1">
        <input type="text" class="bs-form-box w-full bg-gray-100"
               v-bind:placeholder="detail.placeholder" disabled>
        <small class="ml-2 text-gray-500">{{ detail.description }}</small>
      </div>
      <div class="w-full sm:w-1/4 order-1 sm:order-3">
        <div class="flex flex-wrap justify-end">
          <div v-if="!detail.default" class="flex mb-1">
            <div class="h-center" :for="'enabled_' + identifier">
              <div class="text-sm text-gray-500">
                {{ detail.enabled ? 'Enabled' : 'Disabled' }}
              </div>
            </div>
            <input type="checkbox" v-model="detail.enabled" :checked="detail.enabled"
                   :id="'enabled_' + identifier" class="switch ml-1">
          </div>
          <div class="flex mb-1">
            <div class="h-center">
              <button v-if="!(detail.sport_default || detail.default)"
                      @click.prevent="$emit('deleteField',(identifier - 1))"
                      class="btn-sm btn-outline-gray-600 ml-1"><i class="fas fa-trash-alt"></i></button>
            </div>
            <div class="h-center">
              <button @click.prevent="updateShow('form_detail_' + identifier)"
                      class="btn-sm btn-outline-blue-600 ml-1"><i class="fas fa-cog"></i> Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="show" class="flex flex-col">
      <div v-show="!detail.default" class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Label</label>
        </div>
        <div class="sm:w-1/2 flex flex-col">
          <input @keyup="checkTaken(detail.name)" @blur="resetTaken" v-validate="'required|clean'"
                 v-model="detail.name" :name="identifier + '_form_label'" type="text"
                 class="bs-form-box w-full"
                 :disabled="!!detail.sport_default" required>
          <span v-if="detail.sport_default" class="text-blue-600 font-black mb-2">
            This field is a default field for the selected sport and cannot be renamed. Instead of renaming,
            disable this field and add a new one with your desired options.
          </span>
          <span class="text-sm text-red-600">{{ errors.first(identifier + '_form_label') }} {{ exists }}</span>
        </div>
      </div>
      <div class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Description</label>
        </div>
        <div class="sm:w-1/2 flex flex-col">
                    <textarea v-model="detail.description"
                              v-validate="'clean'"
                              :name="identifier + '_form_desc'"
                              class="bs-form-box w-full h-16">{{ detail.description }}</textarea>
          <span class="text-sm text-red-600">{{ errors.first(identifier + '_form_desc') }} {{ exists }}</span>
          <div class="text-gray-600 text-xs">
            Enter the description for the {{ detail.name }} field.
            This will be displayed to the competitor and provide some
            direction on how the field should be filled out.
          </div>
        </div>
      </div>
      <div v-if="detail.type === 'image'" class="w-full">
        <div class="sm:w-1/2 mx-auto">
          <p>The competitor will upload an image cropped to 400x400. All images will go through a safe image
            filter, flagged images will be personally reviewed by a Bowscore staff member before being
            displayed.</p>
        </div>
      </div>
      <div v-else-if="detail.type === 'waiver'" class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Waiver Text</label>
        </div>
        <div class="sm:w-1/2 flex flex-col">
                    <textarea v-model="detail.placeholder" v-validate="'clean|required'"
                              :name="identifier + ' waiver text'" type="text"
                              placeholder="Put the waiver here"
                              class="bs-form-box w-full h-40 overflow-y-auto"></textarea>
          <span class="text-sm text-red-600">
            {{ errors.first(identifier + ' waiver text') }} {{ exists }}</span>
        </div>
      </div>
      <div v-else-if="detail.multiple" class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Options</label>
        </div>
        <div class="sm:w-1/2 flex flex-col">
          <div v-if="detail.sport_default" class="text-blue-600 font-black mb-2">
            This field is a default field for the selected sport. Renaming any of the provided
            options could interfere with scoring and results. Instead of renaming, add a new option
            and then delete any unwanted options.
          </div>
          <div v-for="(option, index) in detail.multiple" class="mb-3 flex w-full flex-col">
            <div class="w-full flex">
              <div class="bs-form-box bg-gray-400 grow-0">
                <input v-if="detail.type === 'checkbox'" :type="detail.type"
                       v-model="option.selected"
                       aria-label="Selector for following text input">
                <input v-else type="radio" :name="'editRadio_' + identifier"
                       v-on:click="radioChanged(index, option)" :id="'option_' + index"
                       :checked='option.selected' aria-label="Selector for following text input">
              </div>
              <input type="text" v-validate="'required|clean|json|min:1|max:40'"
                     :name="'option_label_' + (index + 1)"
                     v-model="option.label" class="bs-form-box flex-grow"
                     aria-label="Text input with selector">
              <div class="grow-0">
                <button class="btn btn-gray-400" @click.prevent="removeOption(index)"><i
                  class="fas fa-trash"></i></button>
              </div>
            </div>
            <div class="w-full text-sm text-red-600">{{ errorMessage(errors, 'option_label_' + (index + 1)) }}
              {{ exists }}
            </div>
          </div>
          
          <div v-show="last" class="text-red-600">
            You cannot delete the last option. {{ detail.sport_default ? '' : 'Feel free to rename it.' }}
          </div>
          <div class="text-center text-gray-600 text-sm w-full">
            Selecting one of the above will set it selected by default.
          </div>
          <div class="text-center">
            <button @click.prevent="addOption" class="btn-sm btn-outline-blue-600"><i
              class="fas fa-plus"></i> Add
              Option
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="detail.type !== 'header' && detail.type !== 'image' && detail.type !== 'date'"
           class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Placeholder</label>
        </div>
        <div class="sm:w-1/2 flex flex-col">
          <input v-model="detail.placeholder" v-validate="'clean'" :name="identifier + 'form_placeholder'"
                 type="text"
                 class="bs-form-box w-full">
          <span class="text-sm text-red-600">{{ errors.first(identifier + 'form_placeholder') }} {{
              exists
            }}</span>
          
          <small class="text-gray-600 description">This will appear in the input field as an example.</small>
        </div>
      </div>
      <div v-if="detail.type !== 'header' && !detail.default" class="flex my-1">
        <div class="sm:w-1/4 flex sm:justify-end">
          <label class="mr-6">Require</label>
        </div>
        <div class="sm:w-1/2 mt-2 flex ml-2">
          <input type="checkbox" v-model="detail.required" :checked="detail.required"
                 class="switch" :id="detail.name + '_required'">
          <div class="h-center ml-2" :for="detail.name + '_required'">
            <div class="text-xs text-gray-600">
              Make {{ detail.name }} Required
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FormDetail",
  props: {
    details: Object,
    identifier: Number,
  },
  data() {
    return {
      show: !this.details.id && !this.details.default && !this.details.sport_default,
      last: false,
      exists: ''
    };
  },
  computed: {
    detail() {
      return this.details;
    }
  },
  methods: {
    errorMessage(errors, label) {
      if (!errors || errors.items.length === 0) return '';
      let error = errors.items.find((f) => f.field === label);
      if (error != null) {
        let message = error.msg;
        if (error.rule === 'max') message += '. If you need additional info please add it to the Description field above.'
        return message;
      }
      return '';
    },
    resetTaken() {
      if (this.exists) {
        this.exists = '';
        this.detail.name = '';
      }
    },
    checkTaken(label) {
      let existing = this.$parent.$parent.formDetail.map(m => m.name.toLowerCase());
      existing.splice(this.identifier - 1, 1);
      if (existing.includes(label.toLowerCase())) {
        this.exists = 'A field with that label already exists.';
      } else {
        this.exists = '';
      }
    },
    radioChanged(index, option) {
      //TODO fix this to not use jquery
      if (option.selected) {
        $.each(this.detail.multiple, function (k, v) {
          v.selected = false;
        });
        $('#option_' + index).prop('checked', false).attr('checked', false);
      } else {
        $.each(this.detail.multiple, function (k, v) {
          v.selected = false;
        });
        option.selected = true;
      }
      
    },
    removeOption(option) {
      if (this.detail.multiple.length > 1) {
        this.$delete(this.detail.multiple, option);
      } else {
        this.last = true;
      }
    },
    addOption() {
      let ids = this.detail.multiple.map(a => a.id);
      let newId = Math.max(...ids) + 1;
      let newOption = {"id": newId, "label": "Option " + newId, "selected": false};
      this.detail.multiple.push(newOption)
    },
    updateShow(element) {
      this.show = !this.show;
      if (this.show) {
        this.$refs[element].scrollIntoView({behavior: 'smooth'});
      }
      
    }
  },
  mounted() {
    if (!this.details.id && !this.details.default && !this.details.sport_default) {
      this.$refs['form_detail_' + this.identifier].scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>

<style scoped>
.editing {
  @apply bg-blue-100;
}

.handleHolder {
  margin-bottom: -7px;
}

.handle {
  cursor: grab;
}

.handle:active {
  cursor: grabbing;
}
</style>
