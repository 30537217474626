<template>
  <div>
    <modal-component class="ml-2">
      <template v-slot:open-modal>
        <span
          type="button"
          data-toggle="modal"
          data-backdrop="true"
          class="text-blue-600 cursor-pointer">
          <i class="fas fa-image"></i>
        </span>
      </template>
      <template v-slot:modal-body>
        <img alt="product image" :src="image" data-backdrop="true"
             class="mx-auto">
      </template>
    </modal-component>
  </div>
</template>
<script>
import ModalComponent from "./ModalComponent.vue";

export default {
  name: "ImageModal",
  components: {ModalComponent},
  props: ['image'],
}
</script>


<style scoped>

</style>