<template>
  <div>
    <div v-if="online">
      <div class="m-2 text-2xl">
        Settings for Tournament: {{ tournamentIn.name }}
        <hr>
      </div>
      <div class="card card-blue-700 mx-2">
        <div class="card-header">
          Tournament Settings
          <transition name="fade" :duration="{ enter: 1800, leave: 800 }">
            <span v-show="updatedMessage" class="text-green-400 float-right">updated</span>
          </transition>
        </div>
        <div class="card-body">
          <form-switch
            @toggled="updateTournament('hide')"
            :model="tournamentIn.hide"
            id="hideTournamentId"
            label="Hide Tournament"
            sub-label="Tournament can still be accessed to anyone with a link."/>
          <form-switch
            @toggled="updateTournament('auto_assignment')"
            :model="tournamentIn.auto_assignment"
            id="autoAssignment"
            label="Automatic Assignment"
            :sub-label="`New competitors will be automatically assigned to a ${baleWord}.`"/>
          <form-switch
            @toggled="updateTournament('self_assignment')"
            :model="tournamentIn.self_assignment"
            id="selfAssignment"
            label="Self Assignment"
            :sub-label="`Allow competitors to assign themselves to a ${baleWord }.`"/>
          <form-switch
            @toggled="updateTournament('accept_button')"
            :model="tournamentIn.accept_button"
            id="acceptButton"
            label="Accept Button"
            sub-label="Disables automatic syncing and requires a score to be accepted before uploaded."/>
          <form-switch
            @toggled="updateTournament('club_devices')"
            :model="tournamentIn.club_devices"
            id="club_devices"
            label="Allow Club Devices"
            :sub-label="devicesString"/>
          <form-switch
            @toggled="updateTournament('disable_registration')"
            :model="tournamentIn.disable_registration"
            id="disableRegistration"
            label="Disable Registration"
            sub-label="This will lock all registration. Unselecting this will still allow registrations after the registration period has closed to be put on the wait list."/>
          <hr>
        </div>
      </div>
      <disable-scoring :tournament-in="tournamentIn"/>
      <div class="card card-blue-700 m-2">
        <div class="card-header">
          Tournament Actions
        </div>
        <div class="card-body">
          <div class="py-2">
            <div class="">
              <clone-tournament :tournament="tournamentIn"/>
              <delete-tournament :tournament="tournamentIn"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <online-feature v-else/>
  </div>

</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";
import CloneTournament from "../../partials/CloneTournament.vue";
import DeleteTournament from "../../partials/DeleteTournament.vue";
import OnlineFeature from "../../partials/OnlineFeature.vue";
import DisableScoring from "./DisableScoring.vue";


/**
 * @typedef {object} tournament
 * @property {string} disable_registration
 */

export default {
  name: "TournamentSettings",
  components: {DisableScoring, OnlineFeature, DeleteTournament, CloneTournament, FormSwitch},
  props: ['tournamentIn'],
  data() {
    return {
      updatedMessage: false,
      tournament: this.tournamentIn,
    }
  },
  computed: {
    baleWord() {
      return this.$trans.choice('search.bale', 1);
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    club() {
      return this.tournament.club;
    },
    clubName() {
      return this.club.name;
    },
    devicesString() {
      let string = `All devices that have been authorized for ${this.clubName} will be able to score this tournament.`;
      string += ' Tournament device authorization can be managed from <a href="/tournaments/';
      string += this.tournament.slug + '#/manage/devices';
      string += '" class="text-blue-600 cursor-pointer" target="_blank"> here</a>.';
      return string
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('refresh');
    next()
  },
  methods: {
    updateTournament(field) {
      let tournamentField = this.tournamentIn[field];
      tournamentField = !tournamentField;
      this.$axios.post(`/tournaments/${this.tournament.slug}/manage`, {
        'update': {[field]: tournamentField}
      })
      .then(() => {
        this.updatedMessage = true;
        setTimeout(this.hideUpdated, 1500);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    hideUpdated() {
      this.updatedMessage = false
    },
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}


</style>