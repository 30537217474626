<template>
  <div class="p-2 flex flex-col">
    <div class="text-2xl border-b mb-2">{{ sponsor ? 'Update' : 'Create' }} a new Sponsor
      Ad
    </div>
    <div>
      <div class="flex flex-col mb-2">
        <label>
          Name
          <input v-model="name" type="text" class="bs-form-box" name="name" placeholder="Enter a unique name">
        </label>
        <small class="text-gray-600">This is a brief description of the ad with the name of the company. EX:
          Lancaster Classic Tournament</small>
      </div>
      <div class="flex flex-col mb-2">
        <label>Portrait Image (1080x1920 .jpg)</label>
        <div class="mb-1" style="margin-bottom:2px;">
          <img v-if="portrait" :src="portrait" height="384" width="216">
        </div>
        <image-upload :title="'Portrait Image (1080x1920 .jpg)'"
                      :option="'portrait'"
                      :height="1920"
                      :width="1080"
                      :multiplier="5"
                      :inc_url="portrait"
                      :post_url="'/sponsorimage'"
                      @update_image="updateImage"
                      @remove_image="removeImage">
          <template v-slot:open-modal>
            <button type="button" class="btn btn-blue-600 m-2" data-toggle="modal"
                    data-target="#cropper"
                    data-backdrop="true">
              {{ portrait ? 'Change' : 'Upload' }} Image
            </button>
          </template>
        </image-upload>
      </div>
      <div class="flex flex-col mb-2">
        <label>Landscape Image (1920x1080 .jpg)</label>
        <div class="mb-1" style="margin-bottom:2px;">
          <img v-if="landscape" :src="landscape" height="216" width="384">
        </div>
        <image-upload :title="'Landscape Image (1920x1080 .jpg)'"
                      :option="'landscape'"
                      :height="1080"
                      :width="1920"
                      :multiplier="4"
                      :inc_url="landscape"
                      :post_url="'/sponsorimage'"
                      @update_image="updateImage"
                      @remove_image="removeImage">
          <template v-slot:open-modal>
            <button type="button" class="btn btn-blue-600 m-2" data-toggle="modal"
                    data-target="#cropper"
                    data-backdrop="true">
              {{ landscape ? 'Change' : 'Upload' }} Image
            </button>
          </template>
        </image-upload>
      </div>
      <div class="flex flex-col mb-2">
        <label>Large Banner (1920x600 .png)</label>
        <div class="mb-1">
          <img v-if="largeBanner" :src="largeBanner">
        </div>
        <image-upload :title="'Large Banner (1920x600 .png)'"
                      :option="'largeBanner'"
                      :height="600"
                      :width="1920"
                      :multiplier="4"
                      :post_url="'/sponsorimage'"
                      :inc_url="largeBanner"
                      :mimetype="'image/png'"
                      @update_image="updateImage"
                      @remove_image="removeImage">
          <template v-slot:open-modal>
            <button type="button" class="btn btn-blue-600 m-2" data-toggle="modal"
                    data-target="#cropper"
                    data-backdrop="true">
              {{ largeBanner ? 'Change' : 'Upload' }} Image
            </button>
          </template>
        </image-upload>
      </div>
      <div class="flex flex-col mb-2">
        <label>Small Banner (600x450 .png)</label>
        <div class="mb-1">
          <img v-if="smallBanner" :src="smallBanner">
        </div>
        <image-upload :title="'Small Banner (600x450 .png)'"
                      :option="'smallBanner'"
                      :height="450"
                      :width="600"
                      :multiplier="3"
                      :post_url="'/sponsorimage'"
                      :inc_url="smallBanner"
                      :mimetype="'image/png'"
                      @update_image="updateImage"
                      @remove_image="removeImage">
          <template v-slot:open-modal>
            <button type="button" class="btn btn-blue-600 m-2" data-toggle="modal"
                    data-target="#cropper"
                    data-backdrop="true">
              {{ smallBanner ? 'Change' : 'Upload' }} Image
            </button>
          </template>
        </image-upload>
      </div>
      <div class="flex flex-col mb-2">
        <label>
          Link
          <input v-model="link" type="text" class="bs-form-box" name="link"
                 placeholder="This link will be visited when the banner is clicked">
        </label>
        <small class="text-gray-600">This link will be visited when the banner or overlay is clicked. Must be
          the full url EX: https://bowscore.com</small>
      </div>
    </div>
    <span v-show="completeError" key="complete_error" class="text-red-600 mr-2">All fields must be provided</span>
    <div class="flex justify-end">
      <button @click="create" class="btn btn-blue-600">{{ sponsor ? 'Update' : 'Create' }} Sponsor Ad
      </button>
    </div>
  
  </div>
</template>

<script>
import ImageUpload from "../partials/ImageUpload.vue";

export default {
  name: "SponsorCreate",
  components: {ImageUpload},
  props: ['sponsor'],
  data() {
    return {
      name: this.sponsor ? this.sponsor.name : null,
      portrait: this.sponsor ? this.sponsor.background_portrait : null,
      landscape: this.sponsor ? this.sponsor.background_landscape : null,
      largeBanner: this.sponsor ? this.sponsor.banner_large : null,
      smallBanner: this.sponsor ? this.sponsor.banner_small : null,
      link: this.sponsor ? this.sponsor.link : null,
      completeError: false,
    }
  },
  methods: {
    updateImage(details) {
      var [url, _, option] = details;
      this[option] = url;
    },
    removeImage(field) {
      this[field] = null;
    },
    create() {
      if (this.name && this.portrait && this.landscape && this.largeBanner && this.smallBanner) {
        let url = this.sponsor ? `/${this.sponsor.id}` : '';
        let type = this.sponsor ? 'put' : 'post';
        this.$axios[type](`/sponsor_themes${url}`, {
          name: this.name,
          background_portrait: this.portrait,
          background_landscape: this.landscape,
          banner_large: this.largeBanner,
          banner_small: this.smallBanner,
          link: this.link,
        }).then((response) => {
          window.location.href = '/sponsor_themes';
        }).catch((error) => {
          console.log(error);
        })
      } else {
        this.completeError = true;
      }
      
    }
  }
}
</script>

<style scoped>

.center {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
</style>
