<script setup>
const props = defineProps(['showLink'])
const linkText = "View Results".toUpperCase();
</script>

<template>
  <div class="heading flex justify-center lg:justify-between">
    <img src="/img/Bowscore Logo.svg" width="315" height="36" alt="bowscore logo">
    <a v-if="showLink" href="/results" class="hidden lg:block link">{{ linkText }}</a>
    <div v-else>&nbsp</div>
  </div>
</template>

<style scoped>
.heading {
  margin-top: 25px;
}

@media (min-width: 1100px) {
  .heading {
    width: 1100px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
  }
}


.link {
  @apply font-extrabold flex flex-col justify-center;
  color: #696969;
  font-family: 'Figtree', 'serif';
  font-size: 20px;
  letter-spacing: 0.4px;
}
</style>