<template>
  <div class="border border-blue-600 rounded p-3 mb-2">
    <div class="flex flex-wrap">
      <div class="flex flex-col w-full  mt-4 px-1 mb-2">
        <label for="round name" class="text-lg">Name</label>
        <input class="bs-form-box w-full md:w-1/2" v-model="name"
               name="round name"
               id="round name"
               v-validate="'required|min:1|max:60|clean'" required>
        <span class="text-sm text-red-600">{{ errors.first('round name') }}</span>
        <span class="text-sm text-gray-600 ml-1">
          Rounds will be named {{ name }} 1, {{ name }} 2, etc...
        </span>
      </div>
      <div class="flex flex-col mt-4 px-1 mb-2 w-1/2 md:w-1/4">
        <label for="end count" class="text-lg">
          # of {{ endsWord }}
        </label>
        <input class="bs-form-box" type="number" max="100" min="1"
               v-model="ends"
               v-validate="'required|min_value:1|max_value:100'"
               name="end count"
               id="end count"
               required>
        <span class="text-sm text-red-600">{{ errors.first('end count') }}</span>
      </div>
      <div class="flex flex-col mt-4 px-1 mb-2 w-1/2 md:w-1/4">
        <label for="arrow count" class="text-lg"># of Arrows</label>
        <input class="bs-form-box" type="number" max="10" min="1"
               name="arrow count"
               id="arrow count"
               v-validate="'required|min_value:1|max_value:10'"
               v-model="arrows" required>
        <span class="text-sm text-red-600">{{ errors.first('arrow count') }}</span>
      </div>
      <div class="flex flex-col mt-4 px-1 mb-2 w-1/2 md:w-1/4">
        <label for="week count" class="text-lg"># of Weeks</label>
        <input class="bs-form-box" type="number" max="10" min="1"
               name="week count"
               id="week count"
               v-validate="'required|min_value:2|max_value:52'"
               v-model="weekCount" required>
        <span class="text-sm text-red-600">{{ errors.first('week count') }}</span>
      </div>
      <div v-if="tournament.targets && tournament.targets.length > 1"
           class="flex flex-col w-1/2 md:w-1/4 mt-4 px-1 mb-2">
        <label for="helperTarget" class="text-lg">Default Target</label>
        <select class="bs-form-box"
                name="helperTarget"
                id="helperTarget"
                v-model="target">
          <option :value="null">None</option>
          <option v-for="target in tournament.targets" :value="target">{{ target.name }}</option>
        </select>
        <span class="text-sm text-gray-600 ml-1">optional</span>
      </div>
    </div>
    <div class="flex justify-center">
      <span class="text-sm text-gray-600 ml-1">
        Clicking the 'Generate' button will replace any existing rounds with those generated.
      </span>
    </div>
    <div class="flex justify-center">
      
      <button @click.prevent="generate()"
              class="btn btn-blue-600 whitespace-nowrap">
        Generate
      </button>
    </div>
  </div>

</template>

<script>
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "SetupHelper.vue",
  props: ["tournament"],
  data() {
    return {
      name: 'Week',
      ends: 10,
      arrows: 3,
      target: this.tournament.targets[0],
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'weekCount'
    ]),
    endsWord(){
      let text = this.$trans.choice('search.end', 2);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
  methods: {
    generate() {
      let list = [];
      let timeIndex = 0;
      let allTimes = this.tournament.line_times;
      for (let i = 0; i < this.weekCount; i++) {
        let roundLineTimes = [];
        let firstMemo = null;
        while (timeIndex < allTimes.length) {
          let time = allTimes[timeIndex];
          if (firstMemo === null) {
            firstMemo = time.memo;
          } else if (time.memo === firstMemo) {
            break;
          }
          roundLineTimes.push(time);
          timeIndex++;
        }
        let round = {
          name: `${this.name} ${i + 1}`,
          arrows_count: `${this.arrows}`,
          ends_count: `${this.ends}`,
          locations: this.tournament.locations,
          line_times: roundLineTimes,
          target: this.target,
          open: true,
        }
        list.push(round);
      }
      this.$emit('submit', list);
    },
  }
}
</script>

<style scoped>

</style>
