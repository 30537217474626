<template>
    <div class="flex-center position-ref full-height">
        <div class="content">
            <h1>Authorize Payment Integration</h1>
            <input type="text" name="cardNumber" id="cardNumber" placeholder="cardNumber"/> <br><br>
            <input type="text" name="expMonth" id="expMonth" placeholder="expMonth"/> <br><br>
            <input type="text" name="expYear" id="expYear" placeholder="expYear"/> <br><br>
            <input type="text" name="cardCode" id="cardCode" placeholder="cardCode"/> <br><br>
            <input type="text" name="cardCode" id="cardCvv" placeholder="cardCvv"/> <br><br>


            <button type="button" @click="sendPaymentDataToAnet()">Pay</button>
            <form id="paymentForm" class="" action="/checkout" method="post">
                <input type="hidden" name="_token" :value="csrf">

                <input type="hidden" name="dataValue" id="dataValue"/>
                <input type="hidden" name="dataDescriptor" id="dataDescriptor"/>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: "AuthorizeNet",
    props: ['client_key', 'api_login'],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },
    methods: {
        responseHandler(response) {
            if (response.messages.resultCode === "Error") {
                var i = 0;
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ": " +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                }
            } else {
                document.getElementById("dataValue").value = response.opaqueData.dataValue;
                document.getElementById("dataDescriptor").value = response.opaqueData.dataDescriptor;
                document.getElementById("paymentForm").submit();
            }
        },
        sendPaymentDataToAnet() {
            var authData = {};
            authData.clientKey = '9WGyqCmqzWawm75KBLY96Dx99bKHpmA6S89hGKKa8aV4pjFdB5aaf2XcpY2RF2Az';
            authData.apiLoginID = this.api_login;

            var cardData = {};
            cardData.cardNumber = document.getElementById("cardNumber").value;
            cardData.month = document.getElementById("expMonth").value;
            cardData.year = document.getElementById("expYear").value;
            cardData.cardCode = document.getElementById("cardCode").value;

            var secureData = {};
            secureData.authData = authData;
            secureData.cardData = cardData;

            Accept.dispatchData(secureData, this.responseHandler);
        },
    }
}
</script>
<style scoped>

</style>
