<template>
    <div>
        <div class="w-full text-center">
            <div class="flex flex-col">
                <div class="flex justify-center mt-3 h-20">
                    <global-search :landing="true"/>
                </div>
                <div class="flex justify-center mb-4">
                    <a href="/login/facebook" class="btn btn-blue-600 w-56">
                        <i class="fab fa-facebook-square"></i>
                        <span class="ml-2">Sign Up Using Facebook</span>
                    </a>
                </div>
                <div class="flex justify-center mb-4">
                    <a href="/login/google" class="btn btn-red-600 w-56">
                        <i class="fab fa-google"></i>
                        <span class="ml-2">Sign Up Using Google</span>
                    </a>
                </div>

                <h2 class="w-2/3 mx-auto"><span>OR</span></h2>
            </div>

        </div>
        <form @submit.prevent="submitForm" ref="form" action="/register" method="post">
            <input type="hidden" name="_token" :value="csrf">

            <div @keyup="submitErrors = false" class="flex flex-wrap w-72 mx-auto justify-between">
                <div class="flex flex-col w-full px-1 mb-8">
                    <input v-validate="'required|min:3|max:90|clean|full_name'" placeholder="Full Name"
                           type="text" name="name" class="bs-form-box" required>
                    <span class="text-sm text-red-500">{{ errors.first('name')}}</span>
                </div>
                <div class="flex flex-col w-full px-1 mb-8">
                    <input v-validate="'required|min:3|max:160|email'" placeholder="Email"
                           type="email" name="email" class="bs-form-box" required>
                    <span class="text-sm text-red-500">{{ errors.first('email')}}</span>
                </div>
                <div class="flex flex-col w-full px-1 mb-8">
                    <input v-validate="'required|min:6|max:60'" placeholder="Password"
                           type="password" name="password" class="bs-form-box" v-model="password" required>
                    <span class="text-sm text-red-500">{{ errors.first('password')}}</span>
                </div>
                <div v-if="password" class="flex flex-col w-full px-1 mb-8">
                    <input v-validate="'required|min:6|max:60'" placeholder="Password Confirmation"
                           type="password" name="password_confirmation" class="bs-form-box" required>
                    <span class="text-sm text-red-500">{{ errors.first('password_confirmation')}}</span>
                </div>
                <div class="flex w-full justify-center mb-4">
                    <button @keyup.stop class="btn btn-blue-650 text-xl font-sans font-medium">
                        Create Account <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
                    </button>
                </div>
                <span v-if="submitErrors" class="w-full text-center text-sm text-red-500">You must correct any errors before registering.</span>
            </div>
            <div class="text-center text-sm">
                <span>Already have an account? <a href="/login" class="text-gray-600">log in</a></span>
            </div>
            <div class="text-center mt-5">
                <a href="/results" class="text-blue-500 hover:text-blue-600 hover:font-extrabold">
                    Just here to check the results of your favorite {{trans.choice('search.archer', 1)}}?
                    Continue without an account.
                </a>
            </div>
        </form>
    </div>
</template>

<script>
    import GlobalSearch from "../GlobalSearch.vue";
    
    export default {
        name: "SignUpOld",
      components: {GlobalSearch},
        data(){
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                submitErrors: false,
                loading: false,
                password: '',
            }
        },
        methods: {
            submitForm(){
                this.loading = true;
                this.$validator.validate().then(valid => {
                    if (valid) {
                        this.$refs.form.submit();
                    } else {
                        this.loading = false;
                        this.submitErrors = true;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    h2 {
        @apply text-gray-500 border-b border-gray-500;
        line-height: 0.1em;
        margin: 10px 0 20px;
    }

    h2 span {
        background: #fff;
        padding: 0 10px;
    }
</style>
