<template>
    <tr v-if="validate()">
        <td class="border bg-gray-200 py-3 px-3">{{deviceName}}</td>
        <td class="border bg-gray-200 py-3 px-3">
            <button @click="addToTournament" class="btn-sm btn-outline-gray-600">
                Add <span v-show="idSpinner"><i class="far fa-spinner fa-spin"></i></span>
                <span v-show="!idSpinner"></span>
            </button>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "DeviceDetails",
        props: ['device', 'clubDevices'],
        data() {
            return {
                idSpinner: false,
            }
        },
        computed: {
            deviceName() {
                return this.device.name ? this.device.name : this.device.device_id;
            }
        },
        methods: {
            validate() {
                let hash = this.device.hash;
                let device_id = this.device.device_id;
                var notExists = this.clubDevices.findIndex(function (f) {
                    return f.hash === hash && f.device_id === device_id;
                });
                return notExists === -1;
            },
            addToTournament() {

                let vm = this;
                this.idSpinner = true;
                this.$axios.post('/device/club_add', {
                    'device': this.device,
                })
                    .then(({data}) => {
                        if (data['error']) {
                            Swal.fire(data['error'])
                        }
                        if (data['success']) {
                            vm.idSpinner = false;
                            vm.$emit('addDevice', data['device'])
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
        }
    }
</script>
`
<style scoped>
    td {
        white-space: nowrap;
    }
</style>