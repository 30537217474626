<template>
  <div v-if="list.length" class="card card-red-700 mb-2">
    <div class="card-header">
      Possible Bad Assignments
    </div>
    <div class="card-body">
      <div v-if="tournament" class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Competitor</th>
            <th>Requested</th>
            <th>Assigned</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in list">
            <td>{{ row['name'] }}</td>
            <td>{{ row['requested'] }}</td>
            <td>{{ row['assigned'] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Competitor ID</th>
            <th>Competitor Name</th>
            <th>Tournament ID</th>
            <th>Requested</th>
            <th>Assigned</th>
            <th>Created At</th>
            <th>Assigned At</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in list">
            <td>{{ row['competitor_id'] }}</td>
            <td>{{ row['name'] }}</td>
            <td>{{ row['tournament_id'] }}</td>
            <td>{{ row['requested'] }}</td>
            <td>{{ row['assigned'] }}</td>
            <td>{{ row['created'] }}</td>
            <td>{{ row['assign_time'] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    
    </div>
  
  </div>
</template>
<script>
export default {
  name: "BadAssignments",
  props: ['tournament'],
  data() {
    return {
      list: [],
    }
  },
  created() {
    let vm = this;
    if (this.tournament) {
      this.$axios.get(`/tournaments/${this.tournament.slug}/bad_assignments`).then(function (response) {
        if (response.data != null) {
          vm.list = response.data.list;
          vm.$emit('updated', vm.list.length > 0);
        }
      });
    } else {
      this.$axios.get('/godmode/bad_assignments').then(function (response) {
        if (response.data != null) {
          vm.list = response.data.list;
        }
      });
    }
  },
  computed: {}
}
</script>
<style scoped>
th {
  @apply text-center py-2;
}
</style>