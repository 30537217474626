<template>
        <div class="card card-blue-700">
            <div class="card-header">
                Scoring Device Authorization
            </div>
            <div class="card-body">
                <div class="w-full mb-2">
                    <div class="flex">
                        <div class="flex flex-col justify-center"><div>Search By Id: </div></div>
                        <div class="bs-form-box ml-3 flex flex-grow">
                            <span class=""><i class="far fa-search"></i></span>
                            <input type="text" class="flex-grow mx-2" v-model="search">
                            <span v-show="search !== ''" @click="search = ''"><i class="far fa-times"></i></span>
                        </div>
                    </div>
                </div>
                <h4>Club Authorized Devices</h4>
                <div class="text-left text-gray-600 text-sm">
                    Click a cell to edit.
                </div>
                <div class="">
                    <table class="table table-striped table-bordered w-full mb-4">
                        <thead>
                            <tr>
                                <th class="table-header bg-white">Active</th>
                                <th class="table-header bg-white">Device Id</th>
                                <th class="table-header bg-white">Expires</th>
                            </tr>
                        </thead>
                        <tbody>
                            <club-device-details v-for="device in clubDevices" :device="device" :key="device.id"></club-device-details>
                            <tr v-if="!clubDevices.length"><td colspan="3" class="border bg-gray-200 text-center py-3 px-3 ">No Devices</td></tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right text-gray-600 text-sm">
                    Device Id is displayed on the scoring device
                </div>
                <hr>
                <div>
                    <h4 class="mb-0">Tournament Authorized Devices</h4>
                    <div class="mt-0 pt-0 pb-2 text-gray-600 text-sm">
                        Adding a device to the club list will allow it to score for any of this clubs tournaments
                    </div>
                    <div v-if="message" class="bg-blue-100 p-2">{{message}}</div>
                    <div class="">
                        <table class="table table-striped table-bordered w-full mb-4 ">
                            <thead>
                            <tr class="justify-between">
                                <th class="table-header bg-white">Device Id</th>
                                <th class="table-header bg-white">Add to Club list</th>
                            </tr>
                            </thead>
                            <tbody class="px-3 py-3 ">
                            <tournament-device-details @addDevice="addDevice" class="" v-for="device in tournamentDevices" :device="device" :clubDevices="clubDevices" :key="device.id + '_tournament'">

                            </tournament-device-details>
                            <tr v-if="!tournamentDevices.length"><td colspan="3" class="border bg-gray-100 py-3 px-3 text-center">No Devices</td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name: "ClubScoringDevices",
        props:['club','inc_devices'],
        data(){
            return {
                search: '',
                message: null,
                devices: this.inc_devices
            }
        },
        computed: {
            selectedDevices(){
                let vm = this;
                if(vm.search){
                    let search = vm.search.toUpperCase();
                    return vm.devices.filter(f => f.name ? f.name.toUpperCase().includes(search) : f.device_id.toUpperCase().includes(search));
                }
                return this.devices

            },
            tournamentDevices(){
                let club = this.clubDevices;
                if (club.length) {
                    return this.selectedDevices.filter(f => f.tournament_id !== null &&
                        (club.findIndex(i => i.hash === f.hash && i.device_id === f.device_id) === -1));
                } else {
                    return this.selectedDevices.filter(f => f.tournament_id !== null);
                }

            },
            clubDevices(){
                return this.selectedDevices.filter(f => f.tournament_id === null);
            }
        },
        methods: {
            addDevice(device){
                let existing = this.clubDevices.find(f => f.id === device.id);
                if(!existing){
                    this.devices.push(device);
                    this.message = 'Device with Id: ' + (device.name ? device.name : device.device_id) + ' added to Club Devices.';
                } else {
                    this.message = 'Device already in Club Devices with the Id: ' + (device.name ? device.name : device.device_id);
                }
                return this.search = '';
            }
        }

    }
</script>

<style scoped>

</style>
