<template>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-header text-left">Name</th>
        <th class="table-header" v-for="option in store.filterList">
          {{ `${option[0].toUpperCase()}${option.slice(1)}` }}
        </th>
        <th class="table-header ">Total Score</th>
        <th v-for="breaker in tieBreakers" class="table-header">Total {{ breaker }}'s</th>
        <th v-if="useHandicap" class="table-header">Handicap</th>
        <th class="table-header " v-if="rounds.length > 1" v-for="round in rounds">{{ round.name }}</th>
      </tr>
      </thead>
      <tbody>
      <competitor-score
        v-for="competitor in filteredCompetitors"
        :competitor="competitors[competitor]"
        :scores="tournamentIn.scoreList[competitor]"
        :place="placeList['overall'].indexOf(competitor) + 1"
        :key="'overall_' + competitor"
        :filter_list="store.filterList"
        :breakdown="true"
        :images="tournamentIn.scoreImages[competitor]"
        :duplicates="store.competitorDuplicates(competitor)"/>
      <tr v-if="!filteredCompetitors.length" class="text-center">
        <td colspan="10" class="table-empty "> No matching results</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import CompetitorScore from "./CompetitorScore.vue";
import {useResultsStore} from "../../../stores/ResultsStore";
import {mapState} from "pinia";

export default {
  name: "OverallResultsTable",
  components: {CompetitorScore},
  props: ['tournamentIn', 'filteredCompetitors', 'competitors', 'placeList'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, [
      'tieBreakers',
      'useHandicap',
      'rounds',
    ]),
  }
}
</script>
<style scoped>

</style>