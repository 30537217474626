<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import {Field} from "vee-validate";

const store = useTournamentStore();

const optionSelected = (option) => {
  store.pricingExtraSelected = option.name;
  store.touched();
}
</script>

<template>
  <div v-if="store.useCustomPricing" class="mt-4">
    <div class="bs-section-header">Options (select one)</div>
    <div class="flex flex-wrap">
      <div class="mr-2 mb-2" v-for="option of store.registrationExtras">
        <button v-if="option.multiple != null" @click.prevent="optionSelected(option)"
                class="bs-input-box" :class="store.pricingExtraSelected === option.name ? 'bg-black text-white' : ''">
          {{ option.name }}
        </button>
      </div>
    </div>
    <div v-if="store.pricingExtraSelected != null" class="mt-4">
      <div v-for="option of store.registrationExtras">
        <div v-if="option.name === store.pricingExtraSelected">
          <div v-for="multiple of option.multiple" class="flex mb-2">
            <div class="flex-3 mr-4">
              <div class="bs-input-box">{{ multiple.label }}</div>
            </div>
            <div class="flex-1">
              <div class="flex moneyContainer">
                <span class="dollarSign"><i class="fa-regular fa-lg fa-dollar-sign"></i></span>
                <Field class="bs-input-box money" :name="`${option.name} ${multiple.label} price`"
                       type="number" min="0" step="1" placeholder="0"
                       @blur="store.updateCustomPrice(option, multiple, $event.target.value)"
                       :value="store.customPricing.find((f) => f.option === option.name && f.multiple_id === multiple.id)?.value ?? store.price"
                       rules="min_value:0"/>
                <span class="cents">.00</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.money {
  font-size: 16px !important;
  padding-right: 36px;
  text-align: right;
}

.moneyContainer {
  @apply text-stone-400;
  font-size: 16px !important;
  position: relative;
  display: inline-block;
  letter-spacing: .32px;
}

.cents {
  position: absolute;
  right: 13px;
  top: 5px;
}

.dollarSign {
  position: absolute;
  left: 10px;
  top: 6px;
}
</style>