<script setup>

import {computed} from "vue";
import {useCompetitorStore} from "../../../stores/competitorStore";
import {ErrorMessage, Field} from "vee-validate";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";

const store = useCompetitorStore();

const validOptions = computed(() => {
  let list = [];
  for (let option of store.regform) {
    if (!store.defaultFields.includes(option.option)) list.push(option);
  }
  return list;
});
</script>

<template>
  <div>
    <div class="bs-card" v-for="option in validOptions">
      <div class="bs-section-header">{{ option.name }}{{ option.type === 'radio' ? ' (select one)' : '' }}</div>
      <div v-if="option.type === 'radio'" class="flex flex-wrap">
        <div v-for="choice in option.multiple" class=" mr-2 mb-2">
          <button @click.prevent="store.choiceTapped(option, choice)" class="bs-input-box"
                  :class="store.selectedChoice(option, choice) ? 'selected' : ''">
            {{ choice.label }}
          </button>
        </div>
      </div>
      <div v-else-if="option.type === 'waiver'">
        <button @click.prevent="store.viewWaiverTapped(option)" class="m-4 mb-8">
          <i class="fa fa-file mr-1"></i> View the waiver
        </button>
        <form-checkbox :disabled="true" title="I have read and understand the waiver. I agree to be bound by its terms."
                       :active="store.waiverIsSigned(option)"/>
      </div>
      <div v-else>
        <Field class="bs-form-box w-full" :name="option.option" rules="clean|required"
               v-model="store.customValues[option.option]"/>
      </div>
      <ErrorMessage :name="option.option" class="errorText"/>
    </div>
  </div>
</template>

<style scoped>
.selected {
  @apply text-white bg-black;
}
</style>