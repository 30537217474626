<template>
  <div>
    <div v-if="showManage">
      <manage-tournaments v-if="tournamentCount" :user="user"/>
      <div class="card card-blue-700">
        <div class="card-header">Manage</div>
        <div class="card-body">
          <div class="flex flex-col md:flex-row">
            <template v-if="showTournaments">
              <div class="flex-1 pr-10">
                <div class="text-2xl">Clubs</div>
                <hr class="py-0 my-0">
                <div v-for="club in user.managed_clubs">
                  <a class="text-blue-400" :href="'/clubs/' + club.slug">{{ club.name }}</a>
                </div>
              </div>
              <!--              <div class="flex-1 pr-10" v-if="tournamentCount">-->
              <!--                <div class="flex flex-wrap justify-between">-->
              <!--                  <div class="text-2xl">Tournaments</div>-->
              <!--                  <div class="h-center mb-1">-->
              <!--                    <new-tournament-button :user="user" :color="'blue-600'"/>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <hr class="py-0 my-0">-->
              <!--                <div v-for="club in user.managed_clubs">-->
              <!--                  <div v-for="(tournament,index) in club.tournaments">-->
              <!--                    <a v-show="index < limit" class="text-blue-400"-->
              <!--                       :href="'/tournaments/' + tournament.slug">{{ tournament.name }}</a>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div v-if="tournamentCount > 5" class="float-right text-sm text-gray-600">-->
              <!--                  <div v-if="limit === 5" @click="limit = tournamentCount">show all-->
              <!--                    {{ tournamentCount }}-->
              <!--                  </div>-->
              <!--                  <div v-else @click="limit = 5">show most recent</div>-->
              <!--                </div>-->
              <!--              </div>-->
            </template>
            <div v-if="showArchers" class="mb-2 flex-1 pr-10">
              <div href="" class="text-2xl">Archers</div>
              <hr class="py-0 my-0">
              <div>
                <a class="text-blue-400" :href="'/profile/' + user.slug + '/archers'">
                  Manage Archers ({{ user.managed_archers.length }})
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showArchers" class="flex mb-12 mt-4">
      <div class=" mr-4 mt-2">
        <i class="far fa-3x fa-fw fa-whistle"></i>
      </div>
      <div class="flex-grow flex flex-col">
        <div class="text-2xl font-sans flex">
          Are you a parent, coach or instructor?
        </div>
        <div>
          <a :href="'/profile/' + user.slug + '/archers'" class="text-xl text-blue-400">Click here to manage
            archers.
          </a>
        </div>
      </div>
    </div>
    <div v-if="!showTournaments" class="flex my-4">
      <div class="mr-4 mt-2">
        <i class="far fa-3x fa-fw fa-users"></i>
      </div>
      <div class="flex-grow flex flex-col">
        <div class="text-2xl font-sans">Are you a Club or Tournament Director?
          <video-tip :link="'https://www.youtube.com/embed/RAyK7iszpTQ'" :title="'How To Create A Club'"/>
        </div>
        <div>
          <a @click.prevent="manageTournaments" href="" class="text-xl text-blue-400">Click here to create a
            Tournament</a>
        </div>
      </div>
    </div>
    <div v-else-if="!tournamentCount" class="flex my-4">
      <div class="mr-4 mt-2">
        <i class="far fa-3x fa-fw fa-medal"></i>
      </div>
      <div class="flex-grow flex flex-col">
        <div class="text-2xl font-sans">Are you a Tournament Director?</div>
        <div>
          <new-tournament-button :user="user" class="float-left" :color="'blue-600'"/>
          <span class="align-middle">
            <video-tip :link="'https://www.youtube.com/embed/rZR3qdlFPjI'" :title="'How To Create A Tournament'"/>
          </span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NewEventButton from "../partials/NewTournamentButton.vue";
import VideoTip from "../partials/VideoTip.vue";
import NewTournamentButton from "../partials/NewTournamentButton.vue";
import ManageTournaments from "./ManageTournaments.vue";

export default {
  name: "ProfileManage",
  components: {ManageTournaments, NewTournamentButton, VideoTip, NewEventButton},
  props: ['user', 'archived'],
  data() {
    return {
      limit: 5,
      showManage: false,
      showArchers: false,
      showTournaments: false,
    }
  },
  computed: {
    tournamentCount() {
      let count = 0;
      this.user.managed_clubs.forEach(function (club) {
        club.tournaments.forEach(f => count++);
      });
      return count;
    }
  },
  methods: {
    manageArchers() {
      window.location.href = '/profile/' + this.user.slug + '/archers';
    },
    manageTournaments() {
      Swal.fire({
        icon: 'info',
        title: 'Tournaments are hosted by Clubs',
        html: 'To host a tournament with an ' +
          '<a href="/clubs" class="text-blue-600 cursor-pointer">existing club</a>, please contact the club manager. ' +
          'To create and become the manager of a new club, click the "Create Club" button',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Create Club'
      }).then(({value}) => {
        if (value) {
          window.location.href = "/clubs/create"
        }
      });
    }
  },
  created() {
    if (this.user.managed_clubs && this.user.managed_clubs.length) {
      this.showManage = true;
      this.showTournaments = true;
    }
    if (this.user.managed_archers && this.user.managed_archers.length) {
      this.showManage = true;
      this.showArchers = true;
    }
  }
}
</script>

<style scoped>

</style>
