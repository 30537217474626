<template>
  <div class="flex-grow">
    <div v-if="tournamentIn.club === null">This page is not available for practice matches and quick shoots.</div>
    <div v-else>
      <div class="mt-2" v-if="tournamentCopy.image">
        <div v-if="godUser" class="pl-2 flex flex-wrap justify-end">
          <div class="flex flex-col justify-center mr-2">
            <span>Image is {{ tournamentCopy.image_enabled ? 'Enabled' : 'Disabled' }}</span>
          </div>
          <span class="flex flex-wrap">
            <button v-if="!tournamentCopy.image_enabled" @click="enable"
                    class="btn-sm btn-outline-blue-600 mr-2 mb-2">Enable Image
            </button>
            <button v-else @click="disable" class="btn-sm btn-outline-gray-600 mr-2  mb-2">Disable Image
            </button>
            <button @click="deleteUser" class="btn-sm btn-outline-red-600 mr-2 mb-2">DELETE TOURNAMENT</button>
          </span>
        
        </div>
      </div>
      <tournament-details :tournament="tournamentCopy" :god="godUser"/>
      <div class="w-full flex bg-black">
        <div class="text-blue-200 flex-grow text-center h-center px-2">
          Share this link online or with folks you want to sign up for this tournament!
        </div>
        <ShareNetwork
          v-for="(network, index) in networks"
          :key="`network_${index}`"
          :url="linkUrl"
          :network="network.network"
          :title="'Bowscore ' + tournament.name"
          :description="tournament.description"
          :class="'text-blue-600 text-2xl mr-3'">
          <i class="cursor-pointer" :class="network.icon"></i>
        </ShareNetwork>
        <span @click="copyToClipboard" class="text-blue-600 text-2xl shareIcon mr-3">
          <i class="fas fa-fw fa-share-square cursor-pointer"></i>
        </span>
        <input type="hidden" :value="linkUrl" id="urlHidden">
      </div>
      <host-postal v-if="tournament.is_master_postal" :master="tournament"/>
      <div v-else>
        <div class="flex m-2 justify-center">
          <router-link to="/register" class="btn text-2xl m-2 py-0 btn-blue-600 w-1/2 md:w-1/3 lg:w-1/4 px-2">
            <a>{{ tournament.userRegistered ? 'Register' : 'Sign Up!' }}</a>
          </router-link>
          <router-link to="/results" class="btn text-2xl m-2 py-0 btn-blue-600 w-1/2 md:w-1/3 lg:w-1/4">
            <a class="whitespace-nowrap"><span class="hidden lg:inline-block">View the&nbsp;</span>Results</a>
          </router-link>
        </div>
        <div class="flex justify-center">
          <div class="w-full md:w-2/3 lg:w-1/2 mx-4 ">
            <span class="text-lg">Find your assignment details here!</span>
            <div class="bs-form-box p-0 flex rounded-lg ">
              <div class="flex flex-col justify-center text-gray-500 p-2 mr-2">
                <i class="fa-2x fas fa-search"></i>
              </div>
              <input class="flex-grow text-xl sm:text-3xl focus:outline-none" ref="searchBar"
                     :placeholder="' Enter an archer or team name'"
                     v-model="search">
              <button v-if="search.length" @click="clearSearch"
                      class="flex flex-col justify-center text-gray-500 p-2 mr-2 font-light">
                <i class="fa-2x fas fa-times"></i>
              </button>
              <div v-else>&nbsp</div>
            </div>
            
            <div v-if="teams">
              <div v-for="team in teams" @click="selectedTeam(team)"
                   class="hover:text-blue-600 cursor-pointer">
                {{ team }}
              </div>
            </div>
            <div v-if="competitors">
              <div v-for="competitor in competitors" @click="selectedCompetitor(competitor.id)"
                   class="hover:text-blue-600 cursor-pointer">
                {{ competitor.name }}
              </div>
            </div>
            <div v-if="competitor" class="p-2 my-2 rounded bg-blue-200 text-2xl flex justify-between">
              <assignment-details :competitor="competitor" :tournament="tournament"/>
              <span @click="competitor = null"><i class="fas fa-times"></i></span>
            </div>
            <div v-else-if="team" class="p-2 my-2 rounded bg-blue-200 text-2xl flex justify-between">
              <div>
                <div class="text-3xl">{{ team }}</div>
                <div v-for="(competitor, i) in getCompetitors(team)"
                     :class="i < (getCompetitors(team).length - 1) ? 'border-b border-blue-700' : ''">
                  <assignment-details :competitor="competitor.id" :tournament="tournament"/>
                </div>
              </div>
              <span @click="team = null"><i class="fas fa-times"></i></span>
            </div>
          </div>
        </div>
      </div>
      
      <div class="p-3 lg:p-8">
        <tournament-detail-list :tournament="tournamentCopy"/>
      </div>
    </div>
  
  </div>
</template>

<script>
import AssignmentDetails from "./AssignmentDetails.vue";
import HostPostal from "./postal/HostPostal.vue";
import TournamentDetails from "./TournamentDetails.vue";
import TournamentDetailList from "./TournamentDetailList.vue";
import Swal from "sweetalert2";

export default {
  name: "Home",
  components: {TournamentDetailList, TournamentDetails, HostPostal, AssignmentDetails},
  props: ['tournamentIn'],
  data() {
    return {
      tournamentCopy: this.tournamentIn,
      search: '',
      team: null,
      competitor: null,
      networks: [
        {network: 'facebook', icon: 'fab fa-fw fa-facebook-square'},
        {network: 'email', icon: 'fas fa-fw fa-envelope'}
      ]
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    user() {
      return this.$store.state.user.user;
    },
    godUser() {
      return this.user && this.user.roles && this.user.roles.find(f => f.name === 'god')
    },
    linkUrl() {
      return location.origin + '/tournaments/' + this.tournament.slug + '#/register'
    },
    competitors() {
      if (this.search && this.search.length > 2) {
        return Object.values(this.tournament.competitorList).filter(f => f.name.toUpperCase().includes(this.search.toUpperCase()))
      }
    },
    teamList() {
      return this.$store.getters['tournament/getTeams'];
    },
    teams() {
      if (this.search && this.search.length > 2) {
        return this.teamList.filter(f => f.toUpperCase().includes(this.search.toUpperCase()))
      }
    }
  },
  methods: {
    getCompetitors(team) {
      return this.$store.getters['tournament/getCompetitorsForTeam'](team)
    },
    selectedTeam(team) {
      this.competitor = null;
      this.team = team;
      this.clearSearch();
    },
    selectedCompetitor(id) {
      this.team = null;
      this.competitor = id;
      this.clearSearch();
    },
    clearSearch() {
      this.search = '';
    },
    copyToClipboard() {
      var url = document.getElementById("urlHidden");
      url.setAttribute('type', 'text');
      url.select();
      document.execCommand('copy');
      url.setAttribute('type', 'hidden');
      Swal.fire('Link copied to clipboard')
    },
    enable() {
      this.$axios
      .post('/godmode/enable_image', {tournament: this.tournamentCopy.slug})
      .then(({data}) => {
        if (data.success) {
          this.tournamentCopy.image_enabled = true;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    disable() {
      this.$axios
      .post('/godmode/disable_image', {tournament: this.tournamentCopy.slug})
      .then(({data}) => {
        if (data.success) {
          this.tournamentCopy.image_enabled = false;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    deleteUser() {
      Swal.fire({
        titleText: 'Delete ' + this.tournamentCopy.name,
        text: 'This is a big deal. Are you sure you want to do delete it?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: () => {
          this.$axios
          .post('/godmode/delete_tournament', {tournament: this.tournamentCopy.slug})
          .then(({data}) => {
            if (data.success) {
              window.location.href = '/tournaments';
            }
          }).catch(({error}) => {
            console.log(error)
          });
        },
      })
      
    }
  }
}
</script>

<style scoped>

</style>
