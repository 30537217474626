<template>
  <div class="card-blue-700 m-2">
    <div class="card-header">Disable Scoring</div>
    <div class="card-body">
      
      <div class="relative">
        <div v-if="refreshing" style="background-color: rgba(255, 255, 255, .7) !important;"
             class="bg-transparent absolute h-full w-full z-10 flex flex-col justify-center">
          <div class="flex justify-center">
            <div>
              <i class="fa-3x fas fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
        <div class="hintText">Scoring will NOT be available on ANY device. You may re-enable scoring at any time.
          This feature is intended to help prevent cheating and is commonly used before and after the tournament.
        </div>
        <form-switch
          @toggled="toggleLock()"
          :model="lockList.tournament"
          id="lockScoring"
          :key="`lock_${tournament.id}_${refreshed}_key`"
          label="Entire Tournament"/>
        <div v-for="(round, index) in tournament.rounds"
             class="flex flex-col md:flex-row"
             :class="index % 2 === 0 ? 'bg-blue-100' : ''">
          <form-switch
            @toggled="toggleLock(round)"
            :model="lockList.rounds[round.id].locked"
            class="flex-1"
            :key="`lock_${round.id}_${refreshed}_key`"
            :id="`lock_${round.id}`"
            :label="round.name"/>
          <div v-for="time in round.line_times" class="flex-1">
            <form-switch
              @toggled="toggleLock(round, time)"
              :model="lockList.rounds[round.id].times[time.id].locked"
              class="flex-1"
              :key="`lock_${round.id}_${time.id}_${refreshed}_key`"
              :id="`lock_${round.id}_${time.id}`"
              :label="time.time"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "DisableScoring",
  components: {FormSwitch},
  props: ['tournamentIn'],
  data() {
    return {
      tournament: this.tournamentIn,
      locks: null,
      refreshed: 0,
      refreshing: false,
    }
  },
  computed: {
    lockList() {
      if (this.locks == null) return this.tournamentIn.lockedList;
      return this.locks;
    },
  },
  methods: {
    toggleLock(round, time) {
      let vm = this;
      this.refreshing = true;
      this.$axios.post(`/tournaments/${this.tournament.slug}/lock_scoring`, {
        'round': round != null ? round.id : null,
        'time': time != null ? time.id : null,
      })
      .then(({data}) => {
        if (data['success'] && data['data']) {
          vm.locks = data['data'];
          vm.refreshed++;
        }
        vm.refreshing = false;
      })
      .catch(function (error) {
        console.log(error);
        vm.refreshing = false;
      });
      
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$emit('refresh');
    next()
  },
}
</script>
<style scoped>

</style>