<script setup>

import {useTournamentStore} from "../../stores/TournamentStore";

const store = useTournamentStore();
</script>

<template>
  <div class="formBanner">
    <div class="flex flex-col sm:flex-row">
      <div class="left mb-2 sm:mb-0">
        <div class="h-full flex flex-col justify-center">
          <img alt="bowscore logo" src="/img/White_Bowscore_Logo.png" class="image">
        </div>
      </div>
      
      <div v-if="store.unsavedChanges" class="middle">
        <div class="buttons">
          <slot name="buttons"></slot>
          <div class="right"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.formBanner {
  @apply bg-stone-600 flex flex-col justify-center;
  
  height: 80px;
}

.left {
  padding-left: 64px;
  
  width: 280px;
  min-width: 280px;
  max-width: 280px;
}


@media (max-width: 600px) {
  .formBanner {
    padding-left: 1rem;
  }
}

.middle {
  @apply flex-grow flex justify-center;
}

.buttons {
  @apply flex justify-end;
  margin-left: 660px;
  max-width: 0px;
  min-width: 10px;
  width: 100%;
}

@media (max-width: 1375px) {
  .middle {
    @apply justify-end
  }
  .buttons {
    @apply ml-0;
  }
}

.right {
  min-width: 10px;
  width: 100%;
  max-width: 200px;
}

.image {
  width: 210px;
}

@media (max-width: 600px) {

}

</style>