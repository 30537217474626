<script setup>
import {onMounted, ref} from "vue";
import axios from 'axios';
import CardSettings from "./CardSettings.vue";


const props = defineProps(['all']);

let tournaments = ref([]);

const loading = ref(true);

onMounted(() => {
  axios.get('/dashboard/recent', {params: {'all': props.all}}).then(({data}) => {
    if (data['success']) {
      tournaments.value = data['list'];
    }
    loading.value = false;
  })
})

const link = (tournament, division) => {
  let link = `/tournaments/${tournament.slug}#/results`;
  if (division) link += tournament.division_link;
  return link;
}

</script>

<template>
  <div class="bs-shadow-card cardStyle">
    <card-settings v-if="!all" name="recent"/>
    <div class="flex">
      <div class="w-full">
        <div class="bs-card-header">Your <span :class=" all ? 'hidden' : ''">recent </span>rankings</div>
        <div v-if="loading" class="flex justify-center">
          <i class="fa-regular fa-4x fa-spinner fa-spin"/>
        </div>
        <div v-else>
          <div v-for="tournament of tournaments" class="tournamentRow sm:flex-row">
            <a :href="link(tournament, false)" class="label">
              {{ tournament.name }}<br class="hidden sm:block">({{ tournament.competitor }})
            </a>
            <div class="text">{{ tournament.points }}/{{ tournament.max_points }}pts</div>
            <a :href="link(tournament, false)" class="text">#{{ tournament.place }} Overall</a>
            <a :href="link(tournament, true)" class="text flex2">
              #{{ tournament.division_place }} {{ tournament.division }}
            </a>
          </div>
          <a v-if="all" :href="'/dashboard'" class="bs-shadow-card block mt-6">User Dashboard</a>
          <a v-else :href="'/dashboard/all_results'" class="bs-shadow-card block mt-6">View All</a>
        </div>
      
      </div>
    
    </div>
  </div>
</template>

<style scoped>
.cardStyle {
  @apply w-full relative mb-4;
  padding-top: 32px;
  padding-bottom: 38px;
}

.tournamentRow {
  @apply mb-4 flex flex-col;
}

.flex2 {
  flex: 2 !important;
}

.label {
  @apply font-bold text-black pr-1;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
  flex: 2;
}

.text {
  @apply font-medium text-stone-400 pr-1;
  flex: 1;
  font-size: 16px;
  line-height: 19px;
}

</style>