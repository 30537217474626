<template>
  <div class="card card-blue-700">
    <div class="card-header">
      My Managers
    </div>
    <div class="card-body">
      <p class="text-sm text-gray-600">
        Managers are authorized to register you for any tournament.
        You may remove them at any time.
      </p>
      <div class="w-3/4 my-3 mx-auto">
        <div class="">Add a Manager</div>
        <div class="md:flex">
          <input v-model="managerEmail" @keyup="failedMessage = ''" class="bs-form-box flex-grow">
          <button @click="addManager" class="btn-sm btn-outline-blue-600">Submit</button>
        </div>
        <span v-if="failedMessage" class="text-sm text-red-500 ml-2">{{ failedMessage }}</span>
        <span class="text-sm text-gray-600 ml-2">Enter the email address that your manager uses to login to
          Bowscore.</span>
      </div>
      <div v-if="user.archer.managers && user.archer.managers.length">
        <div class="text-xl">My Managers</div>
        <hr class="py-0 my-0">
        <div class="flex flex-col">
          <div class="flex mb-3 font-black">
            <div class="flex-2">Name</div>
            <div class="flex-2">Email</div>
            <div class="flex-1">Remove</div>
          </div>
          <div class="flex" v-for="manager in user.archer.managers">
            <div class="flex-2">{{ manager.first_name }} {{ manager.last_name }}</div>
            <div class="flex-2">{{ manager.email }}</div>
            <div class="flex-1"><span @click="removeManager(manager)" class="btn-sm btn-outline-gray-600"><i
              class="fas fa-trash-alt"></i></span></div>
          </div>
        </div>
      
      
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "AllowedManage",
  props: ['inc_user'],
  data() {
    return {
      managerEmail: '',
      failedMessage: '',
      user: this.inc_user,
    }
  },
  methods: {
    addManager() {
      this.$axios
      .post('/profile/' + this.user.slug + '/manager/create', {email: this.managerEmail})
      .then(({data}) => {
        if (data.success) {
          this.user.archer.managers.push(data.manager);
          this.managerEmail = '';
        } else {
          this.failedMessage = data.error.message;
        }
      })
      .catch(({response}) => {
        console.log(response)
      });
    },
    removeManager(manager) {
      Swal.fire({
        titleText: 'Remove ' + manager.full_name + ' as Manager?',
        html: 'This will not affect any existing registrations',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.$axios
          .post('/profile/' + this.user.slug + '/manager/remove', {manager: manager.id})
          .then(({data}) => {
            if (data.success) {
              let index = this.user.archer.managers.findIndex(f => f.id === manager.id);
              if (index !== -1) {
                this.user.archer.managers.splice(index, 1);
              }
            } else {
              this.failedMessage = data.error.message;
            }
          })
          .catch(({response}) => {
            console.log(response)
          });
        }
      });
      
    }
  }
}
</script>

<style scoped>

</style>
