<template>
  <div id="sidebar-container" style="z-index: 1050" class=" min-w-0 lg:min-w-60"
       :class="locked ? 'lg:hidden ' : 'lg:block'">
    <div class="hidden sticky h-full top-0" :class="locked ? 'lg:hidden ' : 'lg:block'">
      <div class="flex flex-col h-full justify-between sticky pin-t text-sm"
           :class="expanded ? 'text-left' : 'text-center'">
        <div class="flex h-full flex-col">
          <div class="bg-black">
            <div @click.prevent="toggleExpand" class="py-2 px-4 text-blue-200 hover:text-blue-400"
                 title="Toggle Navigation">
              <span v-show="expanded"><i class="far fa-2x fa-angle-left"></i></span>
              <span v-show="!expanded"><i class="far fa-2x fa-angle-right"></i></span>
            </div>
          </div>
          <a class="nav-link" href="/results" :class="active === 'results' ? 'active' : ''">
            <i class="fas fa-fw fa-medal"></i>
            <span v-show="expanded">Results</span>
          </a>
          <dropdown v-if="user && user.managed_clubs.length" direction="left" key="tournaments_grouped"
                    css="whitespace-nowrap border"
                    align="top" background="blue-900">
            <template v-slot:trigger>
              <a class="nav-link" href="#" title="Clubs"
                 :class="active === 'clubs' ? 'active' : ''">
                <i class="fas fa-fw fa-store-alt"></i>
                <span v-show="expanded">Clubs</span>
              </a>
            </template>
            <a class="nav-link mb-1" href="/clubs">Find a Club</a>
            <a v-for="org in user.managed_clubs" class="nav-link" :href="'/clubs/' + org.slug">{{ org.name }}</a>
          </dropdown>
          <a v-else class="nav-link" href="/clubs" title="Clubs"
             :class="active === 'clubs' ? 'active' : ''">
            <i class="fas fa-fw fa-store-alt"></i>
            <span v-show="expanded">Clubs</span>
          </a>
          <dropdown v-if="tournamentsImIn.length || tournamentsIManage.length"
                    direction="left" key="tournament_main"
                    css="whitespace-nowrap border"
                    align="top" background="blue-900">
            <template v-slot:trigger>
              <a class="nav-link" href="#" title="Tournaments" key="main_trigger">
                <i class="fas fa-fw fa-calendar-day"></i>
                <span v-show="expanded">{{ tournamentWord(3) }}</span>
              </a>
            </template>
            <a class="nav-link" href="/tournaments">
              Find a {{ tournamentWord(1) }}
            </a>
            <dropdown v-if="tournamentsImIn.length" direction="left" key="tournaments_grouped"
                      :css="navDrop"
                      align="top" background="blue-900">
              <template v-slot:trigger>
                <a class="nav-link" href="#" key="grouped_trigger"
                   title="Tournaments">
                  {{ tournamentWord(2) }} I'm In
                </a>
              </template>
              <a v-for="tournament in tournamentsImIn" class="nav-link"
                 :href="'/tournaments/' + tournament.slug">
                {{ tournament.name }}
              </a>
            </dropdown>
            <dropdown v-if="tournamentsIManage.length" direction="left" key="managed_tournaments"
                      :css="navDrop"
                      align="top" background="blue-900">
              <template v-slot:trigger>
                <a class="nav-link" href="#" key="managed_trigger"
                   title="Tournaments">
                  {{ tournamentWord(2) }} I Manage
                </a>
              </template>
              <a v-for="tournament in tournamentsIManage" class="nav-link"
                 :href="'/tournaments/' + tournament.slug">
                {{ tournament.name }}
              </a>
            </dropdown>
          </dropdown>
          <a v-else class="nav-link" href="/tournaments" :class="active === 'tournaments' ? 'active' : ''">
            <i class="fas fa-fw fa-calendar-day"></i>
            <span v-show="expanded"> {{ tournamentWord(2) }}</span>
          </a>
          <tournament-navigation
            v-if="tournament && tournament.club_id && expanded" :tournament="tournament"
            :user="user"
            :class="'ml-3'"/>
          
          <a href="/archers" class="nav-link" title="Archers" :class="active === 'archers' ? 'active' : ''">
            <i class="fas fa-fw fa-bow-arrow"></i>
            <span v-show="expanded"> {{ archerWord(2) }}</span>
          </a>
          <a v-if="user" class="nav-link" href="/dashboard" title="Dashboard"
             :class="active === 'dashboard' ? 'active' : ''">
            <i class="fas fa-fw fa-tachometer-alt"></i><span v-show="expanded"> Dashboard</span>
          </a>
          <a href="/practice" class="nav-link" title="Practice"
             :class="active === 'practice' ? 'active' : ''">
            <i class="fas fa-fw fa-calendar-exclamation"></i><span v-show="expanded"> Practice</span>
          </a>
          <practice-navigation
            v-if="tournament && !tournament.club_id && expanded" :tournament="tournament"
            :user="user"
            :class="'text-blue-200'"/>
          <a class="nav-link" href="/authorize" title="Authorize" :class="active === 'authorize' ? 'active' : ''">
            <i class="fas fa-fw fa-user-check"></i><span v-show="expanded"> Authorize</span>
          </a>
        </div>
        <div v-if="expanded" style="width:200px;">
          <a href="/build_notes" class="nav-link">
            <div>
              <div>Version:</div>
              <div> {{ version }}</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import TournamentNavigation from "./tournaments/TournamentNavigation.vue";
import PracticeNavigation from "./practice/PracticeNavigation.vue";
import moment from "moment/moment";
import Dropdown from "./partials/Dropdown.vue";

export default {
  name: "SideBar",
  components: {Dropdown, PracticeNavigation, TournamentNavigation},
  props: ['user'],
  data() {
    return {
      expanded: true,
      locked: false,
      hidePWA: false,
      show: [],
      showClubs: false,
      showTournaments: false,
      version: '',
      navDrop: 'max-h-xs max-w-lg w-56 border overflow-y-auto whitespace-normal',
    }
  },
  computed: {
    tournamentsImIn() {
      let list = [];
      if (this.user && this.user.tournaments_grouped) {
        list = this.sortTournaments(this.user.tournaments_grouped);
      }
      return list;
    },
    tournamentsIManage() {
      let list = [];
      if (this.user && this.user.managed_tournaments) {
        list = this.sortTournaments(this.user.managed_tournaments);
      }
      return list;
    },
    tournament() {
      return this.$store.getters['tournament/getTournament'];
    },
    active() {
      let path = window.location.pathname.split('/');
      if (path.length > 1) {
        return path[1] ? path[1] : 'home';
      }
      return 'home'
    },
    isTournament() {
      let path = window.location.pathname.split('/');
      if (path.length > 2 && (path[1] === 'tournaments' || path[1] === 'practice')) {
        if (path.length > 3 && path[3] === 'participant') {
          return false;
        }
        return true;
      }
      return false
    }
  },
  methods: {
    tournamentWord(count) {
      let text = this.$trans.choice('search.tournament', count);
      // let text = 'tournament';
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    archerWord(count) {
      // let text = this.$trans.choice('search.archer', count);
      let text = 'archer';
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    sortTournaments(list) {
      list.sort((a, b) => {
        if (a.updated_at > b.updated_at) {
          return -1;
        }
        if (a.updated_at < b.updated_at) {
          return 1;
        }
        return 0;
      })
      return list;
    },
    clicked(type) {
      if (type === 'club') {
        if (this.showClubs) {
          this.showClubs = false;
        } else if (this.user && this.user.managed_clubs && this.user.managed_clubs.length) {
          this.showClubs = true;
        } else {
          return window.location.href = '/clubs'
        }
        this.expanded = true;
      }
    },
    toggleExpand() {
      this.expanded = !this.expanded;
      document.cookie = 'expandBar = ' + this.expanded + '; path=/';
    },
    async setVersion() {
      await this.$axios.get('/version').then(async ({data}) => {
        if (data.success !== true) return;
        let latest = data.version;
        let time = moment(data.time).local().format('D MMM YY h:mm a');
        let display = `${latest} (${time})`;
        let local = await this.$localForage.getItem('version');
        if (local !== display) {
          await this.$localForage.setItem('version', display);
          this.$store.commit('tournament/CLEAR_TOURNAMENT');
          await this.$localForage.setItem('allTournaments', []);
        }
        this.version = display;
      })
    },
  },
  created() {
    this.setVersion();
  },
  mounted() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.hidePWA = true;
    }
    if (this.tournament) {
      this.$localForage.getItem(this.tournament.id + '_lock').then((locked) => {
        if (locked) {
          this.locked = true;
        }
      });
    }
    let cookie = decodeURIComponent(document.cookie).split(';');
    let found = '';
    var name = 'expandBar=';
    for (var i = 0; i < cookie.length; i++) {
      var c = cookie[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        found = c.substring(name.length, c.length);
      }
      if (found) {
        break;
      }
    }
    this.expanded = found ? JSON.parse(found) : true;
  }
}
</script>

<style scoped>

#sidebar-container {
  @apply shrink-0 bg-blue-900;
}

.nav-link {
  @apply text-blue-200 block px-4 py-2;
}

.nav-link:hover, .nav-link.active {
  @apply text-blue-400;
}

.dropdown-menu {
  @apply bg-blue-900 border-gray-500 border-2 border-l-0;
  z-index: 1050;
}

.dropdown-item {
  @apply bg-blue-900 text-blue-100;
}

.dropdown-item:hover {
  @apply text-blue-500 bg-blue-900;
}
</style>
