<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";

const store = useCompetitorStore();
</script>

<template>
  <div v-if="store.times.length > 1" class="bs-card">
    <div class="bs-section-header">Line Time</div>
    <div class="flex flex-wrap">
      <div v-for="time in store.times" class="mb-2 mr-2">
        <button @click.prevent="store.toggleTime(time)"
                class="bs-input-box"
                :class="store.line_time === time.id ? 'bg-black text-white' : ''">
          {{ time.time }}{{ time.memo != null ? ` (${time.memo})` : '' }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>