<template>
  <div v-if="(round.locations.length > 1 || round.line_times.length > 1) && !competitor">
    <dropdown v-if="availableLocations.length > 1 || availableTimes.length > 1">
      <template v-slot:trigger>
        <div id="assignmentSelection" aria-haspopup="true"
             aria-expanded="false"
             class="dropdown-toggle text-xl sm:text-2xl md:text-3xl lg:text-4xl text-gray-800">
          {{ location.name }} - {{ lineTime.time }}
          <i class="fas fa-caret-down"></i>
        </div>
      </template>
      
      <span v-for="loc in round.locations" class="text-center">
        <span v-for="time in round.line_times">
          <span v-if="!hasRestrictions(time,loc)"
                @click="updateLocTime(loc, time)"
                class="dropdown-item text-xl">
            <span>{{ stringDisplay(loc, time) }}</span>
            <span class="ml-2 font-bold"
                  :class="getColorFromPercentage(getCompletion(round.id,loc.id,time.id))">
              {{ getCompletion(round.id, loc.id, time.id) }}%
            </span>
          </span>
        </span>
      </span>
    </dropdown>
    <div v-else class="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
      <div>Location: {{ location.name }}</div>
      <div>Line Time: {{ lineTime.time }}</div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import Dropdown from "../../partials/Dropdown.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "RestrictLocationTime",
  mixins: [mixin],
  components: {Dropdown},
  computed: {
    ...mapState(useDisplayStore, [
      'incCompetitor',
      'location',
      'lineTime',
      'restrictedLocations',
      'restrictedTimes',
    ]),
    competitor() {
      return this.incCompetitor;
    },
    availableLocations() {
      return this.restrictedLocations.length ? this.restrictedLocations : this.round.locations;
    },
    availableTimes() {
      return this.restrictedTimes.length ? this.restrictedTimes : this.round.line_times;
    }
  },
  methods: {
    stringDisplay(loc, time) {
      return loc.name + ' - ' + time.time;
    },
    hasRestrictions(time, loc) {
      let times = this.restrictedTimes;
      let locations = this.restrictedLocations;
      if (times.length && times.findIndex(t => t.id === time.id) !== -1) return true;
      return !!(locations.length && locations.findIndex(l => l.id === loc.id) !== -1);
    },
  }
}
</script>

<style scoped>

</style>
