<script setup>
import FormCheckbox from "./FormCheckbox.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

const tapped = (option) => {
  store.setTournamentType(option);
}
</script>

<template>
  <div class="bs-card">
    <div class="bs-section-header">Tournament Type</div>
    <FormCheckbox :title="`Single Tournament`" :active="store.single" @tapped="tapped('single')"/>
    <FormCheckbox :title="`League/series`" :sub-title="`Multiple rounds. Drop specified rounds or add a handicap.`"
                  :active="store.league" @tapped="tapped('league')"/>
    <FormCheckbox
      :title="`Postal tournament`"
      :sub-title="`Host online and invite archers everywhere to compete from their home club.`" :active="store.postal"
      @tapped="tapped('postal')"/>
  </div>
</template>

<style scoped>

</style>