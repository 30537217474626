<script setup>

import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";
import {useCompetitorStore} from "../../../stores/competitorStore";
import {computed} from "vue";

const store = useCompetitorStore();

const hasArchers = computed(() => store.managedArchers.length > 0)

</script>

<template>
  <div v-if="hasArchers" class="bs-card">
    <div class="bs-section-header">Registration type</div>
    <form-checkbox title="Myself" @click="store.toggleType('self')" :active="store.registrationType === 'self'"/>
    <form-checkbox title="Group or managed archer"
                   @click="store.toggleType('group')"
                   :active="store.registrationType === 'group'"
                   sub-title="Register a group you've created or an archer you manage"/>
  </div>
</template>

<style scoped>

</style>