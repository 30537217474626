<template>
    <div v-if="!accepted" class="flex flex-wrap flex-col lg:flex-row justify-between p-2 max-w-6xl mx-auto">
        <div class="flex">
            <i class="far fa-2x fa-info-circle"></i>
            <div class="h-center ml-2">
                This site uses cookies for analytics, personalized content and ads. By continuing to browse this site,
                you agree to this use.
            </div>
        </div>
        <div class="h-center">
            <div class="flex flex-wrap justify-center lg:justify-end">
                <a @click.prevent="accept" href="" class="text-blue-600 cursor-pointer">Accept and Dismiss</a>
                <a href="/privacy_policy" target="_blank" class="text-blue-600 cursor-pointer ml-4">Learn More</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CookieNotification",
        data() {
            return {
                accepted: true
            }
        },
        methods: {
            accept() {
                var exp = moment().add(50, 'years');
                exp = new Date(exp).toUTCString();
                document.cookie = 'cookie_notification = true; path=/; expires=' + exp;
                this.accepted = true;
            }
        },
        mounted() {
            try {
                let cookie = decodeURIComponent(document.cookie).split(';');
                if (!cookie) {
                    throw('Cookie error: ' + document.cookie)
                }
                let found = '';
                var name = 'cookie_notification=';
                for (var i = 0; i < cookie.length; i++) {
                    var c = cookie[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        found = c.substring(name.length, c.length);
                    }
                    if (found) {
                        break;
                    }
                }
                this.accepted = found ? JSON.parse(found) : false;
            } catch (error) {
                console.log(error);
            }

        }
    }
</script>

<style scoped>

</style>
