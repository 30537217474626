<template>
  <div>
    <div class="flex justify-between border-b border-black">
      <div>{{ `${bale}${position}. ${name}` }}</div>
      <div>{{ score }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CompetitorLadderResults",
  props: ['tournamentIn', 'ladder', 'step', 'bale', 'position'],
  computed: {
    competitorId() {
      let stepAssignment = this.tournamentIn.assignedCompetitors.ladder[this.ladder].step[this.step];
      return stepAssignment.bales[this.bale].positions[this.position].competitor;
    },
    name() {
      if (this.competitorId != null) {
        let competitor = this.tournamentIn.competitorList[this.competitorId];
        return competitor.name;
      }
      return '';
    },
    score() {
      if (this.competitorId != null) {
        let competitor = this.tournamentIn.scoreList[this.competitorId];
        let key = `${this.ladder}_${this.step}`;
        if (competitor && key in competitor.ladders) {
          let value = competitor.ladders[key];
          let scoreText = value.points;
          if (value.X != null && value.X > 0) {
            scoreText += `-${value.X}x`;
          }
          return scoreText;
        }
      }
      return '';
    }
  }
}
</script>
<style scoped>

</style>