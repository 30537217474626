<script setup>
const contactClicked = () => {
  window.open('https://bowscore.com/contact_us');
};
</script>

<template>
  <div class="w-full flex justify-center">
    <button @click="contactClicked" class="button">contact us</button>
  </div>
</template>

<style scoped>
.button {
  @apply text-white;
  background-color: #FB5202;
  font-weight: 700;
  font-family: 'Figtree', serif;
  border-radius: 3px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  height: 44px;
  width: 338px;
  font-size: 18px;
  letter-spacing: .48px;
  line-height: 25px;
  text-transform: uppercase;
  margin-top: 16px
}
</style>