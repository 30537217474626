<script setup>
import {useStore} from 'vuex'

import NavigationButton from "./NavigationButton.vue";
import {computed, onMounted, ref, watch} from "vue";
import NavigationLink from "./NavigationLink.vue";
import NavigationTournament from "./NavigationTournament.vue";

const props = defineProps({user: Object});

const store = useStore();

const tournament = computed(() => {
  return store.getters['tournament/getTournament'];
})
const listTournaments = ref(false);
const selected = ref(null);


const toggle = (value) => {
  selected.value = selected.value === value ? null : value;
}

const mergeAndSort = (a, b, ids) => {
  let list = [];
  let all = [...a, ...b];
  for (let item of all) {
    if (ids.includes(item.id)) continue;
    list.push(item);
    ids.push(item.id);
  }
  list.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());
  return list;
}

const clubs = computed(() => {
  let managed = props.user.clubs;
  return mergeAndSort(managed, [], []);
})

const tournaments = computed(() => {
  let managed = props.user.managed_tournaments;
  let ids = props.user.archives.map((v) => v.tournament_id);
  return mergeAndSort(managed, [], ids);
  
});

watch(tournament, (newValue) => {
  if (newValue != null) {
    selected.value = 't';
  }
})

onMounted(() => {
  if (tournament != null) selected.value = 't';
})


</script>

<template>
  <div class="positioning">
    <div class="hidden lg:block bs-section-header smLeft">Management</div>
    <hr class="lg:hidden mb-2">
    <div class="text-stone-400 font-semibold">
      <div v-if="props.user.managed_tournaments.length">
        <navigation-button @click="toggle('t')" icon="fa-regular fa-calendar" label="My tournaments" class="smLeft"/>
        <div v-if="selected === 't'" class="opened">
          <button class="mdLeft mb-2 hover:text-black" @click="listTournaments = !listTournaments">
            {{ listTournaments ? "Hide" : "View" }} all
          </button>
          <navigation-tournament v-if="!listTournaments" :tournament="tournament" :user="props.user"/>
          <div v-else class="flex flex-col">
            <a :href="`/tournaments/${option.slug}`" v-for="option of tournaments" class="mdLeft">
              {{ option.name }}
            </a>
          </div>
        </div>
      </div>
      <div v-if="props.user.clubs.length">
        <navigation-button @click="toggle('c')" icon="fa-regular fa-clipboard" label="My clubs" class="smLeft"/>
        <div v-if="selected === 'c'">
          <div v-for="club in clubs" class="mdLeft">
            <a :href="`/clubs/${club.slug}`">{{ club.name }}</a>
          </div>
        </div>
      </div>
      <navigation-link :link="`profile/${props.user.slug}/archers`" icon="fa-regular fa-people-pants-simple"
                       label="My archers"
                       class="py-2 mr-1"/>
    </div>
  </div>
</template>

<style scoped>
a:hover {
  @apply text-black;
}


.selected {
  @apply bg-white text-black font-extrabold py-2;
  min-width: 280px;
}


.xlLeft {
  @apply py-2 mr-1;
  padding-left: 9rem;
}

.lgLeft {
  @apply py-2 mr-1;
  padding-left: 7.5rem;
}

.mdLeft {
  @apply py-2 mr-1;
  padding-left: 3.44rem;
  margin-right: 1rem;
}

.smLeft {
  @apply py-2 mr-1;
}

@media (min-width: 1100px) {
  .xlLeft {
    padding-left: 9rem;
  }
  
  .lgLeft {
    padding-left: 7.5rem;
  }
  
  .mdLeft {
    padding-left: 6rem;
    margin-right: 1rem;
  }
  
  .smLeft {
    margin-left: 3.75rem;
  }
  
  
  .positioning {
    margin-top: 1.25rem;
  }
  
}


.opened {
  font-size: 16px;
  line-height: 1.19rem;
  letter-spacing: .02rem;
}
</style>