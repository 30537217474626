<template>
  <div class="order-3 sm:order-2 w-full sm:w-auto">
    <div v-if="!showDetails" class="hideShow" @click="showDetails = !showDetails">
      Show Details
    </div>
    <div v-else>
      <div v-if="tournament.club_id">
        <div v-if="locked" class="flex justify-center">
          <router-link to="/complete" class="btn-lg btn-outline-blue-600">
            <a>End Scoring</a>
          </router-link>
        </div>
        <div class="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-center border-b">
          {{ tournament.name }}
        </div>
        <restrict-round v-if="!isLadder"/>
        <restrict-location-time v-if="!isLadder"/>
        <restrict-ladder v-if="isLadder"/>
        <restrict-step v-if="isLadder"/>
        <div>
          <restrict-bales/>
          <router-link v-if="hasFailures" to="/reshoots">
            <button class="show mx-auto mb-2 btn-lg btn-green-500">Score Equipment Failures</button>
          </router-link>
        </div>
      </div>
      <div v-else class="text-4xl text-center">
        {{ tournamentName }}
        <restrict-bales/>
      </div>
      <div @click="showDetails = !showDetails"
           class="hideShow">
        Hide Details
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import Dropdown from "../../partials/Dropdown.vue";
import RestrictRound from "./RestrictRound.vue";
import RestrictLocationTime from "./RestrictLocationTime.vue";
import RestrictBales from "./RestrictBales.vue";
import {mapState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";
import RestrictLadder from "./RestrictLadder.vue";
import RestrictStep from "./RestrictStep.vue";

export default {
  name: "RestrictScoring",
  mixins: [mixin],
  components: {RestrictStep, RestrictLadder, RestrictBales, RestrictLocationTime, RestrictRound, Dropdown},
  data() {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['locked']),
    tournamentName() {
      let text = this.tournament.name;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
}
</script>

<style scoped>
.hideShow {
  @apply cursor-pointer text-center my-6 text-blue-600;
}
</style>
