<script setup>
import {useCompetitorStore} from "../../../stores/competitorStore";
import {ref} from "vue";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";

const store = useCompetitorStore();

const show = ref(null);
</script>

<template>
  <div @click="show = null" v-if="store.purchases.length > 0" class="bs-card">
    <div class="bs-section-header">Optional purchases</div>
    <div>
      <div v-for="archer of store.selectedArchers" class="flex mb-2">
        <div class="flex-2 flex flex-col justify-start">
          <div class="bs-input-box">{{ archer.name }}</div>
        </div>
        <div class="flex-2 mx-4">
          <div v-if="show !== archer.id" @click.stop="show = archer.id"
               class="bs-input-box pr-1 flex flex-col justify-center">
            <div class="flex justify-between">
              <div class="purchaseList">{{ store.groupPurchases[archer.id].join(', ') }}</div>
              <i class="fa fa-angle-down"></i>
            </div>
          </div>
          <div v-else class="bs-input-box pr-1 smaller">
            <div class="">
              <FormCheckbox
                v-for="option of store.purchases" :title="`${option.name} $${option.amount}`"
                @click="store.setPurchase(archer, option)"
                :no-margin="store.purchases.length === 1"
                :active="store.groupPurchases[archer.id].includes(option.name)"/>
            </div>
          </div>
        </div>
        <div class="flex-1 flex flex-col justify-start">
          <div class="bs-input-box moneyBox">
            <div class="flex justify-between">
              <div class="flex flex-col justify-center">
                <i class="fa fa-lg fa-dollar-sign"></i>
              </div>
              <span class="text-black">{{ store.purchaseTotal(archer) }}.00</span>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>

<style scoped>
.bs-input-box {
  min-height: 36px;
}

.smaller {
  padding-bottom: 7px;
}

.purchaseList {
  padding-right: 22px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 175px;
  text-overflow: ellipsis;
}

.moneyBox {
  @apply flex flex-col justify-center bg-stone-100 text-stone-400;
}
</style>