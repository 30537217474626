<script>
export default {
  name: "LandingFooter",
  methods: {
    navigateTo(page) {
      let url = 'https://community.bowscore.com';
      if (page === 'terms') url = 'https://bowscore.com/terms_of_service';
      if (page === 'privacy') url = 'https://bowscore.com/privacy_policy';
      window.open(url);
    }
  }
}
</script>

<template>
  <div class="text centered">
    <div class="flex-col-reverse lg:flex-row flex justify-between">
      <div class="copyright"> Copyright Scoring Tech 2024</div>
      <div class="flex flex-col lg:flex-row lg:justify-between">
        <a href="" @click="navigateTo('contact')" class="link">Contact Us</a>
        <a href="" @click="navigateTo('terms')" class="link">Terms and Conditions</a>
        <a href="" @click="navigateTo('privacy')" class="link">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.centered {
  margin: 200px 16px 72px;
}

@media (min-width: 1100px) {
  .link {
    margin-right: 45px;
  }
  
  .copyright {
    margin-left: 45px;
  }
}

.link {
  margin-bottom: 22px;
  color: #000000;
}

.text {
  @apply font-semibold;
  font-size: 16px;
  color: #9D9D9D;
  letter-spacing: .32px;
  line-height: 19px;
}
</style>