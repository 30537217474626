<template>
  <div>
    <div class="my-4 flex justify-center">
      <img style="width:50%" src="/img/Orange_Bowscore_Logo.png" alt="Bowscore">
    </div>
    <div v-if="notFound" class="text-center">
      <div class="text-6xl"> There is no Ladder Board matching this url.</div>
      <div class="text-2xl">Please verify the url from the Tournament -> Manage -> Ladder Board page.</div>
    </div>
    <div v-if="tableData != null" class="flex justify-between mt-2">
      <div v-for="ladderId in ladders" class="w-full mx-4">
        <div class="text-5xl font-extrabold text-center">{{ ladderFromId(ladderId).name }}</div>
        <div v-if="tableData[ladderId] == null" class="text-3xl text-center m-5">
          Loading...
        </div>
        <div v-else>
          <div v-if="tableData[ladderId]['lastScore'] != null" class="flex bg-blue-700 text-3xl text-white mt-4 py-2">
            <div class="flex-3 text-center">
              Step: {{ tableData[ladderId]['lastScore'].step + 1 }} &nbsp;&nbsp;&nbsp; End:
              {{ tableData[ladderId]['lastScore'].end }}
            </div>
            <div v-for="index in tableData[ladderId]['lastArrows']" class="flex-1 text-center">
              Arrow {{ index }}
            </div>
          </div>
          <div class="flex flex-col" v-if="tableData[ladderId]['lastScore'] != null">
            <div :class="firstIsSecond(ladderId) ? 'order-3' : 'order-1'"
                 class="flex bg-blue-600 text-5xl font-extrabold text-white py-2">
              <div class="flex-3 text-center flex flex-col justify-center">
                <div>
                  {{ competitors[tableData[ladderId]['lastScore']['competitor_id']].name }}
                </div>
              </div>
              <div v-for="detail in tableData[ladderId]['lastArrows']" class="flex-1 text-center text-8xl">
                {{ tableData[ladderId]['lastDetail'][detail - 1] ?? '-' }}
              </div>
            </div>
            <hr class="order-2">
            <div v-if="tableData[ladderId]['secondScore'] != null"
                 :class="firstIsSecond(ladderId) ? 'order-1' : 'order-3'"
                 class="flex bg-blue-600 text-5xl font-extrabold text-white py-2">
              <div class="flex-3 text-center flex flex-col justify-center">
                <div>
                  {{ competitors[tableData[ladderId]['secondScore']['competitor_id']].name }}
                </div>
              </div>
              <div v-for="detail in tableData[ladderId]['secondArrows']" class="flex-1 text-center text-8xl">
                {{ tableData[ladderId]['secondDetail'][detail - 1] ?? '-' }}
              </div>
            </div>
          </div>
          
          <div class="my-4" v-for="(data, step) in tableData[ladderId]['steps'].slice().reverse()">
            <div class="flex bg-blue-700 text-3xl text-white mt-2 py-2">
              <div style="flex:6;" class="ml-4">
                Step {{ Object.keys(tableData[ladderId]['steps']).length - step }}
              </div>
              <div v-for="field in ladderFromId(ladderId).ends_count"
                   class="flex-2 text-center">End {{ field }}
              </div>
              <div class="flex flex-3 justify-center">Total</div>
            </div>
            <div class="flex bg-blue-600 text-5xl font-extrabold text-white pt-2">
              <div style="flex:6;" class="ml-4">{{ data.firstCompetitor }}</div>
              <div v-for="(field, index) in data.firstData"
                   :class="index === data.firstData.length - 1 ? 'flex-3' : 'flex-2'"
                   class="text-center">{{ field == '0' ? '-' : field }}
              </div>
            </div>
            <hr>
            <div class="flex bg-blue-600 text-5xl font-extrabold text-white pb-2">
              <div style="flex:6;" class="ml-4">{{ data.secondCompetitor }}</div>
              <div v-for="(field, index) in data.secondData"
                   :class="index === data.secondData.length - 1 ? 'flex-3' : 'flex-2'"
                   class="text-center">{{ field == '0' ? '-' : field }}
              </div>
            </div>
          
          </div>
        </div>
      </div>
    
    </div>
  
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "LadderBoard",
  props: ['tournamentIn', 'boardInfo', 'competitors', 'table', 'notFound'],
  data() {
    return {
      interval: null,
      latest: null,
      ladders: [],
      tableData: null,
      board: this.boardInfo ?? null,
    }
  },
  methods: {
    firstIsSecond(ladderId) {
      let score = this.tableData[ladderId]['lastScore'];
      return !score.isFirst;
    },
    ladderFromId(id) {
      let ladder = this.tournamentIn.ladders.find(f => f.id == id);
      return ladder;
    },
    waitThenCheck() {
      setTimeout(() => this.checkForNew(), 2000);
    },
    checkForNew() {
      let data = {
        params: {
          id: this.board.id,
          updated: this.board.updated_at,
          latest: this.latest,
          ladders: this.ladders,
        }
      };
      this.$axios.get(`/tournaments/${this.tournamentIn.slug}/check_shoot_out`, data).then(({data}) => {
        if (data.success && data.hasNew) {
          if (data.board != null) {
            this.board = data.board;
            this.ladders = this.board.ladder_ids.split('_');
          }
          this.reload();
        } else {
          this.waitThenCheck();
        }
      });
    },
    reload() {
      let data = {
        params: {
          ladders: this.ladders,
        }
      };
      this.$axios.get(`/tournaments/${this.tournamentIn.slug}/shoot_out`, data).then(({data}) => {
        if (data.success && data.data) {
          this.tableData = data.data;
          this.latest = data.data.lastUpdate;
        }
        this.waitThenCheck();
      });
    }
  },
  created() {
    if (this.notFound) return;
    this.ladders = this.board.ladder_ids.split('_');
    this.tableData = this.table;
    this.latest = this.table.lastUpdate;
    this.waitThenCheck()
    // this.interval = setInterval(() => {
    //   this.checkForNew()
    // }, 3000);
  }
}
</script>

<style scoped>

</style>