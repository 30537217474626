const state = {
  user: null,
  fake_value: null,
};

const getters = {
  getUser(state) {
    return state.user;
  },
};
const actions = {};
const mutations = {
  set(state, data) {
    state.user = data.user;
  },
  LOGOUT_USER(state) {
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};