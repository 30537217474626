<template>
  <div>
    <div v-if="adding" class="flex flex-wrap">
      <Form>
        <div class="flex">
          <div class="m-2 flex flex-col">
            <label>Name:</label>
            <Field v-model="name" type="text" name="name" class="bs-form-box" rules="required|clean"/>
            <span class="text-sm text-red-600">
              <ErrorMessage name="name"/>
            </span>
          </div>
          <div class="m-2 flex flex-col">
            <label>Email:</label>
            <Field v-model="email" type="text" name="email" class="bs-form-box" rules="clean"/>
            <span class="text-sm text-red-600">
              <ErrorMessage name="email"/>
            </span>
          </div>
        </div>
      </Form>
      <div class="flex justify-end flex-grow sm:grow-0">
        <div class="flex flex-col justify-end m-2">
          <div class="flex">
            <div @click="cancel" class="btn btn-outline-gray-600">Cancel</div>
            <div @click="createCompetitor" class="btn btn-blue-600 ml-2">
              Create <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="newCompetitor" class="bg-green-200 flex text-xl rounded p-2 mb-1 justify-between">
        <div>
          A new competitor has been added to {{ newCompetitor.location }} on bale {{ newCompetitor.bale }}!
        </div>
        <div class="flex mr-2">
          <div class="text-blue-600 cursor-pointer" @click="goto">Click here to score
            {{ newCompetitor.name }}
          </div>
          <div class="ml-4 text-gray-400" @click="newCompetitor = null"><i class="fas fa-times"></i></div>
        </div>
      </div>
      <div @click="addCompetitor" class="btn btn-blue-600">Add a Competitor</div>
    
    </div>
  </div>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";

export default {
  name: "QuickRegister",
  components: {ErrorMessage, Field, Form},
  data() {
    return {
      adding: false,
      name: '',
      email: '',
      newCompetitor: null,
      loading: false,
    }
  },
  computed: {
    tournament() {
      return this.$store.getters['tournament/getTournament'];
    }
  },
  methods: {
    addCompetitor() {
      this.adding = true;
      this.$validator.resume();
    },
    cancel() {
      this.$validator.pause();
      this.loading = false;
      this.adding = false;
      this.name = '';
      this.email = '';
    },
    createCompetitor() {
      this.loading = true;
      this.$validator.validate().then(result => {
        if (result) {
          axios.post(`/tournaments/${this.tournament.slug}/quick_competitor/`, {
            name: this.name,
            email: this.email
          })
          .then(({data}) => {
            this.cancel();
            this.newCompetitor = data.competitor;
            this.$store.dispatch('tournament/quickAdd', this.newCompetitor);
          })
          .catch((response) => {
          });
        } else {
          this.loading = false;
        }
      });
    },
    goto() {
      this.$emit('goToAdded', this.newCompetitor);
      this.newCompetitor = null;
    }
  }
}
</script>

<style scoped>

</style>
