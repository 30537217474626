<script setup>

import FormCheckbox from "./FormCheckbox.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

</script>

<template>
  <div class="bs-card flex flex-col justify-between">
    <div class="bs-section-header">Team/club information</div>
    <div class="flex">
      <form-checkbox :active="store.teamClubName" @click="store.teamClubName = !store.teamClubName" class="flex-1"
                     title="Team or club name"/>
      <form-checkbox :active="store.teamClubType" @click="store.teamClubType = !store.teamClubType" class="flex-2"
                     title="Specify group type (team or club)"/>
    </div>
  
  </div>
</template>

<style scoped>

</style>