<template>
  <div v-if="shown">
    <div class="w-full gradient px-2 my-2 text-white relative">
      <button @click="close" class="absolute z-20 px-3 pb-3 pt-2" style="right:1px;">
        <i class="fas fa-lg fa-times"></i>
      </button>
      <div class="flex flex-col">
        <div class="flex">
          <div class="appImageSmall gradient flex flex-col justify-center md:hidden">
            <div class="flex justify-center">
              <img src="/img/Bowscore_splash_logo.png" alt="bowscore logo" style="width:70%">
            </div>
          </div>
          <div class="appImageLarge gradient flex-col justify-center hidden md:flex">
            <div class="flex justify-center">
              <img src="/img/Bowscore_splash_logo.png" alt="bowscore logo" style="width:70%">
            </div>
          </div>
          <div class="flex flex-col justify-center">
            <div class="titleSmall md:hidden">Scoring just got easier!</div>
            <div class="titleLarge hidden md:block">Scoring just got easier!</div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <div class="text-center downloadTextSmall sm:hidden">Download the app to get started.</div>
          <div class="text-center downloadTextLarge hidden sm:block">Download the app to get started.</div>
          
          <div class="flex justify-center">
            <button @click="navToStore('apple')" class="linkButton linkButtonSmall m-3 md:hidden">
              Bowscore for iOS
            </button>
            <button @click="navToStore('apple')" class="linkButton linkButtonLarge m-3 hidden md:block">
              Bowscore for iOS
            </button>
            <button @click="navToStore('google')" class="linkButton linkButtonSmall m-3 md:hidden">
              Bowscore for Android
            </button>
            <button @click="navToStore('google')" class="linkButton linkButtonLarge m-3 hidden md:block">
              Bowscore for Android
            </button>
            <button @click="navToStore('amazon')" class="linkButton linkButtonSmall m-3 md:hidden">
              Bowscore for Amazon
            </button>
            <button @click="navToStore('amazon')" class="linkButton linkButtonLarge m-3 hidden md:block">
              Bowscore for Amazon
            </button>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppBanner",
  props: ['tournament'],
  data() {
    return {
      shown: false,
    }
  },
  methods: {
    close() {
      this.shown = false;
      if (this.tournament == null) {
        return this.$cookies.set(`closed_dash`, 'closed', {expires: '150h'});
      }
      this.$axios.get(`/tournaments/${this.tournament.slug}/has_codes`).then(({data}) => {
        let length = '48h';
        if (data.hasOwnProperty('has_codes') && data.has_codes) length = '5h';
        this.$cookies.set(`closed_${this.tournament.id}`, 'closed', {expires: length});
      }).catch((_) => {
        this.$cookies.set(`closed_${this.tournament.id}`, 'closed', {expires: '12h'});
      })
    },
    navToStore(type) {
      let appleLink = 'https://apps.apple.com/us/app/bowscore/id6480208560';
      let googleLink = 'https://play.google.com/store/apps/details?id=com.scoringtech.bowscore';
      let amazonLink = 'https://www.amazon.com/gp/product/B0D1W5G6SJ';
      window.open(type === 'apple' ? appleLink : type === 'google' ? googleLink : amazonLink);
    }
  },
  async mounted() {
    let key = `closed_dash`;
    if (this.tournament != null) key = `closed_${this.tournament.id}`;
    let closed = this.$cookies.get(key);
    if (closed == null) {
      this.shown = true;
    }
  }
}
</script>
<style scoped>
.gradient {
  background-image: linear-gradient(#FF7C02, #F05523);
}

.linkButton {
  @apply bg-white;
  color: #FB5202;
  font-weight: 700;
  font-family: 'Figtree', serif;
  border-radius: 6px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
}

.linkButtonSmall {
  height: 26px;
  width: 160px;
  font-size: 12px;
  letter-spacing: .24px;
  line-height: 14px;
}

.linkButtonLarge {
  height: 46px;
  min-width: 260px;
  max-width: 282px;
  font-size: 21px;
  letter-spacing: .42px;
  line-height: 25px;
}

.downloadTextSmall {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
  font-family: 'Figtree', serif;
}

.downloadTextLarge {
  font-weight: 300;
  font-size: 24px;
  letter-spacing: .48px;
  line-height: 29px;
  font-family: 'Figtree', serif;
}

.titleSmall {
  font-size: 29px;
  font-weight: 800;
  letter-spacing: .58px;
  line-height: 32px;
  margin-right: 50px;
  font-family: 'Figtree', serif;
}

.titleLarge {
  font-size: 53px;
  font-weight: 800;
  letter-spacing: 1.06px;
  line-height: 63px;
  margin-right: 50px;
  font-family: 'Figtree', serif;
}

.appImageSmall {
  @apply border-4 border-white shadow-md;
  height: 79px !important;
  width: 79px !important;
  min-height: 79px !important;
  min-width: 79px !important;
  border-radius: 20px;
  margin: 20px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
}

.appImageLarge {
  @apply border-4 border-white shadow-md;
  height: 143px !important;
  width: 143px !important;
  min-height: 143px !important;
  min-width: 143px !important;
  border-radius: 20px;
  margin: 20px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
}
</style>