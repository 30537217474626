<template>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-header">Place</th>
        <th class="table-header">Round</th>
        <th class="table-header">{{ rounds.length > 1 ? 'Round' : 'Total' }} Score</th>
        <th v-for="breaker in tieBreakers" class="table-header">
          {{ rounds.length > 1 ? 'Round' : 'Total' }} {{ breaker }}'s
        </th>
        <th class="table-header" v-for="end in store.longestRound">
          {{ endHeader(end) }}
        </th>
      </tr>
      </thead>
      <tbody>
      <competitor-score
        v-for="round in rounds"
        :competitor="selectedCompetitor"
        :scores="tournamentIn.scoreList[selectedCompetitor.id]"
        :round="round"
        :place="placeList.rounds[round.id].indexOf(`${selectedCompetitor.id}`) + 1"
        :key="'competitor_only_' + round.id + selectedCompetitor.id"
        :competitor_only="true"
        :detailed="true"
        :images="tournamentIn.scoreImages[selectedCompetitor.id]"
        :duplicates="store.competitorDuplicates(selectedCompetitor.id)"/>
      </tbody>
    </table>
  </div>
</template>
<script>
import CompetitorScore from "./CompetitorScore.vue";
import {useResultsStore} from "../../../stores/ResultsStore";
import {mapState} from "pinia";

export default {
  name: "IndividualResultsTable",
  components: {CompetitorScore},
  props: ['tournamentIn', 'placeList'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, [
      'selectedCompetitor',
      'tieBreakers',
      'rounds',
    ]),
  },
  methods: {
    endHeader(end) {
      let endText = this.$trans.choice('search.end', 1);
      let text = `${endText} ${end}`;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
  }
}
</script>
<style scoped>

</style>