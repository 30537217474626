import {createStore} from 'vuex'
import tournament from './modules/tournament'
import user from './modules/user'
import device from './modules/device'
import sponsor from './modules/sponsor'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'
import * as localForage from "localforage";


const debug = process.env.NODE_ENV !== 'production';
//first define a plugin that emits when the state has been persisted
// const vuexPersistEmitter = () => {
//     return store => {
//         store.subscribe(mutation => {
//             if (mutation.type === 'RESTORE_MUTATION') {
//                 store._vm.$root.$emit('storageReady');
//             }
//         });
//     }
// };

//I only needed to define strict mode here and not in the store.
const vuexLocal = new VuexPersistence({
  asyncStorage: true,
  storage: {
    getItem: async (key) => {
      const raw = await localForage.getItem(key);
      if (typeof raw === 'string' || raw instanceof String) {
        return JSON.parse(raw);
      }
      return raw == null ? {} : raw;
    },
    setItem: async (key, value) => {
      try {
        const valueString = JSON.stringify(value)
        await localForage.setItem(key, valueString)
      } catch (e) {
        console.log(e);
      }

    },
    removeItem: async (key) => localForage.removeItem(key),
  },
});

export const store = createStore({
  modules: {
    tournament,
    user,
    device,
    sponsor
  },
  state: {
    storageReady: false,
    localForage: localForage
  },
  plugins: [
    vuexLocal.plugin,
    // vuexPersistEmitter()
  ]
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})
