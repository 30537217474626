<script>

import LandingHeader from "./LandingHeader.vue";
import LandingLogin from "./LandingLogin.vue";
import LandingSports from "./LandingSports.vue";
import LandingBullets from "./LandingBullets.vue";
import LandingFooter from "./LandingFooter.vue";
import SimpleFooter from "../partials/SimpleFooter.vue";
import LandingNumberedBullet from "./LandingNumberedBullet.vue";
import ContactButton from "../partials/ContactButton.vue";
import LandingGetStarted from "./LandingGetStarted.vue";
import LandingBullet from "./LandingBullet.vue";
import LandingAppAd from "./LandingAppAd.vue";
import TestimonialCard from "./TestimonialCard.vue";

export default {
  name: "LandingPage",
  components: {
    TestimonialCard,
    LandingAppAd,
    LandingBullet,
    LandingGetStarted,
    ContactButton,
    LandingNumberedBullet,
    SimpleFooter,
    LandingFooter, LandingBullets, LandingSports, LandingLogin, LandingHeader
  },
  props: ['user'],
  data() {
    return {
      showDetails: false
    }
  },
  created() {
    if (!this.user) {
      if (this.$store.state.user) {
        this.$store.commit('user/LOGOUT_USER')
      }
    }
  }
}
</script>
<template>
  <div class="w-full">
    <landing-header :show-link="true"/>
    <landing-login text="The easiest way to score and manage archery tournaments."
                   :sign-in="true"
                   image="Archer_and_app2x.png"/>
    <landing-sports/>
    <div class="centered bulletMargin">
      <landing-numbered-bullet
        count="1"
        info="Easily create your next tournament with our online event builder. Set the basic details for your tournament and customize it with options for additional rounds or t-shirt sales."
        header="Easy Setup"/>
      <landing-numbered-bullet
        count="2"
        info="Online registration and payments allow you to save time and simplify event staffing. Archers can also create a Bowscore account to make registering for your tournaments a breeze."
        header="Online Payments"/>
      <landing-numbered-bullet
        count="3"
        info="Score tournaments using our intuitive scoring app on your archers’ devices, your own devices, or we can supply you with devices and provide in-person scoring support."
        header="Simple Scoring"/>
    </div>
    <div class="centered">
      <landing-bullet
        :reversed="false"
        image="make_archer_happy2x.png"
        header="Make archers happy"
        info="Archers can easily score with the app and track all their results online. Parents and friends can follow their archer’s scores live on our website."/>
      <landing-bullet
        :reversed="true"
        image="No_subscription_fees2x.png"
        header="No subscription fees"
        info="Forget about recurring fees or expensive contracts. Our model is simple: we charge archers a small fee ($5 per league, $2 per tournament) when they register."/>
      <landing-bullet
        :reversed="false"
        image="bowscore_customer_support2x.png"
        header="We're here to help"
        info="Your tournament success is important to us. Contact us with any questions and we’ll get you an answer within the hour (during business hours). Need help running your event? We can help with that too! We’ve got scoring devices and staff ready to assist.">
        <ContactButton class="mt-12"/>
      </landing-bullet>
      <TestimonialCard/>
    </div>
    <landing-get-started :assist="true"/>
    <div class="centered">
      <landing-app-ad/>
      <simple-footer class="flex-shrink"/>
    
    </div>
  </div>
</template>


<style scoped>
@media (min-width: 1100px) {
  .centered {
    width: 1100px;
    margin: auto;
  }
}

.bulletMargin {
  margin-bottom: 635px;
}

@media (max-width: 1100px) {
  .bulletMargin {
    margin-bottom: 200px;
  }
}

</style>
