<template>
  <div class="flex-grow sm:m-2">
        <div v-if="tournament.disable_registration" class="flex justify-center">
          Registration is disabled for this event
        </div>
        <div v-else-if="competitor">
          <div v-if="!registrationStarted" class="bg-yellow-200 p-3 m-0 text-xl text-center">
            Registration for this tournament opens {{ tournament.reg_open }}
          </div>
          <div v-else>
            <div v-if="!user" class="bg-red-200 text-red-800 p-3 m-0 text-xl text-center">
              Tournament registration requires an active Bowscore account.
              <a href="/login" class="text-blue-600 underline">Click this link to login</a>
            </div>
            <div v-else-if="!user.email_verified_at" class="bg-red-200 text-red-800 p-3 m-0 text-xl text-center">
              Registration is not allowed until your account email has been verified.
            </div>
            <div v-else>
              <div v-if="waitList" class="bg-yellow-200 p-3 m-0 text-xl text-center">
                {{ waitList }}
              </div>
              <div v-if="added" class="text-center p-5">
                <h2 id="addedCompetitor" class="mb-5">
                  New Competitor <strong>{{ competitor.name }}</strong> Has Been Created!
                </h2>
                <button class="btn btn-gray-600" @click="added = false; loading = false">Create Another Competitor
                </button>
              </div>
              <div v-else class="flex-grow mr-2">
                <video-tip
                  :link="'https://www.youtube.com/embed/f2MKvNR2GeU'"
                  :title="'Registering For A Tournament'"/>
                <form :action="'/tournaments/' + tournament.slug + '/competitors'" method="POST"
                      ref="competitorForm"
                      id="competitorForm"
                      :submit.prevent="createCompetitor">
                  <input type="hidden" name="_token" :value="csrf">
                  <form-wizard
                    @on-complete="createCompetitor"
                    @on-validate="handleValidation"
                    :stepSize="'xs'"
                    :color="'#2779bd'"
                    :errorColor="'#ef5753'"
                    :title="'Competitor Registration'"
                    :subtitle="''">
                    <tab-content title="Competitor" :before-change="() => updateCompetitor()">
                      <competitor-create-edit
                        ref="competitor-create-edit" :tournament="tournament"
                        :inc_competitor="competitor" :user="user"
                        :restrictions="restrictions['options']"/>
                    </tab-content>
                    <tab-content
                      title="Preferences"
                      v-if="showPreferences"
                      :before-change="()=>validateStep('competitor-tournament-options')">
                      <tournament-options
                        ref="competitor-tournament-options"
                        :tournament="tournament"
                        :inc_competitor="competitor"
                        :restrictions="targetRestrictions"/>
                    </tab-content>
                    <tab-content
                      title="Payment"
                      v-if="!timeWaitList && !waitList && (tournament.club.stripe || useAuthNet)"
                      :before-change="()=>validateStep('competitor-payment')">
                      <competitor-payment
                        ref="competitor-payment"
                        @toggleOffline="offlinePayment = !offlinePayment"
                        :tournament="tournament"
                        :inc_competitor="competitor"
                        :restrictions="priceRestrictions"
                        :club="tournament.club"/>
                    </tab-content>
                    <template slot="finish">
                      <div class="wizard-btn"
                           :class="loading ? 'disabled' : ''"
                           style="background-color: rgb(39, 121, 189); border-color: rgb(39, 121, 189); color: white;">
                        {{ loading ? 'Processing' : 'Register' }}
                        <span v-show="loading" class="ml-2">
                          <i class="fas fa-spinner fa-spin"></i>
                        </span>
                      </div>
                    </template>
                  </form-wizard>
                  <input type="hidden" name="offline_payment" :value="offlinePayment">
                  <input type="hidden" name="user_id" :value="user ? user.id : null">
                  <input v-if="manual" type="hidden" name="manual_register" value="true">
                </form>
              
              </div>
            </div>
          </div>
        
        </div>
  </div>

</template>
<script>
import moment from "moment/moment";
import VideoTip from "../partials/VideoTip.vue";
import CompetitorCreateEdit from "./CompetitorCreateEdit.vue";
import TournamentOptions from "./TournamentOptions.vue";
import CompetitorPayment from "./CompetitorPaymentOld.vue";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";
import Swal from "sweetalert2";

export default {
  name: "CompetitorCreateOld",
  components: {CompetitorPayment, TournamentOptions, CompetitorCreateEdit, VideoTip},
  props: ['tournamentIn', 'user', 'manual', 'api_login'],
  data() {
    return {
      added: false,
      loading: false,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      stripe: null,
      elements: null,
      card: null,
      waitList: '',
      offlinePayment: false,
      competitor: null,
      targetRestrictions: [],
      priceRestrictions: [],
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'timeWaitList'
    ]),
    showPreferences() {
      if (this.tournament.line_times.length > 1 && !this.tournament.hide_times) return true;
      if (this.tournament.hide_targets) return false;
      return (this.tournament.targets || {}).length > 1;
    },
    tournament() {
      return this.tournamentIn;
    },
    registrationStarted() {
      let now = new Date();
      let open = new Date(this.tournament.reg_open);
      return now > open;
    },
    restrictions() {
      let types = {options: [], price: [], target: []};
      this.tournament.restrictions.forEach((restriction) => {
        types[restriction.type].push(restriction);
      })
      return types;
    },
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    offlineCompetitors() {
      return this.$store.getters['tournament/getOfflineCompetitors'];
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  mounted() {
    this.timeWaitList = false;
    this.competitor = {
      'name': this.user && this.user.archer ? this.user.archer.name : '',
      'first_name': this.user && this.user.archer ? this.user.archer.first_name : '',
      'last_name': this.user && this.user.archer ? this.user.archer.last_name : '',
      'email': this.user && this.user.archer ? this.user.archer.email : '',
      'form': {},
    }
    this.$axios.post('/tournaments/' + this.tournament.slug + '/waitlist')
    .then(({data}) => {
      if (data.waitlist) {
        if (data.reg_closed) {
          this.waitList = 'The tournament Registration Closed on ' +
            moment(new Date(this.tournament.reg_close)).format('MMM D, YYYY') +
            '. \nNew registrations will be put on a waitlist.';
        } else if (data.full) {
          this.waitList = 'The Max amount of competitors has been exceeded. ' +
            '\nNew registrations will be put on a waitlist.';
        }
      }
      if (!this.waitList && !this.timeWaitList && this.tournament && this.tournament.club && this.tournament.club.stripe && !this.useAuthNet) {
        this.stripe = Stripe(this.tournament.club.stripe.stripe_publishable_key);
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');
        this.card.mount(this.$refs["competitor-payment"].$refs['card']);
      }
    })
    .catch(({response}) => {
      Swal.fire('There was an issue loading initial tournament data. Please refresh the browser and try again.');
      console.log("Failed Response: ", response);
    });
  },
  methods: {
    updateCompetitor() {
      return this.validateStep('competitor-create-edit').then((valid) => {
        if (valid) {
          let targetList = [];
          let priceList = [];
          if (Object.values(this.competitor.form).length) {
            this.restrictions['target'].forEach((restriction) => {
              let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
              let name = field.multiple.find(f => f.id === restriction.multiple_id);
              if (this.competitor.form[field['option']].name === name.label) {
                targetList.push(restriction.type_id);
              }
            })
            this.restrictions['price'].forEach((restriction) => {
              let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
              let name = field.multiple.find(f => f.id === restriction.multiple_id);
              if (this.competitor.form[field['option']].name === name.label) {
                priceList.push(restriction.type_id);
              }
            })
            
          }
          this.targetRestrictions = targetList;
          this.priceRestrictions = priceList;
          return true;
        }
        return false;
      });
    },
    handleValidation(isValid, tabIndex) {
      console.log('Tab: ' + tabIndex + ' valid: ' + isValid);
    },
    async validateStep(name) {
      //TODO figure out why this stopped working
      let refToValidate = this.$refs[name];
      if (name !== 'competitor-create-edit') {
        if (name === 'competitor-tournament-options') {
          return refToValidate.validate();
        }
        return true;
      }
      
      return refToValidate.validate();
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    
    handleStripe() {
      return this.stripe.createToken(this.card).then(({token, error}) => {
        if (error) {
          throw new Error(error.message);
        } else {
          let stripeToken = document.createElement('input');
          stripeToken.setAttribute('name', "stripeToken");
          stripeToken.setAttribute('type', "hidden");
          stripeToken.setAttribute('value', token.id);
          let form = document.querySelector("#competitorForm");
          form.appendChild(stripeToken);
        }
      })
    },
    
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        var html = '<div>'
        Object.values(response.messages.message).forEach((message) => {
          html += `<div>${message.text}</div>`;
        })
        html += '</div>'
        Swal.fire({
          title: 'Error Processing',
          html: html,
          icon: "error",
        })
        this.loading = false;
      } else {
        document.getElementById("authNetToken").value = response.opaqueData.dataValue;
        document.getElementById("authNetDescriptor").value = response.opaqueData.dataDescriptor;
        document.getElementById("authNetCard").innerHTML = '';
        this.submitForm();
      }
    },
    handleAuthNet() {
      var data = {
        authData: {clientKey: this.tournament.club.auth_net_key, apiLoginID: this.api_login},
        cardData: {
          cardNumber: document.getElementById("cardNumber").value,
          month: document.getElementById("expMonth").value,
          year: document.getElementById("expYear").value,
          cardCode: document.getElementById("cardCode").value,
        }
      }
      
      Accept.dispatchData(data, this.responseHandler);
    },
    handleWaitList() {
      let now = moment().format("YYYY-MM-DD HH:mm:ss");
      return this.$axios.post('/tournaments/' + this.tournament.slug + '/waitlist')
      .then(({data}) => {
        this.onlineTournament = data.tournament;
        
        if (data.waitlist || this.timeWaitList) {
          let waitMessage = '';
          if (data.reg_closed) {
            waitMessage = 'The tournament Registration Closed on ' +
              moment(new Date(this.tournament.reg_close)).format('MMM D, YYYY') +
              '. \nWould you like to be put on the waitlist?.';
          } else if (data.full) {
            waitMessage = 'The Max amount of competitors has been exceeded. ' +
              '\nWould you like to be put on the waitlist?.';
          } else if (this.timeWaitList) {
            waitMessage = 'The requested line time is full. \nWould you like to be put on the waitlist?.';
          }
          waitMessage += ' You will not be billed until you are approved.';
          Swal.fire({
            titleText: 'Wait list available',
            icon: 'question',
            showCancelButton: true,
            html: waitMessage,
            confirmButtonText: 'Okay',
            showLoaderOnConfirm: true,
            reverseButtons: true,
          }).then((result) => {
            if (result.value) {
              let wait_list = document.createElement('input');
              wait_list.setAttribute('name', "wait_list");
              wait_list.setAttribute('type', "hidden");
              wait_list.setAttribute('value', now);
              let form = document.querySelector("#competitorForm");
              form.appendChild(wait_list);
              $('#competitorForm').submit();
            } else {
              this.loading = false;
            }
          })
        } else {
          $('#competitorForm').submit();
        }
      })
    },
    
    handleOffline() {
      let competitor = $('#competitorForm').serializeArray();
      competitor = this.formatCompetitor(competitor);
      this.$store.dispatch('tournament/addCompetitor', competitor).then(
        this.competitor = competitor,
        this.added = true,
      );
    },
    createCompetitor() {
      if (!this.loading) {
        this.loading = true;
        if (this.useAuthNet && !this.offlinePayment && !this.waitList && !this.timeWaitList) {
          this.handleAuthNet()
        } else if (this.stripe && !this.offlinePayment && !this.waitList && !this.timeWaitList) {
          this.handleStripe().then(() => {
            this.submitForm();
          }).catch((error) => {
            this.loading = false;
            Swal.fire(error.message)
          })
        } else {
          this.submitForm();
        }
      }
    },
    
    submitForm() {
      if (this.online) {
        this.handleWaitList();
      } else {
        this.handleOffline();
      }
    },
    
    
    formatCompetitor(competitor) {
      let assignments = [];
      let formDetails = [];
      let length = this.offlineCompetitors.length;
      this.tournament.rounds.forEach(function (round) {
        let assignmentDetails = {
          "round_id": round.id,
          "competitor_id": 'offline_' + (length + 1),
          "location": null,
          "line_time": competitor.find(f => f.name === 'line_time') ? competitor.find(f => f.name === 'line_time').value : null,
          "bale": null,
          "approved": round.open ? 1 : 0
        };
        assignments.push(assignmentDetails);
      });
      let regformMultiple = [];
      competitor.forEach(function (detail) {
        let nopeArray = ['name', 'email', 'line_time', 'target_id', 'user_id', '_token'];
        if (nopeArray.findIndex(i => i === detail.name) === -1) {
          if (detail.value) {
            if (detail.name.includes('[]')) {
              let name = detail.name.replace('[]', '');
              if (!regformMultiple[name]) {
                regformMultiple[name] = [];
              }
              regformMultiple[name].push(detail.value)
            } else {
              formDetails.push({'name': detail.value, 'option': detail.name})
            }
          }
        }
        
      });
      if (Object.keys(regformMultiple).length) {
        Object.keys(regformMultiple).forEach(function (name) {
          formDetails.push({'option': name, 'name': name, 'multiple': regformMultiple[name]});
        });
      }
      let targetId = competitor.find(f => f.name === 'target_id') ? parseInt(competitor.find(f => f.name === 'target_id').value) : null;
      let target = this.tournament.targetValues.find(v => v.id === targetId);
      return {
        "id": 'offline_' + (length + 1),
        "name": competitor.find(f => f.name === 'name').value,
        "email": competitor.find(f => f.name === 'email').value,
        "tournament_id": this.tournament.id,
        "line_time": competitor.find(f => f.name === 'line_time') ? competitor.find(f => f.name === 'line_time').value : null,
        "target_id": targetId ? targetId : this.tournament.targets[0].id,
        "target": target ? target : this.tournament.targetValues[0],
        "assignment": assignments,
        "scores": [],
        "regform": formDetails
      };
    }
  }
}
</script>

<style scoped>

</style>
