<template>
  <div class="flex-grow text-center p-4">
    <div>
      Thanks for Scoring!
    </div>
    
    <router-link :to="lockedReturn" class="btn-sm btn-outline-gray-600">
      <a>Back to Scoring</a>
    </router-link>
    <router-link to="/results" class="btn-sm btn-outline-blue-600">
      <a>View Results</a>
    </router-link>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";

export default {
  name: "EndScoring",
  computed: {
    ...mapState(useDisplayStore, ['restrictedLadders']),
    lockedReturn() {
      return this.restrictedLadders.length ? '/score_ladder' : '/score';
    }
  }
}
</script>

<style scoped>

</style>