<template>
  <div class="">
    
    <div class="px-1 sm:px-3">
      <app-banner/>
      <div class="flex flex-col-reverse md:flex-row justify-between w-full mb-5">
        <div class="pt-6 md:pt-0 flex flex-col sm:flex-row w-full md:w-1/2">
          <div v-if="user.image_enabled" class="mr-2 pt-2 xs:pt-0 flex justify-center">
            <div>
              <img width="75" height="75" :src="user.image" alt="Profile Picture">
            </div>
          </div>
          <div class="flex justify-center sm:justify-start">
            <div class="text-3xl xs:text-4xl text-center sm:text-left flex">
              <div class="flex flex-col justify-center">
                {{ user.first_name }}'s Dashboard
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 mt-2 lg:max-w-1/2 flex justify-end">
          <div class="flex flex-col sm:flex-row">
            <div class="mb-5 md:mr-2 text-right">
              <a v-if="user.roles && user.roles.find(f => f.name === 'god')" href="/godmode/dashboard"
                 class="btn btn-blue-600 whitespace-nowrap">
                Go To Godmode Dashboard
              </a>
            </div>
            <div class="text-right">
              <a :href="'/archers/' + user.slug" class="btn btn-blue-600 whitespace-nowrap">
                Go to {{ user.hide ? 'Private' : 'Public' }} Profile
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-wrap" :class="isAdmin ? 'flex-col-reverse' : 'flex-col'">
        <div class="w-full mt-2">
          <user-tournaments :user="user"/>
        </div>
        <div class="w-full mt-2">
          <profile-manage :user="user"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserTournaments from "./UserTournaments.vue";
import ProfileManage from "./ProfileManage.vue";
import AppBanner from "../scoring/AppBanner.vue";

export default {
  name: "UserDashboard",
  components: {AppBanner, ProfileManage, UserTournaments},
  props: ['user'],
  computed: {
    isAdmin() {
      if (this.user.managed_clubs && this.user.managed_clubs.length) {
        return true;
      }
      return this.user.managed_archers && this.user.managed_archers.length;
    }
  }
  
}
</script>

<style scoped>

</style>
