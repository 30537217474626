<template>
  <div>
    <div class="flex justify-center">
      <button @click="detailed = !detailed" class="btn btn-blue-600">
        {{ detailed ? 'Hide' : 'Show' }} Detailed Assignments
      </button>
    </div>
    <div v-if="detailed" class="flex flex-wrap">
      <div v-for="step in steps" class="p-1 mb-3 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
        <div class="bs-form-box" :class="[baleStatus(step) === 'full'?'complete':'incomplete']">
          Step {{ step + 1 }} ({{ stepCount(step) }}/ 2)
        </div>
        <div v-for="bale in assignedCompetitors.step[step].bales" style="margin-bottom:1px;" class="relative"
             :key="`${ladder.id}_${step}_${bale.bale}`">
          <div class="flex flex-col">
            <div class="flex-grow bs-form-box nameHolder">
              <div
                class="btn rounded-r-none w-10 flex-none"
                :class="bale.competitors.length ? 'btn-green-500' : 'btn-red-500'">
                <span>{{ bale.bale }}</span>
              </div>
              <input
                class="searchBox h-center boxContent"
                :ref="`input_${step}_${bale.bale}`"
                v-model="search" tabindex="1"
                :placeholder="bale.competitors.length ? competitorForBale(bale): 'type to filter'"
                v-if="editing === `${step}_${bale.bale}`">
              <div v-else class="h-center boxContent" :class="bale.competitors.length ? '' : 'text-gray-500'">
                <span class="nameBox">
                  {{ bale.competitors.length ? competitorForBale(bale) : 'Open' }}
                </span>
              </div>
              <div v-if="isManager"
                   @click="edit(step, bale)"
                   title="edit position"
                   :class="editing === `${step}_${bale.bale}` ? 'btn border-none' : 'btn btn-gray-400'"
                   class="editPosition">
                <span v-if="editing !== `${step}_${bale.bale}`"><i class="fas fa-pencil-alt"></i></span>
                <span v-else @click.stop="clear()" class="h-center h-full"><i class="fas fa-times"></i></span>
              </div>
            </div>
          </div>
          <div
            v-if="editing != null && editing ===`${step}_${bale.bale}`"
            class="searchListContainer"
            :key="`edit_${step}_${bale.bale}`">
            <div class="searchList">
              <button
                v-if="bale.competitors.length"
                class="searchListItem"
                @click.prevent="remove(step,bale, competitor(bale.competitors[0]))">
                <i class="fas fa-trash"></i> REMOVE COMPETITOR
              </button>
              <button v-if="!competitors.length" class="searchListItem">No Competitors</button>
              <button
                v-for="(competitor, index) in competitors"
                @click.prevent="selected(competitor,step, bale)"
                class="searchListItem" :tabindex="index+2">
                {{ competitor.name }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DetailedShootOutAssignments",
  props: ['ladder', 'user', 'competitors', 'tournamentIn'],
  data() {
    return {
      detailed: false,
      search: '',
      editing: null,
    }
  },
  computed: {
    isManager() {
      return this.user && this.user.auth_manage;
    },
    steps() {
      let list = [];
      for (let i = 0; i < this.ladder.start_ladder - 1; i++) {
        list.push(i);
      }
      return list;
    },
    assignedCompetitors() {
      return this.tournamentIn.assignedCompetitors.ladder[this.ladder.id];
    }
  },
  methods: {
    clear() {
      if (this.search === '') {
        this.editing = null;
      } else {
        this.search = '';
      }
    },
    competitorForBale(bale) {
      let id = bale.competitors[0];
      let competitor = this.competitor(id);
      if (competitor) return competitor.name;
      return 'error';
    },
    
    edit(step, bale) {
      this.editing = `${step}_${bale.bale}`;
    },
    remove(step, bale, competitor) {
      let assignment = this.tournamentIn.ladder_assignments.find(f => {
        if (f.ladder_id !== this.ladder.id) return false;
        return f.competitor_id === competitor.id && f.step === step && f.bale === bale.bale;
      })
      if (assignment != null) {
        this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder_remove`, {assignment_id: assignment.id});
      }
      this.$emit('forceUpdate');
      this.editing = null;
    },
    selected(competitor, step, bale) {
      let data = {
        'bale': bale.bale,
        'bale_position': 'A',
        'tournament_id': this.tournamentIn.id,
        'ladder_id': this.ladder.id,
        'step': step,
        'competitor_id': competitor.id,
      };
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/assign_ladder_single`, {data: data});
      this.$emit('forceUpdate');
      this.editing = null;
    },
    competitor(competitorId) {
      return this.competitors.find(f => f.id === competitorId);
    },
    stepCount(step) {
      return this.assignedCompetitors.step[step].competitors_count;
    },
    baleStatus(step) {
      let competitorCount = this.stepCount(step);
      if (competitorCount === 2) {
        return 'full'
      } else if (competitorCount === 0) {
        return 'empty'
      }
      return 'available'
    },
  }
  
}
</script>

<style scoped>

.boxContent {
  @apply text-center flex-grow overflow-x-hidden mx-2;
}

.nameBox {
  @apply overflow-x-hidden;
  text-overflow: ellipsis;
}

.editPosition {
  @apply cursor-pointer rounded-l-none;
}

.nameHolder {
  @apply flex justify-between p-0;
  overflow: hidden;
  white-space: nowrap;
}

.searchBox {
  @apply flex-grow pl-2;
}

.searchBox:focus {
  outline: none;
}

.searchListContainer {
  @apply bg-blue-800 text-blue-200 rounded-b absolute w-full z-10;
}

.searchList {
  @apply m-2 overflow-y-auto max-h-xxs flex flex-col;
}

.searchListItem {
  @apply text-left;
}

.searchListItem:hover,
.searchListItem:focus {
  @apply bg-blue-600 cursor-pointer;
  outline: none;
}

.complete {
  @apply bg-blue-700 text-blue-200 rounded;
}

.incomplete {
  @apply bg-blue-200 text-blue-600 rounded;
}

.border-none {
  border: none;
}

.stepChild {
  @apply m-0;
}
</style>