<template>
  <div class="w-full flex flex-wrap m-2">
    <div class="card-blue-700 w-full sm:w-1/2 p-1">
      <div class="card-header">Registration Received</div>
      <div class="card-body">
        <div class="flex flex-col">
          <Form @submit="update('registered')">
            <label for="registered">Additional Message:</label>
            <Field as="textarea" name="registered" v-model="registered" rules="clean|max:65000"
                   class="w-full h-28 p-2" :validate-on-input="true"/>
            <ErrorMessage name="registered" class="text-sm text-red-600"/>
            <fieldset>
              <legend class="text-center text-gray-500">Example</legend>
              Competitor Name,<br>
              Thank you for registering for {{ tournament.name }}.
              <pre style="font-family: 'Roboto', sans-serif;" class="text-gray-700 whitespace-pre-wrap my-3">
              {{ registered }}
            </pre>
              Please save this email as the link below is your personal competitor details page.
              <div class="flex justify-center my-4">
                <div class="btn btn-blue-600">
                  Click here to manage your competitor details
                </div>
              </div>
              <div class="bg-gray-200 rounded p-3 my-2">
                Name: Competitor Name<br>
                Email: Competitor Email<br>
                Registration Detail: All details listed out like this<br>
              </div>
              
              Thanks,<br>
              Bowscore<br>
            </fieldset>
            <div class="card-footer">
              This email will be sent to all newly registered competitors regardless of approval status.
            </div>
            <div class="flex justify-end">
              <button type="submit" class="btn btn-blue-600">
                Update
                <span v-if="loading === 'registered'">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="card-blue-700 w-full sm:w-1/2 p-1">
      <div class="card-header">Fee Waived</div>
      <div class="card-body">
        <div class="flex flex-col">
          <Form @submit="update('waived')">
            <label for="waived">Additional Message:</label>
            <Field as="textarea" name="waived" v-model="waived" rules="clean|max:65000"
                   class="w-full h-28 p-2" :validate-on-input="true"/>
            <ErrorMessage class="text-sm text-red-600" name="waived"/>
            
            <fieldset>
              <legend class="text-center text-gray-500">Example</legend>
              Competitor Name,<br>
              Your fee for {{ tournament.name }} has been waived.
              <pre style="font-family: 'Roboto', sans-serif;" class="text-gray-700 whitespace-pre-wrap my-3">{{
                  waived
                }}</pre>
              <div class="flex justify-center my-4">
                <div class="btn btn-blue-600">
                  Visit Tournament Page
                </div>
              </div>
              Thanks,<br>
              Bowscore<br>
            </fieldset>
            <div class="card-footer">
              This email will be sent to competitors upon having their tournament fee waived.
            </div>
            <div class="flex justify-end">
              <button type="submit" class="btn btn-blue-600">
                Update
                <span v-if="loading === 'waived'">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div class="card-blue-700 w-full sm:w-1/2 p-1">
      <div class="card-header">Staff Member</div>
      <div class="card-body">
        <div class="flex flex-col">
          <Form @submit="update('staff')">
            <label for="staff">Additional Message:</label>
            <Field as="textarea" name="staff" v-model="staff" rules="clean|max:65000" class="w-full h-28 p-2"
                   :validate-on-input="true"/>
            <ErrorMessage class="text-sm text-red-600" name="staff"/>
            
            <fieldset>
              <legend class="text-center text-gray-500">Example</legend>
              Staff Member Name,<br>
              You have been added to the staff for {{ tournament.name }}.
              <pre style="font-family: 'Roboto', sans-serif;"
                   class="text-gray-700 whitespace-pre-wrap my-3">{{ staff }}</pre>
              <div class="flex justify-center my-4">
                <div class="btn btn-blue-600">
                  Visit Tournament Page
                </div>
              </div>
              
              Thanks,<br>
              Bowscore<br>
            </fieldset>
            <div class="card-footer">
              This email will be sent to competitors that are set as staff.
            </div>
            <div class="flex justify-end">
              <button type="submit" class="btn btn-blue-600">
                Update
                <span v-if="loading === 'staff'">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form, ErrorMessage, Field} from "vee-validate";

export default {
  name: "TournamentMessaging",
  components: {Form, Field, ErrorMessage},
  props: ['tournamentIn'],
  data() {
    return {
      registered: '',
      payment: '',
      waived: '',
      staff: '',
      approved: '',
      loading: '',
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
  },
  methods: {
    update(occasion) {
      this.loading = occasion;
      this.$axios.post(`/tournaments/${this.tournament.slug}/message/update`, {
        occasion: occasion,
        message: this[occasion]
      }).then(() => {
        this.loading = '';
      })
    },
    setData() {
      let vm = this;
      this.tournament.messages.forEach(function (message) {
        vm[message.occasion] = message.message;
      })
    }
  },
  mounted() {
    this.setData();
  }
}
</script>

<style scoped>
.card-footer {
  @apply border-t pt-3 mt-3;
}
fieldset {
  @apply my-2 p-3 rounded border border-gray-400;
}
</style>
