<template>
  <div class="w-full">
    <label v-if="canImport" class="btn btn-outline-blue-600 m-1">
      <input @change="importTapped" type="file" id="registrations" name="registrations"
             accept="text/csv" class="hidden">
      <i class="fas fa-file-upload mr-2"></i> Import Ladder CSV
    </label>
    <div v-for="(ladder, lIndex) in ladders" class="m-2 p-3 border border-blue-600 bg-blue-100 rounded">
      <div class="w-full">
        <div class="flex justify-between">
          <div class="text-xl">{{ ladderName(ladder, lIndex) }}: {{ getCompetitors(ladder) }}</div>
          <div>
            <button class="btn-outline-gray-600 btn" @click="remove(lIndex)">
              <i class="fas fa-trash"></i>
            </button>
          </div>
        </div>
        <div class="w-full flex flex-wrap">
          <label class="pr-2 w-full sm:w-1/3">
            Name
            <Field type="text" :name="`name_${lIndex}`" v-model="ladder.name" class="bs-form-box block w-full"
                   :id="`name_${lIndex}`"/>
          </label>
          <div class="w-full sm:w-2/3">
            <div :for="`info_${lIndex}`">Info (optional)</div>
            <Field
              type="textArea"
              v-model="ladder.info"
              :id="`info_${lIndex}`"
              :name="`info_${lIndex}`"
              class="bs-form-box autoExpand w-full"
              rules="clean|max:65000"/>
          </div>
        </div>
        <div v-for="(group,gIndex) in restrictions(ladder)" class="w-full flex flex-wrap">
          <label v-for="detail in optionDetails" class="mr-3">
            Restrict {{ detail.name }}
            <select
              v-model="group[detail.name]"
              class="bs-form-box block w-full"
              :id="`${lIndex}_${gIndex}_${detail.name}`">
              <option :value="null">No Restriction</option>
              <option v-for="option in detail.options">{{ option }}</option>
            </select>
          </label>
          <div class="flex flex-col justify-end">
            <button
              v-if="Object.keys(group).length" class="btn-sm px-2 m-2"
              :class="`btn-${gIndex + 1 < Object.keys(restrictions(ladder)).length ? 'gray' : 'blue'}-600`"
              @click="groupButtonClicked(ladder, gIndex)">
              {{ gIndex + 1 < Object.keys(restrictions(ladder)).length ? 'Remove this' : 'Add another' }}
              group
            </button>
          </div>
        </div>
      </div>
      <div class="w-full flex my-2">
        <div class="mr-4">Ladder Type:</div>
        <div class="mr-4">
          <input type="radio" id="one" :value="1" v-model="ladder.shoot_out"/>
          <label for="one">Shoot-out</label>
        </div>
        <div>
          <input type="radio" id="two" :value="0" v-model="ladder.shoot_out"/>
          <label for="two">Bracket</label>
        </div>
      </div>
      <div class="w-full flex justify-between flex-wrap">
        <label>
          Competitor Count
          <input v-if="ladder.shoot_out" v-model="ladder.start_ladder" type="number" min="2"
                 class="bs-form-box block w-full">
          <select v-else v-model="ladder.start_ladder" class="bs-form-box block w-full" :id="`start_${lIndex}`">
            <option v-for="count in validTypes(ladder)">{{ count }}</option>
          </select>
        </label>
        <label v-if="!ladder.shoot_out && tournament.rounds.length > 1">
          Starting Results Point
          <select v-model="ladder.start_results" class="bs-form-box block w-full" :id="`point_${lIndex}`">
            <option v-for="(name, id) in roundOptions" :value="id">
              {{ name }}
            </option>
          </select>
        </label>
        <label>
          # of Ends
          <input type="number" v-model="ladder.ends_count" class="bs-form-box block w-full" :id="`ends_${lIndex}`">
        </label>
        <label>
          # of Arrows
          <input type="number" v-model="ladder.arrows_count" class="bs-form-box block w-full" :id="`arrows_${lIndex}`">
        </label>
      </div>
    </div>
    
    <div class="m-2 flex w-full justify-center">
      <button class="btn btn-blue-600" @click="addLadder">Add a ladder</button>
    </div>
    <div v-if="ladders.length" class="m-2">
      <div>Summary</div>
      <div v-for="(ladder, index) in ladders">
        {{ index + 1 }}.
        {{ ladderSummary(ladder) }}
      </div>
      <div>
        <button class="btn btn-blue-600" @click="saveChanges">
          Save <i v-if="saving" class="fas fa-spinner fa-spin"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";
import {Field} from "vee-validate";
import Swal from "sweetalert2";

export default {
  name: "LadderSetup",
  components: {Field, FormSwitch},
  props: ['tournamentIn'],
  data() {
    return {
      ladders: [],
      currentGroup: 0,
      selectedOption: null,
      addRestriction: false,
      types: [256, 128, 64, 32, 16, 8, 4, 2, 1],
      tournament: this.tournamentIn,
      roundOptions: {},
      saving: false,
    }
  },
  watch: {
    tournamentIn() {
      this.setLadders();
      this.setRoundOptions();
    }
  },
  computed: {
    canImport() {
      // if (this.ladders.length) return false;
      let validIds = [1, 501, 774, 2802, 3441];
      let user = this.$attrs.user;
      return !!(user && validIds.includes(user.id));
    },
    registrationOptions() {
      return this.tournament.regform.filter(f => f.multiple !== null);
    },
    optionDetails() {
      let details = [];
      this.registrationOptions.forEach(function (option) {
        let multiple = option.multiple.map(m => m.label);
        details.push({'name': option.name, 'options': multiple})
      });
      return details;
    },
    
  },
  methods: {
    validTypes(ladder) {
      let list = [];
      for (let count in this.types) {
        if (count >= ladder.end_ladder) list.push(count);
      }
      return list;
    },
    async importTapped() {
      await Swal.fire({
        titleText: `Import Ladders and Competitors?`,
        html: 'This will create ladders, competitors, and assignments based off a previous event.',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: async () => {
          await this.importLadder()
        }
      })
    },
    async importLadder() {
      let vm = this;
      let formData = new FormData();
      let csv = document.querySelector('#registrations');
      if (!csv.files.length) {
        return Swal.fire('No file has been selected. Please try again.');
      }
      vm.showLoader = true;
      formData.append("registrations", csv.files[0]);
      await this.$axios.post(`/tournaments/${vm.tournament.slug}/ladder_import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        this.$emit('refresh');
      }).catch(({response}) => {
        console.log(response);
        vm.showLoader = false;
        Swal.fire({
          titleText: 'Upload process failure',
          html: response.data.message,
          icon: 'error',
        });
      });
    },
    ladderName(ladder, index) {
      return ladder.name ? ladder.name : `Ladder ${index + 1}`;
    },
    setRoundOptions() {
      let list = {0: 'All'};
      this.tournament.rounds.forEach((round) => {
        list[round.id] = round.name;
      });
      list[null] = 'None';
      this.roundOptions = list;
    },
    saveChanges() {
      this.saving = true;
      this.$axios.post(`/tournaments/${this.tournament.slug}/ladders`, {ladders: this.ladders}).then((response) => {
        let updated = response.data;
        let vm = this;
        updated.forEach((ladder) => {
          ladder.restrictions = vm.restrictions(ladder);
        })
        this.ladders = updated;
        this.$emit('refresh');
        setTimeout(function () {
          vm.saving = false;
        }, 1000);
        
      })
    },
    ladderSummary(ladder) {
      let string = this.getCompetitors(ladder);
      string += `- ${this.ladderString(ladder)} - ${ladder.ends_count} End${ladder.ends_count > 1 ? "s" : ''} of
            ${ladder.arrows_count} arrow${ladder.arrows_count > 1 ? 's' : ''} - assignments`;
      if (ladder.start_results === null) {
        string += ' are not based off of results';
      } else {
        string += ` based off of ${this.roundOptions[ladder.start_results]} results.`;
      }
      return string;
    },
    restrictions(ladder) {
      if (typeof ladder.restrictions === "string") {
        return JSON.parse(ladder.restrictions);
      } else {
        return ladder.restrictions;
      }
    },
    getCompetitors(ladder) {
      let string = '';
      let restrictions = this.restrictions(ladder);
      let first = true;
      for (let restriction in restrictions) {
        let restrictionString = '';
        for (let key of Object.entries(restrictions[restriction])) {
          restrictionString += key[1] == null ? '' : `${key[0]}:${key[1]} `;
        }
        if (restrictionString.length) {
          string += first ? restrictionString : `& ${restrictionString}`;
          first = false;
        }
      }
      return string.length ? string : 'All Competitors';
    },
    ladderString(ladder) {
      if (ladder.shoot_out) {
        return `Shoot-out: ${ladder.start_ladder} competitors`;
      } else {
        let start = this.types.indexOf(parseInt(ladder.start_ladder));
        let end = this.types.indexOf(parseInt(ladder.end_ladder));
        return `Bracket: ${this.types.slice(start, end + 1).join(', ')}`;
      }
      
    },
    addLadder() {
      let ladder = null;
      if (this.ladders.length) {
        ladder = Object.assign({}, this.ladders[this.ladders.length - 1]);
        ladder['id'] = null;
        ladder['name'] = `Ladder ${this.ladders.length + 1}`;
        ladder.restrictions = [{}];
      } else {
        ladder = {
          id: null,
          name: 'Ladder 1',
          info: null,
          restrictions: [{}],
          ends_count: 3,
          arrows_count: 3,
          start_ladder: 8,
          end_ladder: 1,
          start_results: 0,
          shoot_out: 1,
        }
      }
      this.ladders.push(ladder);
    },
    setLadders() {
      if (this.tournamentIn.ladders && this.tournamentIn.ladders.length) {
        this.ladders = this.tournamentIn.ladders;
        let vm = this;
        this.ladders.forEach((ladder) => {
          ladder.restrictions = vm.restrictions(ladder);
        })
      }
    },
    remove(ladderIndex) {
      Swal.fire({
        title: 'Are you sure you want to remove this Ladder?',
        text: this.ladderSummary(this.ladders[ladderIndex]),
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.ladders.splice(ladderIndex, 1);
        }
      })
    },
    groupButtonClicked(Ladder, groupIndex) {
      if (groupIndex + 1 < Object.values(Ladder.restrictions).length) {
        console.log('remove it')
        Ladder.restrictions.splice(groupIndex, 1);
      } else {
        Ladder.restrictions.push({})
      }
    }
    
  },
  mounted() {
    this.setLadders();
    this.setRoundOptions();
  }
}
</script>

<style scoped>

</style>
