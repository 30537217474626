<template>
    <div class="card card-blue-700">
        <div class="card-header">
            <span @click="show = !show" class="mr-2">
                <span v-show="show"><i class="fas fa-minus"></i></span>
                <span v-show="!show"><i class="fas fa-plus"></i></span>

            </span>

            Scoring Device Authorization
        </div>
        <div v-if="show" class="card-body">
            <div class="m-2">
                <div class="m-auto py-0 max-w-sm bs-form-box flex">
                    <span class="h-center"><i class="fas fa-search"></i></span>
                    <input type="text" class="flex-grow mx-2 pl-2" v-model="search">
                    <div v-if="search !== ''" class="h-center" @click="search = ''">
                        <span class=""><i class="fas fa-times"></i></span>
                    </div>
                </div>
            </div>

            <div class="text-left text-gray-500 text-sm">
                Click a cell to edit.
            </div>
            <div class="table-responsive bootstrap">
                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Active</th>
                        <th>Device Id</th>
                        <th>Expires</th>
                    </tr>
                    </thead>
                    <tbody>
                    <device-details v-for="device in selectedDevices" :device="device"
                                    :key="device.id"></device-details>
                    <tr v-if="!selectedDevices.length">
                        <td colspan="3" class="text-center">No Devices<br>
                            <a :href="'/clubs/' + club.slug + '/settings'">Visit Club Devices</a></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-right text-gray-500 text-sm">
                Device Id is displayed on the scoring device
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ScoringDevices",
        props: ['tournament', 'club', 'devices'],
        data() {
            return {
                search: '',
                show: false
            }
        },
        computed: {
            selectedDevices() {
                return this.devices.filter(f => f.device_id.toUpperCase().includes(this.search.toUpperCase()));
            }
        },

    }
</script>

<style scoped>

</style>