<script setup>
import {ref} from "vue";
import NewTournamentButton from "./NewTournamentButton.vue";
import NewClubButton from "./NewClubButton.vue";
import SideNavigation from "../navigation/SideNavigation.vue";
import {useDisplayStore} from "../../stores/DisplayStore";

const props = defineProps({user: Object})
const firstName = props.user?.first_name ?? 'Login';
const csrf = ref(document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
const hasUser = props.user != null;

const store = useDisplayStore();
</script>

<template>
  <div class="relative">
    <button @click="store.toggleOpen()" class="hidden bs-input-box userButton lg:flex flex-col justify-center">
      <span class="w-full flex justify-between">{{ firstName }}<i class="fa-solid fa-user"></i></span>
    </button>
    <button @click="store.toggleOpen()" class="smallButton flex lg:hidden flex-col justify-center">
      <span class="w-full flex justify-end"><i class="fa fa-bars"></i></span>
    </button>
    <div v-if="store.showHamburgerDropdown" class="bs-card infoCard">
      <div class="lg:hidden">
        <side-navigation :user="props.user"/>
        <hr class="my-2">
      </div>
      <div v-if="hasUser">
        <a href="/account" class="navItem">My account</a>
        <div class="navItem">Create a team/group</div>
        <new-club-button :show-button="false">
          <div class="navItem">Register a club</div>
        </new-club-button>
        <new-tournament-button :custom-button="true" :user="props.user">
          <div class="navItem">Create a tournament</div>
        </new-tournament-button>
      </div>
      <div v-else>
        <a href="/login" class="navItem">Login</a>
        <a href="/register" class="navItem">Sign up</a>
      </div>
      <hr class="topPadding">
      <a target="_blank" href="https://community.bowscore.com" class="navItem topPadding">Community forum</a>
      <a href="/build_notes" class="navItem">Changelog</a>
      <a target="_blank" href="https://community.bowscore.com" class="navItem">Contact us</a>
      <hr v-if="hasUser" class="topPadding">
      <form v-if="hasUser" method="post" action="/logout">
        <input type="hidden" name="_token" :value="csrf">
        <button class="w-full flex justify-start navItem topPadding bottomPadding">Log out</button>
      </form>
    </div>
  </div>

</template>

<style scoped>
.topPadding {
  margin-top: 8px;
}

.bottomPadding {
  margin-bottom: 8px;
}

.navItem {
  @apply font-semibold text-stone-400 block;
  padding: 8px 24px !important;
  font-size: 16px;
  letter-spacing: .32px;
  line-height: 19px;
}

.navItem:hover {
  @apply bg-stone-300;
  cursor: pointer;
}

.bs-card {
  padding: 0;
}

.infoCard {
  @apply absolute;
  padding-top: 8px;
  padding-bottom: 8px;
  top: 30px;
  right: -8px;
  width: 332px;
  z-index: 11;
}

@media (min-width: 1100px) {
  .infoCard {
    padding-top: 8px;
    padding-bottom: 8px;
    top: 46px;
    right: -7px;
  }
}


.smallButton {
  @apply font-bold text-stone-400;
  font-size: 16px;
  width: 132px;
  height: 40px;
  padding-left: 19px;
}

.userButton {
  @apply font-bold text-stone-400;
  font-size: 16px;
  width: 132px;
  height: 40px;
  padding-left: 19px;
  padding-right: 19px;
}
</style>