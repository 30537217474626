<script setup>

import CardSettings from "./CardSettings.vue";

const props = defineProps(['header', 'text', 'buttonText', 'buttonLink', 'image', 'name'])

</script>

<template>
  <div class="relative">
    <card-settings :name="props.name"/>
    <div class="bs-shadow-card largeCard">
      <div class="hidden md:block">
        <div class="flex justify-between">
          <div class="flex flex-col justify-between">
            <div class="bs-section-header">{{ header }}</div>
            <div class="infoText"> {{ text }}</div>
            <a :href="buttonLink" class="bs-shadow-card mt-4">{{ buttonText }}</a>
          </div>
          <div style="min-width:300px; width:300px; max-width:300px">
            <img v-if="image" :src="`/img/${image}`" alt="image of archers" class="image">
            <slot v-else></slot>
          </div>
        </div>
      </div>
      <div class="md:hidden">
        <div class="bs-section-header">{{ header }}</div>
        <div class="flex">
          <div class="infoText"> {{ text }}</div>
          <img v-if="image" :src="`/img/${image}`" alt="image of archers" class="image">
          <slot v-else></slot>
        </div>
        <a :href="buttonLink" class="bs-input-box w-full mt-4">{{ buttonText }}</a>
      </div>
    </div>
  </div>

</template>

<style scoped>
.bs-section-header {
  @apply font-extrabold;
  font-size: 16px;
  letter-spacing: .02rem;
  line-height: 19px;
}

.infoText {
  @apply font-medium text-black;
  line-height: 24px;
  letter-spacing: .02rem;
}

.bs-input-box {
  @apply font-bold block;
  text-align: center;
  line-height: 17px;
  letter-spacing: .018rem;
}

.image {
  border-radius: 3px;
  margin: 1rem 6.25rem;
  width: 136px;
  height: 136px;
}

@media (max-width: 768px) {
  .image {
    min-width: 125px;
    width: 125px;
    height: 125px;
    margin: .5rem 0 .5rem .5rem;
  }
}

.largeCard {
  @apply mb-4 w-full;
  padding-top: 32px;
  padding-bottom: 38px;
}

</style>