<template>
  <div class="card card-blue-100 mb-2">
    <div v-if="tournament.matched && matchesText()" class="card-header-sm w-full">
      {{ matchesText() }}
    </div>
    <div class="card-body flex flex-col sm:flex-row justify-between pt-1 pb-2">
      <div class="flex flex-col w-full md:w-1/2">
        <div class="flex flex-col sm:block">
          <a :href="'/tournaments/' + tournament.slug" class="text-blue-600 text-xl sm:text-3xl mr-2 cursor-pointer">
            {{ tournament.name }}
          </a>
          <div v-if="tournament.club">
            <a :href="'/clubs/' + tournament.club.slug" class="text-blue-500 sm:text-xl cursor-pointer">
              hosted by {{ tournament.club.name }}
            </a>
          </div>
        </div>
        <div>Sport: {{ tournament.sport.name }}</div>
        <div>Tournament Dates: {{ start }} - {{ end }}</div>
        <div>Registration Open: {{ open }} - {{ close }}</div>
        <span v-if="location">{{ location }}</span>
        <div class="flex">
          <a v-if="status === 'open' && space"
             :href="'/tournaments/' + tournament.slug + '#/register'"
             class="btn-blue-600 btn mt-2 w-36 p-1 mr-2">Register</a>
          <a v-if="started"
             :href="'/tournaments/' + tournament.slug + '#/results' + (search ? '?search=' + search : '')"
             class="btn-blue-600 btn mt-2 w-36 p-1 mr-2">View Results</a>
        </div>
      </div>
      
      <div class="hidden md:flex w-1/2 h-center">
        <div v-if="tournament.description" class="h-40 overflow-y-auto">
          <pre style="font-family: 'Roboto', sans-serif;"
               class="text-gray-600 px-4 whitespace-pre-wrap">{{ tournament.description }}</pre>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TournamentListItem",
  props: ['tournament', 'search'],
  data() {
    return {
      showDescription: this.tournament.description && this.tournament.description.length > 200,
    }
  },
  computed: {
    started() {
      let now = new Date();
      let time = new Date(this.tournament.start_time);
      return moment(time).isBefore(now);
    },
    start() {
      let time = new Date(this.tournament.start_time);
      return moment(time).format('DD MMM');
    },
    end() {
      let time = new Date(this.tournament.end_time);
      return moment(time).format('DD MMM YYYY');
    },
    open() {
      let time = new Date(this.tournament.reg_open);
      return moment(time).format('DD MMM');
    },
    close() {
      let time = new Date(this.tournament.reg_close);
      return moment(time).format('DD MMM YYYY');
    },
    location() {
      let loc = '';
      if (this.tournament.address) {
        if (this.tournament.address.city) {
          loc += this.tournament.address.city;
          if (this.tournament.address.state) {
            loc += ' - ';
          }
        }
        loc += this.tournament.address.state ? this.tournament.address.state : '';
      }
      return loc;
    },
    status() {
      let now = new Date();
      let status = '';
      let openTime = new Date(this.tournament.reg_open);
      let closeTime = new Date(this.tournament.reg_close);
      let open = moment(openTime);
      let close = moment(closeTime);
      if (open > now) {
        status = 'opening';
      } else if (close < now) {
        status = 'closed';
      } else {
        status = 'open';
      }
      return status;
    },
    space() {
      return this.tournament.competitors_count < this.tournament.max_competitors
    }
  },
  methods: {
    formatTime(time) {
      return moment(time).format('D MMM hh:mm A');
    },
    statusMessage(status) {
      let openTime = new Date(this.tournament.reg_open);
      let closeTime = new Date(this.tournament.reg_close);
      let message = "";
      if (status === 'opening') {
        message += 'Registration opens ';
        message += moment(openTime).format('MMMM D');
      } else if (status === 'closed') {
        message += 'Registration closed ';
        message += moment(closeTime).format('MMMM D');
      } else {
        message += 'Registration closes ';
        message += moment(closeTime).format('MMMM D');
      }
      return message;
    },
    statusClass(status) {
      let color = '';
      if (status === 'opening') {
        color = 'text-blue-500';
      } else if (status === 'closed') {
        color = 'text-orange-700';
      } else {
        color = 'text-blue-700';
      }
      return color;
    },
    matchesText() {
      let text = '';
      if (this.tournament.matched.archers || this.tournament.matched.teams) {
        text = 'Matches ';
        if (this.tournament.matched.archers) {
          if (this.tournament.matched.archers.length < 5) {
            text += (this.tournament.matched.archers.length > 1 ? 'competitors' : 'competitor') + ': ';
            text += this.tournament.matched.archers.join(', ');
          } else {
            text += this.tournament.matched.archers.length + ' competitors with a similar name';
          }
          if (this.tournament.matched.teams) {
            text += ', and ';
          }
        }
        if (this.tournament.matched.teams) {
          if (this.tournament.matched.teams.length < 5) {
            text += (this.tournament.matched.teams.length > 1 ? 'teams' : 'team') + ': ';
            text += this.tournament.matched.teams.join(', ') + '. ';
          } else {
            text += this.tournament.matched.teams.length + ' teams with a similar name.';
          }
        }
      }
      return text;
    }
  }
}
</script>

<style scoped>
.largeDescription {
  overflow: hidden;
  max-height: 100px;
}
</style>
