<template>
  <div v-if="teams.length" class="flex justify-between md:justify-around pb-2">
    <router-link to="/results" class="btn btn-outline-blue-600 flex-1 md:mx-5" exact>
      <a>Individual</a>
    </router-link>
    <router-link v-if="useTeams" to="/results/team" class="btn btn-outline-blue-600 flex-1 md:mx-5">
      <a>Team</a>
    </router-link>
    <router-link v-if="useClubs" to="/results/club" class="btn btn-outline-blue-600 flex-1 md:mx-5">
      <a>Club</a>
    </router-link>
    <router-link v-if="useCombined" to="/results/combined" class="btn btn-outline-blue-600 flex-1 md:mx-5">
      <a>Team/Club</a>
    </router-link>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";

export default {
  name: "TeamClubResultsLinks",
  props: ['teams'],
  mixins: [mixin],
  computed: {
    teamClubExists() {
      let form = this.tournament.regform;
      let exists = form.filter(function (r) {
        return r.option === 'team/club' || r.option === 'team_club' && r.enabled === 1;
      });
      return exists.length;
    },
    useClubs() {
      if (!this.teamClubExists) return false;
      let all = Object.values(this.tournament.competitorList).filter(function (competitor) {
        let exists = competitor.regform.find(f => f.option === 'team/club' || f.option === 'team_club');
        return typeof exists !== 'undefined' && exists.name === 'Club';
      })
      return all.length;
    },
    useCombined() {
      let all = Object.values(this.tournament.competitorList).filter(function (competitor) {
        let type = competitor.regform.find(f => f.option === 'team/club' || f.option === 'team_club');
        let name = competitor.regform.find(f => f.option === 'team/club-name' || f.option === 'team_club_name');
        if (type === null || typeof type === 'undefined') {
          return typeof name !== 'undefined' && name !== null;
        }
        return false;
      })
      return all.length || !this.teamClubExists;
    },
    useTeams() {
      if (!this.teamClubExists) return false;
      let all = Object.values(this.tournament.competitorList).filter(function (competitor) {
        let exists = competitor.regform.find(f => f.option === 'team/club' || f.option === 'team_club');
        return typeof exists !== 'undefined' && exists.name === 'Team';
      })
      return all.length;
    }
  }
}
</script>

<style scoped>

</style>
