<template>
  <div class="md:w-1/2 my-8 mx-auto">
    <div class="card card-blue-700">
      <div class="card-header">
        {{ header != null ? header : 'Edit Restrictions with a Quick Setup Code' }}
      </div>
      <div class="card-body">
        <div class="flex flex-col text-center w-full sm:w-3/4 md:1/2 m-auto my-3">
          <div class="text-xl sm:text-2xl">
            Begin scoring with this device
            <tool-tip class="text-sm" :size="'w-full'">
              <template v-slot:message>
                <div class="text-2xl">What is a scoring code?</div>
                <p>A scoring code is a quick way to set up a scoring device. Each code is unique to a {{
                    this.$trans.choice('search.tournament', 1)
                  }}.
                  Some codes may be linked to a specific location, line time, or bale/stake. Entering a valid code will
                  redirect you to the proper scoring page and authorize the device for scoring that {{
                    this.$trans.choice('search.tournament', 1)
                  }}.</p>
                <div class="text-2xl">Where do I get a code?</div>
                <p>Codes can be generated on the {{ this.$trans.choice('search.tournament', 1) }} manage page. These codes
                  are powerful,
                  and should only be given to people with express permission to score. Codes can be disabled and
                  regenerated from the
                  {{ this.$trans.choice('search.tournament', 1) }} manage page.</p>
              </template>
            </tool-tip>
          </div>
          <input maxlength="4" v-model="code" name="code" :disabled="lock" class="bs-form-box w-full">
          <div v-if="invalid" class="text-red-600 text-left">{{ invalid }}</div>
          <div v-if="setDevice" class="text-left">Setting up device...</div>
          <small>Please enter the 4 letter code provided by the {{ this.$trans.choice('search.tournament', 1) }} director.
          </small>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "SetupFinder",
  props: ['header'],
  data() {
    return {
      code: '',
      invalid: '',
      lock: false,
      setDevice: false,
    }
  },
  watch: {
    code(text) {
      if (text.length === 4) {
        this.checkCode()
      } else if (text.length) {
        this.invalid = ''
      }
    }
  },
  methods: {
    checkCode() {
      let vm = this;
      this.$axios
      .get('/set_device', {
        params: {
          'code': this.code,
          'device': this.$store.getters['device/getDeviceId'],
        }
      })
      .then(({data}) => {
        if (data.error) {
          vm.invalid = data.error.message;
          vm.lock = true;
          setTimeout(function () {
            vm.lock = false;
            vm.code = ''
          }, 4000);
        }
        if (data.success) {
          vm.setDevice = true;
          if (data.tournament.club_id != null) {
            window.location.href = `/tournaments/${data.tournament.slug}?code=${data.code.code}`;
          } else {
            window.location.href = `/practice/${data.tournament.slug}/practice/score`;
            
          }
          let urlString = '/tournaments/';
          if (data.tournament.club_id == null) urlString = '/practice/';
          window.location.href = urlString + data.tournament.slug + '?code=' + data.code.code;
        }
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>
