<template>
  <div class="h-full flex flex-col justify-center">
    <div class="flex justify-center">
      <div class="searchBox z-10" :class="landing ? 'absolute' : ''">
        <div class="bs-input-box flex h-full">
          <div class="flex flex-col justify-center text-stone-400">
            <i class="fa-regular fa-search"></i>
          </div>
          <input class="flex-grow ml-4 focus:outline-none"
                 placeholder="Search (tournaments, clubs, or archers)"
                 v-model="search">
          <button v-if="search.length" @click="clearSearch" class="clearButton">
            <i class="fa-lg fas fa-times"></i>
          </button>
        </div>
        <div v-if="search && Object.keys(resultList).length"
             class="bg-blue-700 text-blue-200 rounded-b-lg border-b-4 border-r-4 border-l-4">
          <div v-if="resultList.tournaments.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">{{ tournamentsWord }}</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/tournaments?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="tournament in resultList.tournaments" class="mx-3">
              <a class="flex justify-between resultItem" :href="'/tournaments/' + tournament.slug">
                <div>{{ tournament.name }}</div>
                <div v-if="tournament.address && tournament.address.city">{{ tournament.address.city }},
                  {{ tournament.address.state }}
                </div>
              </a>
            </div>
          </div>
          <div v-if="resultList.clubs.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Clubs</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/clubs?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="club in resultList.clubs" class="mx-3">
              <a class="flex justify-between resultItem" :href="'/clubs/' + club.slug">
                <div>{{ club.name }}</div>
                <div v-if="club.rangeAddress && club.rangeAddress.city">{{ club.rangeAddress.city }},
                  {{ club.rangeAddress.state }}
                </div>
                <div v-else-if="club.mailingAddress && club.mailingAddress.city">
                  {{ club.mailingAddress.city }}, {{ club.mailingAddress.state }}
                </div>
                <div v-else-if="club.address">{{ club.address.city }}, {{ club.address.state }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.competitors.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Competitors</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/competitors?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="competitor in resultList.competitors" class="mx-3">
              <a class="flex justify-between resultItem"
                 :href="'/tournaments/' + competitor.tournament.slug + '#/results'">
                <div>{{ competitor.name }}</div>
                <div>{{ competitor.tournament.name }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.profiles.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Profiles</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/profiles?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="profile in resultList.profiles" class="mx-3">
              <a v-if="profile.slug" class="resultItem" :href="'/archers/' + profile.slug">
                <div>{{ profile.full_name }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.teams.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Team/Clubs</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/teams?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="competitor in resultList.teams" class="mx-3">
              <a class="flex justify-between resultItem"
                 :href="'/tournaments/' + competitor.tournament.slug + '#/results?team=' + encodeURI(competitor.team)">
                <div>{{ competitor.name }}</div>
                <div>{{ competitor.team }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSearch",
  props: ['landing'],
  data() {
    return {
      search: '',
      resultList: {},
    }
  },
  watch: {
    search(text) {
      if (!text.length) {
        this.resultList = {};
      } else if (text.length > 2 || Object.keys(this.resultList).length) {
        this.submitSearch();
      }
    }
  },
  computed: {
    tournamentsWord() {
      let text = this.$trans.choice('search.tournament', 2);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.resultList = {};
    },
    submitSearch() {
      this.$axios.get('/search', {params: {'search': this.search}}).then(({data}) => {
        this.resultList = data.results;
      });
    }
  }
}
</script>

<style scoped>
.searchBox {
  width: 540px;
  height: 40px;
}

.bs-input-box {
  @apply text-stone-400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .28px;
}

@media (min-width: 1100px) {
  .bs-input-box {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .32px;
  }
}


.clearButton {
  @apply flex flex-col justify-center text-gray-500 p-2 mr-2 font-light;
}

.resultItem {
  @apply text-blue-200 py-1 text-lg;
}
</style>
