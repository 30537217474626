<template>
  <div class="card card-blue-700 w-full sm:w-1/2 m-2">
    <div class="card-header">Handicap Designation</div>
    <div class="card-body flex flex-col">
      <span class="p-2 mb-3 border border-blue-600 rounded hintText">
        This point value will be added to the competitors overall results for this League.
      </span>
      <div>
        <label for="handicap type">Handicap Type</label>
        <select name="handicap type" id="handicap type" class="bs-form-box w-full"
                v-model="handicapType" @change="setTournamentHandicap">
          <option>None</option>
          <option>Manual</option>
          <option>League</option>
          <option>Bowscore</option>
        </select>
      </div>
      <div v-if="handicapType === 'Manual'">
        <div v-for="competitor in tournament.competitorList" class="py-2 flex">
          <span class="flex flex-col justify-center w-1/4 pr-2 text-right">{{ competitor.name }}</span>
          <input
            class="bs-form-box"
            type="number"
            max="1000"
            min="0"
            :name="`${competitor.id}handicap`"
            :id="`${competitor.id}handicap`"
            v-model="competitor.handicap.handicap"
            @input="manualHandicap(competitor)">
        </div>
      </div>
      <div v-else-if="handicapType === 'League'">
        <div class="p-2 my-3 border border-blue-600 rounded hintText">
          Handicap points will be automatically generated based off the competitors performance during their lowest
          designated number of undropped rounds. Handicap points will be 1/2 of the difference between the competitor
          score and the top score for their class.
        </div>
        <div v-if="maxRounds < 1">
          <p>There are not enough undropped rounds to calculate a league handicap</p>
        </div>
        <div v-else>
          <label for="roundCount"># of rounds</label>
          <input
            class="bs-form-box"
            type="number"
            :max="maxRounds"
            min="1"
            name="handicapRounds"
            id="handicapRounds"
            v-model="handicapRounds"
            @input="roundsUpdated()">
        </div>
        <div v-if="calculatedTable === null">
          <div v-if="isOnline" class="flex justify-center">
            <div class="flex flex-col">
              <div class="flex justify-center">Calculating Handicaps</div>
              <div class="flex justify-center"><i class="fas fa-2x fa-spinner fa-spin"></i></div>
            </div>
          </div>
          <div v-else>
            <div class="flex justify-center">This feature requires an internet connection.</div>
          </div>
        </div>
      </div>
      
      <div v-else-if="handicapType === 'Bowscore'">
        <div class="p-2 my-3 border border-blue-600 rounded hintText">
          Handicap points will be automatically generated based off the competitors performance leading up to the
          designated cutoff time. This will only become available for competitors with at least 90 scored arrows. The
          score will be a 30 arrow average from the last 90 up to 180 arrows for their class. Handicap points will be
          1/2 of the difference between the competitor score and the maximum possible score rounded down.
        </div>
        <div>
          <label for="cutoff">Cutoff Time:</label>
          <flat-pickr
            class="bs-form-box"
            v-model="cutoff"
            name="cutoff"
            id="cutoff"
            :config="cutoffOptions"
            @on-close="setCutoff"/>
        </div>
        <div v-if="calculatedTable === null">
          <div v-if="cutoff !== null && isOnline" class="flex justify-center">
            <div class="flex flex-col">
              <div class="flex justify-center">Calculating Handicaps</div>
              <div class="flex justify-center"><i class="fas fa-2x fa-spinner fa-spin"></i></div>
            </div>
          </div>
          <div v-else-if="cutoff !== null">
            <div class="flex justify-center">This feature requires an internet connection.</div>
          </div>
        </div>
      </div>
      <div v-if="calculatedTable !== null" class="py-2 table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-header text-left">Competitor</th>
            <th class="table-header text-center">Score</th>
            <th class="table-header text-center">Max</th>
            <th class="table-header text-center">Handicap</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in calculatedTable">
            <td>{{ row[0] }}</td>
            <td class="text-center">{{ row[1] }}</td>
            <td class="text-center">{{ row[2] }}</td>
            <td class="text-center">{{ row[3] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
// import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
// import FlatPickr from "vue-flatpickr-component/src/component";

/**
 * @typedef {object} tournament
 * @property {string} name
 * @property {object} handicap
 */


export default {
  name: "HandicapDesignation",
  props: ['tournament'],
  data() {
    return {
      handicapType: this.tournament.handicap != null ? this.tournament.handicap.type : 'None',
      calculatedTable: null,
      cutoff: this.incomingCutoff(),
      handicapRounds: 1,
      cutoffOptions: {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 15,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
      }
    }
  },
  watch: {
    handicapType(newValue, oldValue) {
      this.calculatedTable = null;
      if (newValue === 'Bowscore' && this.cutoff !== null) {
        this.getBowscoreHandicaps();
      } else if (newValue === 'League') {
        this.getLeagueHandicaps();
      }
    },
    handicapRounds() {
      this.getLeagueHandicaps();
    }
  },
  computed: {
    maxRounds() {
      let totalRounds = this.tournament.rounds.length;
      let dropped = this.tournament.dropped_rounds ?? 0;
      return totalRounds - dropped;
    },
    isOnline() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  mounted() {
    if (this.handicapType === 'Bowscore' && this.cutoff !== null) {
      this.getBowscoreHandicaps();
    } else if (this.handicapType === 'League') {
      this.getLeagueHandicaps();
    }
  },
  methods: {
    incomingCutoff() {
      if (this.tournament.handicap !== null) {
        return this.tournament.handicap.cutoff != null ? this.tournament.handicap.cutoff : null;
      }
      return null;
    },
    setCutoff() {
      axios.post(`/tournaments/${this.tournament.slug}/handicap_cutoff`, {
        'cutoff': this.cutoff,
      }).then((_) => {
        this.calculatedTable = null;
        this.getBowscoreHandicaps();
      });
    },
    getLeagueHandicaps() {
      axios.get(`/tournaments/${this.tournament.slug}/calculated_handicaps/${this.handicapType}`).then((data) => {
        this.calculatedTable = data['data']['data'];
      });
    },
    getBowscoreHandicaps() {
      axios.get(`/tournaments/${this.tournament.slug}/calculated_handicaps/${this.handicapType}`).then((data) => {
        this.calculatedTable = data['data']['data'];
      });
    },
    async roundsUpdated() {
      await axios.post(`/tournaments/${this.tournament.slug}/handicap_rounds`, {
        'count': this.handicapRounds,
      }).then((_) => {
        this.calculatedTable = null;
        this.getLeagueHandicaps()
      })
      console.log('updatedRounds ' + this.handicapRounds);
    },
    async manualHandicap(competitor) {
      await axios.post(`/tournaments/${this.tournament.slug}/handicap/${competitor.id}`, {
        'details': {'handicap': competitor.handicap, 'type': this.handicapType},
      });
    },
    async setTournamentHandicap() {
      await axios.post(`/tournaments/${this.tournament.slug}/handicap`, {
        'type': this.handicapType,
      });
    }
  }
}
</script>


<style scoped>

</style>
