<template>
  <div class="flex flex-wrap">
    <div class="w-full text-gray-600">
      This address is where the tournament will actually take place.
      This is not your personal address or a business mailing address.
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 mb-2">
      <label for="address 1">Address 1</label>
      <Field type="text" name="address 1" id="address 1" class="bs-form-box" required
             rules="'required|min:5|clean'" v-model="tournament.address.address1"/>
      <span class="text-sm text-red-600">{{ errors.first('address 1') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 mb-2">
      <label for="address 2">Address 2
        <small class="text-gray-600">optional</small>
      </label>
      <input type="text" name="address 2" id="address 2" class="bs-form-box" v-validate="'min:2|clean'"
             v-model="tournament.address.address2">
      <span class="text-sm text-red-600">{{ errors.first('address 2') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/4 mt-4 px-1 mb-2">
      <label for="city">City/Town</label>
      <input type="text" name="city" id="city" class="bs-form-box" required
             v-validate="'required|min:2|clean'" v-model="tournament.address.city">
      <span class="text-sm text-red-600">{{ errors.first('city') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/4 mt-4 px-1 mb-2">
      <label for="state">State/Region/Providence</label>
      <input type="text" name="state" id="state" class="bs-form-box" required
             v-validate="'required|min:2|clean'" v-model="tournament.address.state">
      <span class="text-sm text-red-600">{{ errors.first('state') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/4 mt-4 px-1 mb-2">
      <label for="zip">Zip/Postal Code</label>
      <input type="text" name="zip" id="zip" class="bs-form-box" required
             v-validate="'required|min:5|clean'" v-model="tournament.address.zip">
      <span class="text-sm text-red-600">{{ errors.first('zip') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/4 mt-4 px-1 mb-2">
      <label for="country">Country</label>
      <input type="text" name="country" id="country" class="bs-form-box" required
             v-validate="'required|clean'" v-model="tournament.address.country">
      <span class="text-sm text-red-600">{{ errors.first('country') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-2/3 mt-4 px-1 mb-2">
      <label>{{ locationWord(3) }} </label>
      <div class="flex flex-col" v-for="(location, index) in tournament.locations">
        <div class="mb-1 flex">
          <div class="bs-form-box min-w-0 flex-grow flex justify-between py-0 pr-0 mr-2">
            <div class="h-center">
              <input @input="$emit('updateRoundLocations', location)" class="min-w-0 flex-grow"
                     v-validate="'required|min:2|max:30|clean|json'" v-model="location.name"
                     :name="'location '+ (index + 1)" required>
            </div>
            <div v-if="tournament.locations.length > 1" class="">
              <div @click="$emit('removeLocation', index)" class="btn btn-outline-gray-600">
                <i class="fas fa-trash"></i>
              </div>
            </div>
          </div>
          <div class="bs-form-box flex py-0 pr-0">
            <input @change="$emit('updateRoundLocations', location)" type="number" class="w-10" min="1"
                   max="250"
                   v-validate="'required|min_value:1|max_value:250'" v-model="location.bales"
                   :name="'location '+(index + 1)+' ' + this.$trans.choice('search.bale', 4)" required>
            <div class="flex flex-col justify-center text-gray-600 mr-2"><span class="">{{ baleWord(3) }}</span></div>
          </div>
        </div>
        <div class="flex justify-between">
          <span class="text-sm text-red-600 text-left">{{ errors.first('location ' + (index + 1)) }}</span>
          <span
            class="text-sm text-red-600 text-right">{{ errors.first('location ' + (index + 1) + ' ' + this.$trans.choice('search.bale', 4)) }}</span>
        </div>
      
      </div>
      <div class="flex justify-end">
        <div @click="$emit('addLocation')" class="btn-sm btn-outline-blue-600">Add
          {{ locationWord(1) }}
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/3 mt-10 px-1 mb-2 text-sm text-gray-600">
      <div>
        {{ locationWord(3) }} are used to help divide large tournaments.
        Smaller tournaments should not add additional {{ locationWord(1) }}.
      </div>
      <div class="mt-2">
        The number of {{ this.$trans.choice('search.bale', 2) }} will restrict the number of competitors.
        If you need to add {{ this.$trans.choice('search.bale', 2) }}, this number can be changed at any time.
      </div>
    
    </div>
  </div>
</template>

<script>
import {Field} from "vee-validate";

export default {
  name: "TournamentLocation",
  components: {Field},
  props: ['tournament'],
  data() {
    return {}
  },
  methods: {
    locationWord(count) {
      let text = this.$trans.choice('search.location', count);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    baleWord(count) {
      let text = this.$trans.choice('search.bale', count);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
  }
}
</script>

<style scoped>

</style>
