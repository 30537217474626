<script>
import {Form, ErrorMessage, Field} from "vee-validate";
import FormCheckbox from "../tournaments/form/FormCheckbox.vue";

export default {
  name: "Login",
  components: {FormCheckbox, Form, ErrorMessage, Field},
  data() {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      email: '',
      password: '',
      loading: false,
    }
  },
  methods: {
    navigateHome() {
      window.location = '/';
    }
  }
}
</script>

<template>
  <div>
    <div class="flex justify-center mt-5">
      <img @click="navigateHome()" src="/img/orange_logo_315_wide.png" alt="bowscore logo" class="logo">
    </div>
    <div class="flex justify-center">
      <Form action="/login" method="post" class="flex flex-col" v-slot="{ meta }">
        <input type="hidden" name="_token" :value="csrf">
        <Field placeholder="Email" type="email" name="email" class="bs-form-box" rules="required|email"
               v-model="email"/>
        <ErrorMessage name="email" class="errorText"/>
        <Field placeholder="Password" type="password" name="password" class="bs-form-box" v-model="password"
               rules="required|min:6"/>
        <ErrorMessage name="password" class="errorText"/>
        <FormCheckbox class="ml-2 mt-4" name="remember" id="remember" title="Remember me"></FormCheckbox>
        
        <button :disabled="!meta.valid" class="createButton" type="submit" @click="loading = true"
                :style="`background-color: #${meta.valid ? 'FB5202': '9D9D9D'};`">
          Sign In <span v-if="loading"><i class="ml-2 fas fa-spin fa-spinner"></i></span>
        </button>
        <a class="text-blue-500 hover:text-blue-600 hover:underline btn-link mt-4" href="/password/reset">
          Forgot Your Password?
        </a>
      </Form>
    </div>
  </div>
</template>

<style scoped>

.logo {
  width: 120px;
  margin-top: 26px;
  margin-bottom: 44px;
}


.bs-form-box {
  padding-left: 20px;
  padding-right: 20px;
  height: 44px;
  font-family: 'Figtree', 'serif';
  font-size: 16px;
  letter-spacing: .32px;
  line-height: 20px;
  font-weight: 500;
  width: 240px;
  margin-top: 16px;
}

.createButton {
  width: 240px;
  height: 44px;
  margin-top: 32px;
  border-radius: 3px;
  text-transform: uppercase;
  color: #FFF;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: .32px;
  line-height: 19px;
}

@media (min-width: 576px) {
  .logo {
    width: 240px !important;
    margin-top: 72px;
  }
  
  .bs-form-box {
    height: 60px !important;
    width: 300px !important;
    
  }
  
  .createButton {
    width: 300px !important;
    height: 60px !important;
  }
}
</style>