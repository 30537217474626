<template>
  <div>
    <div v-if="store.round.ends_count > 5 && endCompletion" class="flex mx-2">
      <dropdown align="top" direction="left" width="auto" class="endList">
        <template v-slot:trigger>
          <button type="button" class="btn-lg btn-blue-700 dropdown-toggle">
            {{ endWord }}: {{ store.end }}
            <i class="fas fa-caret-down"></i>
          </button>
        </template>
        <a v-for="e in store.round.ends_count" @click.prevent="updateEnd(e)"
           class="dropdown-item whitespace-nowrap text-left" href=""
           :class="endCompletion[e] >= 100?'btn-blue-800':(endCompletion[e] === 0?'btn-gray-100':'btn-blue-600')">
          {{ e }}
          {{ endCompletion[e] >= 100 ? 'complete' : (endCompletion[e] === 0 ? '' : 'partial') }}
        </a>
      </dropdown>
    </div>
    <div v-else class="flex p-2">
      <div class="text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl m-2 h-center leading-none">
        {{ endWord }}:
      </div>
      <div v-for="e in store.round.ends_count" @click="updateEnd(e)" class="xs:hidden m-2 btn-sm"
           :class="endClasses[e]">{{ e }}
      </div>
      <div v-for="e in store.round.ends_count" @click="updateEnd(e)"
           class="hidden xs:block sm:hidden m-2 btn"
           :class="endClasses[e]">{{ e }}
      </div>
      <div v-for="e in store.round.ends_count" @click="updateEnd(e)" class="hidden sm:block m-2 btn-lg"
           :class="endClasses[e]">{{ e }}
      </div>
    </div>
  </div>

</template>

<script>
import mixin from "../../mixins/scoring_mixins";
import Dropdown from "../partials/Dropdown.vue";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";

export default {
  name: "EndDisplay.vue",
  components: {Dropdown},
  mixins: [mixin],
  data() {
    return {
      store: useDisplayStore(),
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, ['end', 'notAccepted']),
    endWord() {
      let text = this.$trans.choice('search.end', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    endClasses() {
      let selectedEnd = this.end;
      let round = this.round;
      let list = {};
      let vm = this;
      let array = [...Array(round.ends_count).keys()];
      array.forEach((_, end) => {
        let btn = 'btn-';
        if (selectedEnd !== end + 1) btn += 'outline-';
        let scoresForEnd = 0;
        let competitorCount = 0;
        Object.values(this.filteredCompetitors).forEach(function (bale) {
          Object.values(bale).forEach(function (competitor) {
            competitorCount++;
            scoresForEnd += vm.tournament.scoreList[competitor].rounds[round.id].ends[end + 1].complete;
          })
        });
        let finished = competitorCount * this.round.arrows_count;
        if (scoresForEnd > 0) {
          btn += scoresForEnd >= finished ? 'blue-800' : 'gray-400';
        } else {
          btn += 'gray-400';
        }
        list[end + 1] = btn;
      });
      return list;
    },
  },
  methods: {
    updateEnd(end) {
      if (this.notAccepted.length) {
        Swal.fire('Scores must be accepted or denied before switching end');
      } else {
        this.end = end;
      }
    },
  }
}
</script>

<style scoped>
.endList :deep(.dropdown-item) {
  @apply px-8 leading-loose text-2xl text-center;
}
</style>
