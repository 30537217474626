<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {Field} from "vee-validate";
import {onMounted, ref} from "vue";
import {loadStripe} from "@stripe/stripe-js";
import FormCheckbox from "../../tournaments/form/FormCheckbox.vue";

const store = useCompetitorStore();

const props = defineProps(['stripeKey'])
const stripePromise = store.clubTakesPayments ? loadStripe(props.stripeKey) : null;


const elements = ref(null);
const card = ref(null);
const stripe = ref(null);


const registerTapped = () => {
  if (store.clubTakesPayments) {
    this.handleStripe();
  } else {
    store.submitForm();
  }
}

const handleStripe = () => {
  return stripe.value.createToken(card.value).then(({token, error}) => {
    if (error) {
      throw new Error(error.message);
    } else {
      store.stripeToken = token;
      store.submitForm();
    }
  })
};

onMounted(async () => {
  if (store.clubTakesPayments) {
    stripe.value = await loadStripe(props.stripeKey);
    elements.value = stripe.value.elements();
    card.value = elements.value.create('card');
    card.value.mount('#card-element');
  }
})


</script>

<template>
  <div>
    <div class="bs-card">
      <div class="bs-card-header">Payment Information</div>
      <div>
        <FormCheckbox :active="store.payOffline"
                      @change="store.payOffline = !store.payOffline"
                      sub-title="By checking this box, you are agreeing to pay the amount outlined above prior to competing in the tournament."
                      title="Make payment offline"/>
        <div v-if="!store.payOffline" id="card-element">
        
        </div>
      </div>
      <!-- //TODO: add this if I can figure out how to style my own stripe form-->
      <!-- <div v-if="false" class="flex">-->
      <!--        <div class="flex-4">-->
      <!--          <label for="card number" class="bs-section-header">Card number</label>-->
      <!--          <Field name="card number" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--        <div class="flex-2 mx-4">-->
      <!--          <label for="expiration date" class="bs-section-header">Expiration date</label>-->
      <!--          <Field name="expiration date" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--        <div class="flex-1">-->
      <!--          <label for="cvv" class="bs-section-header">CVV</label>-->
      <!--          <Field name="cvv" class="bs-input-box"/>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="flex justify-between">
      <div v-if="store.payOffline || store.clubTakesPayments">
        <button @click.prevent="registerTapped" class="btn btn-black ml-2">
          Register <span v-if="store.loading" class="fa fa-spinner fa-spin"></span>
        </button>
      </div>
      <div>
        <div v-if="store.error" class="errorText">
          <span v-html="store.error"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.errorText {
  white-space: pre-wrap;
}
</style>