<template>
  <div class="flex flex-wrap">
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pr-4 mb-2">
      <label for="start time">Start Time</label>
      <flat-pickr
        class="bs-form-box w-full"
        v-model="tournament.start_time"
        name="start time"
        id="start time"
        v-validate="'required'"
        :config="startTimeOptions"
        @on-change="onStartChange"/>
      <span class="text-sm text-red-600">{{ errors.first('start time') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pl-4 mb-2">
      <label for="end time">End Time</label>
      <flat-pickr
        class="bs-form-box w-full"
        name="end time"
        id="end time"
        v-model="tournament.end_time"
        v-validate="'required'"
        :config="endTimeOptions"
        @on-change="onEndChange"/>
      <span class="text-sm text-red-600">{{ errors.first('end time') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pr-4 mb-2">
      <label for="registration open">Registration Opens</label>
      <flat-pickr
        class="bs-form-box w-full"
        v-model="tournament.reg_open"
        v-validate="'required'"
        name="registration open"
        id="registration open"
        :config="regOpenOptions"
        @on-change="onOpenChange"/>
      <span class="text-sm text-red-600">{{ errors.first('registration open') }}</span>
    
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pl-4 mb-2">
      <label for="registration close">Registration Closes</label>
      <flat-pickr
        class="bs-form-box w-full"
        name="registration close"
        id="registration close"
        v-validate="'required'"
        v-model="tournament.reg_close"
        :config="regCloseOptions"/>
      <span class="text-sm text-red-600">{{ errors.first('registration close') }}</span>
    </div>
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pr-4 mb-2">
      <div class="flex justify-between">
        <label>Line Times </label>
        <div v-if="tournament.is_league">
          <div class="flex justify-end">
            <div>
              <button @click.prevent="showLeagueHelper = !showLeagueHelper"
                      class="btn-sm btn-outline-blue-600 whitespace-nowrap" id="line_times">
                {{ showLeagueHelper ? "Add Times Manually" : "Use League Helper" }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <line-time-helper
        @submit="submitGenerated"
        v-if="tournament.is_league && showLeagueHelper"
        :line-time-options="lineTimeOptions"/>
      <div v-else>
        <div class="mb-1 flex flex-col w-full" tabindex="0" v-for="(t, index) in tournament.line_times">
          <div class="bs-form-box flex p-0 pl-2 w-full ">
            <div class=" whitespace-nowrap flex-grow">
              <div class="whitespace-nowrap flex w-full pt-2">
                <div class="focus-within:z-100 focus-within:absolute" tabindex="0">
                  <flat-pickr
                    class="flex-grow"
                    v-model="tournament.line_times[index].time"
                    v-validate="'required'"
                    :name="'time ' + (index + 1)"
                    :altInput="true"
                    :config="lineTimeOptions"
                    @on-change="$emit('updateRoundTimes', t)"/>
                </div>
                <div class="text-gray-600 flex w-0">
                  <input v-if="editMemo === index" @blur="finishEditing(index)"
                         type="text"
                         @keydown.enter="$event.stopPropagation()"
                         :name="'memo_'+(index + 1)" autofocus
                         v-model="tournament.line_times[index].memo"
                         placeholder="EX: Elementary Only"
                         maxlength="35"
                         v-validate="'clean|json|max:30'"
                         class="w-96">
                  <span v-else @click="editMemo = index">
                    <span v-if="tournament.line_times[index].memo">
                      {{ tournament.line_times[index].memo }}
                    </span>
                    <span @click="editMemo = true" v-else>add optional memo</span>
                  </span>
                </div>
              </div>
            </div>
            
            <span v-if="tournament.line_times.length > 1">
              <div @click="$emit('removeTime', index)" class="btn btn-outline-gray-600">
                <i class="fas fa-trash"></i>
              </div>
            </span>
            <span v-else>
              <div class="">
                <i class=""></i>
              </div>
            </span>
          </div>
          <span class="text-sm text-red-600">{{ errors.first('time ' + (index + 1)) }}</span>
          <span class="text-sm text-red-600">{{ errors.first('memo_' + (index + 1)) }}</span>
        </div>
        <div class="flex flex-col-reverse md:flex-row md:justify-between">
          <div class="text-sm text-gray-600 mr-3">
            If all competitors start at the same time leave this field as the starting time for the tournament.
          </div>
          <div class="flex justify-end">
            <div>
              <button @click.prevent="addTime()" class="btn-sm btn-outline-blue-600 whitespace-nowrap">
                Add Time
              </button>
            </div>
          </div>
        </div>
      
      </div>
    </div>
    
    <div class="flex flex-col w-full md:w-1/2 mt-4 px-1 md:pl-4 mb-2">
      <label for="time_zone">Timezone </label>
      <multiselect
        v-model="tournament.timezone"
        :options="timezones"
        :searchable="true"
        :placeholder="'Begin typing to filter list'"
        name="timezone"
        id="time_zone"
        :show-labels="false"
        class="multiselect_blue"/>
      <div v-if="tournament.line_times.length > 1" class="formElement">
        <form-switch
          @toggled="tournament.hide_times = !tournament.hide_times"
          :model="tournament.hide_times"
          :id="'hide times'"
          :label="'Hide Times From Registration'"
          :sub-label="'Competitors will not be able to choose their time when registering. If automatic assignments are not enabled the competitor will need to be manually assigned after registration.\n If this option is not selected, competitors will request their starting line time upon registration.'"/>
      </div>
    </div>
  </div>
</template>

<script>
import LineTimeHelper from "../league/LineTimeHelper.vue";
import moment from "moment/moment";
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "TournamentTime",
  components: {FormSwitch, LineTimeHelper},
  props: ['tournament', 'lineTimeOptions', 'startTimeOptions', 'endTimeOptions', 'regOpenOptions', 'regCloseOptions', 'timezones'],
  data() {
    return {
      editMemo: '',
      showLeagueHelper: !('id' in this.tournament),
    }
  },
  methods: {
    submitGenerated(generated) {
      let list = [];
      for (let index = 0; index < generated.length; index++) {
        let timeString = generated[index];
        let m = moment(new Date(timeString));
        let item = {
          'id': index + 1,
          'time': timeString,
          'memo': m.format('dddd h:mm A'),
        };
        list.push(item);
        if (index === generated.length - 1) {
          m.add(2, 'hours');
          let endTime = m.format('D MMM YYYY h:mm A');
          this.tournament.end_time = endTime;
          this.$emit('onEndChange', endTime);
        }
      }
      this.tournament.line_times = list;
      
      for (let item of list) {
        this.$emit('updateRoundTimes', item);
      }
      this.showLeagueHelper = false;
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    finishEditing(index) {
      if (!this.errors.first('memo_' + (index + 1))) {
        this.editMemo = '';
      }
    },
    addTime() {
      if (this.editMemo === '') {
        this.$emit('addTime')
      }
    },
    onStartChange(selectedDates, dateStr, instance) {
      this.$emit('onStartChange', dateStr);
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.$emit('onEndChange', dateStr);
    },
    onOpenChange(selectedDates, dateStr, instance) {
      this.$emit('onOpenChange', dateStr);
    },
    
  }
}
</script>

<style scoped>
.multiselect_blue :deep( .multiselect__tag),
.multiselect_blue :deep(.multiselect__option--highlight) ,
.multiselect_blue :deep(.multiselect__tag-icon:hover)  {
  @apply bg-blue-600;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 0;
}
</style>
