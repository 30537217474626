import axios from 'axios';

const state = {
  device: null,
};

const getters = {
  getDevice(state) {
    return state.device;
  },
  getDeviceId(state) {
    return state.device ? state.device.device_id : null;
  },
  verify: (state) => (tournamentId, clubId, allowClub) => {
    if (state.device == null) return false;
    let tournament = state.device.tournaments.indexOf(tournamentId) !== -1;
    let club = state.device.clubs.indexOf(clubId) !== -1;
    if (allowClub) {
      return tournament || club;
    }
    return tournament;
  }
};
const actions = {
  setDevice({commit}) {
    let token = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
    axios.post('/device/store', {
      'hash': token,
    }).then(({data}) => {
      commit("SET", data.device);
    }).catch(({response}) => {
      console.log(response);
    });
  },
  setName({state, commit}, name) {
    commit("SET_NAME", name)
  },
  async updateAuthorizations({state, commit}) {
    axios.get('/device', {
      params: {
        'device_id': state.device.device_id,
      }
    }).then(async ({data}) => {
      await commit("SET", data.device);
    }).catch(({response}) => {
      console.log(response);
    });

  }
};
const mutations = {
  SET(state, device) {
    state.device = device
  },
  SET_NAME(state, name) {
    state.device.name = name;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
