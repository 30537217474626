<template>
  <tournament-form
    v-if="inc_tournament"
    :inc_tournament="inc_tournament"
    :club="club"
    :sport-list="sportList"
    :target-list="targetList"/>
</template>

<script>
import TournamentForm from "./TournamentFormOld.vue";

export default {
  name: "TournamentFormBlank",
  components: {TournamentForm},
  props: {
    inc_tournament: Object,
    inc_user: Object,
    club: Object,
    sportList: Object,
    targetList: Object,
    lang: Object
  },
  
}
</script>

<style scoped>

</style>
