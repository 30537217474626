<template>
  <div>
    <h2 class="text-2xl">Payment</h2>
    <hr>
    <div class="flex justify-center pt-2">
      <div class="w-full sm:w-1/2" ref="card-details">
        <div class="flex">
          <div class="w-16">Price:</div>
          <div>${{ standardPrice }}.00</div>
        </div>
        <input type="hidden" name="payment" :value="maxPayment">
        <div v-if="tournament.payments.pricing_extras && tournament.payments.pricing_extras.length">
          <input type="hidden" name="pricing_extras" :value="JSON.stringify(pricing_extras)">
          <div class="flex">
            <div class="w-16">Extras:</div>
            <div>
              <div v-for="(extra, index) in tournament.payments.pricing_extras">
                <input type="checkbox" v-model="pricing_extras" :value="extra" :disabled="restrictions.includes(index)">
                <label class="ml-1">
                  <span class="flex">
                    {{ extra.name }} ( {{ extra.type }}${{ extra.amount }}.00 )
                    <image-modal v-if="extra.imageUrl !== undefined && extra.enabled" :image="extra.imageUrl"/>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="!tournament.payments.require_down_pay_at_register" class="flex border-t py-2">
            <div class="w-28">Adjusted Price:</div>
            <div>${{ maxPayment }}.00 <span v-if="feeMessage"> (processing fee)</span></div>
          </div>
        </div>

        <div v-if="tournament.payments.require_down_pay_at_register" class="form-group">
          <label>Payment Amount</label>
          <div class="flex bs-form-box p-0 mb-2">
            <div class=" bg-gray-200 px-3 flex-extralight h-center border-2">$</div>
            <input class="pl-2 flex-grow"
                   type="number"
                   v-model="paymentAmount"
                   min="0"
                   v-validate="'required|min_value:' + minPayment + '|max_value:' + maxPayment"
                   name="payment"
                   required>
            <div class=" bg-gray-200 h-center px-2 flex-extralight border-2">.00</div>
          </div>
          <div class="text-sm text-red-600 w-full">{{ errors.first('payment') }}</div>
          <span v-if="tournament.payments.require_down_pay_at_register">
            <small class="text-gray-600">Minimum Down Payment: ${{ minPayment }}.00</small>
            <br>
            <small class="text-gray-600">Full Payment: ${{ maxPayment }}.00</small>
          </span>
        </div>

        <div class="w-full mb-2" v-show="!offlinePayment">
          <div v-if="useAuthNet">
            <div class="bs-form-box" id="authNetCard">
              <input class="w-1/3 focus:outline-none" maxlength="16" type="text" name="cardNumber"
                     id="cardNumber" placeholder="Card number"/>
              <div class="text-right float-right w-2/3">
                <input class="w-1/4 justify-end text-right focus:outline-none" type="text"
                       name="expMonth" id="expMonth" maxlength="2" placeholder="MM"/>{{ ' / ' }}
                <input class="w-1/4 justify-end focus:outline-none" maxlength="4" type="text"
                       name="expYear" id="expYear" placeholder="YYYY"/>
                <input class="w-1/4 justify-end text-right focus:outline-none" maxlength="4" type="text"
                       name="cardCode" id="cardCode" placeholder="CVV"/>
              </div>
            </div>
            <input type="hidden" name="authNetToken" id="authNetToken"/>
            <input type="hidden" name="authNetDescriptor" id="authNetDescriptor"/>
          </div>
          <div v-else>
            <div ref="card" class="bs-form-box flex flex-col justify-center"></div>
          </div>
        </div>
        <div v-if="tournament.payments.allow_offline_payments">
          <form-switch
              class="w-full"
              @toggled="toggleOffline"
              :model="offlinePayment"
              :id="'offlinePayment'"
              :label="'Pay Offline'"
              :subLabel="offlineLabel"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

/**
 * @typedef {object} payments
 * @property {integer} down_payment_amount
 */
import ModalComponent from "../partials/ModalComponent.vue";
import FormSwitch from "../partials/FormSwitch.vue";
import ImageModal from "../partials/ImageModal.vue";

export default {
  name: "CompetitorPaymentOld",
  components: {ImageModal, FormSwitch, ModalComponent},
  props: ['tournament', 'restrictions'],
  data() {
    return {
      offlinePayment: false,
      pricing_extras: [],
      paymentAmount: this.maxPayment,
      feeMessage: false,
      processingAmount: this.tournament.payments.processing_fee ? this.tournament.payments.processing_fee + 1 : 0,
      offlineLabel: 'This tournament accepts payments offline. By selecting this option you are accepting the responsibility of handling payment outside of Bowscore.'
    }
  },
  watch: {
    restrictions(newVal) {
      newVal.forEach((index) => {
        let extra = this.tournament.payments.pricing_extras[index];
        let exists = this.pricing_extras.indexOf(extra)
        if (exists === -1) {
          this.pricing_extras.push(extra);
        }
      })
    }
  },
  computed: {
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    minPayment() {
      if (this.tournament.payments.require_down_pay_at_register) {
        return this.tournament.payments.down_payment_amount + this.tournament.payments.processing_fee;
      }
      return this.maxPayment;
    },
    standardPrice() {
      return this.tournament.payments.price + this.tournament.payments.processing_fee;
    },
    maxPayment() {
      let amount = this.standardPrice;
      this.pricing_extras.forEach(extra => {
        if (extra.type === '+') {
          amount += parseInt(extra.amount)
        } else {
          amount -= parseInt(extra.amount)
        }
      });
      this.feeMessage = amount < this.processingAmount;
      return amount < this.processingAmount ? this.processingAmount : amount;
    },
    disabled() {
      return this.tournament.payments.require_full_pay_at_register === 1
    },
  },
  methods: {
    toggleOffline() {
      this.offlinePayment = !this.offlinePayment;
      this.$emit('toggleOffline')
    },
  },
}
</script>

<style scoped>

</style>
