<template>
    <div class="xs:w-full md:w-50 lg:w-1/3 xl:w-1/4 mx-auto my-2 p-3">
        <div class="text-3xl">Sign up for our mailing list</div>
        <div>
            <form @submit.prevent action="/mailer" method="POST" id="notificationForm">
                <input type="hidden" name="_token" :value="csrf">
                <div class="flex flex-col">
                    <div class="flex flex-col  px-1 mt-2 md:mt-0">
                        <label for="name">Name </label>
                        <input v-validate="'required|min:3|max:25'"
                               type="text" name="name" class="bs-form-box" required v-model="name">
                        <span class="text-sm text-red-600">{{ errors.first('name')}}</span>
                    </div>
                    <div class="flex flex-col px-1 mt-2 md:mt-0">
                        <label for="name">Email </label>
                        <input v-validate="'required|min:3|max:40|email'"
                               type="text" name="email" class="bs-form-box" required v-model="email">
                        <span class="text-sm text-red-600">{{ errors.first('email')}}</span>
                    </div>

                    <div class="mt-2 flex">
                        <input type="checkbox" class="mx-3 mt-2"
                               name="accept" v-model="accept" v-validate="'required'">
                        <label class="" for="accept">
                            I wish to receive email notifications for Bowscore updates and features.
                        </label>
                    </div>
                    <span class="text-sm text-red-600">{{ errors.first('accept')}}</span>

                    <div>
                        <button @click="submitForm" class="btn-sm btn-outline-gray-600 float-right">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotificationSignup",
        data() {
            return {
                name: '',
                email: '',
                accept: false,
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')

            }
        },
        methods: {
            submitForm() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        $('#notificationForm').submit();
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>