<template>
  <div>
    <div class="">
      {{ tournament.competitorList[competitor].name }}
    </div>
    <div class="flex flex-col"
         :class="i < (assignments.length - 1) ? 'border-b border-blue-700 border-dashed' : ''"
         v-for="(assignment, i) in assignments">
      <div v-if="assignment.approved">
        <div v-if="tournament.rounds.length > 1">
          Round: {{ Object.values(tournament.rounds).find(f => f.id === assignment.round_id).name }}
        </div>
        <div v-if="tournament.locations.length > 1">
          Location: {{ assignment.location ? assignment.location : 'not assigned' }}
        </div>
        <div v-if="tournament.line_times.length > 1">
          Line Time: {{ time(assignment.line_time) ? time(assignment.line_time) : 'not assigned' }}
        </div>
        Bale: {{ assignment.bale ? assignment.bale : 'not assigned' }}
      </div>
    
    </div>
  </div>
</template>

<script>
export default {
  name: "AssignmentDetails",
  props: ['tournament', 'competitor'],
  computed: {
    assignments() {
      return Object.values(this.tournament.assignments).filter(f => f.competitor_id === this.competitor)
    },
  },
  methods: {
    time(id) {
      let time = Object.values(this.tournament.line_times).find(f => {
        return f.id === parseInt(id)
      });
      return time ? time.time : null
    }
  }
}
</script>

<style scoped>

</style>
