<template>
  <div class="relative h-8">
    <div class="flex">
      <button v-show="!start" @click="start = true" class="bg-blue-700 text-blue-100 px-2 rounded"><i
        class="fas fa-plus"></i></button>
      <template v-if="start">
        <div>
          <button @click="clear" class="bg-blue-700 text-blue-100 px-2 rounded-l">
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div>
          <button @click="expand1 = !expand1" class="bg-blue-500 text-blue-100 rounded-l-none pl-2"
                  :class="[selected1 ? 'rounded-r-none pr-1' : 'pr-3']">
            {{ helpText }}{{ selected1 ? selected1.name + ' : ' : '' }}
            <span v-show="!expand1 && !selected1"><i class="ml-1 fas fa-caret-down"></i></span>
            <span v-show="expand1 && !selected1"><i class="ml-1 fas fa-caret-up"></i></span>
            <div v-if="expand1" class="border-t border-blue-100 pt-2 mt-1 text-left">
              <div v-for="option1 in nestedOptions"
                   @click.prevent="selected1 = option1, selected2 = null, helpText = null">{{ option1.name }}
              </div>
            </div>
          </button>
        </div>
        <div>
          <button v-if="selected1" @click="expand2 = !expand2"
                  class="rounded-l-none rounded-r text-blue-100 pl-1 pr-3"
                  :class="selected2 ? 'bg-blue-500' : 'bg-blue-700'">
            {{ selected2 ? selected2 : 'Select ' + selected1.name }}
            <span v-show="!expand2 && !selected2"><i class="ml-1 fas fa-caret-down"></i></span>
            <span v-show="expand2 && !selected2"><i class="ml-1 fas fa-caret-up"></i></span>
            <div v-if="expand2" class="border-t border-blue-100 pt-2 mt-1 text-left">
              <div v-for="option2 in selected1.options" @click.prevent="complete(option2)">{{ option2 }}</div>
            </div>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
    export default {
        name: "TwoChoiceSelector",
        props:['nestedOptions','identifier','settings', 'help_text', 'position'],
        data(){
            return {
                start: false,
                selected1: null,
                selected2: null,
                expand1: false,
                expand2: false,
                helpText: this.help_text ? this.help_text : 'Sort by ',
            }
        },
        methods:{
            complete(selected){
                this.selected2 = selected;
                this.$emit('selected',this.identifier, this.selected1.name, this.selected2, this.position);
            },
            clear(){
                this.start = false;
                this.selected1 = null;
                this.selected2 = null;
                this.helpText = 'Sort by ';
                this.$emit('clearSelection', this.identifier, this.position)
            }
        },
        mounted(){
            let index = this.settings.findIndex(f => f.identifier === this.identifier && f.position === this.position);
            if(index !== -1){
                this.start = true;
                let choiceName = this.settings[index].choice_1;
                let choiceIndex = this.nestedOptions.findIndex(f => f.name === choiceName);
                if(choiceIndex !== -1){
                    this.selected1 = this.nestedOptions[choiceIndex];
                }
                this.selected2 = this.settings[index].choice_2;
            }
        }
    }
</script>

<style scoped>

</style>