<template>
  <div>
    <div v-if="tournament.line_times.length > 1 && !tournament.hide_times" class="w-full flex flex-wrap my-4">
      <label class="w-1/2 sm:w-1/4 order-0" for="time">Requested Line Time</label>
      <div class="w-full sm:w-1/2 order-2 sm:order-1">
        <select class="bs-form-box w-full" name="line_time" v-validate="'required'" id="timeSelection"
                @change="setTime">
          <option :value="null">None Selected</option>
          <option v-for="time in availableTimes"
                  :value="time.time.id"
                  class="flex justify-between"
                  :disabled="time.available <= 0 && time.type === 'restricted'">
            {{ time.time.time }} {{ time.time.memo ? "(" + time.time.memo + ")" : '' }}
            <span v-if="time.available <= 0">&nbsp;&nbsp;&nbsp;{{ fullText(time) }} </span>
          </option>
        </select>
        <div class="text-sm text-gray-600">
          <div>
            Your selection will not always be accepted. A finalized time will be chosen by the tournament director.
          </div>
          <div class="py-1">
            Restricted times are based off your selections from step 1. If the selected time is marked as full or
            full-restricted you will be added to the wait list. If a time is marked as full-restricted, it may still
            have space but not for your selection.
          </div>
          <div>
            If no times appear in the dropdown list, have the tournament director update the assignment settings.
          </div>
        </div>
      </div>
      <div class="text-right text-red-600 w-1/2 sm:w-1/4 float-right order-1 sm:order-3"> Required</div>
    </div>
    <div v-if="showTargets" class="w-full flex flex-wrap my-4">
      <label class="w-1/2 sm:w-1/4 order-0" for="target">Target</label>
      <div class="w-full sm:w-1/2 order-2 sm:order-1">
        <select class="bs-form-box w-full" name="target_id">
          <option v-for="target in tournament.targets" required :value="target.id"
                  v-if="restrictions.includes(parseInt(target.id)) || !restrictions.length">
            {{ target.name }}
          </option>
        </select>
        <span class="text-sm text-gray-600">
          This will be your default target. You may choose a different target during the tournament while at
          any {{ this.$trans.choice('search.bale', 1) }}.
        </span>
      </div>
      <div class="text-right text-red-600 w-1/2 sm:w-1/4 float-right order-1 sm:order-3">
        Required
      </div>
    </div>
  </div>

</template>

<script>
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";

export default {
  name: "TournamentOptions",
  props: ['tournament', 'restrictions', 'inc_competitor'],
  data() {
    return {
      noTime: null,
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'timeWaitList',
      'restrictedDetail'
    ]),
    showTargets() {
      if (this.tournament.hide_targets) return false;
      return (this.tournament.targets || {}).length > 1;
    },
    
    availableTimes() {
      let roundId = this.tournament.rounds[0]['id'];
      let timeList = [];
      let restrictDetails = this.restrictedDetail;
      if (restrictDetails == null) return timeList;
      for (let time of this.tournament.rounds[0]['line_times']) {
        let timeData = restrictDetails[time['id']];
        if (timeData !== undefined && timeData !== null) {
          if (!timeData.restrictions) {
            timeList.push(this.noRestrictions(roundId, time));
            continue;
          }
          timeList.push(this.locationRestrictions(timeData.locations, time, roundId));
        }
      }
      return timeList;
    },
  },
  methods: {
    fullText(time) {
      let text = 'Full';
      if (time.type !== 'full') text = time.restrictions;
      if (time.full_restricted) text = 'Full - Restricted';
      return `*** ${text.toUpperCase()} ***`;
    },
    baleRestrictions(locationData, time, roundId) {
      let restrictions = [];
      let waitList = false;
      for (let baleIndex in locationData.bales) {
        let baleData = locationData.bales[baleIndex];
        if (!baleData.restrictions) {
          // location restriction failed?
          if (!baleData.allowed) continue;
          let assignedCount = this.assignedCount(roundId, time['id'], locationData['location'], baleIndex);
          let available = this.tournament.bale_competitors;
          let space = available - assignedCount;
          if (space > 0) return {'available': true};
        } else {
          if (baleData.allowed) {
            //There are restrictions, but the archer matches
            let assignedCount = this.assignedCount(roundId, time['id'], locationData['location'], baleIndex);
            let available = this.tournament.bale_competitors;
            let space = available - assignedCount;
            if (space > 0) {
              return {'available': true};
            } else {
              waitList = true;
            }
          } else {
            restrictions.push(baleData.list);
          }
        }
      }
      return {'available': false, 'restrictions': restrictions, 'waitList': waitList};
    },
    locationRestrictions(locations, time, roundId) {
      let restricted = [];
      let restrictions = [];
      let availability = [];
      let waitList = false;
      for (let locData of locations) {
        if (locData.restrictions) {
          if (locData.allowed) {
            let baleData = this.baleRestrictions(locData, time, roundId);
            if (baleData['available']) {
              return {'time': time, 'available': 1, 'type': 'full'};
            } else {
              if (baleData['waitList']) {
                waitList = true;
                availability.push(false);
                continue;
              } else if (baleData['restrictions'].length) {
                restricted.push(true);
                restrictions = [...restrictions, ...baleData['restrictions']];
                continue;
              }
            }
            let assignedCount = this.assignedCount(roundId, time['id'], locData['location']);
            let available = this.totalSpots(this.tournament.rounds[0], locData['location']);
            let space = available - assignedCount;
            if (space > 0) {
              return {'time': time, 'available': space, 'type': 'full'};
            } else {
              availability.push(false);
            }
          } else {
            restricted.push(true);
            restrictions.push(locData.list);
          }
        } else {
          let baleData = this.baleRestrictions(locData, time, roundId);
          if (baleData['available']) {
            return {'time': time, 'available': 1, 'type': 'full'};
          } else {
            if (baleData['waitList']) {
              waitList = true;
              availability.push(false);
            } else if (baleData['restrictions'].length) {
              restricted.push(true);
              restrictions = [...restrictions, ...baleData['restrictions']];
            }
          }
        }
      }
      if (restricted.length === 0 || restricted.includes(false)) {
        return {
          'time': time,
          'available': availability.length ? 0 : 1,
          'type': 'full',
          'full_restricted': waitList,
        };
      } else {
        let string = this.restrictionString(restrictions)
        return {'time': time, 'available': 0, 'type': 'restricted', 'restrictions': string};
      }
    },
    restrictionString(restrictions) {
      let restrictionsList = {};
      for (let r of restrictions) {
        for (let restriction of r) {
          let split = restriction.split(': ');
          let name = split[0];
          if (!(name in restrictionsList)) restrictionsList[name] = [];
          restrictionsList[name].push(split[1])
        }
      }
      let string = '';
      for (let key of Object.keys(restrictionsList)) {
        string += key + ': ';
        let set = [...new Set(restrictionsList[key])];
        string += set.join(',') + ' ';
      }
      return string;
    },
    noRestrictions(roundId, time) {
      let assignedCount = this.assignedCount(roundId, time['id']);
      let available = this.totalSpots(this.tournament.rounds[0]);
      return {'type': 'full', 'available': available - assignedCount, 'time': time};
    },
    assignedCount(roundId, timeId, locId, bale) {
      let nested = this.tournament.assignedCompetitors.rounds[roundId].line_times[timeId];
      if (locId) nested = nested.locations[locId];
      if (bale) {
        let intBale = parseInt(bale) + 1;
        return nested.bales[intBale].competitors.length;
      }
      return nested.competitors_count;
    },
    totalSpots(round, locationId) {
      let count = 0;
      round.locations.forEach(function (location) {
        if (!locationId || locationId === location.id) {
          count += Object.keys(location.bales).length;
        }
      });
      return (count * this.tournament.bale_competitors)
    },
    setTime() {
      let timeId = document.getElementById("timeSelection").value;
      if (timeId.length) {
        let time = this.availableTimes.find((time) => {
          return time.time.id.toString() === timeId;
        });
        if (time && time.available <= 0) {
          this.timeWaitList = true;
          return;
        }
      }
      this.timeWaitList = false;
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
  },
}
</script>

<style scoped>

</style>
