<template>
    <tr v-if="device">
        <td>
            <div class="h-center">
                <div class="flex">
                    <input @change="toggleAuthorize" type="checkbox" class="switch-sm"
                           :id="'active_' + device.id"
                           v-model="authorized">
                    <div class="px-2">
                        {{authorized ? 'Yes' : 'No'}}
                    </div>
                </div>
            </div>
        </td>
        <td>
            {{device.name ? device.name : 'none'}}
        </td>
        <td>
            {{device.device_id}}
        </td>
        <td>
            <span v-show="!expiration" class="text-grey-light">not authorized</span>
            <div v-if="expiration" class="flex flex-col">
                <span @click="expInput = !expInput">{{formatTime(expiration)}}</span>
                <flat-pickr
                        v-show="expInput"
                        class="form-control"
                        v-model="expiration"
                        :config="expirationOptions"
                        @on-close="updateExpiration"
                ></flat-pickr>
            </div>

        </td>
    </tr>
</template>

<script>
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';

    export default {
        name: "DeviceDetails",
        props: ['inc_device'],
        data() {
            return {
                device: this.inc_device,
                expiration: this.formatTime(this.inc_device.pivot.expiration),
                expInput: false,
                expireTimeOptions: {},
                authorized: this.checkAuthorized(),
                expirationOptions: {
                    dateFormat: 'd M Y h:i K',
                    enableTime: true,
                    minuteIncrement: 15,
                    minDate: new Date(),
                    allowInput: true,
                    static: true,
                    plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})]
                }
            }
        },
        methods: {
            toggleAuthorize() {
                this.device.pivot.authorized = !this.device.pivot.authorized;
                this.expiration = this.device.pivot.authorized ? this.formatTime(this.device.pivot.expiration) : null;
                this.updateDevice();
            },
            updateExpiration() {
                this.device.pivot.expiration = this.expiration;
                this.updateDevice();
            },
            updateDevice() {
                axios.post('/device/update', {
                    'device': this.device,
                })
                    .then(({data}) => {
                        if (data['error']) {
                            Swal.fire(data['error'])
                        }
                        if (data['success']) {
                            this.expInput = false;
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            formatTime(time) {
                return time ? moment(time).format('D MMM YYYY h:mm A') : null;
            },
            setExpireTimeOptions() {
                this.expireTimeOptions = {
                    format: 'D MMM YYYY hh:mm A',
                    stepping: 15,
                    showClose: true,
                    showClear: true,
                    minDate: new Date(),
                    maxDate: moment().add(100, 'y'),
                    date: moment(this.expiration)
                };
            },
            checkAuthorized() {
                return this.inc_device.pivot.authorized && (moment(this.inc_device.pivot.expiration) > moment())
            },
        },
        mounted() {
            this.setExpireTimeOptions();
        }
    }
</script>

<style scoped>
    td {
        white-space: nowrap;
    }
</style>
