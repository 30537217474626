<script setup>
import {useDashboardStore} from "../../../stores/DashboardStore";
import {onMounted, ref} from "vue";
import axios from 'axios';
import CardSettings from "./CardSettings.vue";

const store = useDashboardStore();


let tournaments = ref([]);

const loading = ref(true);


onMounted(() => {
  axios.get('/dashboard/tournaments').then(({data}) => {
    if (data['success']) {
      tournaments.value = data['list'];
    }
    loading.value = false;
  })
})

</script>

<template>
  <div class="bs-shadow-card cardStyle">
    <card-settings name="tournaments"/>
    <div class="flex justify-between">
      <div class="w-full">
        <div class="bs-card-header">Tournaments you manage</div>
        <div v-if="loading" class="flex justify-center">
          <i class="fa-regular fa-4x fa-spinner fa-spin"/>
        </div>
        <div v-else>
          <div v-for="tournament of tournaments" class="tournamentRow sm:flex-row">
            <a :href="`/tournaments/${tournament.slug}`" class="label">{{ tournament.name }}</a>
            <div class="text">{{ tournament.spots }} spots left</div>
            <div class="text">{{ store.formattedAddress(tournament) }}</div>
            <div class="text">{{ store.formattedDate(tournament) }}</div>
            <a :href="`/clubs/${tournament.club.slug}`" class="text">{{ tournament.club.name }}</a>
          </div>
          <a :href="'/dashboard/all_tournaments'" class="bs-shadow-card block mt-6">View All</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardStyle {
  @apply w-full relative mb-4;
  padding-top: 32px;
  padding-bottom: 38px;
}

.tournamentRow {
  @apply mb-4 flex flex-col;
  cursor: pointer;
}

.label {
  @apply font-bold text-black pr-1;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
  flex: 2;
}

.text {
  @apply font-medium text-stone-400 pr-1;
  flex: 1;
  font-size: 16px;
  line-height: 19px;
}
</style>