<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import FormCheckbox from "./FormCheckbox.vue";
import moment from "moment";
import {computed} from "vue";

const store = useTournamentStore();

const maxDate = computed(() => {
  if (store.endDate != null) {
    let endDate = new Date(store.endDate);
    return moment(endDate);
  }
  let dateStart = new Date(store.startDate);
  let start = moment(dateStart);
  let end = start.add(1, 'day').subtract(1, "minute");
  return end;
})

const validTime = (time) => {
  let datetime = new Date(time);
  let momentTime = moment(datetime);
  let dateStart = new Date(store.startDate);
  let start = moment(dateStart).add(1, 'minute');
  if (momentTime.isBetween(start, maxDate.value)) return null;
  return false;
}

const formatStartDate = (time) => {
  let dateStart = new Date(time);
  let start = moment(dateStart).startOf('day');
  store.startDate = start.toDate();
}

const closed = (times) => {
  store.sortTimes(times);
  store.touched();
}


</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Date & Time</div>
    <div>
      <div class="bs-section-header">Time Zone</div>
      <select class="bs-input-box mb-4" v-model="store.timezone">
        <option v-for="option in store.timezoneOptions" :selected="option === store.timezone">{{ option }}</option>
      </select>
    </div>
    <div class="flex flex-col sm:flex-row">
      <div class="flex-1 mb-4 sm:mb-0">
        <div class="bs-section-header">Event date</div>
        <VueDatePicker :auto-apply="true" :enable-time-picker="false" :max-date="store.multiDay ? store.endDate : null"
                       :model-value="store.startDate"
                       @update:model-value="formatStartDate"
                       @date-update="store.touched()"
                       :clearable="false"
                       :timezone="store.timezone">
          <template #input-icon>
            <div class="flex justify-center ml-3">
              <i class="fa-regular fa-lg fa-calendar-range text-stone-400"></i>
            </div>
          </template>
        </VueDatePicker>
        <VueDatePicker class="mt-3" v-if="store.multiDay" :auto-apply="true" :enable-time-picker="false"
                       :min-date="moment(new Date(store.startDate)).add(1, 'day').toDate()"
                       :timezone="store.timezone"
                       @date-update="store.touched()"
                       :clearable="false"
                       :state="store.endDate === null ? false : null"
                       v-model="store.endDate" :required="true">
          <template #input-icon>
            <div class="flex justify-center ml-3">
              <i class="fa-regular fa-lg fa-calendar-range text-stone-400"></i>
            </div>
          </template>
        </VueDatePicker>
        <form-checkbox class="ml-2 mt-3 lighter" :active="store.multiDay"
                       @click="store.toggleMultiDay"
                       title="Multi-day tournament"/>
      </div>
      <div class="w-4"></div>
      <div class="flex-1">
        <div class="bs-section-header">Start time</div>
        <div v-for="(time, i) in store.times">
          <VueDatePicker class="mb-3" :is24="false"
                         :min-date="store.startDate"
                         :max-date="maxDate.toDate()"
                         :ignore-time-validation="true"
                         :key="`Line Time ${i}`"
                         @closed="closed(store.times)"
                         v-model="store.times[i]['time']"
                         :timezone="store.timezone"
                         minutes-increment="15"
                         time-picker-inline
                         :state="validTime(store.times[i]['time'])"
                         @cleared="store.removeTime(i)">
            <template #input-icon>
              <div class="flex justify-center ml-3">
                <i class="fa-regular fa-lg fa-clock-nine text-stone-400"></i>
              </div>
            </template>
          </VueDatePicker>
        </div>
        <button @click.prevent="store.addTime" class="lighter ml-2">
          <i class="far fa-plus"></i> Add line times
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.lighter {
  @apply font-medium text-stone-500;
  letter-spacing: 0.018rem;
  line-height: 17px;
}

</style>