<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps(['tournamentIn'])


const text = computed(() => props.tournamentIn.description ?? '');
const textElement = ref(null);
const isOverflowing = ref(false);

let expanded = ref(false);
const checkOverflow = () => {
  const el = textElement.value;
  if (el) {
    isOverflowing.value = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;
  }
};

onMounted(() => {
  checkOverflow();
});

watch(text, () => {
  checkOverflow();
});
</script>

<template>
  <div v-if="tournamentIn.description != null" class="bs-card">
    <div class="bs-card-header">Description</div>
    <div class="description" ref="textElement" :style="`min-height: 50px; max-height:${expanded ? null : '200px'};`">
      {{ tournamentIn.description }}
    </div>
    <div v-if="isOverflowing" @click="expanded = !expanded" class="text-center mt-4">
      <i :class="`fa-regular fa-xl fa-${expanded ? 'angle-up' : 'angle-down'}`"></i>
    </div>
  </div>
</template>

<style scoped>

.description {
  overflow: hidden;
}

</style>