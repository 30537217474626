<template>
    <div class="">
        <div class="flex justify-around flex-wrap pb-0 mb-0 w-full">
            <div class="m-2" v-for="arrow in round.arrows_count">
                <div class="text-center">
                    Arrow {{arrow}}
                </div>
                <div v-if="target_values" class="w-full flex flex-wrap p-3">
                    <div class="w-1/3 p-0" v-for="target in target_values">
                        <div @click="updateScoreForArrow(arrow,target)" class="btn"
                             :class="classForScore(arrow, target)">
                            {{target.name}}
                        </div>
                    </div>
                    <div class="w-full p-0 mt-1">
                        <div @click="clearScore(arrow)" class=" btn-sm btn-outline-gray-600 ">
                            clear
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-between pb-2 px-2">
            <div @click="close" class="btn btn-outline-gray-600">{{notAccepted.length ? 'deny' : 'close'}}</div>
            <div v-if="tournament.accept_button && !tournament.accept_end" @click="acceptScores"
                 class="btn btn-outline-blue-600">accept
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "ScoringRow",
        props: ['tournament', 'end', 'inc_competitor', 'round', 'lineTime', 'location', 'bale', 'endNotAccepted', 'acceptAll', 'verified'],
        data() {
            return {
                device: this.verified ? 'logged_in' : 'not_verified',
                notAccepted: [],
                replace: [],
                blocked: false,
                notAcceptedEnd: [],
                competitor: this.inc_competitor,
            }
        },
        computed: {
            online() {
                return this.$store.state.tournament.online;
            },
            scores() {
                if (this.notAccepted.length || this.notAcceptedEnd.length) {
                    let scores = [];
                    this.competitor.scores.forEach(f => scores.push(f));
                    this.notAccepted.forEach(f => scores.push(f));
                    this.notAcceptedEnd.forEach(f => scores.push(f));
                    return scores;
                }
                return this.competitor.scores;
            },
            user() {
                return this.$store.state.user.user;
            },
            target_values() {
                let target = this.tournament.targetValues.find(f => f.id === this.competitor.target_id);
                return target.values;
            }
        },

        mounted() {
            this.$localForage.getItem(this.tournament.id + '_device').then((device) => {
                if (device) {
                    this.device = device;
                }
            });
        },
        watch: {
            end(newValue, oldValue) {
                if (this.notAccepted.length && !this.blocked) {
                    Swal.fire('Scores must be accepted or denied before switching ' + this.$trans.choice('search.end', 1));
                    this.$emit('updateEnd', oldValue);
                    this.blocked = true;
                } else if (this.blocked) {
                    this.blocked = false;
                }
            },
            round(newValue, oldValue) {
                if (this.notAccepted.length && !this.blocked) {
                    Swal.fire('Scores must be accepted or denied before switching rounds');
                    this.$emit('updateRound', oldValue);
                    this.blocked = true;
                } else if (this.blocked) {
                    this.blocked = false;
                }
            },
            endNotAccepted(newValue, oldValue) {
                if (!newValue.length) {
                    this.notAcceptedEnd = [];
                }
            },
            acceptAll(newValue, oldValue) {
                if (newValue) {
                    this.competitor.scores = this.competitor.scores.concat(this.notAcceptedEnd);
                }
            },
        },
        methods: {
            updateScoreForArrow(arrow, target) {
                let beginningScoreCount = this.scores.filter(s => s.round === this.round.id && s.end === this.end).length;
                let newScore = {
                    tournament_id: this.tournament.id,
                    tournament_name: this.tournament.name,
                    competitor_id: this.competitor.id,
                    device: this.device,
                    uploader_id: this.user ? this.user.id : null,
                    competitor_name: this.competitor.name,
                    class: this.getCompetitorClass(),
                    division: this.getCompetitorDivision(),
                    line_time: this.lineTime.time,
                    location: this.location.name,
                    arrow: arrow,
                    target_hit: target.name,
                    score: target.value,
                    round: this.round.id,
                    round_name: this.round.name,
                    end: parseInt(this.end),
                    authorized: this.verified,
                    created_at: moment().format(),
                };
                if (!this.tournament.accept_button) {
                    this.$store.dispatch('tournament/updateScore', {
                        'score': newScore,
                        'lineTime': this.lineTime,
                        'location': this.location,
                        'bale': this.bale
                    }).then(() => {
                        if (beginningScoreCount !== this.round.arrows_count &&
                            this.scores.filter(s => s.round === this.round.id && s.end === this.end).length === this.round.arrows_count) {
                            this.$emit('acceptScores');
                        }
                    });
                } else {
                    let vm = this;
                    if (!this.tournament.accept_end) {
                        //compare against not accepted
                        let index = vm.notAccepted.findIndex(f => f.arrow === arrow && f.end === parseInt(vm.end) && f.round === vm.round.id);
                        if (index !== -1) {
                            vm.notAccepted.splice(index, 1);
                        }
                        //compare against saved scores
                        let i = vm.competitor.scores.findIndex(f => f.arrow === arrow && f.end === parseInt(vm.end) && f.round === vm.round.id);
                        if (i !== -1) {
                            vm.replace.push(vm.scores[i]);
                            vm.competitor.scores.splice(i, 1);
                        }

                        vm.notAccepted.push(newScore);
                        this.$emit('scoring')
                    } else {
                        //compare against not accepted
                        let index = vm.notAcceptedEnd.findIndex(f => f.arrow === arrow && f.end === parseInt(vm.end) && f.round === vm.round.id && f.competitor_id === newScore.competitor_id);
                        if (index !== -1) {
                            vm.notAcceptedEnd.splice(index, 1);
                        }
                        //compare against saved scores
                        let i = vm.competitor.scores.findIndex(f => f.arrow === arrow && f.end === parseInt(vm.end) && f.round === vm.round.id && f.competitor_id === newScore.competitor_id);
                        if (i !== -1) {
                            vm.replace.push(vm.scores[i]);
                            vm.competitor.scores.splice(i, 1);
                        }
                        vm.notAcceptedEnd.push(newScore);
                        this.$emit('notAccepted', newScore);
                    }

                }
            },
            clearScore(arrow) {
                if (this.tournament.accept_end) {
                    console.log('clear this');
                    let index = this.notAcceptedEnd.findIndex(f => f.arrow === arrow);
                    if (index !== -1) {
                        this.notAcceptedEnd.splice(index, 1);
                    }
                    this.$emit('clear', arrow, this.end, this.competitor.id)
                }
                if (this.notAccepted.length) {
                    let index = this.notAccepted.findIndex(f => f.arrow === arrow);
                    if (index !== -1) {
                        this.notAccepted.splice(index, 1);
                    }
                }
                this.$store.dispatch('tournament/clearScore', {
                    'round': this.round,
                    'lineTime': this.lineTime,
                    'location': this.location,
                    'bale': this.bale,
                    'competitor': this.competitor,
                    'arrow': arrow,
                    'end': this.end
                });
            },
            classForScore(arrow, target) {
                let foundScore = this.scores.find(f => f.arrow === arrow && f.end === this.end && f.target_hit === target.name && f.round === this.round.id)
                if (foundScore) {
                    return 'btn-blue-600';
                }

                return 'btn-outline-blue-600';
            },
            getCompetitorClass() {
                let detail = this.competitor.regform.find(r => r.option === 'class');
                return detail ? detail.name : null
            },
            getCompetitorDivision() {
                let detail = this.competitor.regform.find(r => r.option === 'division');
                return detail ? detail.multiple : null
            },
            cancelScores() {
                this.notAccepted = [];
                if (this.replace.length) {
                    this.competitor.scores = this.scores.concat(this.replace);
                    this.replace = [];
                }
                this.$emit('endScoring');
            },
            close() {
                this.cancelScores();
                this.$emit('hideScoring');
            },
            acceptScores() {
                let score_count = this.scores.filter(s => s.round === this.round.id && s.end === this.end).length;
                if (score_count < this.round.arrows_count) {
                    Swal.fire({
                        titleText: 'Accept Scores?',
                        html: this.competitor.name + ' has only scored ' + score_count + ' of ' + this.round.arrows_count + ' arrows for this end.',
                        showCancelButton: true,
                        confirmButtonText: 'Approve',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.value) {
                            if (this.notAccepted.length) {
                                this.$store.dispatch('tournament/updateScores', {
                                    'scores': this.notAccepted,
                                    'lineTime': this.lineTime,
                                    'location': this.location,
                                    'bale': this.bale
                                }).then(() => {
                                    this.$emit('acceptScores')
                                });
                            }

                        }
                    });
                } else {
                    if (this.notAccepted.length) {
                        this.$store.dispatch('tournament/updateScores', {
                            'scores': this.notAccepted,
                            'lineTime': this.lineTime,
                            'location': this.location,
                            'bale': this.bale
                        }).then(() => {
                            this.competitor.scores = this.competitor.scores.concat(this.notAccepted);
                            this.notAccepted = [];
                            this.$emit('endScoring');
                            this.$emit('acceptScores')

                        });
                    }
                }

            }

        },
    }
</script>

<style scoped>
    .btn-blue-600 {
        @apply bg-blue-600 text-blue-100;
    }

    .btn-outline-blue-600 {
        @apply border-blue-600 text-blue-600;

    }

    .btn-outline-blue-600:hover {
        @apply bg-blue-600 text-blue-100;

    }
</style>