<template>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
      <tr>
        <th class="table-header text-left">Name</th>
        <th class="table-header ">{{ rounds.length > 1 ? 'Round' : 'Total' }} Score</th>
        <th v-for="breaker in tieBreakers" class="table-header"> {{ breakerHeader(breaker) }}</th>
        <th class="table-header" v-for="end in round.ends_count"> {{ endHeader(end)}}</th>
      </tr>
      </thead>
      <tbody>
      <competitor-score
        v-for="competitor in filteredCompetitors"
        :competitor="competitors[competitor]"
        :scores="tournamentIn.scoreList[competitor]"
        :round="round"
        :key="'regular_results' + round.id + competitor"
        :place="placeList['overall'].indexOf(competitor) + 1"
        :images="tournamentIn.scoreImages[competitor]"
        :duplicates="store.competitorDuplicates(competitor)"/>
      <tr v-if="!filteredCompetitors.length" class="text-center">
        <td class="table-empty" colspan="100"> No matching results</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import CompetitorScore from "./CompetitorScore.vue";
import {useResultsStore} from "../../../stores/ResultsStore";
import {mapState} from "pinia";

export default {
  name: "RoundResultsTable",
  components: {CompetitorScore},
  props: ['tournamentIn', 'filteredCompetitors', 'competitors', 'placeList'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  computed: {
    ...mapState(useResultsStore, [
      'tieBreakers',
      'rounds',
      'round'
    ]),
  },
  methods: {
    breakerHeader(breaker) {
      let roundWord = this.rounds.length > 1 ? 'Round' : 'Total';
      return `${roundWord} ${breaker}'s`;
    },
    endHeader(end) {
      let endWord = this.$trans.choice('search.end', 1);
      let text = `${endWord} ${end}`;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  }
}
</script>
<style scoped>

</style>