<script setup>
import {useCompetitorStore} from "../../../stores/competitorStore";

const store = useCompetitorStore();
</script>

<template>
  <div v-if="store.purchases.length" class="bs-card">
    <div class="bs-section-header">Optional items</div>
    <div class="flex flex-wrap">
      <div v-for="extra of store.purchases" class="mr-2 mb-2">
        <button @click.prevent="store.purchaseTapped(extra)" class="bs-input-box"
                :class="store.selectedPurchases.includes(extra) ? 'bg-black text-white' : ''">
          {{ extra.name }} ${{ extra.amount }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>