<template>
  <div class="w-full md:w-1/2 mb-2 pr-0 md:pr-2">
    <div class="card card-blue-700">
      <div class="card-header">
        Find a User
      </div>
      <div class="card-body">
        <div class="bs-form-box w-full py-0 flex">
          <span class="h-center"><i class="fas fa-search"></i></span>
          <input type="text" class="flex-grow pl-2" v-model="search"
                 placeholder="Search by name or email">
          <span class="h-center" v-if="search.length" @click="search = ''">
            <i class="fas fa-times"></i>
          </span>
        </div>
        
        <div v-if="users && users.length">
          <div class="text-center bg-blue-100 rounded p-2">Click a row to login as that user</div>
          <table class="m-2 w-full">
            <tr class="border-b">
              <th>Name</th>
              <th>Email</th>
              <th>ID</th>
              <th>Actions</th>
            </tr>
            <tr v-for="user in users" @click="login(user)" class="cursor-pointer">
              <td>{{ user.first_name }} {{ user.last_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.id }}</td>
              <td>
                <button @click.stop="resetPassword(user)" class="btn-sm btn-outline-red-500">
                  Reset PW
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FindUsers",
  data() {
    return {
      search: '',
      users: [],
    }
  },
  watch: {
    search(newVal) {
      if (newVal.length > 2) {
        this.$axios.get(`/godmode/find`, {params: {search: this.search}})
        .then(({data}) => {
          this.users = data.users;
        })
      }
    }
  },
  methods: {
    login(user) {
      window.location.href = `/godmode/login/${user.id}`;
    },
    resetPassword(user) {
      window.location.href = `/godmode/reset_password/${user.id}`;
    }
  }
  
}
</script>

<style scoped>
td, th {
  @apply mr-3
}

</style>
