<template>
    <div class="p-2">
        <h1 class="xxs:hidden">Sponsor Theme List</h1>
        <hr>
        <div v-if="sponsors.length" class="table-responsive">
            <table class="table table-bordered table-striped">
                <thead>
                <tr>
                    <th class="table-header text-center">ID</th>
                    <th class="table-header">Name</th>
                    <th class="table-header text-center">Set</th>
                    <th class="table-header text-center">Edit</th>
                    <th class="table-header text-center">Delete</th>
                </tr>
                </thead>
                <tbody>
                <sponsor-row v-for="sponsor in sponsors" :sponsor="sponsor"
                             :key="sponsor.id"></sponsor-row>
                </tbody>


            </table>
        </div>
        <div v-else>
            There are no sponsors.
        </div>

        <div class="flex justify-between">
            <button class="btn btn-blue-700" @click="removeSponsor">Remove Set Sponsor</button>
            <a href="/sponsor_themes/create" class="btn btn-blue-700">Create a New Sponsor</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SponsorList",
        props: ['sponsors'],
        methods: {
            removeSponsor() {
                this.$store.commit('sponsor/REMOVE_SPONSOR', {
                    'sponsor': this.sponsor
                });
            }
        }
    }
</script>

<style scoped>

</style>
