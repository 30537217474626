import {defineStore} from "pinia";
import axios from 'axios';
import moment from "moment/moment";

export const useDashboardStore = defineStore('dashboard', {
  state: () => {
    return {
      order: [3, 1, 2, 4],
      keyNums: {
        'ProfileCard': 0,
        'RegisterCard': 1,
        'TournamentCard': 2,
        'CoachCard': 3,
        'AppCard': 4,
      },
      user: null,
      toggled: null,
    };
  },
  getters: {},
  actions: {
    toggle(name) {
      if (this.toggled === name) {
        this.toggled = null;
      } else {
        this.toggled = name;
      }
    },
    navigate(tournament) {
      window.location.href = `/tournaments/${tournament.slug}`;
    },
    formattedAddress(tournament) {
      let address = tournament.address;
      let city = address.city;
      let state = address.state;
      if (city != null && state != null) return `${city}, ${state}`;
      if (city != null) return city;
      if (state != null) return state;
      return '';
    },
    formattedDate(tournament) {
      const start = new Date(tournament.start_time);
      let momentStart = moment(start);
      const end = new Date(tournament.end_time);
      let momentEnd = moment(end);
      if (momentStart.date() === momentEnd.date()) {
        return momentStart.format('MMM D');
      }
      let dateString = momentStart.format('MMM D');
      if (momentStart.month() === momentEnd.month()) {
        return `${dateString}${momentEnd.format('-D')}`;
      } else if (momentStart.year() === momentEnd.year()) {
        return `${dateString}${momentEnd.format(' - MMM D')}`;
      }
      return `${momentStart.format('MMM D')}${momentEnd.format(' - MMM D')}`;
    },
    setup(user) {
      this.user = user;
      this.order = user.dashboard_order != null ? JSON.parse(user.dashboard_order) : this.initializeOrder();
    },
    initializeOrder() {
      let list = [];
      if (!this.user.archer.defaults.length) list.push(DashOrder.profile);
      if (this.user.competitors.length) {
        if (this.user.upcoming_tournaments.length) list.push(DashOrder.upcoming);
        if (this.user.past_tournaments.length) list.push(DashOrder.recent);
        list.push(DashOrder.recommended);
      } else {
        list.push(DashOrder.register);
      }
      if (this.user.clubs.length) {
        list.push(DashOrder.tournaments);
        list.push(DashOrder.clubs);
      } else {
        list.push(DashOrder.tournament);
      }
      if (this.user.managed_archers.length) {
        list.push(DashOrder.archers)
      } else {
        list.push(DashOrder.coach);
      }
      list.push(DashOrder.app);
      return list;
    },
    displayClass(key) {
      let index = this.order.indexOf(DashOrder[key]);
      if (index < 0) return 'hidden';
      return `order-${index + 1}`;
    },
    moveUp(key) {
      let index = this.order.indexOf(DashOrder[key]);
      if (index < 1) return;
      [this.order[index], this.order[index - 1]] = [this.order[index - 1], this.order[index]];
      this.update();
    },
    moveDown(key) {
      let index = this.order.indexOf(DashOrder[key]);
      if (index === -1 || index === this.order.length - 1) return;
      [this.order[index], this.order[index + 1]] = [this.order[index + 1], this.order[index]];
      this.update();
    },
    hide(key) {
      let index = this.order.indexOf(DashOrder[key]);
      this.order.splice(index, 1);
      this.update();
    },
    reset() {
      this.order = [...Array(DashOrder.app + 1).keys()];
      this.update();
    },
    update() {
      axios.post('/dashboard/order', {'list': this.order}).then(({data}) => {

      });
    }
  },
})


class DashOrder {
  static tournaments = 0;
  static profile = 1;
  static register = 2;
  static recent = 3;
  static upcoming = 4;
  static recommended = 5;
  static tournament = 6;
  static clubs = 7;
  static coach = 8;
  static groups = 9;
  static archers = 10;
  static app = 11;

}