<script setup>

import {ErrorMessage, Field} from "vee-validate";
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">E-Commerce</div>
    <div class="flex">
      <div class="flex-3 mr-4">
        <div class="bs-section-header">Product</div>
        <div v-for="(_, index) in store.products" class="mb-2">
          <Field class="bs-input-box" v-model="store.products[index].product" :name="`product_${index}`"
                 placeholder="Tournament t-shirt"/>
        </div>
      </div>
      <div class="flex-1">
        <div class="bs-section-header">Price</div>
        <div v-for="(_, index) in store.products" class="mb-2">
          <div class="flex">
            <div class="flex moneyContainer">
              <span class="dollarSign"><i class="fa-regular fa-lg fa-dollar-sign"></i></span>
              <Field class="bs-input-box money" v-model="store.products[index].price" :name="`price_${index}`"
                     type="number" min="0" step="1" placeholder="0"
                     rules="min_value:0"/>
              <span class="cents">.00</span>
            </div>
            <span v-if="store.products.length > 1"
                  @click="store.removeProduct(index)"
                  class="flex flex-col justify-center ml-2">
              <i class="fa-regular fa-trash-can"></i>
            </span>
          </div>
          <ErrorMessage class="errorText" :name="`price_${index}`"/>
        </div>
      </div>
    </div>
    <button @click.prevent="store.addProduct">
      <span class="mx-2"><i class="fa-regular fa-plus"></i></span>Add more items
    </button>
  </div>
</template>

<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.money {
  font-size: 16px !important;
  padding-right: 36px;
  text-align: right;
}

.moneyContainer {
  @apply text-stone-400;
  font-size: 16px !important;
  position: relative;
  display: inline-block;
  letter-spacing: .32px;
}

.cents {
  position: absolute;
  right: 13px;
  top: 5px;
}

.dollarSign {
  position: absolute;
  left: 10px;
  top: 6px;
}
</style>