<template>
  <div class="bs-form-box mb-2">
    <span class="badge">{{ 3 }}</span>
    <- This symbol shows how many times a competitor has had a score edited
    <tool-tip>
      <template v-slot:message>
        <div>
          <div class="text-2xl">Score Edits</div>
          <p class="pb-2 text-lg">
            All edited scores will show up here. By clicking on the <span class="badge">{{ 3 }}</span> symbol, you can
            see what score was edited, the values for the original and the edited score, and the time that score was
            entered.
          </p>
          <p class="text-lg">
            This feature is provided to help spot cheating as soon as it happens. Not all score edits are due to
            cheating.
          </p>
        </div>
      </template>
    </tool-tip>
  </div>
</template>
<script>
import ToolTip from "./ToolTip.vue";

export default {
  name: "BadgeInfo",
  components: {ToolTip}
}
</script>
<style scoped>
.badge {
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 900;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}
</style>