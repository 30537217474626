<template >
    <div>
        <div @click="toggleModal">
            <slot name="open-modal"></slot>
        </div>

        <div v-if="modal" @click.self="toggleModal"
             class="animated fadeIn fixed inset-0 z-50 overflow-auto bg-smoke-500 flex">
            <div class="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex shadow rounded mt-20 md:mt-16 overflow-auto">
                <!--// mb-24 add this class if there is a menu at the bottom that cuts off the modal. Without it it will be centered.-->
                <slot name="modal-title"></slot>
                <slot name="modal-body"></slot>
                <slot name="modal-footer"></slot>
                <span @click="toggleModal" id="closeModal" class="absolute top-0 right-0 pt-4 px-4">X</span>
            </div>
        </div>
    </div>
</template>


<script>
    export default {
        name: "ModalComponent",
        data() {
            return { modal: false }
        },
        methods: {
            toggleModal() {
                this.modal = !this.modal;
            }
        }
    }
</script>
