<template>
  <div>
    <div v-if="!isJeff"> This page is for Jeff only.</div>
    <div v-else>
      <div>
        <label for="sport">Sport</label>
        <select name="sport" id="sport" class="bs-form-box h-10" v-model="selectedSport">
          <option
            v-for="sport in templates"
            :selected="selectedSport"
            name="sport" :value="sport">{{ sport.name }}
          </option>
        </select>
        <button v-if="selectedSport != null" @click="update()" class="btn btn-blue-600">Update</button>
      </div>
      
      <div v-if="selectedSport != null" class="table-responsive">
        <table class="table table-striped table-responsive">
          <thead>
          <tr>
            <th class="table-header">ID</th>
            <th class="table-header">Label</th>
            <th class="table-header">Value</th>
            <th class="table-header"> Abbreviation</th>
            <th class="table-header">Type</th>
            <th class="table-header"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="option in options" class="">
            <td class="">{{ option.id }}</td>
            <td class="">
              <VueMultiselect
                v-model="option.label"
                :options="labels"
                :searchable="true"
                :show-labels="false"
                :taggable="true"
                :hideSelected="true"
                :placeholder="'Select Label'"
                :multiple="false"
                :resetAfter="false"
                @tag="addLabel"
                class="multiselect_blue"/>
            </td>
            <td class=""><input type="text" class="bs-form-box w-full" required v-model="option.value"></td>
            <td class="">
              <input type="text" class="bs-form-box flex-1" required v-model="option.abbreviation"
                     :placeholder="'none'">
            </td>
            <td class="">
              <select class="bs-form-box h-10 w-full" v-model="option.type">
                <option v-for="type in types" :selected="option.type" name="type" :value="type">{{ type }}</option>
              </select>
            </td>
            <td>
              <div class="flex justify-around w-full">
                <i @click="deleteOption(option)" class="fa-2x fa fa-trash"></i>
                <i @click="duplicateOption(option)" class="fa-2x fa fa-copy"></i>
              </div>
            </td>
          </tr>
          </tbody>
        
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TemplateEditor",
  props: ['user', 'templates'],
  data() {
    return {
      selectedSport: null,
      types: ['radio', 'date', 'text', 'checkbox'],
      labels: ['Class', 'Division', 'Age', 'Team/Club Name', 'Gender'],
      deleted: [],
      extra: [],
      temp: 0,
    }
  },
  methods: {
    deleteOption(option) {
      this.deleted.push(option.id);
    },
    duplicateOption(option) {
      let copy = {};
      copy['id'] = `${this.temp} temp`;
      copy['label'] = option['label'];
      copy['type'] = option['type'];
      copy['value'] = '';
      copy['abbreviation'] = '';
      this.temp++;
      this.extra.push(copy);
    },
    addLabel(value) {
      this.labels.push(value);
    },
    update() {
      this.$axios.post('/update_template', {sport: this.selectedSport, options: this.options}).then(({data}) => {
        debugger;
      })
    }
  },
  computed: {
    isJeff() {
      return this.user.id === 1;
    },
    options() {
      if (this.selectedSport == null) return [];
      let options = [...this.selectedSport.options, ...this.extra];
      options = options.filter((f) => !this.deleted.includes(f.id));
      options.sort((a, b) => {
          if (a.label < b.label) return -1;
          if (a.label > b.label) return 1;
          return 0;
        }
      )
      return options;
      
    }
  }
}
</script>
<style scoped>

</style>
