<template>
    <div class="flex flex-wrap">
        <div class="w-full md:w-2/3 lg:w-1/2 flex flex-col p-2">
            <div class="flex flex-col mb-3 lg:mb-8">
                <div class="uppercase text-lg font-bold border-b mr-10 flex">
                    Time
                    <div v-if="user && user.auth_manage" class="h-center">
                        <a :href="'/tournaments/' + tournament.slug + '#/manage/edit?tab=time'"
                           class="ml-2 text-gray-600 text-sm">
                            <i class="fas fa-pen"></i>
                        </a>
                    </div>
                </div>
                <div class="ml-2">
                    <tournament-detail-row :name="'Tournament Starts'" :value="startTime"></tournament-detail-row>
                    <tournament-detail-row :name="'Tournament Ends'" :value="endTime"></tournament-detail-row>
                    <tournament-detail-row :name="'Registration Opens'" :value="regOpen"></tournament-detail-row>
                    <tournament-detail-row :name="'Registration Closes'" :value="regClose"></tournament-detail-row>
                </div>
            </div>
            <div class="flex flex-col mb-3 lg:mb-8">
                <div class="uppercase text-lg font-bold border-b mr-10 flex">
                    Details
                    <div v-if="user && user.auth_manage" class="h-center">
                        <a :href="'/tournaments/' + tournament.slug + '#/manage/edit?tab=sport'"
                           class="ml-2 text-gray-600 text-sm">
                            <i class="fas fa-pen"></i>
                        </a>
                    </div>
                </div>
                <div class="ml-2">
                    <tournament-detail-row :name="'Sport'" :value="tournament.sport.name"></tournament-detail-row>
                    <tournament-detail-row :name="'Address'" :value="tournamentAddress"></tournament-detail-row>
                    <tournament-detail-row :name="tournament.line_times.length > 1 ? 'Line Times' : 'Line Time'"
                                           :value="''">
                        <div v-if="tournament.line_times.length > 1" v-for="line_time in tournament.line_times"
                             :key="line_time.id" class="ml-5"> {{ line_time.time }} {{
                                line_time.memo ? "(" +
                                    line_time.memo + ")" : ''
                            }}
                        </div>
                        <span v-else>{{ tournament.line_times[0].time }} {{
                                tournament.line_times[0].memo ? "(" +
                                    tournament.line_times[0].memo + ")" : ''
                            }}</span>
                    </tournament-detail-row>
                    <tournament-detail-row :name="(tournament.targets || {}).length > 1 ? 'Targets' : 'Target'"
                                           :value="''">
                        <div v-if="(tournament.targets || {}).length > 1" v-for="target in tournament.targets"
                             :key="target.id" class="ml-5"> {{ target.name }}
                        </div>
                        <span v-else>{{ tournament.targets[0].name }}</span>
                    </tournament-detail-row>
                    <tournament-detail-row v-if="tournament.rounds.length > 1" :name="'Rounds'" :value="''">
                        <div v-for="round in tournament.rounds" :key="round.id" class="ml-5"> {{ round.name }}</div>
                    </tournament-detail-row>
                </div>
            </div>


        </div>
        <div class="w-full md:w-1/3 lg:w-1/2 p-2">
            <div class="uppercase text-lg font-bold border-b mr-10 flex">
                Tournament Description
                <div v-if="user && user.auth_manage" class="h-center">
                    <a :href="'/tournaments/' + tournament.slug + '#/manage/edit?tab=info'"
                       class="ml-2 text-gray-600 text-sm">
                        <i class="fas fa-pen"></i>
                    </a>
                </div>
            </div>
            <pre style="font-family: 'Roboto', sans-serif;"
                 class="text-gray-700 whitespace-pre-wrap">{{ tournament.description }}</pre>
        </div>
    </div>
</template>

<script>
import moment from "moment/moment";

export default {
    name: "TournamentDetailList",
    props: ['tournament'],
    computed: {
        user() {
            return this.$store.state.user.user;
        },
        startTime() {
            let time = new Date(this.tournament.start_time);
            return moment(time).format('DD MMM YYYY h:mm A')
        },
        endTime() {
            let time = new Date(this.tournament.end_time);
            return moment(time).format('DD MMM YYYY h:mm A')
        },
        regOpen() {
            let time = new Date(this.tournament.reg_open);
            return moment(time).format('DD MMM YYYY h:mm A')
        },
        regClose() {
            let time = new Date(this.tournament.reg_close);
            return moment(time).format('DD MMM YYYY h:mm A')
        },
        tournamentAddress() {
            let address = '';
            address += this.tournament.address.address1 ? this.tournament.address.address1 : '';
            address += this.tournament.address.address2 ? ' ' + this.tournament.address.address2 : '';
            address += this.tournament.address.city ? ' ' + this.tournament.address.city : '';
            address += this.tournament.address.state ? ', ' + this.tournament.address.state : '';
            address += this.tournament.address.zip ? ' ' + this.tournament.address.zip : '';
            address += this.tournament.address.country ? ' ' + this.tournament.address.country : '';
            return address;
        },
        linkUrl() {
            return location.origin + '/tournaments/' + this.tournament.slug + '#/register'
        }
    },
    methods: {
        copyToClipboard() {
            var url = document.getElementById("urlHidden");
            url.setAttribute('type', 'text');
            url.select();
            document.execCommand('copy');
            url.setAttribute('type', 'hidden');
            Swal.fire('Link copied to clipboard')
        },
    }
}
</script>

<style scoped>
.shareIcon {
    margin-right: 5px;
}

.shareIcon.hover {
    cursor: pointer;
}
</style>
