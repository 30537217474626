<template>
    <div>
        <div class="bg-red-200 mt-3 p-3">
            <div class="bg-red-600 text-white rounded p-1">
                Authorize.net is currently being tested. All transactions are ran in sandbox mode and will not handle
                actual money. Do not use this feature on an actual tournament.
            </div>
            <div class="text-center text-xl">Authorize.net</div>
            <ul class="list-disc ml-5">
                <li>Using Authorize.net will require the Bowscore processing fee to be handled on the club level instead
                    of the
                    competitor level. Clubs will be sent an invoice to be paid within 2 weeks of the tournaments end.
                </li>
                <li>You must have a merchant account with authorize.net, then paste your key below</li>
            </ul>
            <input placeholder="Authorize.net Key" type="text" name="auth_net_key" class="bs-form-box w-full"
                   v-model="club.auth_net_key">
            <div class="flex justify-end">
                <transition name="fade" :duration="{ enter: 1800, leave: 800 }">
                    <span v-show="updatedMessage" class="text-xl text-blue-600 mr-2">updated</span>
                </transition>
                <button class="btn-sm btn-blue-600 mt-2" @click="saveKey">Save Key
                    <span v-if="loading"><i class="fas fa-spinner fa-spin"></i></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClubAuthnet",
    props: ['club', 'user'],
    data() {
        return {
            loading: false,
            updatedMessage: false,
        }
    },
    methods: {

        saveKey() {
            this.loading = true;
            this.$axios.post(`/clubs/${this.club.slug}/settings/auth_net_key`, {key: this.club.auth_net_key}).then((response) => {
                this.loading = false;
                if (response.data.success) {
                    this.updatedMessage = true;
                    setTimeout(this.hideUpdated, 1500);
                }
            })
        },
        hideUpdated() {
            this.updatedMessage = false;
        },

    }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 2s;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
</style>
