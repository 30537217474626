<template>
  <div class="flex flex-grow mb-4">
    <div class="flex w-full flex-col">
      <div class="text-gray-600">
        This is the competitor registration form template.<br>
        Sport default fields are provided by the
        {{ tournament.sport ? tournament.sport.name : 'selected' }} sport, which can be edited to fit your
        needs.<br>
        You may also add as many customizable fields as you like.<br>
        Fields may be re-ordered by clicking and dragging the <i class="mx-2 fas fa-grip-lines"></i> handle.
      </div>
      <draggable v-model="formDetail" handle=".handle">
        <form-detail
          v-for="(detail, form_index) in formDetail"
          :key="form_index + 1"
          :details="detail"
          :identifier="form_index + 1"
          @deleteField="deleteField"/>
      </draggable>
      <div class="flex justify-end mt-2">
        <div v-if="errorCount !== 0" class="text-red-600 text-sm">Please fix any errors before
          submitting. Errors: {{ errorCount }}
        </div>
      </div>
      <div v-if="tournament.regform && regform && formDetail">
        <div class="flex justify-center">
          <button @click.prevent="addClicked()" class="w-48 btn btn-gray-600">
            <i class="fas fa-plus mr-2"></i> Add Field
          </button>
        </div>
      </div>
      <div v-else class="flex justify-center">
        <div>
          <div>There was an error loading the registration form.</div>
          <div class="text-center">
            <button @click.prevent="regform = tournament.regform" class="btn btn-blue-600">
              Click here to reload form
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

import FormDetail from "./FormDetail.vue";

export default {
  name: "RegistrationForm",
  components: {FormDetail},
  props: {
    tournament: Object,
    regform: Array,
  },
  data() {
    return {
      formDetail: null,
      loading: false,
      existingLabels: [],
    }
  },
  watch: {
    regform(newVal, oldVal) {
      if (!oldVal || !oldVal.length) {
        this.formDetail = newVal
      }
    },
    formDetail(newVal) {
      this.tournament.regform = newVal;
    }
    
  },
  computed: {
    errorCount() {
      return this.$validator.errors.items.length
    }
  },
  methods: {
    addClicked() {
      Swal.fire({
        titleText: 'Choose the Field Type',
        input: 'select',
        inputOptions: {
          phone: 'Telephone',
          text: 'Single Line Text',
          textarea: 'Paragraph Text',
          checkbox: 'Multiple Selection',
          radio: 'Single Selection',
          selection: 'Dropdown',
          header: 'Section Header',
          waiver: 'Waiver',
          image: 'Image',
          date: 'Date',
        },
        inputPlaceholder: 'Select a type',
        showCancelButton: true,
        reverseButtons: true,
        animation: false,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (!value) {
              resolve('You need to select a field type')
            } else {
              resolve()
            }
          })
        }
      }).then(({value, dismiss}) => {
        if (!dismiss) {
          this.addField(value);
        }
      })
    },
    validate: function () {
      return new Promise(resolve => {
        resolve(!this.$validator.errors.items.length);
      })
    },
    deleteField(key) {
      Swal.fire({
        titleText: 'Delete ' + this.formDetail[key].name + '?',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.formDetail.splice(key, 1);
        }
      });
    },
    addField(type) {
      let field = {
        "id": null,
        "tournament_id": this.tournament.id ? this.tournament.id : null,
        "competitor_id": null,
        "option": "",
        "name": type.charAt(0).toUpperCase() + type.slice(1) + " Field",
        "multiple": null,
        "type": type,
        "description": "",
        "placeholder": "",
        "order": 1,
        "required": false,
        "enabled": true,
        "default": false,
        "sport_default": false,
        "deleted_at": null
      };
      switch (type) {
        case 'phone':
          field['type'] = 'text';
          field['name'] = 'Telephone';
          break;
        case 'waiver':
          field['name'] = 'Accept Waiver';
          field['description'] = 'This waiver must be accepted in order to register for the tournament.';
          field['multiple'] = [{"id": 1, "label": "accepted", "selected": false}];
          field['required'] = true;
          break;
        case 'checkbox':
        case 'radio':
        case 'selection':
          field['multiple'] = [{"id": 1, "label": "Option 1", "selected": false},
            {"id": 2, "label": "Option 2", "selected": false},
            {"id": 3, "label": "Option 3", "selected": false}];
          break;
        default:
          break;
      }
      
      this.formDetail.push(field);
    }
  },
  created() {
    if (this.tournament.regform) {
      this.formDetail = this.tournament.regform;
    }
  }
}
</script>

<style scoped>

</style>
