<template>
  <span class="-m-1 ml-1" @mouseover="hovering = true" @mouseout="hovering = false">
    <span class="text-blue-600 text-xs border-blue-600 border-2 p-1 rounded-full cursor-pointer
                 hover:bg-blue-600 hover:text-blue-100">
      <span @click="showVideo"><i class="fas fa-video"></i></span>
      <span v-if="hovering"> Video Tutorial </span>
    
    </span>
  </span>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "VideoTip",
  props: ['link', 'title', 'footer'],
  data() {
    return {
      displayVideo: false,
      hovering: false,
    }
  },
  methods: {
    showVideo() {
      let player = `<div class="${this.title ? '' : 'mt-10'} bg-blue-700 relative" style="padding-top:56.25%">
          <iframe
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              src="${this.link}"
              frameborder="0" allow="accelerometer; encrypted-media; gyroscope;"
              allowfullscreen></iframe>
        </div>`;
      Swal.fire({
        titleText: this.title,
        html: player,
        width: '600px',
        footer: this.footer,
        showConfirmButton: false,
        showCloseButton: true,
      })
    }
  }
}
</script>

<style scoped>

</style>
