<template>
  <div class="w-full sm:w-1/2 m-2">
    <div class="card card-blue-700 ">
      <div class="card-header">Drop Rounds</div>
      <div class="card-body flex flex-col">
        <label for="drop count" class="text-lg">Rounds to drop</label>
        <input
          class="bs-form-box mb-3"
          type="number"
          :max="tournament.rounds.length - 1"
          min="0"
          name="drop count"
          id="drop count"
          onkeydown="if(event.key==='.'){event.preventDefault();}"
          oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
          v-validate="'required'"
          v-model="dropRounds"
          @input="updateDropCount"
        >
        <span v-if="errors.first('drop count')" class="errorText">
          Number must be less than the number of rounds
        </span>
        <span class="hintText">
          This will ignore scores for the specified number of rounds in the final results calculation. The
          rounds with the lowest scores for each individual competitor will be dropped. Set this field to 0 to
          include all rounds.
        </span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "DropRounds",
  props: ['tournament'],
  data() {
    return {
      dropRounds: this.tournament.dropped_rounds,
    }
  },
  methods: {
    async updateDropCount() {
      let passed = await this.$validator.validate();
      if (passed) {
        await axios.post('/tournaments/' + this.tournament.slug + '/league', {
          'dropCount': this.dropRounds,
        });
      } else {
        console.log(`${this.dropRounds} failed`);
      }
    }
  }
}
</script>

<style scoped>

</style>
