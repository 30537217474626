<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Scoring Lock Code
    </div>
    <div class="card-body py-0">
      <div class="text-center text-2xl py-3">{{ code ? code : 'no code required' }}</div>
      <div class="px-2 pb-3 mx-3 mb-2 flex flex-col">
        <div class="flex">
          <input v-model="newCode" @change="updateCode($event.target.value)" type="text" class="bs-form-box flex-grow">
          <button class="btn btn-blue-600 ml-1" @click="generateCode()">Generate</button>
        </div>
        <div class="hintText my-2">
          Manually enter any code that you would like, or tap "Generate" to create a random 4 letter code
        </div>
        <div class="hintText mb-2">
          Use this code by clicking the <i class="fas fa-lock"></i> on the scoring page of any device to
          lock/unlock/edit scoring restrictions.
        </div>
        <div class="hintText">Codes are <span class="font-black">NOT</span> case sensitive</div>
        <div class="flex justify-end">
          <button @click="save()" class="btn btn-outline-gray-600 my-2">
            update code
            <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
          </button>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "LockCode",
  props: ['tournament'],
  data() {
    return {
      code: this.tournament.lock_code,
      newCode: this.tournament.lock_code,
      loading: false,
    }
  },
  methods: {
    generateCode() {
      let length = 4;
      let letters = 'abcdefghijklmnopqrstuvwxyz';
      let code = '';
      for (let i = 0; i < length; i++) {
        let index = Math.floor(Math.random() * (letters.length));
        code += letters[index];
      }
      this.updateCode(code);
    },
    updateCode(value) {
      this.newCode = value.toLowerCase();
    },
    save() {
      this.loading = true;
      this.$axios.post('/tournaments/' + this.tournament.slug + '/manage', {
        'update': {lock_code: this.newCode}
      })
      .then(({data}) => {
        if (data.success) {
          this.code = this.newCode;
          this.loading = false;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>

</style>
