<script setup>

import FormCheckbox from "./FormCheckbox.vue";
import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Personal Information</div>
    <div class="flex textStyle">
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="true" title="Archer name" :disabled="true"/>
        <FormCheckbox @click.prevent class="bottomMargin" :active="true" :disabled="true" title="Email"/>
        <FormCheckbox :active="store.gender" title="Gender" @click="store.gender = !store.gender"/>
      </div>
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="store.phone" title="Phone number"
                      @click="store.phone = !store.phone"/>
        <FormCheckbox class="bottomMargin" :active="store.regCity" title="City"
                      @click="store.regCity = !store.regCity"/>
        <FormCheckbox :active="store.regState" title="State" @click="store.regState = !store.regState"/>
      </div>
      <div class="flex-1">
        <FormCheckbox class="bottomMargin" :active="store.country" title="Country"
                      @click="store.country = !store.country"/>
        <FormCheckbox :active="store.number" title="Membership number" @click="store.number = !store.number"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bottomMargin {
  margin-bottom: 16px;
}

.textStyle {
  @apply font-medium text-black;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
}
</style>