<template>
  <div class="w-full">
    <div v-if="onlineTournament" class="flex flex-wrap">
      <div class="p-1 w-full md:w-1/2">
        <lock-code :tournament="onlineTournament"/>
        <user-scoring :tournament="onlineTournament"/>
      </div>
      <div class="p-1 w-full md:w-1/2">
        <tournament-devices
          :tournament="onlineTournament.id"
          :club="onlineTournament.club"
          :inc_devices="onlineTournament.devices"/>
      </div>
      <div class="p-1 w-full">
        <quick-setup :tournament="onlineTournament"/>
      </div>
    </div>
    <div v-else class="text-center flex-grow py-32">
      <h1 class="mt-4 text-5xl">Loading Device Details</h1>
      <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
    </div>
  </div>

</template>

<script>
import UserScoring from "./UserScoring.vue";
import LockCode from "./LockCode.vue";
import TournamentDevices from "./TournamentDevices.vue";
import QuickSetup from "./QuickSetup.vue";

export default {
  name: "ManageDevices",
  components: {QuickSetup, TournamentDevices, LockCode, UserScoring},
  props: ['tournamentIn'],
  computed: {
    tournament() {
      return this.tournamentIn;
    },
  },
  data() {
    return {
      onlineTournament: null,
    }
  },
  mounted() {
    this.$axios
    .get('/tournaments/' + this.tournament.slug + '/devices')
    .then(({data}) => {
      this.onlineTournament = data.tournament;
    })
    .catch(({response}) => {
      console.log(response);
    });
    
  },
}
</script>

<style scoped>

</style>
