<script setup>

</script>

<template>
  <div class="bs-shadow-card position py-12 mx-4">
    <i class="fa-sharp fa-2x solid fa-quote-left text-stone-400 mb-8"></i>
    <div class="text mb-8">
      “The appeal of Bowscore for Next Arrow Archery was there are no subscription fees!! We hear a lot from our
      customers how they hate having to pay subscription fees for a tournament they may only shoot once or annually. We
      also love the feature to ‘CLONE’ a tournament!! It really assists me with time utilization making things <span
      class="pop">much more
      efficient</span>. Just simply Clone your event and edit the dates and or times. This is incredibly useful for
      repeat
      events or annual events. Our customers and our team members <span class="pop">love the “Quick Shoot”
      feature</span> for 1:1 or team
      practice matches. The Bowscore team are incredibly knowledgeable, helpful, accessible and patient!! We <span
      class="pop">highly
      recommend</span> the Bowscore app!”
    </div>
    <div class="signed">Steph Eastwood, Next Arrow Archery</div>
  </div>
</template>

<style scoped>
.pop {
  @apply font-bold;
  font-size: 32px;
  line-height: 36px;
  color: #FB5202;
}

.position {
  margin-top: 70px;
  margin-bottom: 360px;
}

.text {
  @apply text-stone-600 font-medium;
  font-size: 24px;
  line-height: 36px;
}

.signed {
  @apply text-stone-600 font-bold;
  font-size: 24px;
  line-height: 29px;
}

.bs-shadow-card {
  box-shadow: 0 12px 24px #00000029;
  border: 1px solid #E2E2E2;
  border-radius: 6px;
  padding: 56px;
}

@media (max-width: 1100px) {
  .bs-shadow-card {
    padding: 22px;
  }
  
  .pop {
    font-size: 26px;
  }
  
  .text {
    font-size: 20px;
    line-height: 29px;
  }
}

</style>