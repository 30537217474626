<template>
  <div>
    <modal-component>
      <template v-slot:open-modal>
        <div class="cursor-pointer">
          <slot name="open-modal">
            <!--            <img v-if="url" type="button" data-toggle="modal"-->
            <!--                 data-target="#cropper"-->
            <!--                 crossorigin="use-credentials"-->
            <!--                 :style="'width:200px;height:200px'"-->
            <!--                 :alt="`${option} Image`"-->
            <!--                 :src="enabled ? url : '/img/imagePending.jpg'"-->
            <!--                 data-backdrop="true" class="align-middle">-->
            <div class="text-center">
              <button @click.prevent class="btn btn-outline-blue-600">Upload Image</button>
            </div>
          </slot>
        </div>
      </template>
      <template v-slot:modal-body>
        <div>
          Editing / Uploading images is currently unavailable.
          <!--          <div class="flex flex-col" v-if="online">-->
          <!--          <div class="w-full">-->
          <!--            <div-->
          <!--              v-if="url && !enabled"-->
          <!--              class="text-center text-gray-600 m-2">-->
          <!--              This image has been flagged as possibly inappropriate. Bowscore admin have-->
          <!--              been notified and will enable the image upon successful review.-->
          <!--            </div>-->
          <!--            <div v-else class="text-center text-gray-600 m-2">{{ title }}</div>-->
          <!--            -->
          <!--            -->
          <!--            <div class="flex justify-center">-->
          <!--              <button @click="cropImage">Crop image</button>-->
          <!--              <cropper ref="cropper" class="example-cropper" :crossorigin="'use-credentials'"-->
          <!--                       :src="enabled ? url : '/img/imagePending.jpg'"/>-->
          <!--            -->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <template>-->
          <!--            <div class="flex justify-center text-gray-600 mb-2">-->
          <!--              Click and drag to change position. Use the scroll wheel or pinch to zoom.-->
          <!--            </div>-->
          <!--            -->
          <!--            <div class="flex justify-center mb-2">-->
          <!--              <button @click.prevent="saveImage()"-->
          <!--                      class="btn btn-outline-secondary">-->
          <!--                Upload-->
          <!--                <span v-show="loading"><i class="fas fa-spin fa-spinner"></i></span>-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          </template>-->
          <!--        </div>-->
          <!--        <div v-else>-->
          <!--          Images uploads are an online only feature. Please check your network connection and try again.-->
          <!--        </div>-->
          <!--        <div class="text-center bg-red-200 text-red-900">-->
          <!--          {{ errorMessage }}-->
          <!--          {{ tooSmall ? `Image is too small, it must be at least ${width}px wide and ${height}px high` : '' }}-->
          <!--        </div>-->
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import ModalComponent from "./ModalComponent.vue";
import Swal from "sweetalert2";
import moment from "moment";
import {Cropper} from "vue-advanced-cropper"

export default {
  name: "ImageUpload",
  components: {Cropper, ModalComponent},
  props: {
    inc_url: String,
    inc_enabled: {type: [Number, Boolean], default: 1},
    width: {type: Number, default: 400},
    height: {type: Number, default: 400},
    title: String,
    multiplier: {type: Number, default: 2},
    option: String,
    type: String,
    file_id: [String, Number],
    post_url: String,
    mimetype: {type: String, default: 'image/jpeg'}
  },
  data() {
    return {
      url: this.inc_url,
      enabled: this.inc_enabled,
      loading: false,
      myCroppa: null,
      errorMessage: null,
      
    }
  },
  computed: {
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    showUpload() {
      return this.myCroppa && this.myCroppa.hasImage() && !this.tooSmall;
    },
    tooSmall() {
      if (this.myCroppa && this.myCroppa.hasImage()) {
        let width = this.myCroppa.naturalWidth;
        let height = this.myCroppa.naturalHeight;
        if (height < this.height || width < this.width) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    cropImageOther() {
      const result = this.$refs.cropper.getResult();
      if (result) {
        const formData = new FormData();
        result.toBlob(async blob => {
          formData.append(
            "File",
            blob,
            "myImageName.png"
          );
          // add optional stuff like an path to save the image
          formData.append("path", "/theKoiIsAwesome");
          // You can use axios, fetch and other libraries instead here
          let config = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };
          let res = axios.post(`${ImageResource}`, formData, config);
          console.log("[save bg img]", result);
          // Perhaps you should add the setting appropriate file format here
        }, "image/png");
      }
    },
    
    removeImage() {
      Swal.fire({
        title: 'Delete Image?',
        icon: 'error',
        showCancelButton: true,
        reverseButtons: true,
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.$emit('remove_image', this.option)
          this.url = '';
          document.getElementById('closeModal').click();
        }
      })
      
    },
    imageError(error) {
      if (error === 'exceed') {
        this.errorMessage = 'That file is too large';
      } else if (error === 'mismatch') {
        this.errorMessage = 'Filetype not accepted';
      }
    },
    cropImage() {
      let vm = this;
      vm.loading = true;
      let url = this.post_url ? this.post_url : `/image/${vm.type}/update/${vm.file_id}`
      let suffix = this.mimetype === 'image/jpeg' ? 'jpg' : 'png';
      const {canvas} = this.$refs.cropper.getResult();
      if (canvas) {
        const formData = new FormData();
        canvas.toBlob(async blob => {
          formData.append(
            "image",
            blob,
            `${moment().unix()}_${vm.option}.${suffix}`
          );
          let config = {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          };
          vm.$axios.post(url, formData, config)
          .then(({data}) => {
            vm.url = data.path;
            vm.enabled = data.enabled;
            vm.myCroppa.remove();
            vm.loading = false;
            vm.$emit('update_image', [vm.url, vm.enabled, vm.option])
            document.getElementById('closeModal').click();
          })
          .catch(function (response) {
            if (response.response && response.response.data && response.response.data.message) {
              vm.errorMessage = response.response.data.message;
            } else {
              vm.errorMessage = response.message;
            }
            vm.loading = false;
            console.log(response);
          });
        }, vm.mimetype, .06);
      }
    },
  }
}
</script>

<style scoped>

</style>
