<script setup>
import {useCompetitorStore} from "../../../stores/competitorStore";

const store = useCompetitorStore();

const groupName = (group) => {
  return group === 'all' ? 'all' : group.name;
}
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Groups & Individuals</div>
    <div class="mb-4">
      <div class="bs-section-header">Your groups</div>
      <div class="flex flex-wrap">
        <div v-for="group of store.groups" class="mr-2">
          <button @click.prevent="store.groupToggled(group)" class="bs-input-box"
                  :class="store.selectedGroups.includes(group) ? 'bg-black text-white' : ''">
            {{ groupName(group) }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <div class="bs-section-header">Managed archers</div>
      <div class="flex flex-wrap">
        <div v-for="archer in store.managedArchers" class="mr-2 mb-2">
          <button @click.prevent="store.archerToggled(archer)" class="bs-input-box"
                  :class="store.selectedArchers.includes(archer) ? 'bg-black text-white' : ''">{{ archer.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>