<template>
  <div>
    <div v-if="loadingTournament" class="bg-yellow-400 flex justify-center py-2">
      <div>
        <span class="mr-2"><i class="fas fa-spin fa-spinner"></i></span>
        Loading latest tournament details. Any registration or scoring changes made during this process will be lost.
      </div>
    </div>
    <div v-if="tournament">
      <div v-if="locked && $route.name !== 'practiscore'"
           class="bg-blue-600 text-blue-100 flex justify-around p-1">
        <div class="flex flex-col justify-center">
          This device is locked for scoring.
        </div>
        <div @click="showLock = true" class="">
          <span><i class="fas fa-lock-alt fa-lg"></i></span>
        </div>
        <div class="flex flex-col justify-center">
          <div>
            <router-link to="/practice/score" class="text-blue-100">
              Click here to return to scoring.
            </router-link>
          </div>
        </div>
      </div>
      <lock-device
        v-if="showLock"
        @close="showLock = false"
        @unlock="unlockDevice"
        :tournament="tournament"
        :locked="locked"/>
      <div v-if="!expired">
        <div class="flex flex-col md:flex-col-reverse lg:flex-row ">
          <router-view
            @add-device="addDevice"
            @refresh="checkForNew"
            :sports="sports"
            :tournamentIn="tournament"
            :user="user"
            :updated="updated"
            class="mb-5 w-full"/>
        </div>
      </div>
      <div v-else class="p-5 text-center">
        <div class="text-xl">This practice tournament expired on {{ expiredDate }}.</div>
        <div>
          You may clone to create a new practice tournament with the same setup and competitors, but without any of the
          scores.
        </div>
        <div class="flex justify-around mt-16">
          <div class="flex flex-col">
            <a href="/practice/" class="btn btn-outline-gray-600">Create a new Practice Tournament</a>
          </div>
          
          <div class="flex flex-col">
            <new-tournament-button :user="user" :color="'gray-600'" :size="'btn'"/>
          </div>
          <div class="flex flex-col">
            <a :href="'/practice/' + inc_tournament.slug + '/clone'" class="btn btn-outline-gray-600">
              Clone {{ inc_tournament.name }}
            </a>
          </div>
        
        </div>
      </div>
    </div>
    <div v-else class="text-center py-32">
      <h1 class="mt-4">Loading Tournament Details</h1>
      <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
    </div>
  </div>


</template>

<script>
import NewTournamentButton from "../partials/NewTournamentButton.vue";
import LockDevice from "../scoring/lock/LockDevice.vue";
import moment from "moment";

export default {
  name: "PracticeSuite",
  components: {LockDevice, NewTournamentButton},
  props: ['inc_tournament', 'inc_user', 'inc_sports'],
  data() {
    return {
      locked: false,
      verified: true,
      device_id: null,
      onlineTournament: null,
      sports: this.inc_sports,
      updated: null,
      showLock: false,
      changed: '',
      loadingTournament: false,
    }
  },
  computed: {
    expired() {
      let created = moment(this.inc_tournament.created_at).add(7, 'd');
      return moment() - created > 0;
    },
    expiredDate() {
      return moment(this.inc_tournament.created_at).add(7, 'd').format('dddd, MMMM Do YYYY, h:mm:ss a')
    },
    tournament() {
      return this.$store.getters['tournament/getTournament'];
    },
    user() {
      return this.inc_user !== 0 ? this.$store.state.user.user : null;
    },
  },
  created() {
    if (this.inc_tournament.target_style) {
      let default_locale = this.inc_tournament.target_style === 'Bales' ? 'bow_bale' : 'bow_stake';
      this.$trans.locale = default_locale;
    }
    if (this.inc_tournament.slug) {
      this.locked = this.$store.getters['tournament/getLocked'](this.inc_tournament.slug)
    }
    this.afterCreate();
  },
  
  methods: {
    addDevice(id) {
      this.device_id = id;
    },
    afterCreate() {
      let vm = this;
      window.addEventListener('onunload', function () {
        vm.$store.dispatch('tournament/clearTournament');
      });
      this.$store.commit('user/set', {'user': this.inc_user});
      let stored = this.$store.getters['tournament/getTournament'];
      if (stored && stored.id === this.inc_tournament.id) {
        this.onlineTournament = stored;
        this.checkForNew()
      } else {
        //reset User Auth
        this.$localForage.getItem('allTournaments').then((allTournaments) => {
          if (allTournaments) {
            let existing = allTournaments.findIndex(f => f.id === this.inc_tournament.id);
            if (existing !== -1) {
              let tournament = allTournaments[existing];
              this.$store.commit('tournament/SET_TOURNAMENT', {
                'tournament': tournament
              });
              this.onlineTournament = tournament;
              this.checkForNew()
            } else {
              this.getOnlineTournament();
            }
          } else {
            this.getOnlineTournament();
          }
        })
      }
      if (this.inc_tournament.slug) {
        this.locked = this.$store.getters['tournament/getLocked'](this.inc_tournament.slug)
      }
    },
    checkForNew() {
      this.$axios.get('/tournaments/' + this.inc_tournament.slug + '/latest',
        {params: {'updated_at': this.onlineTournament.updated_at}}
      ).then(({data}) => {
        if (data.update) {
          this.loadingTournament = true;
          this.getOnlineTournament();
        } else {
          this.updated = new Date();
        }
      });
    },
    
    getOnlineTournament() {
      this.$axios.get('/tournaments/' + this.inc_tournament.slug + '/suite/tournament')
      .then(({data}) => {
        this.onlineTournament = data.tournament;
        this.$store.commit('tournament/SET_TOURNAMENT', {
          'tournament': this.onlineTournament
        });
        
        this.loadingTournament = false;
        this.updated = new Date();
      }).catch((error) => {
        this.loadingTournament = false;
        if (!this.onlineTournament) {
          this.loadingMessage = null;
        }
        console.log("axios error", error);
      });
    },
  }
}
</script>

<style scoped>

</style>
