<template>
  <div class="flex flex-wrap">
    <div class="flex flex-col w-full md:w-1/2 px-1 mt-4">
      <label for="name">Tournament Name</label>
      <input v-validate="'required|min:3|max:100|clean'"
             type="text" name="name" id="name" class="bs-form-box" required v-model="tournament.name">
      <span class="text-sm text-red-600">{{ errors.first('name') }}</span>
      <span class="text-sm text-gray-600"> This is the formal name of the tournament.</span>
    </div>
    <div class="flex flex-col w-1/2 md:w-1/4 mt-4 px-1">
      <label for="max competitors">Max # of Competitors</label>
      <input type="number" min="1" id="max competitors" name="max competitors" class="bs-form-box"
             v-validate="'required|min_value:1'" required v-model="tournament.max_competitors">
      <span class="text-sm text-red-600">{{ errors.first('max competitors') }}</span>
    </div>
    <div class="flex flex-col w-1/2 md:w-1/4 mt-4 px-1">
      <label :for="trans.choice('search.bale', 1)  + 'competitors'">
        Competitors per {{ baleWord }}
      </label>
      <input type="number" min="1" max="8" :name="trans.choice('search.bale', 1)  + 'competitors'"
             :id="trans.choice('search.bale', 1)  + 'competitors'"
             class="bs-form-box"
             v-validate="'required|min_value:1|max_value:8'" required
             v-model="tournament.bale_competitors">
      <span class="text-sm text-red-600">{{ errors.first(this.$trans.choice('search.bale', 1) + 'competitors') }}</span>
    </div>
    <div class="flex flex-col w-full mt-4 px-1">
      <label for="description">Tournament Description</label>
      <textarea v-model="tournament.description"
                name="description"
                id="description"
                class="bs-form-box h-48 sm:h-auto autoExpand"
                v-validate="'clean|max:65000|required'"></textarea>
      <div class="text-sm text-gray-600">
        Add any extra details, instructions, or policies for this tournament.
      </div>
      <span class="text-sm text-red-600">{{ errors.first('description') }}</span>
    </div>
    <div class="w-full flex flex-wrap mt-4 ">
      <div class="w-full text-xl">Tournament Settings
        <span v-if="!tournament.id" class="text-gray-600 text-sm">
          These can be changed at any time.</span>
      </div>
      <div class="flex flex-col w-full sm:w-1/2">
        <form-switch
          @toggled="tournament.hide = !tournament.hide"
          :model="tournament.hide"
          :id="'hideTournamentId'"
          :label="'Hide Tournament'"
          :sub-label="linkString">
        </form-switch>
        
        <form-switch
          @toggled="tournament.accept_button = !tournament.accept_button"
          :model="tournament.accept_button"
          :id="'acceptButton'"
          :label="'Accept Button'"
          :sub-label="'Disables automatic syncing and requires a score to be accepted before uploaded.'">
        </form-switch>
      </div>
      
      <div class="flex flex-col w-full sm:w-1/2">
        <form-switch
          @toggled="
                    tournament.auto_assignment = !tournament.auto_assignment"
          :model="tournament.auto_assignment"
          :id="'autoassignment'"
          :label="'Automatic Assignment'"
          :sub-label="'New competitors will be automatically assigned to a ' + this.$trans.choice('search.bale', 1) + '.'">
        </form-switch>
        <form-switch
          @toggled="tournament.self_assignment = !tournament.self_assignment"
          :model="tournament.self_assignment"
          :id="'selfassignment'"
          :label="'Self Assignment'"
          :sub-label="'Competitors can change their own ' + this.$trans.choice('search.bale', 1) + ' assignment.'">
        </form-switch>
      </div>
      
      <div class="flex flex-col w-full sm:w-1/2">
        <form-switch
          @toggled="tournament.club_devices = !tournament.club_devices"
          :model="tournament.club_devices"
          :id="'club_devices'"
          :label="'Allow Club Devices'"
          :sub-label="devicesString">
        </form-switch>
      </div>
      <div v-if="showPostal" class="flex flex-col w-full sm:w-1/2">
        <form-switch
          @toggled="tournament.is_master_postal = !tournament.is_master_postal"
          :model="tournament.is_master_postal"
          :id="'postal'"
          :label="'Host Postal'"
          :sub-label="'Postal tournaments allow archers from around the world to compete locally and be ' +
                      'scored globally. This tournament will just be the template, all registration and scoring will ' +
                      'be done on tournaments created with this template.'">
        </form-switch>
      </div>
      <div v-if="!('id' in this.tournament)" class="flex flex-col w-full sm:w-1/2">
        <form-switch
          @toggled="setLeague"
          :model="tournament.is_league"
          :id="'league'"
          :label="'League'"
          :sub-label="'Adds an option to drop specified number of rounds. Allows a competitor handicap to be added to results.'"
        />
      </div>
    </div>
    
    <cover-image
      :inc_tournament="tournament"
      :type="'tournament'"
      :inc_club="club"
      @updateImage="updateImage"
      class="w-full mt-4 px-1 mb-2"
    />
  
  </div>
</template>

<script>
import CoverImage from "../../partials/CoverImage.vue";
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "TournamentInfo",
  components: {FormSwitch, CoverImage},
  props: ['tournament', 'club'],
  computed: {
    baleWord() {
      let text = this.$trans.choice('search.bale', 1);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    showPostal() {
      return !this.tournament.master_postal_id && !this.tournament.competitors_count;
    },
    linkString() {
      let string = 'Tournament can still be accessed to anyone with <a href="/tournaments/';
      string += this.tournament.slug;
      string += '" class="text-blue-600 cursor-pointer" target="_blank"> this link</a>.';
      return string
    },
    devicesString() {
      let string = 'All devices that have been authorized for ' + this.club.name + ' will be able to score this tournament.';
      if (this.tournament.slug) {
        string += ' Tournament device authorization can be managed from <a href="/tournaments/';
        string += this.tournament.slug + '#/manage/devices';
        string += '" class="text-blue-600 cursor-pointer" target="_blank"> here</a>.';
      } else {
        string += ' Tournament device authorization will be available from the Tournament > Manage > Devices page once this tournament has been created.'
      }
      return string
    }
  },
  methods: {
    updateImage(details) {
      this.tournament.image = details[0];
      this.tournament.image_enabled = details[1];
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    setLeague() {
      this.tournament.is_league = !this.tournament.is_league;
      this.tournament.payments.processing_fee = this.tournament.is_league ? 5 : 2;
    }
  }
}
</script>

<style scoped>
label {
  @apply text-xl;
}
</style>
