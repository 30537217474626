<template>
  <div class="flex-grow sm:m-2">
    <div v-if="tournament.disable_registration" class="flex justify-center">
      Registration is disabled for this event
    </div>
    <div v-else-if="user && user.managed_archers && user.managed_archers.length">
      <!--      <form-wizard-->
      <!--        @on-complete="createCompetitors"-->
      <!--        :stepSize="'xs'"-->
      <!--        :color="'#2779bd'"-->
      <!--        :errorColor="'#ef5753'"-->
      <!--        :title="'Group Registration'"-->
      <!--        :subtitle="''">-->
      <!--        <tab-content title="Competitors" :before-change="updateArchers">-->
      <!--          <member-selection :tournament="tournamentIn" :user="user" @setCheckedNames="setCheckedNames"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content title="Details" :before-change="()=>validateStep('group-data')">-->
      <!--          <group-form-data-->
      <!--            ref="group-data"-->
      <!--            :tournament="tournamentIn"-->
      <!--            :checked-names="checkedNames"-->
      <!--            @setRestrictions="setUserRestrictions"/>-->
      <!--        </tab-content>-->
      <!--        <tab-content-->
      <!--          title="Payment"-->
      <!--          v-if="tournament.club.stripe || useAuthNet"-->
      <!--          :before-change="()=>validateStep('group-payment')">-->
      <!--          <group-payment-->
      <!--            ref="group-payment"-->
      <!--            @toggleOffline="offlinePayment = !offlinePayment"-->
      <!--            :tournament="tournament"-->
      <!--            :restrictions="userRestrictions"-->
      <!--            :archers="checkedNames"/>-->
      <!--        </tab-content>-->
      <!--        <template v-slot:finish>-->
      <!--          <div class="wizard-btn"-->
      <!--               style="background-color: rgb(39, 121, 189); border-color: rgb(39, 121, 189); color: white;">-->
      <!--            Register-->
      <!--            <span v-show="loading" class="ml-2">-->
      <!--              <i class="fas fa-spinner fa-spin"></i>-->
      <!--            </span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </form-wizard>-->
    </div>
    <div v-else class="text-center flex flex-col">
      <div class="text-3xl">Uh oh!</div>
      <div class="text-xl">It looks like you don't have anyone to manage.</div>
      <a v-if="user" :href="'/profile/' + user.slug + '/archers'" class="text-blue-500">
        Visit your manage page to add some archers
      </a>
      <div v-else>
        You must be logged in for this feature. <a href="/login">Log In</a>
      </div>
    
    </div>
  </div>
</template>

<script>
/**
 * @typedef {object} club
 * @property {string} auth_net_key
 */

/**
 * @typedef {object} stripe
 * @property {string} stripe_publishable_key
 * @property {function} createToken
 */
import MemberSelection from "./MemberSelection.vue";
import GroupFormDataOld from "./GroupFormDataOld.vue";
import GroupPaymentOld from "./GroupPaymentOld.vue";

export default {
  name: "RegisterGroup",
  components: {GroupPaymentOld, GroupFormDataOld, MemberSelection},
  props: ['tournamentIn', 'user', 'api_login'],
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      loading: false,
      offlinePayment: false,
      userRestrictions: {},
      checkedNames: [],
    }
  },
  computed: {
    tournament() {
      return this.tournamentIn;
    },
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
  },
  mounted() {
    if (this.user && this.user.managed_archers && this.user.managed_archers.length) {
      if (this.tournament.club.stripe && !this.useAuthNet) {
        if (typeof (Stripe) == 'undefined') return;
        this.stripe = Stripe(this.tournament.club.stripe.stripe_publishable_key);
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card');
        if (this.$refs && this.$refs["group-payment"] && this.$refs["group-payment"].$refs) {
          this.card.mount(this.$refs["group-payment"].$refs['card']);
        }
      }
    }
  },
  methods: {
    setCheckedNames(names) {
      this.checkedNames = names;
      this.setUserRestrictions();
    },
    setUserRestrictions() {
      let restrictions = {options: [], price: [], target: []};
      this.tournament.restrictions.forEach((restriction) => {
        restrictions[restriction.type].push(restriction);
      })
      let restricted = {};
      this.checkedNames.forEach((competitor) => {
        let list = {options: [], price: [], target: []};
        restrictions['options'].forEach((restriction) => {
          let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
          let name = field.multiple.find(f => f.id === restriction.multiple_id);
          if (competitor.registration[field['option']].value === name.label) {
            list['options'].push(restriction.type_id);
          }
        })
        restrictions['target'].forEach((restriction) => {
          let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
          let name = field.multiple.find(f => f.id === restriction.multiple_id);
          if (competitor.registration[field['option']].value === name.label) {
            list['target'].push(restriction.type_id);
            competitor.registration['target']['value'] = restriction.type_id;
          }
        })
        restrictions['price'].forEach((restriction) => {
          let field = this.tournament.regform.find(f => f.id === restriction.regform_id);
          let name = field.multiple.find(f => f.id === restriction.multiple_id);
          if (competitor.registration[field['option']].value === name.label) {
            list['price'].push(restriction.type_id);
          }
        })
        restricted[competitor.name] = list;
      })
      this.userRestrictions = restricted;
    },
    updateArchers() {
      if (this.tournament.payments.pricing_extras && this.tournament.payments.pricing_extras.length) {
        if (this.$refs['group-payment']) {
          this.checkedNames.forEach(archer => {
            this.$refs['group-payment'].pricingExtras[archer.id] = []
          })
        }
      }
      this.setUserRestrictions();
      return true;
    },
    async validateStep(name) {
      let refToValidate = this.$refs[name];
      let validation = await refToValidate.$validator.validate();
      return validation;
    },
    register(axiosData) {
      axios.post(`/tournaments/${this.tournament.slug}/bulk_register`, axiosData).then(({data}) => {
        if (data.success) {
          Swal.fire('Registration Complete').then(() => {
            window.location.href = '/tournaments/' + this.tournament.slug;
          })
        } else if (data['error']) {
          Swal.fire(data['error'].message);
        }
        this.loading = false;
      })
    },
    handleStripe() {
      return this.stripe.createToken(this.card).then(({token, error}) => {
        if (error) {
          throw new Error(error.message);
        } else {
          let amount = this.$refs['group-payment'].$refs["payment_amount"].value;
          this.register({
            'competitors': this.checkedNames,
            'stripeToken': token.id,
            'paymentAmount': amount,
          })
        }
      })
    },
    responseHandler(response) {
      if (response.messages.resultCode === "Error") {
        let html = '<div>'
        Object.values(response.messages.message).forEach((message) => {
          html += `<div>${message.text}</div>`;
        })
        html += '</div>'
        Swal.fire({
          title: 'Error Processing',
          html: html,
          icon: "error",
        })
        this.loading = false;
      } else {
        document.getElementById("authNetCard").innerHTML = '';
        let amount = this.$refs['group-payment'].$refs["payment_amount"].value;
        this.register({
          'competitors': this.checkedNames,
          'authNetToken': response.opaqueData.dataValue,
          'authNetDescriptor': response.opaqueData.dataDescriptor,
          'paymentAmount': amount,
        })
      }
    },
    handleAuthNet() {
      var data = {
        authData: {clientKey: this.tournament.club.auth_net_key, apiLoginID: this.api_login},
        cardData: {
          cardNumber: document.getElementById("cardNumber").value,
          month: document.getElementById("expMonth").value,
          year: document.getElementById("expYear").value,
          cardCode: document.getElementById("cardCode").value,
        }
      }
      
      Accept.dispatchData(data, this.responseHandler);
    },
    createCompetitors() {
      this.loading = true;
      this.$validator.validate().then(valid => {
        if (!valid) {
          Swal.fire({
            icon: 'error',
            text: 'Cannot submit registrations with invalid/incomplete competitor details'
          });
          this.loading = false;
        } else {
          if (!this.offlinePayment && this.useAuthNet || this.stripe) {
            let extras = this.$refs['group-payment'].pricingExtras;
            this.checkedNames.forEach(archer => {
              if (extras[archer.id]) {
                archer.pricing_extras = extras[archer.id];
              }
            });
          }
          if (this.useAuthNet && !this.offlinePayment) {
            this.handleAuthNet()
          } else if (this.stripe && !this.offlinePayment) {
            this.handleStripe().catch((error) => {
              this.loading = false;
              Swal.fire(error.message)
            })
          } else {
            this.register({
              'competitors': this.checkedNames,
            })
          }
        }
      });
    },
  },
}
</script>

<style scoped>

a {
  @apply text-blue-600 cursor-pointer;
}
</style>
