<template>
  <div class="flex flex-col">
    <div v-if="user.is_admin" class="flex justify-between mb-2">
      <button @click="enable" class="btn btn-outline-blue-600">Enable Image</button>
      <button @click="disable" class="btn btn-outline-gray-600">Disable Image</button>
      <button @click="deleteUser" class="btn btn-outline-red-600">DELETE USER</button>
    </div>
    <div v-if="user.is_admin" class="text-center">Image is {{ user.image_enabled ? 'Enabled' : 'Disabled' }}</div>
    <img :src="image" width="350" height="350" alt="Profile Picture">
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "PublicImage",
  props: ['inc_user'],
  data() {
    return {
      user: this.inc_user,
    }
  },
  computed: {
    image() {
      if (this.user.image && (this.user.image_enabled || this.user.is_admin)) {
        return this.user.image
      } else {
        return '/img/defaultProfileImage.jpg'
      }
    }
  },
  methods: {
    enable() {
      this.$axios
      .post('/godmode/enable_image', {user: this.user.slug})
      .then(({data}) => {
        if (data.success) {
          this.user.image_enabled = true;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    disable() {
      this.$axios
      .post('/godmode/disable_image', {user: this.user.slug})
      .then(({data}) => {
        if (data.success) {
          this.user.image_enabled = false;
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    deleteUser() {
      Swal.fire({
        titleText: 'Delete ' + this.user.full_name,
        text: 'This is a big deal. Are you sure you want to do delete them?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: () => {
          this.$axios
          .post('/godmode/delete_user', {user: this.user.slug})
          .then(({data}) => {
            if (data.success) {
              window.location.href = '/archers';
            }
          }).catch(({error}) => {
            console.log(error)
          });
        },
      })
      
    }
  }
}
</script>

<style scoped>

</style>