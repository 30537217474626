<template>
  <div class="m-4">
    <ladder-assignment-card
      v-for="(ladder, index) in ladders"
      @forceUpdate="forceUpdate"
      :key="ladder.id"
      :restrictions="restrictions(ladder)"
      :ladder="ladder"
      :index="index"
      :user="user"
      :competitors-in="ladderCompetitors(ladder)"
      :tournament-in="tournamentIn"
    />
  </div>
</template>
<script>

import LadderAssignmentCard from "./LadderAssignmentCard.vue";

export default {
  name: "LadderAssignment",
  components: {LadderAssignmentCard},
  props: ['tournamentIn', 'user'],
  data() {
    return {
      search: '',
      competitors: {},
      unLaddered: this.tournamentIn.competitorList,
    }
  },
  computed: {
    
    ladders() {
      return this.tournamentIn.ladders;
    },
  },
  created() {
    this.tournamentIn.ladders.forEach((ladder) => {
      this.competitors[ladder.id] = this.ladderList(ladder);
    })
  },
  methods: {
    forceUpdate() {
      this.$emit('forceUpdate');
    },
    ladderCompetitors(ladder) {
      let list = this.competitors[ladder.id];
      return list;
    },
    ladderList(ladder) {
      let restrictions = this.restrictions(ladder);
      let list = [];
      let unLaddered = {...this.unLaddered};
      Object.keys(unLaddered).forEach((id) => {
        let competitor = unLaddered[id];
        if (restrictions.length) {
          restrictions.forEach((restriction) => {
            let r = restriction;
            let c = competitor;
            let successList = [];
            Object.keys(restriction).forEach((key) => {
              let lowerKey = key.toLowerCase();
              let regform = c.regform.find((r) => {
                return r.option === lowerKey
              });
              if (regform) {
                if (regform['name'] === r[key]) {
                  return successList.push(true);
                }
              }
              return successList.push(false);
            })
            if (!successList.includes(false)) {
              list.push(competitor);
            }
          })
        }
      });
      return list;
    },
    
    restrictions(ladder) {
      let type = typeof (ladder.restrictions);
      if (type === 'object') return ladder.restrictions;
      let data = JSON.parse(ladder.restrictions);
      return data;
    },
  }
}
</script>

<style scoped>

</style>