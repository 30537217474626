<template>
  <div v-if="mustAccept" class="flex-grow my-1" :class="styling">
    <button v-if="acceptType === 'deny'" @click="denyScores()" class="btn btn-gray-600 w-full">
      Deny
    </button>
    <button v-else @click="acceptScores()" class="btn btn-green-600 w-full">
      Accept
    </button>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "ScoreAccept",
  props: ['competitorId', 'baleNumber', 'acceptType', 'styling'],
  mixins: [mixin],
  computed: {
    ...mapState(useDisplayStore, ['notAccepted', 'round']),
    ...mapWritableState(useDisplayStore, ['replace']),
    mustAccept() {
      let cId = this.competitorId;
      let rId = this.roundItem.id;
      let vm = this;
      if (this.notAccepted.length) {
        let exists = this.notAccepted.filter(function (f) {
          return f.competitor_id === cId && f[vm.roundWord.toLowerCase()] === rId;
        });
        return exists.length;
      }
      return false;
    },
  },
  methods: {
    acceptScores() {
      this.store.clearCompetitorNotAccepted(this.competitorId);
      let newReplace = this.replace.filter(s => s.competitor_id !== this.competitorId);
      this.replace = newReplace;
      this.checkEndCompletion();
    },
    denyScores() {
      this.store.clearCompetitorNotAccepted(this.competitorId);
      let scoreList = this.replace.filter(s => s.competitor_id === this.competitorId);
      scoreList.forEach((score) => {
        this.$store.dispatch('tournament/updateScore', score);
        this.$store.commit('tournament/REMOVE_OFFLINE_SCORE', score);
        this.$store.commit('tournament/UPDATE_LOCAL_SCORE', score);
      });
      let newReplace = this.replace.filter(s => s.competitor_id !== this.competitorId);
      this.replace = newReplace;
    },
  }
}
</script>

<style scoped>

</style>
