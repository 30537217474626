<template>
    <div>
        <stripe-connect :club="club" :user="user" :client_id="client_id"></stripe-connect>
        <club-authnet :club="club" :user="user"></club-authnet>
        <div class="flex flex-col mt-4">
            <label>Default Currency</label>
            <select class="bs-form-box" v-model="club.currency">
                <option v-for="option in currencies" :value="option">{{option}}</option>
            </select>
            <small class="text-gray-500">Main banking currency</small>
        </div>
        <div class="flex justify-end">
            <transition name="fade" :duration="{ enter: 1800, leave: 800 }" >
                <span v-show="updatedMessage" class="text-xl text-blue-600 mr-2">updated</span>
            </transition>
            <button @click="updateSettings()" class="btn-sm btn-blue-600">
                Update Payment Settings <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
            </button>
        </div>

    </div>
</template>

<script>
    export default {
        name: "PaymentSettings",
        props: ['club','currencies','user','client_id'],
        data(){
            return {
                loading: false,
                updatedMessage: false,

            }
        },
        methods:{
            updateSettings(){
                this.loading = true;
                this.$axios.post(/clubs/ + this.club.slug + '/settings', {
                    'details': {
                        'currency':this.club.currency
                    }
                })
                    .then(({data}) => {
                        this.loading = false;
                        this.updatedMessage = true;
                        setTimeout(this.hideUpdated, 1500);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            },
            hideUpdated(){
                this.updatedMessage = false;
            },
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 2s;
    }
    .fade-enter-from, .fade-leave-to{
        opacity: 0;
    }
</style>
