<template>
  <div>
    <div class="flex justify-between">
      <span class="text-xl font-bold">Rounds</span>
      <div v-if="tournament.is_league">
        <div class="flex justify-end">
          <div>
            <button
              @click.prevent="showLeagueHelper = !showLeagueHelper"
              class="btn-sm btn-outline-blue-600 whitespace-nowrap"
              id="line_times">
              {{ showLeagueHelper ? "Manually Setup Rounds" : "Use League Helper" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <setup-helper
      v-if="showLeagueHelper && tournament.is_league"
      :tournament="tournament"
      @submit="generateRounds"
    />
    <div v-else>
      <div class="bg-gray-200 p-3 mb-2" v-for="(round, index) in tournament.rounds">
        <div class="flex flex-wrap">
          <div class="flex flex-col w-full md:w-1/2  mt-4 px-1 mb-2">
            <label :for="'round '+(index + 1)+' name'" class="text-lg">Name</label>
            <input
              class="bs-form-box" v-model="tournament.rounds[index].name"
              :name="'round '+(index + 1)+' name'"
              :id="'round '+(index + 1)+' name'"
              v-validate="'required|min:1|max:60|clean'" required>
            <span class="errorText">{{ errors.first('round ' + (index + 1) + ' name') }}</span>
          </div>
          <div class="flex flex-col mt-4 px-1 mb-2"
               :class=" tournament.targets && tournament.targets.length > 1 ? 'w-1/3 md:w-1/6' : 'w-1/2 md:w-1/4'">
            <label :for="'round '+(index + 1)+' ' + this.$trans.choice('search.end', 2)" class="text-lg">
              # of {{ endWord }}
            </label>
            <input
              class="bs-form-box" type="number" max="100" min="1"
              v-model="tournament.rounds[index].ends_count"
              v-validate="'required|min_value:1|max_value:100'"
              :name="'round '+(index + 1)+' ' + this.$trans.choice('search.end', 2)"
              :id="'round '+(index + 1)+' ' + this.$trans.choice('search.end', 2)"
              required>
            <span class="errorText">
              {{ errors.first('round ' + (index + 1) + ' ' + this.$trans.choice('search.end', 2)) }}
            </span>
          </div>
          <div
            class="flex flex-col mt-4 px-1 mb-2"
            :class="tournament.targets && tournament.targets.length > 1 ? 'w-1/3 md:w-1/6' : 'w-1/2 md:w-1/4'">
            <label :for="'round '+(index + 1)+' arrows'" class="text-lg"># of Arrows</label>
            <input
              class="bs-form-box" type="number" max="10" min="1"
              :name="'round '+(index + 1)+' arrows'"
              :id="'round '+(index + 1)+' arrows'"
              v-validate="'required|min_value:1|max_value:10'"
              v-model="tournament.rounds[index].arrows_count" required>
            <span class="errorText">{{ errors.first('round ' + (index + 1) + ' arrows') }}</span>
          </div>
          <div v-if="tournament.targets && tournament.targets.length > 1"
               class="flex flex-col w-1/3 md:w-1/6 mt-4 px-1 mb-2">
            <label :for="'round '+(index + 1)+' target'" class="text-lg">Default Target</label>
            <select
              class="bs-form-box"
              :name="'round '+(index + 1)+' target'"
              :id="'round '+(index + 1)+' target'"
              v-model="tournament.rounds[index].target">
              <option :value="null">None</option>
              <option v-for="target in tournament.targets" :value="target">{{ target.name }}</option>
            </select>
            <span class="hintText ml-1">optional</span>
          </div>
          <div class="flex flex-col w-full md:w-1/2  mt-4 px-1 mb-2">
            <label class="text-lg">
              Line Times
              <span class="hintText ml-2">Only times added in step 3 will be available.</span>
            </label>
            <multiselect
              v-if="tournament.line_times.length > 1 || tournament.rounds[index].line_times.length === 0"
              :options="getTimesList(tournament.line_times,index)"
              :id="`Round ${index + 1} time`"
              :name="`Round ${index + 1} time`"
              :searchable="true"
              :show-labels="false"
              :hideSelected="true"
              label="time"
              track-by="time"
              :resetAfter="true"
              @select="addRoundTime"
              class="mb-1 multiselect_blue"
            ></multiselect>
            <span class="errorText">{{ errors.first(`Round ${index + 1} time`) }}</span>
            <!--                        The following div is a hacky way to use v-validate on something that is not a form input.-->
            <div v-if="!tournament.rounds[index].line_times.length">
              <input class="hidden" :name="`round ${index + 1} line times`" v-validate="'required'">
              <span class="errorText">{{ errors.first(`round ${index + 1} line times`) }}</span>
            </div>
            <div v-for="(t, timeIndex) in tournament.rounds[index].line_times" :id="'assigned_' + timeIndex"
                 class="bs-form-box flex justify-between mb-1"
                 :class="round.line_times.length > 1 ? 'pr-0 py-0' :''">
              <div :key="'line_time_' + t" class="timeHolder flex flex-col flex-grow justify-center">
                {{ t.time }} {{ t.memo ? "(" + t.memo + ")" : '' }}
              </div>
              <div v-if="round.line_times.length > 1" @click="$emit('removeRoundTime',timeIndex,index) "
                   class="flex">
                <span class="btn btn-outline-gray-600"><i class="fas fa-trash"></i></span>
              </div>
            </div>
          </div>
          <div class="flex flex-col w-full md:w-1/2  mt-4 px-1 mb-2">
            <label class="text-lg">{{ locationWord }}
              <span class="ml-2 hintText">
                Only {{ this.$trans.choice('search.location', 2) }} added in step 4 will be available.
              </span>
            </label>
            <multiselect
              v-if="tournament.locations.length > 1 || tournament.rounds[index].locations.length === 0"
              :options="getLocationsList(tournament.locations,index)"
              :id="`Round ${index + 1} location`"
              :name="`Round ${index + 1} location`"
              :searchable="true"
              :show-labels="false"
              :hideSelected="true"
              :resetAfter="true"
              label="name"
              track-by="name"
              @select="addRoundLocation"
              class="mb-1 multiselect_blue"
            ></multiselect>
            <span class="errorText">{{ errors.first(`Round ${index + 1} location`) }}</span>
            <div v-for="(loc, locIndex) in tournament.rounds[index].locations"
                 :id="'assignedLoc_' + locIndex"
                 class="bs-form-box flex justify-between mb-1"
                 :class="round.locations.length > 1 ? 'pr-0 py-0' :''">
              <div :key="'location_' + loc.name" class="flex flex-col flex-grow justify-center">
                {{ loc.name }}
              </div>
              <div v-if="round.locations.length > 1" @click="$emit('removeRoundLocation',locIndex,index)"
                   class="flex">
                <span class="btn btn-outline-gray-600"><i class="fas fa-trash"></i></span>
              </div>
            </div>
          </div>
          <form-switch
            class="w-full sm:w-1/2"
            @toggled="tournament.rounds[index].open = !tournament.rounds[index].open"
            :model="tournament.rounds[index].open"
            :id="'openRound_' + index"
            :label="'Open'"
            :subLabel="'All registrations will be automatically approved.\n'+
              'Scoring and ' + this.$trans.choice('search.bale', 1) + ' assignments will be ' +
              'available for all Competitors. If not selected, Competitors must be manually ' +
              'approved before participating in this round.'"
          />
          <form-switch
            class="w-full sm:w-1/2"
            @toggled="round.hidden = !round.hidden"
            :model="round.hidden"
            :id="'hideRound_' + index"
            :label="'Hide Round'"
            :subLabel="'Only club admin will see this round. This feature is designed to keep line ' +
              'times and assignments from being publicly available. ' +
              'You may come back to this page at any time to toggle the feature.'"/>
        </div>
        <div class="flex justify-end">
          <div v-if="tournament.rounds.length > 1" @click="$emit('removeRound',index)"
               class="btn btn-outline-gray-600">
            <i class="fas fa-trash"></i>
            Remove Round
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div @click="$emit('addRound')" class="btn btn-outline-blue-600">Add Round</div>
      </div>
    </div>
  
  </div>
</template>

<script>

import SetupHelper from "../league/SetupHelper.vue";
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "TournamentSetup",
  components: {FormSwitch, SetupHelper},
  props: ['tournament'],
  data() {
    return {
      showLeagueHelper: !('id' in this.tournament),
    }
  },
  computed: {
    locationWord() {
      let text = this.$trans.choice('search.location', 3);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    endWord() {
      let text = this.$trans.choice('search.end', 3);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
  methods: {
    getTimesList(times, index) {
      let list = [];
      let tournament = this.tournament;
      times.forEach(function (time) {
        let exists = false;
        tournament.rounds[index].line_times.forEach(t => t.id === time.id ? exists = true : null);
        tournament.rounds[index].line_times.forEach(t => t.time === time.time ? exists = true : null);
        list.forEach(t => t.time === time.time ? exists = true : null);
        if (!exists) list.push(time);
      });
      return list;
    },
    getLocationsList(locations, index) {
      let list = [];
      let tournament = this.tournament;
      locations.forEach(function (location) {
        let exists = false;
        tournament.rounds[index].locations.forEach(loc => loc.id === location.id ? exists = true : null);
        if (!exists) {
          list.push(location);
        }
      });
      return list;
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    addRoundTime(selected, id) {
      this.$emit('addRoundTime', selected, id)
    },
    addRoundLocation(selected, id) {
      this.$emit('addRoundLocation', selected, id);
    },
    generateRounds(list) {
      this.showLeagueHelper = !this.showLeagueHelper;
      this.$emit('generateHelperRounds', list);
    }
  },
  
  
}
</script>

<style scoped>
.multiselect_blue :deep(.multiselect__tag) ,
.multiselect_blue :deep(.multiselect__option--highlight) ,
.multiselect_blue :deep(.multiselect__tag-icon:hover)  {
  @apply bg-blue-600;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 0;
}

</style>
