<template>
  <div class="m-1 flex">
    <drop-rounds :tournament="tournamentIn"/>
    <handicap-designation :tournament="tournamentIn"/>
  </div>
</template>

<script>

import DropRounds from "../league/DropRounds.vue";
import HandicapDesignation from "../league/HandicapDesignation.vue";

export default {
  name: "ManageLeague.vue",
  components: {HandicapDesignation, DropRounds},
  props: ['tournamentIn']
}
</script>

<style scoped>

</style>
