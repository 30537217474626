<template>
    <div class="border-b border-solid border-black">
        <div :class="[scoreStatus(), scoreRow ? 'scoring' : '']" :style="'top: ' + headerY + 'px;'" class="flex justify-between p-2 text-center pr-4 competitorDetail">
            <div @click="scoreBale()" class="flex-2">
                {{bale}}
            </div>
            <div @click="toggleScoring" class="flex-3 text-left">
                {{competitor.name}} <span v-if="scoring" class="text-blue-500 ml-2">SCORING</span>
            </div>
            <div @click="toggleScoring" class="flex-2">
                {{overallScore}}
                <span v-if="is3d">
                    {{overall12?'   ' + overall12 + '-12s':''}}
                </span>
                <span v-else>
                    {{overallX?'-' + overallX + 'X':''}}
                </span>
            </div>
            <div @click="toggleScoring" class="flex-2">
                {{endTotal}}
                <span v-if="is3d">
                    {{end12?'   ' + end12 + '-12s':''}}
                </span>
                <span v-else>
                    {{endX?'-' + endX + 'X':''}}
                </span>
            </div>
            <div @click="toggleScoring" class="flex-2" v-for="arrow in round.arrows_count">
                {{scoreForArrow(arrow) ? scoreForArrow(arrow).target_hit : 0}}
<!--                DJ requested to remove individual arrow edit. Uncomment below if reversed.-->
<!--                    <span @click="scoreOne = [arrow]" v-show="scoreOne.indexOf(arrow) === -1">{{scoreForArrow(arrow) ? scoreForArrow(arrow).target_hit : 0}}</span>-->
<!--                    <div v-show="scoreOne.indexOf(arrow) !== -1" class="row">-->
<!--                        <div class="col-4 p-0" v-for="target in tournament.target.values">-->
<!--                            <div @click="updateScoreForArrow(arrow,target)" class="btn btn-block"-->
<!--                                 :class="scoreStyling(arrow,target)">-->
<!--                                {{target.name}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-12 pr-0">-->
<!--                            <small @click="scoreOne = []" class="btn btn-ghost-secondary float-right mt-0 pr-0 pt-0"><span class="text-black-50">close</span></small>-->
<!--                        </div>-->
<!--                    </div>-->
            </div>
        </div>
        <keep-alive>
            <scoring-row v-on:hideScoring="toggleScoring"
                         v-on:acceptScores="$emit('checkScores')"
                         @scoring="startScoring"
                         @endScoring="endScoring"
                         @updateEnd="updateEnd"
                         @updateRound="updateRound"
                         @notAccepted="notAccepted"
                         @clear="clear"
                         :ref="'score_' + competitor.id"
                         v-show="scoreRow" :tournament="tournament" :end="end" :round="round"
                         :inc_competitor="competitor"
                         :lineTime="lineTime"
                         :location="location"
                         :bale="bale"
                         :scores="scoresForRound"
                         :key="competitor.id + '_scoring'"
                         :endNotAccepted="notAcceptedEnd"
                         :acceptAll="acceptAll"
                         :verified="verified"
            ></scoring-row>
        </keep-alive>


    </div>

</template>

<script>
    export default {
        name: "CompetitorRow",
        props: ['competitor','tournament','end','scoreRow','round', 'bale','verified','lineTime','location','notAcceptedEnd', 'acceptAll'],
        data() {
            return {
                scoreOne: [],
                showScoring: this.scoreRow,
                headerY: 40,
                scoring: false
            }
        },
        mounted(){
            this.getHeaderY();

        },
        computed: {
            is3d(){
                return this.tournament.target_style === 'Stakes'
            },
            scoresForRound(){
                return this.competitor.scores.filter(f => f.round === this.round.id);
            },
            overallScore(){
                let scoreTotal = 0;
                this.scoresForRound.forEach(function(score){
                    scoreTotal += score.score;
                });
                return scoreTotal;
            },
            overall12(){
                let count = 0;
                this.scoresForRound.forEach(function(score){
                    if(score.target_hit === '12'){
                        count++;
                    }
                });
                return count;
            },
            overallX(){
                let count = 0;
                this.scoresForRound.forEach(function(score){
                    if(score.target_hit === 'X'){
                        count++;
                    }
                });
                return count;
            },
            scoresForEnd(){
                return this.scoresForRound.filter(f => f.end === this.end);
            },
            endTotal(){
                let scoreTotal = 0;
                this.scoresForEnd.forEach(function(score){
                    scoreTotal += score.score;
                });
                return scoreTotal;
            },
            endX(){
                let count = 0;
                this.scoresForEnd.forEach(function(score){
                    if(score.target_hit === 'X'){
                        count++;
                    }
                });
                return count;
            },
            end12(){
                let count = 0;
                this.scoresForEnd.forEach(function(score){
                    if(score.target_hit === '12'){
                        count++;
                    }
                });
                return count;
            },
        },
        methods: {
            notAccepted($score){
                this.$emit('notAccepted',$score);
            },
            updateEnd($end){
                this.$emit('updateEnd',$end);
            },
            updateRound($end){
                this.$emit('updateRound',$end);
            },
            startScoring(){
                this.scoring = true;
                this.$emit('scoring')
            },
            endScoring(){
                this.scoring = false;
                this.$emit('endScoring')
            },
            getHeaderY(){
                this.headerY = this.$parent.$refs.tableHeader.clientHeight;
            },
            toggleScoring(){
                this.$emit('updateScoreRow',this.competitor.id);
                this.scoreOne = []
            },

            scoreStatus(){
                if(this.scoresForEnd.length === this.round.arrows_count){
                    return 'text-blue-600';
                } else if ( this.scoresForEnd.length  > 0 ) {
                    return 'text-blue-400';
                }
                return 'text-gray-600';
            },

            scoreBale(){
                this.$emit('updateScoreAll')

            },
            updateScoreForArrow(arrow, target){
                let newScore = {tournament_id: this.tournament.id, competitor_id: this.competitor.id, arrow: arrow, target_hit: target.name, score: target.value, round: this.round.id, end: parseInt(this.end)};

                this.$store.dispatch('tournament/updateScore', {
                    'score': newScore,
                    'lineTime' : this.lineTime,
                    'location' : this.location,
                    'bale' : this.bale
                });
                this.scoreOne = [];
            },
            scoreForArrow(arrow){
                return this.scoresForEnd.find(s => s.arrow === arrow)
            },
            scoreStyling(arrow, target){
                let score = this.scoresForEnd.find(s => s.arrow === arrow);
                if(score && score.target_hit === target.name){
                    return 'btn-blue-700';
                }
                return 'btn-outline-blue-700';
            },
            clear(arrow, end, competitor){
                this.$emit('clear',arrow,end,competitor);
            }
        }
    }
</script>

<style scoped>
    .competitorDetail {
        position: sticky;
        z-index: 1;
    }
    .scoring {
        border-bottom: 1px;
        @apply bg-white border-gray-200 border-dashed;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter-from, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
</style>