<script>
export default {
  name: "LandingBullets",
  methods: {
    contactClicked() {
      window.open('https://community.bowscore.com');
    },
    navToStore(type) {
      let appleLink = 'https://apps.apple.com/us/app/bowscore/id6480208560';
      let googleLink = 'https://play.google.com/store/apps/details?id=com.scoringtech.bowscore';
      window.open(type === 'apple' ? appleLink : googleLink);
    }
  }
}
</script>

<template>
  <div class="centered">
    <div class="section">
      <div class="flex-1 flex justify-center">
        <div class="flex flex-col justify-center">
          <div class="title">Simple & Stress free</div>
          <ul>
            <li class="bullet"><i class="fa fa-long-arrow-right mr-5"></i>Step-by-step tournament setup</li>
            <li class="bullet"><i class="fa fa-long-arrow-right mr-5"></i>Online registration & payments</li>
            <li class="bullet">
              <i class="fa fa-long-arrow-right mr-5"></i>Intuitive scoring app (iOS, Android, and Amazon)
            </li>
          </ul>
        </div>
      </div>
      <div class="flex-1 flex-col justify-center">
        <div class="flex justify-center">
          <img src="/img/female_archer.png">
        </div>
      </div>
    </div>
    <div class="section flex-col-reverse">
      <div class="flex-1 flex-col justify-center">
        <div class="flex justify-center">
          <img src="/img/archer_family.png">
        </div>
      </div>
      <div class="flex-1 flex justify-center">
        <div class="flex flex-col justify-center">
          <div class="title">free for clubs</div>
          <div class="paragraph">
            Bowscore is completely free fro clubs and tournament organizers. We charge archers just $2 per tournament
            registration or $5 for an entire league.
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="flex-1 flex justify-center">
        <div class="flex flex-col justify-center">
          <div class="title">Great for archers, parents, & coaches</div>
          <div class="paragraph">
            Archers can score with the app and track all their results online. Parents can follow their archer's scores
            live on our website. Coaches can register their entire team in just a few steps.
          </div>
        </div>
      </div>
      <div class="flex-1 flex-col justify-center">
        <div class="flex justify-center">
          <img src="/img/gauntlet_archer.png">
        </div>
      </div>
    </div>
    <div class="section flex-col-reverse">
      <div class="flex-1 flex-col justify-center">
        <div class="flex justify-center">
          <img src="/img/chandler_bowscore.png">
        </div>
      </div>
      <div class="flex-1 flex justify-center">
        <div class="flex flex-col justify-center">
          <div class="title">Fast help, from a human</div>
          <div class="paragraph mb-0">
            Your tournament success is important to us. Contact us with any questions and we'll get you an answer within
            the hour (during business hours). If your problem isn't easily answered ina a quick email, we'll give you a
            call to make sure you get the help you need.
          </div>
          <div class="flex justify-center">
            <button @click="contactClicked()" class="button">Contact Us</button>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="flex-1 flex justify-center">
        <div class="flex flex-col justify-center">
          <div class="title">Try it for yourself</div>
          <div class="paragraph mb-5">
            Download the Bowscore app to score your tournaments or run a quick shoot with your friends. The app is
            completely free to use.
          </div>
          <div class="flex flex-col lg:flex-row mt-5">
            <img @click="navToStore('apple')" src="/img/ios_download.png" class="mb-5 lg:mr-5">
            <img @click="navToStore('android')" src="/img/android_download.png" class="mb-5">
          </div>
        </div>
      
      </div>
      <div class="flex-1 flex-col justify-center mt-10 lg:mt-0">
        <div class="flex justify-center">
          <img src="/img/phone_and_icon.png">
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.centered {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1100px) {
  .centered {
    width: 1100px;
  }
  
  .section {
    display: flex !important;
    flex-direction: row !important;
    margin-top: 300px;
    margin-left: 0;
    margin-right: 0;
  }
  
  .title {
    font-size: 48px;
    margin-bottom: 40px;
    line-height: 58px;
  }
  
  .bullet {
    font-size: 26px;
    margin-bottom: 30px;
  }
  
  .paragraph {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 0px;
  }
  
  .button {
    letter-spacing: .48px;
    line-height: 25px;
    margin-top: 64px;
    margin-bottom: 0px;
  }
}

.button {
  @apply text-white;
  background-color: #FB5202;
  font-weight: 700;
  font-family: 'Figtree', serif;
  border-radius: 3px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  height: 60px;
  width: 300px;
  font-size: 18px;
  letter-spacing: .36px;
  line-height: 22px;
  text-transform: uppercase;
  margin-top: 32px;
  margin-bottom: 48px;
  
}


.title {
  @apply font-extrabold;
  font-family: 'Figtree', 'serif';
  font-size: 32px;
  color: #2EA1D8;
  text-transform: uppercase;
  margin-bottom: 40px;
  line-height: 38px;
}

.bullet {
  @apply font-bold;
  font-family: 'Figtree', 'serif';
  font-size: 18px;
  color: #696969;
  margin-bottom: 24px;
  line-height: 22px;
}

.paragraph {
  @apply font-medium;
  font-family: 'Figtree', 'serif';
  font-size: 18px;
  line-height: 24px;
  color: #696969;
  margin-bottom: 48px;
}

.section {
  @apply flex flex-col;
  margin-top: 200px;
  margin-left: 16px;
  margin-right: 16px;
}
</style>