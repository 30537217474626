<template>
    <div class="w-full">
        <div v-if="!enabled">
            The profile picture you have provided was flagged as possibly inappropriate and is currently being
            reviewed.
        </div>
        <div class="flex justify-around">
            <div>
                <img :src="imgUrl" width="130" height="130" alt="Profile Image">
            </div>
            <div class="flex flex-col justify-end">
                <div class="flex flex-wrap justify-center">
                    <image-upload :title="'Profile Image (400x400 .jpg)'"
                                  :option="'profile'"
                                  :height="400"
                                  :width="400"
                                  :multiplier="2"
                                  :inc_url="imgUrl"
                                  :post_url="'/profile/' + user.slug + '/image/update'"
                                  @update_image="updateImage"
                                  @remove_image="removeImage">
                        <template v-slot:open-modal>
                            <button type="button" class="btn btn-blue-600" data-toggle="modal" data-target="#cropper"
                                    data-backdrop="true">
                                Upload Image
                            </button>
                        </template>
                    </image-upload>
                </div>
            </div>
        </div>
        <div class="text-sm text-gray-600">
            This image will be displayed on your public profile.
        </div>
    </div>

</template>

<script>
    export default {
        name: "ProfileImage",
        props: ['user'],
        data(){
            return {
                enabled: (this.user.image && this.user.image_enabled) || !this.user.image,
                imgUrl: this.user.image,
            }
        },
        methods: {
            updateImage(details) {
                var [url, enabled, _] = details;
                this.imgUrl = url;
                this.enabled = enabled;
            },
            removeImage() {
                this.$axios.post('/profile/' + this.user.slug + '/image/delete')
                    .then(({data}) => {
                        if (data.success) {
                            this.imgUrl = '/img/defaultProfileImage.jpg';
                            this.enabled = true;
                            this.user.image = null;
                        }
                    })
            },
        }
    }
</script>

<style scoped>

</style>
