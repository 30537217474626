<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Scoring Device Authorization
    </div>
    <div class="card-body">
      <div class="m-2">
        <div class="text-center">Manually Add Device By ID</div>
        <div class="mx-auto w-full sm:w-3/4 xl:w-1/2">
          <div class="py-0 max-w-sm my-2 bs-form-box flex">
            <input @keyup="error =  ''" type="text" class="flex-grow mx-2 pl-2" v-model="addId">
          </div>
          <div class="text-sm text-red-600">
            {{ error }}
          </div>
          <div class="text-sm text-gray-600">
            All authorized devices can score for this tournament.
            You may restrict them by clicking the <i class="fas fa-lock"></i> icon on their scoring screen.
          </div>
          <div class="flex justify-end">
            <button @click.prevent="addDevice()" class="btn btn-blue-600">Authorize Device</button>
          </div>
        </div>
      
      </div>
      <hr class="my-3">
      <div class="flex flex-wrap justify-between">
        <button @click.prevent="show = !show" class="btn btn-gray-600">{{ show ? 'Hide' : 'Show' }} Devices</button>
        <div class="text-gray-600 text-sm h-center">
          Device Id is displayed in the lower left corner of the screen on any Bowscore page.
        </div>
      </div>
      
      <div v-if="show">
        <div class="m-2">
          <div class="mx-auto w-1/2">
            <div class="max-w-sm bs-form-box flex">
              <span class="h-center"><i class="fas fa-search"></i></span>
              <input type="text" class="flex-grow mx-2 pl-2" v-model="search">
              <div v-if="search !== ''" class="h-center" @click="search = ''">
                <span class=""><i class="fas fa-times"></i></span>
              </div>
            </div>
            <span class="text-center text-sm text-gray-600">filter by device Id</span>
          </div>
        
        </div>
        
        <div class="text-left text-gray-600 text-sm">
          Click a cell to edit.
        </div>
        <div class="table-responsive ">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Authorized</th>
              <th class="table-header">Device Name</th>
              <th class="table-header">Device Id</th>
              <th class="table-header">Expires</th>
            </tr>
            </thead>
            <tbody>
            <device-details v-for="device in selectedDevices" :inc_device="device" :key="device.id"/>
            <tr v-if="!selectedDevices.length">
              <td colspan="4" class="table-empty text-center">
                No Devices
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    
    
    </div>
  </div>
</template>

<script>
import DeviceDetails from "../../clubs/TournamentDeviceDetails.vue";

export default {
  name: "TournamentDevices",
  components: {DeviceDetails},
  props: ['tournament', 'club', 'inc_devices'],
  data() {
    return {
      search: '',
      addId: '',
      devices: this.inc_devices,
      show: false,
      error: ''
    }
  },
  computed: {
    selectedDevices() {
      return this.devices ? this.devices.filter(f => f.device_id.toUpperCase().includes(this.search.toUpperCase())) : {};
    }
  },
  methods: {
    addDevice() {
      this.$axios.post('/device/tournament_add', {device: this.addId, tournament: this.tournament})
      .then(({data}) => {
        if (data['error']) {
          this.error = data['error'].message;
        } else {
          this.show = true;
          this.search = '';
          this.devices = data.devices;
        }
      })
    }
  }
  
}
</script>

<style scoped>

</style>
