<template>
    <tr v-if="!deleted">
        <td class="text-center">{{sponsor.id}}</td>
        <td>{{sponsor.name}}</td>
        <td class="text-center" @click="setSponsor()"><a @click.prevent href=""><i class="fas fa-desktop"></i></a></td>
        <td class="text-center">
            <a :href="'/sponsor_themes/' + sponsor.id + '/edit'"><i class="fas fa-edit"></i></a>
        </td>
        <td class="text-center">
            <a v-if="!deleting" @click.prevent="deleting = true" href=""><i class="fas fa-trash"></i></a>
            <span v-if="deleting">
                <button class="btn btn-red-600" @click="deleteSponsor">DELETE</button>
                <a @click.prevent="deleting = false" href=""><i class="fas fa-ban"></i></a>
            </span>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "SponsorRow",
        props: ['sponsor'],
        data() {
            return {
                deleting: false,
                deleted: false
            }
        },
        methods: {
            setSponsor() {
                this.$store.commit('sponsor/SET', {
                    'sponsor': this.sponsor
                });
            },
            deleteSponsor() {
                this.$axios.delete(`sponsor_themes/${this.sponsor.id}`).then(() => {
                    this.deleted = true;
                })
            }
        }
    }
</script>

<style scoped>

</style>
