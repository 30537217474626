<template>
  <div class="card card-blue-700 mb-3">
    <div class="card-header">
      Activity Breakdown
    </div>
    <div class="card-body">
      <div v-if="breakdown !== null" class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-header"></th>
            <th class="table-header">24 Hours</th>
            <th class="table-header">7 Days</th>
            <th class="table-header">30 Days</th>
            <th class="table-header">Year</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, key) in breakdown">
            <td>{{ key }}</td>
            <td v-for="v in value">{{ v }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="flex justify-center">
        <div class="flex flex-col">
          <div>Loading Breakdown</div>
          <div class="flex justify-center">
            <i class="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "ActivityBreakdown",
  props: [],
  data() {
    return {
      breakdown: null,
    }
  },
  created() {
    //TODO remove this when done testing the API
    // let details = {
    //   'archers': 1,
    //   'ends': 10,
    //   'arrows': 3,
    //   'target': 15,
    //   'bales': false
    // }
    // let details = {"winner":36836, "tournament":844, "ladder":38, "step":3, "code":"ylwh"};
    //
    //
    // let body = {"data": details, "code": "upvc"};
    // this.$axios.get('/api/v1/code/yhpn', {
    //   headers: {'x-api-key': '0e8fbc02-d660-4038-a050-19c4ea03d77c'},
    // }).then(({data}) => {
    //   debugger;
    // })
    
    
    this.$axios.get('/godmode/activity_breakdown')
    .then(({data}) => {
      if (data) {
        this.breakdown = data.data;
      }
    });
  }
}
</script>

<style scoped>

</style>
