<template>
  <div>
    <dropdown v-if="rounds.length > 1 && restrictedRounds.length !== 1">
      <template v-slot:trigger>
        <div id="roundSelection"
             class="text-xl sm:text-2xl md:text-3xl lg:text-4xl dropdown-toggle text-gray-800"
             aria-haspopup="true" aria-expanded="false">
          Round: {{ roundName(round) }}
          <i class="fas fa-caret-down"></i>
        </div>
      </template>
      <span v-for="round in rounds" class="w-full">
        <span @click="updateRound(round);"
              class="dropdown-item flex justify-between text-xl">
          <span>{{ round.name }}</span>
          <span class="ml-2 text-center font-bold"
                v-if="!competitor"
                :class="getColorFromPercentage(getCompletion(round.id))">
            {{ getCompletion(round.id) }}%
          </span>
        </span>
      </span>
    </dropdown>
    <div v-else-if="tournament.rounds.length > 1"
         class="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
      Round: {{ roundName(round) }}
    </div>
  </div>

</template>

<script>

import mixin from "../../../mixins/scoring_mixins";
import Dropdown from "../../partials/Dropdown.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "RestrictRound.vue",
  mixins: [mixin],
  components: {Dropdown},
  methods: {
    roundName(round){
      let text = round.name;
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['incCompetitor', "restrictedRounds", 'restrictedTimes']),
    competitor() {
      return this.incCompetitor;
    },
    rounds() {
      if (!this.restrictedTimes.length) return this.tournament.rounds;
      let list = [];
      if (this.restrictedTimes.length) {
        this.tournament.rounds.forEach((round) => {
          let valid = false;
          this.restrictedTimes.forEach((time) => {
            let exists = round.line_times.findIndex((f) => {
              return f.id === time.id;
            });
            if (exists !== -1) valid = true;
          })
          if (valid) list.push(round);
        });
        
      }
      return list;
    },
  }
}
</script>

<style scoped>

</style>
