<template>
  <div class=" mt-10 w-full h-full">
    <div v-if="tournamentCount || archived.length" class="card card-blue-700 mb-4 relative">
      <div v-if="checked.length" class="hidden md:flex absolute staticCard border border-gray-400 card shadow">
        <div class="mx-3 my-5">
          <span class="btn btn-gray-400 rounded-lg text-black px-12 py-3" @click="archive()">{{ archiveWord }}</span>
          <span class="ml-3 btn btn-gray-400 rounded-lg text-black px-12 py-3" @click="clear()">
            Clear&nbsp;<span class="hidden lg:inline">Selection</span>
          </span>
        </div>
      </div>
      <div v-if="checked.length" class="fixed md:hidden staticCard border border-gray-400 card shadow">
        <div class="mx-1 my-2 w-80 flex justify-around">
          <span class="btn btn-gray-400 rounded-lg text-black px-12 py-2" @click="archive()">{{ archiveWord }}</span>
          <span class="ml-3 btn btn-gray-400 rounded-lg text-black px-12 py-2" @click="clear()">Clear</span>
        </div>
      </div>
      <div class="card-header">
        Tournaments
      </div>
      <div class="card-body p-sm-2 p-md-3" style="min-height: 300px;">
        <div class="px-4 pt-4">
          <div class="flex">
            <div class="bs-form-box p-0 flex rounded-lg bg-gray-400 w-full sm:w-1/2">
              <div class="flex flex-col justify-center text-gray-600 p-2 mr-2">
                <i class="fa-xl fas fa-search"></i>
              </div>
              <input
                class="flex-grow text-lg text-gray-600 focus:outline-none bg-gray-400 placeholder-gray-600"
                ref="searchBar"
                :placeholder="`Filter tournaments (${tournamentCount})`"
                v-model="search">
              <button
                v-if="search.length"
                @click="clearSearch"
                class="flex flex-col justify-center text-gray-600 p-2 mr-2 font-light">
                <i class="fa-xl fas fa-times"></i>
              </button>
              <div v-else>&nbsp</div>
            </div>
            <new-tournament-button
              :hide-plus="true"
              :user="user"
              :color="'blue-600'"
              :size="'btn-md'"
              class="ml-6 hidden sm:block"/>
            <new-tournament-button
              :hide-text="true"
              :user="user"
              :color="'blue-600'"
              :size="'btn-md'"
              class="ml-6 block sm:hidden"/>
          </div>
          <div v-if="archived.length"
               class="py-2"
               :class="showArchive ? 'text-red-600' : 'text-blue-600'"
               @click="toggleArchive()">
            {{ showArchive ? 'Hide ' : 'Show ' }} Archived Tournaments
          </div>
          <div v-else class="h-6"></div>
        </div>
        
        <div class="overflow-x-auto overflow-y-auto">
          <table class="table-fixed" :style="`width:${fullWidth}px;`">
            <thead>
            <tr>
              <th :style="`width: ${wBox}px;`"><input class="ml-4 pt-1" type="checkbox" @change="toggleAll"></th>
              <th :style="`width: ${wDate}px;`"
                  :class="`${sortBy === 'date' ? 'selected' : 'not'}`"
                  class="pb-1 hidden sm:table-cell"
                  @click="sort('date')">
                Start Date
                <i v-if="sortBy === 'date'"
                   :class="`icon far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
              </th>
              <th :style="`width: ${wTournament}px;`" class="pb-1 flex sm:hidden">
                <div class="mr-6" :class="`${sortBy === 'date' ? 'selected' : 'not'}`" @click="sort('date')">
                  Start Date
                  <i v-if="sortBy === 'date'"
                     :class="`iconSm far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
                </div>
                <div @click="sort('tournament')" :class="`${sortBy === 'tournament' ? 'selected' : 'not'}`">
                  Tournament
                  <i v-if="sortBy === 'tournament'"
                     :class="`iconSm far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
                </div>
              </th>
              <th :style="`width: ${wTournament}px;`"
                  :class="`${sortBy === 'tournament' ? 'selected' : 'not'}`"
                  class=" pb-1 hidden sm:table-cell"
                  @click="sort('tournament')">Tournament
                <i v-if="sortBy === 'tournament'"
                   :class="`icon far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
              </th>
              <th :style="`width: ${wSport}px;`"
                  :class="`${sortBy === 'sport' ? 'selected' : 'not'}`"
                  class="pb-1"
                  @click="sort('sport')">Sport
                <i v-if="sortBy === 'sport'"
                   :class="`icon far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
              </th>
              <th :style="`width: ${wClub}px;`"
                  :class="`${sortBy === 'club' ? 'selected' : 'not'}`"
                  class="pb-1"
                  @click="sort('club')">Club
                <i v-if="sortBy === 'club'"
                   :class="`icon far fa-angle-${sortOrder === 'desc' ? 'down' : 'up'}`"></i>
              </th>
              <th :style="`width: ${wLocation}px;`" class="pb-1 not">Location</th>
              <th class="sm:hidden" :style="`width:${wDate}px;`"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="tournament in filteredTournaments" class="mt-1"
                :class="isChecked(tournament) ?'bg-gray-200' : ''">
              <td class="w-16">
                <input
                  class="ml-4"
                  type="checkbox"
                  :checked="isChecked(tournament)"
                  @change="toggleTournament(tournament)">
              </td>
              <td class="cell hidden sm:block">
                <div :style="`width: ${wDate - wGap}px;`" class="textCell">{{ time(tournament) }}</div>
              </td>
              <td class="cell hidden sm:table-cell">
                <a :style="`width: ${wTournament - wGap}px;`" class="textCell block cell "
                   :href="'/tournaments/' + tournament.slug">{{ tournament.name }}</a>
              </td>
              <td class="cell table-cell sm:hidden">
                <div class="relative">
                  <a :style="`width: ${wTournament - wGap}px; bottom:-17px;`"
                     class="textCell cell block absolute"
                     :href="'/tournaments/' + tournament.slug">{{ tournament.name }}
                  </a>
                  <div :style="`width: ${wDate - wGap}px; bottom:-31px;`" class="tinyTime sm:hidden absolute ">
                    {{ time(tournament) }}
                  </div>
                </div>
              </td>
              <td class="cell">
                <div :style="`width: ${wSport - wGap}px;`" class="textCell">{{ sport(tournament) }}</div>
              </td>
              <td class="cell">
                <a :style="`width: ${wClub - wGap}px;`"
                   class="textCell cell block"
                   :href="'/clubs/' + clubSlug(tournament)">{{ club(tournament) }}
                </a>
              </td>
              <td class="cell">
                <div :style="`width: ${wLocation - wGap}px;`" class="textCell">{{ location(tournament) }}</div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import NewTournamentButton from "../partials/NewTournamentButton.vue";
import moment from "moment";

export default {
  name: "ManageTournaments",
  components: {NewTournamentButton},
  props: ['user'],
  data() {
    return {
      search: '',
      checked: [],
      wBox: 44.0,
      wDate: 180.0,
      wTournament: 330.0,
      wSport: 170.0,
      wClub: 290.0,
      wLocation: 300.0,
      wGap: 10.0,
      sortBy: 'date',
      sortOrder: 'desc',
      showArchive: false,
      archived: [],
    }
  },
  created() {
    let ids = this.user.archives.map(function (t) {
      return t.tournament_id;
    });
    this.archived = ids;
  },
  computed: {
    archiveWord() {
      return this.showArchive ? 'Restore' : 'Archive';
    },
    fullWidth() {
      return this.wBox + this.wDate + this.wTournament + this.wClub + this.wSport + this.wLocation;
    },
    tournamentCount() {
      return this.tournaments.length;
    },
    filteredTournaments() {
      let search = this.search.toLowerCase();
      let tournaments = this.tournaments;
      if (search !== '') {
        tournaments = tournaments.filter(function (tournament) {
          return tournament.name.toLowerCase().includes(search);
        })
      }
      tournaments = this.sortList(tournaments);
      return tournaments;
    },
    
    tournaments() {
      let list = [];
      let vm = this;
      this.user.managed_clubs.forEach(function (club) {
        club.tournaments.forEach(f => {
          if (vm.showArchive) {
            if (vm.archived.includes(f.id)) list.push(f);
          } else {
            if (!(vm.archived.includes(f.id))) list.push(f);
          }
        });
      });
      return list;
    },
  },
  methods: {
    toggleArchive() {
      this.showArchive = !this.showArchive;
    },
    clear() {
      this.checked = [];
    },
    archive() {
      this.$axios.post('/dashboard/archive', {'list': this.checked, 'archive': !this.showArchive}).then(({data}) => {
        if (data['success']) {
          this.archived = data['list'];
          this.showArchive = false;
          this.checked = [];
        } else {
          this.checked = [];
          Swal.fire({
            icon: 'error',
            title: 'Failed to update archive. Please try again.',
          })
        }
      });
    },
    sortList(tournaments) {
      let sorted = tournaments;
      if (this.sortBy === 'date') {
        sorted = this.sortTime(sorted);
      } else if (this.sortBy === 'tournament') {
        sorted = this.sortTournament(sorted);
      } else if (this.sortBy === 'sport') {
        sorted = this.sortSport(sorted);
      } else if (this.sortBy === 'club') {
        sorted = this.sortClub(sorted);
      }
      return sorted;
    },
    sortClub(tournaments) {
      let sorted = tournaments;
      sorted = tournaments.sort((a, b) => {
        let c = this.sortOrder === 'asc' ? a : b;
        let d = this.sortOrder === 'asc' ? b : a;
        let cName = this.club(c).toLowerCase();
        let dName = this.club(d).toLowerCase();
        let val = cName > dName ? 1 : (cName === dName ? 0 : -1);
        return val;
      });
      return sorted;
    },
    sortTournament(tournaments) {
      let sorted = tournaments;
      sorted = tournaments.sort((a, b) => {
        let c = this.sortOrder === 'asc' ? a : b;
        let d = this.sortOrder === 'asc' ? b : a;
        let cName = c.name.toLowerCase();
        let dName = d.name.toLowerCase();
        let val = cName > dName ? 1 : (cName === dName ? 0 : -1);
        return val;
      });
      return sorted;
    },
    sortSport(tournaments) {
      let sorted = tournaments;
      sorted = tournaments.sort((a, b) => {
        let c = this.sortOrder === 'asc' ? a : b;
        let d = this.sortOrder === 'asc' ? b : a;
        let cName = c.sport.abbreviation.toLowerCase();
        let dName = d.sport.abbreviation.toLowerCase();
        let val = cName > dName ? 1 : (cName === dName ? 0 : -1);
        return val;
      });
      return sorted;
    },
    sortTime(tournaments) {
      let sorted = tournaments;
      sorted = tournaments.sort((a, b) => {
        let c = this.sortOrder === 'asc' ? a : b;
        let d = this.sortOrder === 'asc' ? b : a;
        let cTime = new Date(c.start_time);
        let dTime = new Date(d.start_time);
        return cTime - dTime;
      });
      return sorted;
    },
    sort(type) {
      if (this.sortBy === type) {
        this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy = type;
      }
    },
    location(tournament) {
      let club = this.user.managed_clubs.find(function (t) {
        return tournament.club_id === t.id;
      });
      if (!club) return '';
      let address = club.range_address;
      if (!address) return '';
      let both = [];
      if (address.city) both.push(address.city);
      if (address.state) both.push(address.state);
      return both.join(', ');
    },
    sport(tournament) {
      return tournament.sport.abbreviation;
    },
    club(tournament) {
      let club = this.user.managed_clubs.find(function (t) {
        return tournament.club_id === t.id;
      });
      return club != null ? club.name : '';
    },
    clubSlug(tournament) {
      let club = this.user.managed_clubs.find(function (t) {
        return tournament.club_id === t.id;
      });
      return club != null ? club.slug : '';
    },
    isChecked(tournament) {
      return this.checked.indexOf(tournament['id']) !== -1;
    },
    toggleAll() {
      if (this.checked.length === this.filteredTournaments.length) {
        this.checked = [];
      } else {
        let list = [];
        this.filteredTournaments.forEach(function (tournament) {
          list.push(tournament.id);
        })
        this.checked = list;
      }
    },
    toggleTournament(tournament) {
      let exists = this.checked.indexOf(tournament['id']);
      if (exists !== -1) {
        this.checked.splice(exists, 1);
      } else {
        this.checked.push(tournament['id']);
      }
    },
    clearSearch() {
      this.search = '';
    },
    time(tournament) {
      let start = new Date(tournament['start_time']);
      return moment(start).format('L')
    }
  }
}
</script>

<style scoped>
.cell {
  @apply pb-1 text-blue-600 font-medium text-lg;
}

.icon {
  padding-left: 3px;
  transform: scale(1.5);
}


.iconSm {
  padding-left: 3px;
  transform: scale(1.5);
}

.selected {
  @apply text-lg font-bold;
}

.not {
  @apply text-lg font-medium text-gray-500;
}

.staticCard {
  bottom: 50px;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  border-style: solid !important;
  border-radius: 10px;
}

.tinyTime {
  @apply pb-1 text-gray-500 font-medium text-sm;
}

.textCell {
  white-space: nowrap;
  overflow-x: clip;
  text-overflow: ellipsis;
}
</style>
