<script setup>

import {useTournamentStore} from "../../../stores/TournamentStore";

const store = useTournamentStore();

const navigateToForm = () => {
  store.showRegForm = true;
  window.scroll({top: 0, behavior: "smooth"});
}


</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Registration Form</div>
    <button @click.prevent="navigateToForm" class="linkText">
      <i class="fa-regular fa-lg fa-memo"></i> <span class="ml-1">View/edit the default registration form</span>
    </button>
  </div>
</template>

<style scoped>
.linkText {
  @apply text-black font-bold mt-8;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: .018rem;
}
</style>