<template>
    <div class="dropdown relative">

        <!-- trigger -->
        <div class="dropdown-toggle text-gray-600"
             v-bind:class="{'text-gray-900': isOpen }"
             @click.prevent="clickedToggle()">
            <slot name="trigger"></slot>
        </div>


        <!-- menu links -->
        <div v-show="isOpen"
             class="dropdown-menu z-50 absolute md:bg-card rounded ls:shadow border-gray-400"
             :class="[align ? align + '-0' : '',css ? css : 'py-2 border whitespace-nowrap', direction ? direction + '-full' : '', background ? 'bg-' + background : 'bg-white']"
             :style="{ width, bottom }">
            <slot></slot>
        </div>
    </div>

</template>


<script>

    export default {
        props: {
            align: { default: 'left' },
            width: { default: 'auto' },
            bottom: { default: 'auto' },
            css: { default: ''},
            direction: { default: 'top'},
            background: { default: 'white' }
        },
        data() {
            return {
                isOpen: false,
            }
        },

        watch: {
            isOpen(oldVal) {
                if (oldVal) {
                    document.addEventListener('click', this.closeIfClickedOutside);
                }
            }
        },

        methods: {
            clickedToggle() {
                this.isOpen = !this.isOpen;
            },
            closeIfClickedOutside(event) {
                if (!event.target.closest('.dropdown')) {
                    this.isOpen = false;
                    document.removeEventListener('click', this.closeIfClickedOutside);
                } else if (event.target.closest('.dropdown-menu') && !event.target.closest('.dropdown-toggle')) {
                    this.isOpen = false;
                    document.removeEventListener('click', this.closeIfClickedOutside);
                }
            }
        }
    }


</script>
