<template>
  <div class="flex-grow">
    <div class="h-full h-center float-right mr-5">
      <span @click="showSearch()" class="text-gray-600 text-lg sm:text-xl cursor-pointer">
        <i class="fas fa-search"></i>
      </span>
    </div>
    <div class="flex lg:flex justify-center flex-grow">
      <div v-if="show" class="w-11/12 lg:w-full max-w-xl z-10 fixed lg:absolute lg:top-auto lg:right-auto"
           style="top:55px; right:5px;">
        <div class="bs-form-box p-0 flex rounded-lg">
          <div class="flex flex-col justify-center text-gray-500 p-2 mr-2">
            <i class="fa-2x fas fa-search"></i>
          </div>
          <input class="flex-grow text-xl sm:text-3xl focus:outline-none" ref="searchBar"
                 :placeholder="' Find ' + this.$trans.choice('search.archer', 2) + ', ' + this.$trans.choice('search.tournament', 2) + ', and clubs'"
                 v-model="search">
          <button v-if="search.length" @click="clearSearch"
                  class="flex flex-col justify-center text-gray-500 p-2 mr-2 font-light">
            <i class="fa-2x fas fa-times"></i>
          </button>
          <div v-else>&nbsp</div>
        </div>
        <div v-if="search && Object.keys(resultList).length"
             class="bg-blue-700 text-blue-200 rounded-b-lg border-b-4 border-r-4 border-l-4">
          <div v-if="resultList.tournaments.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">{{ tournamentsWord }}</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/tournaments?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="tournament in resultList.tournaments" class="mx-3">
              <a class="text-blue-200 flex justify-between" :href="'/tournaments/' + tournament.slug">
                <div>{{ tournament.name }}</div>
                <div v-if="tournament.address && tournament.address.city">{{ tournament.address.city }},
                  {{ tournament.address.state }}
                </div>
              </a>
            </div>
          </div>
          <div v-if="resultList.clubs.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Clubs</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/clubs?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="club in resultList.clubs" class="mx-3">
              <a class="text-blue-200 flex justify-between" :href="'/clubs/' + club.slug">
                <div>{{ club.name }}</div>
                <div v-if="club.rangeAddress && club.rangeAddress.city">{{ club.rangeAddress.city }},
                  {{ club.rangeAddress.state }}
                </div>
                <div v-else-if="club.mailingAddress && club.mailingAddress.city">
                  {{ club.mailingAddress.city }}, {{ club.mailingAddress.state }}
                </div>
                <div v-else-if="club.address">{{ club.address.city }}, {{ club.address.state }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.competitors.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Competitors</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/competitors?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="competitor in resultList.competitors" class="mx-3">
              <a class="text-blue-200 flex justify-between"
                 :href="'/tournaments/' + competitor.tournament.slug + '#/results'">
                <div>{{ competitor.name }}</div>
                <div>{{ competitor.tournament.name }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.profiles.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Profiles</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/profiles?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="profile in resultList.profiles" class="mx-3">
              <a v-if="profile.slug" class="text-blue-200" :href="'/archers/' + profile.slug">
                <div>{{ profile.full_name }}</div>
              </a>
            </div>
          </div>
          <div v-if="resultList.teams.length">
            <div class="bg-blue-900 px-2 py-1 flex justify-between">
              <div class="text-xl">Team/Clubs</div>
              <div class="text-sm flex flex-col justify-end">
                <a :href="'/search/teams?search=' + search" class="text-blue-400">more...</a>
              </div>
            </div>
            <div v-for="competitor in resultList.teams" class="mx-3">
              <a class="text-blue-200 flex justify-between"
                 :href="'/tournaments/' + competitor.tournament.slug + '#/results?team=' + encodeURI(competitor.team)">
                <div>{{ competitor.name }}</div>
                <div>{{ competitor.team }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSearch",
  data() {
    return {
      search: '',
      resultList: {},
      show: false,
    }
  },
  created() {
    console.log(this.trans);
  },
  watch: {
    search(text) {
      if (!text.length) {
        this.resultList = {};
      } else if (text.length > 2 || Object.keys(this.resultList).length) {
        this.submitSearch();
      }
    }
  },
  computed: {
    tournamentsWord() {
      let text = this.$trans.choice('search.tournament', 2);
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    }
  },
  methods: {
    showSearch() {
      this.show = !this.show;
      this.$nextTick(() => {
        if (this.$refs.searchBar) {
          this.$refs.searchBar.focus();
        }
      })
    },
    clearSearch() {
      this.search = '';
      this.resultList = {};
    },
    submitSearch() {
      axios
      .get('/search', {
        params: {
          'search': this.search
        }
      })
      .then(({data}) => {
        this.resultList = data.results;
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>
