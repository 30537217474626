<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import {ErrorMessage, Field} from "vee-validate";

const store = useTournamentStore();
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Event Location</div>
    <div class="bs-section-header">Address</div>
    <Field v-model="store.address" class="bs-input-box" type="text" name="address" rules="required|clean|min:2"/>
    <ErrorMessage name="address" class="errorText"/>
    <div class="flex mt-3">
      <div class="flex-3">
        <div class="bs-section-header">City</div>
        <Field v-model="store.city" class="bs-input-box" name="city" rules="required|clean"/>
        <ErrorMessage name="city" class="errorText"/>
      </div>
      <div class="flex-1 mx-4">
        <div class="bs-section-header">State</div>
        <Field v-model="store.state" name="state" class="bs-input-box" rules="required|clean"/>
        <ErrorMessage name="state" class="errorText"/>
      </div>
      <div class="flex-2">
        <div class="bs-section-header">Zip Code</div>
        <Field v-model="store.zipCode" name="zip code" class="bs-input-box" rules="required|clean"/>
        <ErrorMessage name="zip code" class="errorText"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>