<template>
  <div class="table-responsive bg-white">
    <table class="table table-bordered table-striped" :key="componentKey">
      <tr>
        <th class="table-header"
            v-for="detail in tournament.regform"
            v-if="detail.option !== 'header-field' && detail.enabled">
          {{ detail.name }}
        </th>
        <th v-if="showTimes" class="table-header">Line Time</th>
        <th v-if="showTarget" class="table-header">Target</th>
      </tr>
      <tr v-for="archer in checkedNames">
        <td v-for="detail in tournament.regform"
            v-if="detail.type !== 'header' && detail.enabled">
          <span class="flex justify-center" v-if="userRestricted(archer, detail.id, 'options')">
            N/A
          </span>
          <image-upload
            v-else-if="detail.type === 'image'"
            :title="detail.name + ' - ' + archer.name"
            :option="detail.option"
            :type="'competitor'"
            :file_id="tournament.id"
            @update_image="updateImage(archer, $event)"
            @remove_image="removeImage(archer, $event)">
            <template v-slot:open-modal>
              <img v-if="cellValue(archer, detail)"
                   type="button"
                   data-toggle="modal"
                   data-target="#cropper"
                   :style="'width:50px;height:50px'"
                   :alt="`${archer.registration[detail.option].option} Image`"
                   :src="imageSource(archer, detail)"
                   data-backdrop="true"
                   class="mx-auto"
              >
              <a @click.prevent v-else :class="requiredClass(archer, detail)" href="">none</a>
            </template>
          </image-upload>
          <span v-else @click="cellClicked(archer, detail)">
            <span v-if="detail.type === 'checkbox'">
              <span @click.prevent
                    v-if="cellHasValue(archer, detail)"
                    :class="errors.first(archer.id+'_'+detail.id) ? 'border-b border-red-600' : ''">
                {{ cellValue(archer, detail) }}
              </span>
              <a @click.prevent v-else :class="requiredClass(archer, detail)" href="">none</a>
              <span @click.stop :ref="archer.id+'_'+detail.id" v-show="isEditing(archer, detail)">
                <multiselect
                  v-model="archer.registration[detail.option].value"
                  :options="detail.multiple.map(m => m.label)"
                  :searchable="true"
                  :show-labels="false"
                  :hideSelected="true"
                  :placeholder="'Select all that apply'"
                  :multiple="true"
                  v-validate="detail.required ? 'required' : ''"
                  :resetAfter="false"
                  :name="archer.id+'_'+detail.id"
                  @close="editing = archer.id+'_'+detail.id ? '' : editing; $emit('setRestrictions'); resetTimes(archer)"
                ></multiselect>
              </span>
            </span>
            <span v-else-if="detail.enabled">
              <span @click.prevent v-if="cellHasValue(archer, detail)"
                    :class="errors.first(archer.id+'_'+detail.id) ? 'border-b border-red-600' : ''">
                {{ cellValue(archer, detail) }}
              </span>
              <a @click.prevent v-else :class="requiredClass(archer, detail)"
                 href="">none</a>
              <span @click.stop v-show="editing === archer.id+'_'+detail.id">
                <select v-if="detail.multiple"
                        :ref="archer.id+'_'+detail.id"
                        v-model="archer.registration[detail.option].value"
                        :name="archer.id+'_'+detail.id"
                        v-validate="detail.required ? 'required' : ''"
                        @change="editing = archer.id+'_'+detail.id ? '' : editing; $emit('setRestrictions'); resetTimes(archer)"
                        class="bs-form-box w-full">
                  <option v-for="option in detail.multiple" :value="option.label">
                    {{ option.label }}
                  </option>
                </select>
                <input v-else-if="detail.option === 'email'"
                       :ref="archer.id+'_'+detail.id"
                       @blur="editing = ''"
                       v-model="archer.registration[detail.option].value"
                       v-validate="detail.required ? 'required|email' : 'email'"
                       :name="archer.id+'_'+detail.id"
                       class="bs-form-box w-full">
                <input v-else
                       :ref="archer.id+'_'+detail.id"
                       @blur="editing = ''"
                       v-model="archer.registration[detail.option].value"
                       v-validate="detail.required ? 'required' : ''"
                       :name="archer.id+'_'+detail.id"
                       class="bs-form-box w-full"
                       :class="errors.first(archer.id+'_'+detail.id) ? 'border-b border-red-600' : ''">
              </span>
            </span>
          </span>
        </td>
        <td v-if="showTimes" @click="editField(archer.id,'time')">
          <span v-if="selectedTime(archer) !== null" @click.prevent> {{ selectedTime(archer) }}</span>
          <a v-else href="" @click.prevent class="border-b border-red-600">none</a>
          <span @click.stop v-show="editing === archer.id+'_time'">
            <select
              :ref="archer.id+'_time'"
              v-model="archer.registration['line_time'].value"
              :name="archer.id+'_time'"
              v-validate="'required'"
              @blur="editing = archer.id+'_time' ? '' : editing"
              class="bs-form-box w-full">
              <option :value="null">None Selected</option>
              <option v-for="option in availableTimesArcher(archer)"
                      :disabled="option.available <= 0"
                      :value="option.time.id">
                {{ option.time.time }} {{ option.time.memo ? '(' + option.time.memo + ')' : '' }}
                <span v-if="option.available <= 0">&nbsp;&nbsp;&nbsp;{{ fullText(option) }}</span>
              </option>
            </select>
          </span>
        </td>
        <td v-if="showTarget" @click="editField(archer.id,'target')">
          <span @click.prevent v-if="archer.registration['target'].value"
                :class="errors.first(archer.id+'_target') ? 'border-b border-red-600' : ''">
            {{ targetValue(archer) }}
          </span>
          <a href="" @click.prevent v-else class="border-b border-red-600">none</a>
          <span @click.stop v-show="editing === archer.id+'_target'">
            <select
              :ref="archer.id+'_target'"
              v-model="archer.registration['target'].value"
              :name="archer.id+'_target'"
              v-validate="'required'"
              @blur="editing = archer.id+'_target' ? '' : editing; resetTimes(archer)"
              class="bs-form-box w-full">
              <option
                v-for="option in targetOptions"
                :value="option.id"
                v-if="!userRestricted(archer, option.id, 'target')">
                {{ option.name }}
              </option>
            </select>
          </span>
        </td>
      
      </tr>
    </table>
    <div class="text-red-600 text-lg" v-show="hasErrors">
      Invalid fields are underlined in red. All of these issues must be fixed to continue.
    </div>
    <div class="text-lg" v-show="anyFull">
      If you wish to be put on the wait list for a full line time, you must register each competitor individually
      <router-link to="/register">
        (click here)
      </router-link>
    </div>
  </div>
</template>

<script>
import ImageUpload from "../../partials/ImageUpload.vue";

export default {
  name: "GroupFormDataOld",
  components: {ImageUpload},
  props: ['tournament', 'checkedNames'],
  data() {
    return {
      editing: '',
      componentKey: 0,
      userRestrictions: {},
      userTimes: {},
    }
  },
  computed: {
    anyFull() {
      for (let time of this.availableTimes) {
        if (time.available <= 0) return true;
      }
      return false;
    },
    availableTimes() {
      let roundId = this.tournament.rounds[0]['id'];
      let timeList = [];
      for (let time of this.tournament.rounds[0]['line_times']) {
        let list = [];
        let assignedCount = this.assignedCount(roundId, time['id']);
        let available = this.totalSpots(this.tournament.rounds[0])
        list['available'] = available - assignedCount;
        list['time'] = time;
        timeList.push(list);
      }
      return timeList;
    },
    hasErrors() {
      return this.errors.items.length;
    },
    showTimes() {
      return this.tournament.rounds[0].line_times.length > 1;
    },
    showTarget() {
      return this.tournament.targets !== null && this.tournament.targets.length > 1;
    },
    targetOptions() {
      return this.tournament.targets;
    }
  },
  methods: {
    resetTimes(archer) {
      delete this.userTimes[archer.id];
      archer.registration['line_time'].value = null;
    },
    
    fullText(time) {
      let text = 'Full';
      if (time.type !== 'full') text = time.restrictions;
      if (time.full_restricted) text = 'Full - Restricted';
      return `*** ${text.toUpperCase()} ***`;
    },
    baleRestrictions(locationData, time, roundId) {
      let restrictions = [];
      let waitList = false;
      for (let baleIndex in locationData.bales) {
        let baleData = locationData.bales[baleIndex];
        if (!baleData.restrictions) {
          // location restriction failed?
          if (!baleData.allowed) continue;
          let assignedCount = this.assignedCount(roundId, time['id'], locationData['location'], baleIndex);
          let available = this.tournament.bale_competitors;
          let space = available - assignedCount;
          if (space > 0) return {'available': true};
        } else {
          if (baleData.allowed) {
            //There are restrictions, but the archer matches
            let assignedCount = this.assignedCount(roundId, time['id'], locationData['location'], baleIndex);
            let available = this.tournament.bale_competitors;
            let space = available - assignedCount;
            if (space > 0) {
              return {'available': true};
            } else {
              waitList = true;
            }
          } else {
            restrictions.push(baleData.list);
          }
        }
      }
      return {'available': false, 'restrictions': restrictions, 'waitList': waitList};
    },
    locationRestrictions(locations, time, roundId) {
      let restricted = [];
      let restrictions = [];
      let availability = [];
      let waitList = false;
      for (let locData of locations) {
        if (locData.restrictions) {
          if (locData.allowed) {
            let baleData = this.baleRestrictions(locData, time, roundId);
            if (baleData['available']) {
              return {'time': time, 'available': 1, 'type': 'full'};
            } else {
              if (baleData['waitList']) {
                waitList = true;
                availability.push(false);
                continue;
              } else if (baleData['restrictions'].length) {
                restricted.push(true);
                restrictions = [...restrictions, ...baleData['restrictions']];
                continue;
              }
            }
            let assignedCount = this.assignedCount(roundId, time['id'], locData['location']);
            let available = this.totalSpots(this.tournament.rounds[0], locData['location']);
            let space = available - assignedCount;
            if (space > 0) {
              return {'time': time, 'available': space, 'type': 'full'};
            } else {
              availability.push(false);
            }
          } else {
            restricted.push(true);
            restrictions.push(locData.list);
          }
        } else {
          let baleData = this.baleRestrictions(locData, time, roundId);
          if (baleData['available']) {
            return {'time': time, 'available': 1, 'type': 'full'};
          } else {
            if (baleData['waitList']) {
              waitList = true;
              availability.push(false);
            } else if (baleData['restrictions'].length) {
              restricted.push(true);
              restrictions = [...restrictions, ...baleData['restrictions']];
            }
          }
        }
      }
      if (restricted.length === 0 || restricted.includes(false)) {
        return {
          'time': time,
          'available': availability.length ? 0 : 1,
          'type': 'full',
          'full_restricted': waitList,
        };
      } else {
        let string = this.restrictionString(restrictions)
        return {'time': time, 'available': 0, 'type': 'restricted', 'restrictions': string};
      }
    },
    restrictionString(restrictions) {
      let restrictionsList = {};
      for (let r of restrictions) {
        for (let restriction of r) {
          let split = restriction.split(': ');
          let name = split[0];
          if (!(name in restrictionsList)) restrictionsList[name] = [];
          restrictionsList[name].push(split[1])
        }
      }
      let string = '';
      for (let key of Object.keys(restrictionsList)) {
        string += key + ': ';
        let set = [...new Set(restrictionsList[key])];
        string += set.join(',') + ' ';
      }
      return string;
    },
    noRestrictions(roundId, time) {
      let assignedCount = this.assignedCount(roundId, time['id']);
      let available = this.totalSpots(this.tournament.rounds[0]);
      return {'type': 'full', 'available': available - assignedCount, 'time': time};
    },
    assignedCount(roundId, timeId, locId, bale) {
      let nested = this.tournament.assignedCompetitors.rounds[roundId].line_times[timeId];
      if (locId) nested = nested.locations[locId];
      if (bale) {
        let intBale = parseInt(bale) + 1;
        return nested.bales[intBale].competitors.length;
      }
      return nested.competitors_count;
    },
    totalSpots(round, locationId) {
      let count = 0;
      round.locations.forEach(function (location) {
        if (!locationId || locationId === location.id) {
          count += Object.keys(location.bales).length;
        }
      });
      return (count * this.tournament.bale_competitors)
    },
    
    setTimes(archerId, data) {
      let roundId = this.tournament.rounds[0]['id'];
      let timeList = [];
      let restrictDetails = data;
      if (restrictDetails == null) return timeList;
      
      for (let time of this.tournament.rounds[0]['line_times']) {
        let timeData = restrictDetails[time['id']];
        if (!timeData.restrictions) {
          timeList.push(this.noRestrictions(roundId, time));
          continue;
        }
        timeList.push(this.locationRestrictions(timeData.locations, time, roundId));
      }
      this.userTimes[archerId] = timeList;
      return timeList;
      
      //
      //
      // for (let time of this.tournament.rounds[0]['line_times']) {
      //   let list = [];
      //   let full = true;
      //   if (Object.keys(restrictDetails).length && restrictDetails[time['id']] != null) {
      //     let assTime = this.tournament.assignedCompetitors.rounds[roundId].line_times[time['id']];
      //     for (let key of restrictDetails[time['id']].keys()) {
      //       let restrictedLoc = restrictDetails[time['id']][key];
      //       let loc = this.tournament.rounds[0]['locations'][key];
      //       let assLoc = assTime.locations[loc['id']];
      //       let perBale = this.tournament.bale_competitors;
      //       if (restrictedLoc.length === 0) {
      //         //all bales are available
      //         let allowed = Object.keys(assLoc.bales).length * perBale;
      //         let available = (allowed - assLoc.competitors_count) > 0;
      //         if (available) {
      //           full = false;
      //         }
      //       } else {
      //         //only listed bales are available
      //         for (let bale of restrictedLoc) {
      //           let assBale = assLoc.bales[parseInt(bale) + 1];
      //           if (assBale == null) {
      //             //bale was deleted after assignment settings made
      //             continue;
      //           }
      //           let ass = assBale.competitors.length;
      //           let available = (perBale - ass) > 0;
      //           if (available) {
      //             full = false;
      //             break;
      //           }
      //         }
      //       }
      //       if (!full) break;
      //     }
      //   } else {
      //     let assignedCount = this.assignedCount(roundId, time['id']);
      //     let available = this.totalSpots(this.tournament.rounds[0]);
      //     full = (available - assignedCount) <= 0;
      //   }
      //   list['available'] = full ? 0 : 1;
      //   list['time'] = time;
      //   timeList.push(list);
      // }
      // this.userTimes[archerId] = timeList;
      // return timeList;
    },
    
    availableTimesArcher(archer) {
      if (this.userTimes[archer.id]) return this.userTimes[archer.id];
      let timeList = [];
      console.log(archer.name);
      axios.post('/tournaments/' + this.tournament.slug + '/available_times',
        {data: archer.registration, group: true},
      )
      .then(({data}) => {
        if (!data.success) return timeList;
        if (data.detailedTimes !== null) {
          return this.setTimes(archer.id, data.detailedTimes);
        }
        
      }).catch((error) => {
      
      });
      
      //
      // let roundId = this.tournament.rounds[0]['id'];
      // let timeList = [];
      // for (let time of this.tournament.rounds[0]['line_times']) {
      //   let list = [];
      //   let assignedCount = this.assignedCount(roundId, time['id']);
      //   let available = this.totalSpots(this.tournament.rounds[0])
      //   list['available'] = available - assignedCount;
      //   list['time'] = time;
      //   timeList.push(list);
      // }
      this.userTimes[archer.id] = timeList;
      return timeList;
    },
    selectedTime(archer) {
      if (archer.registration['line_time'].value == null) return null;
      let availableTimes = this.availableTimes.find(f => f.time.id === archer.registration['line_time'].value);
      return availableTimes ? availableTimes.time.time : null;
    },
    // assignedCount(roundId, timeId) {
    //   return this.tournament.assignedCompetitors.rounds[roundId].line_times[timeId].competitors_count;
    // },
    // totalSpots(round) {
    //   let count = 0;
    //   round.locations.forEach(function (location) {
    //     count += Object.keys(location.bales).length;
    //   });
    //   return (count * this.tournament.bale_competitors)
    // },
    cellClicked(archer, detail) {
      if (detail.type === 'waiver') return this.waiver(detail, archer.registration[detail.option])
      return this.editField(archer.id, detail.id);
    },
    cellHasValue(archer, detail) {
      if (this.cellValue(archer, detail) && this.cellValue(archer, detail).length) return true;
      return detail.name === 'First Name' || detail.name === 'Last Name';
    },
    cellValue(archer, detail) {
      if (archer.registration[detail.option].value) {
      } else if (detail.name === 'First Name') {
        archer.registration[detail.option].value = archer.first_name;
      } else if (detail.name === 'Last Name') {
        archer.registration[detail.option].value = archer.last_name;
      }
      return archer.registration[detail.option].value;
    },
    editField(archer_id, detail_id) {
      this.editing = archer_id + '_' + detail_id;
      this.$nextTick(() => this.$refs[archer_id + '_' + detail_id][0].focus());
    },
    imageSource(archer, detail) {
      if (archer.registration[detail.option].enabled) {
        return this.cellValue(archer, detail);
      }
      return '/img/imagePending.jpg';
    },
    isEditing(archer, detail) {
      return this.editing === archer.id + '_' + detail.id;
    },
    removeImage(archer, option) {
      archer.registration[option].value = '';
    },
    requiredClass(archer, detail) {
      return detail.required ? 'border-b border-red-600' : ''
    },
    targetValue(archer) {
      let targetId = parseInt(archer.registration['target'].value);
      let value = this.tournament.targets.find(f => parseInt(f.id) === targetId).name;
      return value;
    },
    updateImage(archer, details) {
      let [url, enabled, option] = details;
      archer.registration[option].value = url;
      archer.registration[option].enabled = enabled;
    },
    userRestricted(archer, id, type) {
      if (!Object.values(this.userRestrictions).length) return false;
      if (this.userRestrictions.hasOwnProperty(archer.name)) {
        return this.userRestrictions[archer.name][type].includes(id);
      }
      return true;
    },
    waiver(detail, archer) {
      Swal.fire({
        html: `<pre class="whitespace-pre-line overflow-y-auto"
                     style="font-family: 'Roboto', sans-serif;"
                     v-if="detail.placeholder">${detail.placeholder}</pre>`,
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        animation: false,
      }).then(({value, dismiss}) => {
        if (value) {
          this.archer['value'] = 'accepted';
          this.componentKey++;
        }
        
      })
    },
  },
  
}
</script>

<style scoped>
.vue-form-wizard :deep( .wizard-title ) {
  @apply text-3xl;
}

a {
  @apply text-blue-600 cursor-pointer;
}
</style>
