<script setup>

import {Field} from "vee-validate";
import {useTournamentStore} from "../../../stores/TournamentStore";
import TournamentExtraPrice from "./TournamentExtraPrice.vue";
import FormCheckbox from "./FormCheckbox.vue";
import {computed} from "vue";

const store = useTournamentStore();

const showCheckbox = computed(() => {
  if (store.price == null) return false;
  return store.registrationExtras.length;
})
</script>

<template>
  <div class="bs-card">
    <div class="bs-card-header">Registration Price</div>
    <div class="flex">
      <div class="flex-1">
        <div class="bs-section-header">Tournament price</div>
        <div class="flex moneyContainer">
          <span class="dollarSign"><i class="fa-regular fa-lg fa-dollar-sign"></i></span>
          <Field v-model="store.price" class="bs-input-box money" name="tournament_price" placeholder="0"/>
          <span class="cents">.00</span>
        </div>
      </div>
      <div class="flex-1 mx-2">
        <div class="bs-section-header">Bowscore fee</div>
        <div class="bs-input-box flex justify-between text-stone-400 bg-stone-100">
          <div class="flex flex-col justify-center">
            <i class="fa-regular fa-lg fa-dollar-sign"></i>
          </div>
          <span>{{ store.bowscoreFee }}.00</span>
        </div>
      </div>
      <div class="flex-1">
        <div class="bs-section-header">Registration total</div>
        <div class="bs-input-box flex justify-between text-stone-400 bg-stone-100">
          <div class="flex flex-col justify-center">
            <i class="fa-regular fa-lg fa-dollar-sign"></i>
          </div>
          <span class="text-black">{{ store.registrationTotal }}.00</span>
        </div>
      </div>
    </div>
    <FormCheckbox @click="store.allowOffline = !store.allowOffline" :active="store.allowOffline"
                  :disabled="!store.hasStripe" class="my-4"
                  title="Allow offline payments" sub-title="Clubs without Stripe will always have this selected"/>
    <div v-if="showCheckbox" class="mt-2">
      <FormCheckbox @click="store.useCustomPricing = !store.useCustomPricing" :active="store.useCustomPricing"
                    title="Set price by registration options (class, division, etc)"/>
    </div>
    <TournamentExtraPrice/>
  </div>
</template>

<style scoped>

.bs-input-box {
  height: 34px;
  font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media (max-width: 420px) {
  .bs-section-header {
    height: 30px;
  }
}

.money {
  font-size: 16px !important;
  padding-right: 36px;
  text-align: right;
}

.moneyContainer {
  @apply text-stone-400;
  font-size: 16px !important;
  position: relative;
  display: inline-block;
  letter-spacing: .32px;
}

.cents {
  position: absolute;
  right: 13px;
  top: 5px;
}

.dollarSign {
  position: absolute;
  left: 10px;
  top: 4.5px;
}
</style>