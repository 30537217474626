import {defineStore} from "pinia";

export const useResultsStore = defineStore('results', {
  state: () => {
    return {
      lineTime: null,
      location: null,
      end: 1,
      round: {},
      overall: null,
      title: null,
      selectedRound: null,
      selectedClass: null,
      selectedDivision: null,
      selectedCompetitor: null,
      selectedTarget: null,
      selectedGender: null,
      selectedTeam: null,
      fullBreakdown: false,
      search: '',
      fullSelectionLink: null,
      printing: false,
      teams: [],
      orderedC: 0,
      refreshing: false,
      showUpdated: false,
      minimize: false,
      breakdownArray: {},
      limitBreak: false,
      allDuplicates: null,
      count: 0,
      regform: null,
      useHandicap: false,
      tieBreakers: null,
      unassignedCompetitors: [],
      tournamentName: '',
      rounds: null,
      targetValues: null,
      targetStyle: null,
      isLeague: false,
      handicap: null,
      targets: null,
      showLadders: false,
    };
  },

  getters: {
    divisionArray(state) {
      let options = state.regform.find(detail => detail.option === 'division');
      if (options && options.enabled) {
        return options.multiple;
      } else {
        return [null];
      }
    },
    classArray(state) {
      let options = state.regform.find(detail => detail.option === 'class');
      if (options && options.enabled) {
        return options.multiple
      } else {
        return [null];
      }
    },
    genderArray(state) {
      let options = state.regform.find(detail => detail.option === 'gender');
      if (options && options.enabled) {
        return options.multiple
      } else {
        return [null];
      }
    },
    filterList(state) {
      let list = [];
      if (state.divisionArray && state.divisionArray.length > 1) {
        list.push('division')
      }
      if (state.classArray && state.classArray.length > 1) {
        list.push('class')
      }
      if (state.targets.length > 1) {
        list.push('target')
      }
      if (state.genderArray && state.genderArray.length > 1) {
        list.push('gender')
      }
      return list;
    },

    longestRound(state) {
      let count = 0;
      let nope = false;
      state.rounds.forEach(round => round.ends_count > count ? count = round.ends_count : nope = true);
      return count;
    },

    filteredUnassigned(state) {
      let competitors = state.unassignedCompetitors;
      if (state.search.length) {
        let search = state.search.toLowerCase();
        competitors = competitors.filter(function (competitor) {
          let name = competitor.name.toLowerCase();
          return name.includes(search);
        })
      }
      return competitors;
    },

  },
  actions: {
    updateUrl() {
      let filters = {};
      if (!this.overall) {
        filters['round'] = this.round.slug;
      }
      if (this.selectedTeam) {
        filters['team'] = this.selectedTeam;
      }
      if (this.selectedDivision) {
        filters['division'] = this.selectedDivision.label;
      }
      if (this.selectedClass) {
        filters['class'] = this.selectedClass.label;
      }
      if (this.selectedTarget) {
        filters['target'] = this.selectedTarget.name;
      }
      if (this.selectedGender) {
        filters['gender'] = this.selectedGender.label;
      }
      if (this.selectedCompetitor) {
        filters['competitor'] = this.selectedCompetitor;
      }
      if (this.search) {
        filters['search'] = this.search;
      }
      // this.router.push({query: filters}).catch(() => {
      // });
      this.fullSelectionLink = window.location.href;
    },
    competitorDuplicates(competitorId) {
      if (this.allDuplicates === null) return [];
      if (competitorId in this.allDuplicates) {
        return this.allDuplicates[competitorId];
      }
      return [];
    },
  },
})