<script setup>

import {useDashboardStore} from "../../../stores/DashboardStore";


const store = useDashboardStore();

const props = defineProps(['name'])


const updateDisplay = (type) => {
  if (type === 'up') {
    store.moveUp(props.name)
  } else if (type === 'down') {
    store.moveDown(props.name)
  } else if (type === 'reset') {
    store.reset()
  } else {
    store.hide(props.name)
  }
  store.toggled = null;
}
</script>

<template>
  <div>
    <div v-if="store.toggled === props.name" class="menuSelect bs-card ">
      <div class="flex flex-col">
        <button v-if="store.order.length > 1" @click="updateDisplay('up')" value="up">Move Up</button>
        <button v-if="store.order.length > 1" @click="updateDisplay('down')" value="down">Move Down</button>
        <button v-if="store.order.length > 1" @click="updateDisplay('hide')" value="hide">Hide</button>
        <button @click="updateDisplay('reset')" value="reset">Show All</button>
      </div>
    </div>
    <div class="menuButton" @click.stop="store.toggle(props.name)">
      <i class="fa-regular fa-ellipsis m-5"></i>
    </div>
  </div>
</template>

<style scoped>
button {
  text-align: start;
}

.menuSelect {
  @apply font-medium;
  line-height: 25px;
  position: absolute;
  top: 35px;
  right: 10px;
  z-index: 100;
  text-align: start;
}

.menuButton {
  position: absolute;
  top: 12px;
  right: 0;
  z-index: 5;
}
</style>