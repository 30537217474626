<template>
  <div v-if="showButton" class="flex flex-col justify-center">
    <div class="btn-md btn-outline-blue-600 mr-4 whitespace-nowrap" @click="addClicked()">
      +<i class="fa fa-bow-arrow"></i>
    </div>
  </div>
</template>
<script>
import mixin from "../../../mixins/scoring_mixins";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "AddArrow",
  props: ['competitorId', 'end'],
  mixins: [mixin],
  computed: {
    ...mapWritableState(useDisplayStore, ['localExtra']),
    extraKey() {
      return `${this.tournament.id}_${this.roundIndex}_${this.end}`;
    },
    extra() {
      if (this.localExtra.hasOwnProperty(this.extraKey)) {
        return this.localExtra[this.extraKey];
      }
      return 0;
    },
    score() {
      let round = `${(this.roundWord.toLowerCase())}s`
      return this.tournament.scoreList[this.competitorId][round][this.roundIndex];
    },
    allExtra() {
      return this.extraArrows + this.extra;
    },
    extraArrows() {
      if (this.isLadder && this.tournament.extra_arrows.length) {
        let copy = [...this.tournament.extra_arrows];
        let vm = this;
        let filtered = copy.filter(a => {
          return a.ladder_id === vm.ladder.id && a.step === vm.step;
        })
        return filtered.length;
      }
      return 0;
    },
    complete() {
      let arrows = this.roundItem.arrows_count * this.ladder.ends_count;
      return (arrows + this.allExtra) === this.score.complete;
    },
    versus() {
      let assignments = this.assignedCompetitors.ladder[this.ladder.id].step[this.step];
      if (this.ladder.shoot_out) {
        if (assignments.competitors_count === 2) {
          for (let bale of Object.values(assignments.bales)) {
            if (bale.competitors[0] !== this.competitorId) return bale.competitors[0];
          }
        }
      }
      return null;
    },
    versusScore() {
      if (this.versus == null) return null;
      let round = `${(this.roundWord.toLowerCase())}s`
      return this.tournament.scoreList[this.versus][round][this.roundIndex];
    },
    showButton() {
      if (!this.ladder) return false;
      if (this.ladder.ends_count !== this.end) return false;
      if (!this.complete) return false;
      if (this.ladder.shoot_out && (this.versus !== null)) {
        let arrowCount = (this.roundItem.arrows_count * this.ladder.ends_count) + this.allExtra;
        let vComplete = this.versusScore.complete === arrowCount;
        let cComplete = this.score.complete === arrowCount;
        if (vComplete && cComplete) {
          return this.versusScore.points === this.score.points;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    addClicked() {
      let data = {
        'tournament_id': this.tournament.id,
        'round_id': this.round === null ? null : this.round.id,
        'ladder_id': this.round === null ? this.roundItem.id : null,
        'step': this.round === null ? this.step : null,
        'end': this.end,
        'arrow': this.roundItem.arrows_count + this.allExtra + 1,
      };
      
      let shortScore = {
        arrow: data.arrow,
        target_hit: null,
        target_id: null,
        score: null
      }
      if (!this.localExtra.hasOwnProperty(this.extraKey)) {
        this.localExtra[this.extraKey] = 0;
      }
      this.localExtra[this.extraKey] = this.localExtra[this.extraKey] + 1;
      
      let score = this.tournament.scoreList[this.competitorId][`${this.roundWord.toLowerCase()}s`][this.roundIndex]
        .ends[this.end];
      if (score.extras === null) score.extras = {};
      score.extras[data.arrow] = shortScore;
      
      if (this.versus != null) {
        let vScore = this.tournament.scoreList[this.versus][`${this.roundWord.toLowerCase()}s`][this.roundIndex]
          .ends[this.end];
        if (vScore.extras === null) vScore.extras = {};
        vScore.extras[data.arrow] = shortScore;
      }
      this.$axios.post(`/tournaments/${this.tournament.slug}/add_arrow`, {
        'data': data,
      })
    }
  }
  
}
</script>
<style scoped>

</style>