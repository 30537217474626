<template>
  <span class="text-blue-600">
    <span v-if="!displayMessage" @click="displayMessage = true"><i class="fal fa-info-circle"></i></span>
    <div class="bg-white z-10 relative p-2" :class='size ? size : "w-full md:w-1/2"' v-if="displayMessage">
      <span @click="displayMessage = false" class="flex justify-end">
        <i class="fas fa-times"></i>
      </span>
      <slot name="message"></slot>
    </div>
  </span>

</template>

<script>
export default {
  name: "ToolTip",
  props: ['size'],
  data() {
    return {
      displayMessage: false,
    }
  }
}
</script>

<style scoped>

</style>