<template>
    <div class="my-2 flex">
        <input @change="$emit('toggled')" type="checkbox" :ref="id" class="switch" :id="id" :checked="model">
        <div class="h-center ml-2">
            <label class="text-lg mb-0" :for="id">
                {{label}}
            </label>
            <div v-html="subLabel" class="text-sm text-gray-600">

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "FormSwitch",
        props: ['id', 'model', 'label', 'subLabel']
    }
</script>

<style scoped>

</style>
