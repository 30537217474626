<script setup>
import {computed} from "vue";
import SimpleCard from "./SimpleCard.vue";

const props = defineProps(['roles', 'name'])

const header = computed(() => {
  if ('competitor' in props.roles) return 'Compete with friends!';
  if (props.roles.length) return 'Simplify scoring - download the Bowscore app!';
  return 'Score from your mobile device'
})

const link = computed(() => {
  let platform = navigator.platform;
  if (['iPhone', 'iPod', 'iPad', 'Macintosh', 'MacPPC', 'mac68k', 'MacIntel'].includes(platform)) {
    return 'https://apps.apple.com/us/app/bowscore/id6480208560';
  } else {
    let ua = navigator.userAgent;
    let isKindle = /Kindle/i.test(ua) || /Silk/i.test(ua) || /KFTT/i.test(ua) || /KFOT/i.test(ua) || /KFJWA/i.test(ua) || /KFJWI/i.test(ua) || /KFSOWI/i.test(ua) || /KFTHWA/i.test(ua) || /KFTHWI/i.test(ua) || /KFAPWA/i.test(ua) || /KFAPWI/i.test(ua);
    if (isKindle) return 'https://www.amazon.com/gp/product/B0D1W5G6SJ';
    return 'https://play.google.com/store/apps/details?id=com.scoringtech.bowscore';
  }
})

const text = computed(() => {
  if (props.roles.length === 0 || 'competitor' in props.roles) {
    return "Download the Bowscore app and easily score your practice sessions with friends. It's completely free and available on iOS, Android and Amazon devices.";
  }
  return "Download the Bowscore app and easily score your next tournament. It’s free to use and available on iOS, Android, and Amazon devices.";
})
</script>

<template>
  <simple-card :header="header" :text="text" buttonText="Download the app" :buttonLink="link" :name="name">
    <div class="iconBox flex flex-col justify-center">
      <img style="width:80px;" src="/img/Bowscore_splash_logo.png" alt="Bowscore" class="mx-auto">
    </div>
  
  </simple-card>
</template>

<style scoped>
.iconBox {
  @apply bg-orange-600;
  border-radius: 30px;
  margin: 1rem 6.25rem;
  width: 136px;
  height: 136px;
  background-image: linear-gradient(#FF7C02, #F05523);
}

@media (max-width: 768px) {
  .iconBox {
    min-width: 125px;
    width: 125px;
    height: 125px;
    margin: .5rem 0 .5rem .5rem;
  }
}
</style>