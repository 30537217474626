<template>
  <div>
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 p-2">
        <div class="flex justify-between">
          <div class="h-center">
            <label for="price">Price</label>
          </div>
          <div class="flex bs-form-box p-0">
            <div class=" bg-gray-200 px-3 flex-extralight h-center">$</div>
            <input class="ml-2 flex-grow" type="number" v-model="tournament.payments.price" min="0"
                   v-validate="'required|min_value:0|max_value:1000000'" id="price" name="price" required>
            <div class=" bg-gray-200 h-center px-2 flex-extralight">
              .00
            </div>
          </div>
        </div>
        <div class="text-sm text-red-600 text-right">{{ errors.first('price') }}</div>
        <div class="flex" v-for="(tp, index) in timedPrice">
          <label class="mr-2">
            Price
            <div class="flex bs-form-box p-0">
              <div class=" bg-gray-200 px-3 flex-extralight h-center">$</div>
              <input class="ml-2 flex-grow" type="number" v-model="timedPrice[index].new_price" min="0"
                     :name="'Timed Price ' + (index + 1)"
                     v-validate="'required|min_value:0|max_value:1000000'" required>
              <div class=" bg-gray-200 h-center px-2 flex-extralight">
                .00
              </div>
            </div>
            <span class="text-sm text-red-600">{{ errors.first('Timed Price ' + (index + 1)) }}</span>
          </label>
          <label>
            Date/Time to Activate
            <flat-pickr
              class="bs-form-box w-full"
              v-model="timedPrice[index].post_at"
              v-validate="'required'"
              :name="'Date/Time to Activate ' + (index + 1)"
              :config="timeOptions"
            ></flat-pickr>
            <span class="text-sm text-red-600">{{
                errors.first('Date/Time to Activate ' + (index + 1))
              }}</span>
          </label>
          <div class="flex flex-col justify-end my-2 ml-2">
            <button @click="timedPrice.splice(index, 1)" class="btn btn-gray-600">
              <i class="fas fa-trash-alt"></i></button>
          </div>
        </div>
        <div class="flex justify-end my-1">
          <button @click.prevent="createTimedPrice" class="btn-sm btn-gray-600"
                  :class="timedPrice.length ? 'text-center': ''">
            {{ timedPrice.length ? 'Add' : 'Create' }} Timed Price Change
          </button>
          <button @click.prevent="createExtra"
                  v-if="!tournament.payments.pricing_extras || !tournament.payments.pricing_extras.length"
                  class="btn-sm btn-blue-600 ml-2">Create Price Adjustments
          </button>
        </div>
        <div v-if="tournament.payments.pricing_extras.length"
             v-for="(price, index) in tournament.payments.pricing_extras"
             class="flex flex-wrap justify-between my-1">
          <image-upload
            :title="'Optional 400x400 Image'"
            :option="`product_extra_${index}`"
            :type="'product'"
            :file_id="`${tournament.id}_${index}`"
            @update_image="updateImage(index, $event)"
            @remove_image="removeImage(index, $event)"
          >
            <template v-slot:open-modal>
              <img v-if="price['imageUrl'] !== undefined" type="button" data-toggle="modal"
                   data-target="#cropper" :style="'width:34px;height:34px'" alt="product image"
                   :src="price['enabled'] ? price['imageUrl'] : '/img/imagePending.jpg'"
                   data-backdrop="true" class="align-middle">
              <span v-else type="button" data-toggle="modal" data-target="#cropper"
                    style="width:34px; height:34px"
                    data-backdrop="true" class="text-blue-600 cursor-pointer h-center text-center"><i
                class="fas fa-image"></i></span>
            </template>
          </image-upload>
          <input class="bs-form-box w-32" type="text" placeholder="Adjustment Name"
                 :name="'name ' + (index + 1)" v-validate="'required|clean|json'" v-model="price.name">
          <select class="bs-form-box" v-model="price.type" v-validate="'required'"
                  :name="'type ' + (index + 1)">
            <option value="+">increase</option>
            <option value="-">decrease</option>
          </select>
          <div class="flex bs-form-box p-0">
            <div class="bg-gray-200 px-3 flex-extralight h-center">$</div>
            <input class="ml-2 w-12" type="number" v-model="price.amount" :name="'price ' + (index + 1)"
                   v-validate="'required|min_value:0|max_value:10000'" min="0" required>
            <div class=" bg-gray-200 h-center px-2 flex-extralight">.00</div>
          </div>
          <div @click.prevent="removeExtra(index)" class="btn btn-gray-600">
            <i class="fas fa-trash-alt"></i>
          </div>
          <div class="text-sm text-red-600 justify-between flex w-full">
            <span>{{ errors.first('name ' + (index + 1)) }}</span>
            <span>{{ errors.first('type ' + (index + 1)) }}</span>
            <span>{{ errors.first('price ' + (index + 1)) }}</span>
          </div>
        </div>
        <div v-if="tournament.payments.pricing_extras && tournament.payments.pricing_extras.length"
             class="flex justify-center my-1">
          <button @click.prevent="createExtra" class="btn-sm btn-blue-600">Add Another Adjustment</button>
        </div>
        
        <div class="flex justify-between">
          <label>Processing Fee</label>
          <div>
            ${{ tournament.payments.processing_fee }}.00
          </div>
        </div>
        <hr>
        <div class="flex justify-between">
          <label>Total</label>
          <div>
            ${{ total }}.00
          </div>
        </div>
        <div v-if="setupPayments" class="text-center p-2 text-red-800 bg-red-100">
          This club is not set up to take online payments.<br> Visit
          <a :href="'/clubs/' + club.slug + '/settings/'" target="_blank" class="text-blue-500">payment
            settings</a> to enable
          this feature.
        </div>
        <div v-else>
          <div v-if="canPayOffline" class="mt-2 custom-control custom-switch">
            <input @change="$emit('toggleOfflinePayments')" type="checkbox" class="custom-control-input"
                   id="allowOffline" v-model="tournament.payments.allow_offline_payments">
            <label class="custom-control-label" for="allowOffline">
              Allow Offline Payments
              <small class="text-black-50">
                competitors can pay at the tournament
              </small>
            </label>
          </div>
          <div class="mt-2 custom-control custom-switch">
            <input @change="$emit('toggleFullPay')" type="checkbox" class="custom-control-input"
                   id="fullPayAtRegister" v-model="tournament.payments.require_full_pay_at_register">
            <label class="custom-control-label" for="fullPayAtRegister">
              Require Full Payment to Register
            </label>
          </div>
          <div class="mt-2 custom-control custom-switch">
            <input @change="$emit('toggleDownPay')" type="checkbox" class="custom-control-input"
                   id="downPayAtRegister" v-model="tournament.payments.require_down_pay_at_register">
            <label class="custom-control-label" for="downPayAtRegister">
              Require Down Payment to Register
            </label>
          </div>
          <div v-if="tournament.payments.require_down_pay_at_register" class="w-full">
            <div class="flex justify-between">
              <div class="flex flex-col justify-center">
                <label for="down price">Down Payment Amount</label>
              </div>
              <div class="flex bs-form-box p-0">
                <div class="bg-gray-200 h-center px-3 flex-extralight">$</div>
                <input class="ml-2 flex-grow" type="number"
                       v-model="tournament.payments.down_payment_amount" min="1"
                       v-validate="'required|min_value:1|max_value:' + total" name="down price"
                       id="down price"
                       required>
                <div class="bg-gray-200 h-center px-2 flex-extralight">
                  .00
                </div>
              </div>
            </div>
            <div class="text-sm text-red-600 text-right">{{ errors.first('down price') }}</div>
          
          </div>
          <div v-if="selectGateWay" class="border-2 border-blue-600 rounded p-2">
            <div class="mb-2">This club is set up to take both Stripe and Authorize.net payments. Select the
              payment gateway used for this tournament.
            </div>
            <div>
              <input v-model="tournament.payments.use_stripe"
                     id="stripe"
                     type="radio"
                     name="useStripe"
                     :value="1"
                     class="form-check-input">
              <label class="form-check-label">Stripe </label>
            </div>
            <div>
              <input v-model="tournament.payments.use_stripe"
                     id="auth"
                     name="useStripe"
                     type="radio"
                     :value="0"
                     class="form-check-input">
              <label class="form-check-label"> Authorize.net </label>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 p-4 bg-blue-100 rounded mt-2">
        <h2 class="text-2xl">Bowscore Fee</h2>
        <hr>
        <p class="p-2">
          We require a minimal processing fee of $2.00 ($5.00 for a league) per participating archer.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';

export default {
  name: "TournamentPayment",
  props: ['tournament', 'setupPayments', 'total', 'club'],
  data() {
    return {
      timedPrice: this.tournament.timed_prices,
    }
  },
  mounted() {
    if (this.selectGateWay && (this.tournament.payments.use_stripe == null)) {
      this.tournament.payments.use_stripe = 1;
    }
  },
  computed: {
    canPayOffline() {
      return this.godUser || this.user && this.user.id === 3758;
    },
    godUser() {
      let exists = this.user && this.user.roles && this.user.roles.find(f => f.name === 'god');
      return exists != null;
    },
    user() {
      return this.$store.getters['user/getUser'];
    },
    timeOptions() {
      return {
        dateFormat: 'd M Y h:i K',
        enableTime: true,
        minuteIncrement: 60,
        minDate: this.tournament.reg_open,
        maxDate: this.tournament.reg_close,
        allowInput: true,
        plugins: [new ConfirmDatePlugin({confirmText: "Done ", confirmIcon: '', theme: 'light'})],
        static: false,
      };
    },
    selectGateWay() {
      return this.club.stripe && this.club.auth_net_key;
    }
  },
  methods: {
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    createExtra() {
      if (!this.tournament.payments.pricing_extras) {
        this.tournament.payments.pricing_extras = [];
      }
      this.tournament.payments.pricing_extras.push({name: '', type: '+', amount: ''})
    },
    removeExtra(index) {
      this.tournament.payments.pricing_extras.splice(index, 1);
    },
    createTimedPrice() {
      this.timedPrice.push({
        tournament_id: this.tournament.id,
        new_price: this.tournament.payments.price,
        post_at: null
      })
    },
    updateImage(index, details) {
      var [url, enabled, _] = details;
      this.tournament.payments.pricing_extras[index]['imageUrl'] = url;
      this.tournament.payments.pricing_extras[index]['enabled'] = enabled;
    },
    removeImage(index) {
      delete this.tournament.payments.pricing_extras[index].imageUrl;
    },
  },
}
</script>

<style scoped>

</style>
