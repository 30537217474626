<template>
  <div class="bg-blue-100 shadow-inner">
    <div class="flex flex-col">
      <div class="mx-auto mt-2 w-full md:w-3/4 lg:w-1/2 max-w-lg bs-form-box flex">
        <span class="h-center"><i class="fas fa-search"></i></span>
        <input type="text" class="flex-grow pl-2 mx-2" v-model="search" @blur="store.updateUrl()" id="searchBar">
        <div v-if="search !== ''" class="h-center" @click="search = ''">
          <span class=""><i class="fas fa-times"></i></span>
        </div>
      </div>
      <div class="flex justify-center flex-wrap">
        <div>
          <div @click='overallSelected' class="btn-sm m-2" :class="overall?'btn-blue-600':'btn-outline-blue-600'">
            Overall
          </div>
        </div>
        <dropdown v-if="tournament.rounds.length > 1" class="m-2" width="180px">
          <template v-slot:trigger>
            <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
              Round{{ overall ? '' : ': ' + round.name }} <i class="fas fa-caret-down"></i>
            </button>
          </template>
          <a v-for="round in tournament.rounds" class="dropdown-item" href="" @click.prevent="roundSelected(round)">
            {{ round.name }}
          </a>
        </dropdown>
        <div v-else>
          <div
            @click='roundSelected(tournament.rounds[0])' class="btn-sm m-2"
            :class="overall?'btn-outline-blue-600':'btn-blue-600'">
            Breakdown
          </div>
        </div>
        <div v-if="store.divisionArray.length > 1">
          <div class="btn-group m-2">
            <div v-if="selectedDivision" @click="cancelDivision" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown width="180px">
              <template v-slot:trigger class=" ">
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Division{{ selectedDivision ? ': ' + selectedDivision.label : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="division in store.divisionArray" class="dropdown-item" href=""
                 @click.prevent="divisionSelected(division)">{{ division ? division.label : '' }}</a>
            </dropdown>
          </div>
        </div>
        <div v-if="store.classArray.length > 1">
          <div class="btn-group m-2">
            <div v-if="selectedClass" @click="cancelClass" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown v-if="store.classArray" width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Class{{ selectedClass ? ': ' + selectedClass.label : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="c in store.classArray" class="dropdown-item" href=""
                 @click.prevent="classSelected(c)">{{ c ? c.label : '' }}</a>
            </dropdown>
          </div>
        </div>
        <div v-if="store.genderArray">
          <div class="btn-group m-2">
            <div v-if="selectedGender" @click="cancelGender" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Gender{{ selectedGender ? ': ' + selectedGender.label : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="g in store.genderArray" class="dropdown-item" href=""
                 @click.prevent="genderSelected(g)">{{ g ? g.label : '' }}</a>
            </dropdown>
          </div>
        </div>
        <div v-if="tournament.targets.length > 1">
          <div class="btn-group m-2">
            <div v-if="selectedTarget" @click="cancelTarget" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Target{{ selectedTarget ? ': ' + selectedTarget.name : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="target in tournament.targets" class="dropdown-item" href=""
                 @click.prevent="targetSelected(target)">{{ target.name }}</a>
            </dropdown>
          </div>
        </div>
        <div v-if="teams && teams.length">
          <div class="btn-group m-2">
            <div v-if="selectedTeam" @click="cancelTeam" class="btn-sm btn-outline-gray-600">X</div>
            <dropdown width="180px">
              <template v-slot:trigger>
                <button type="button" class="btn-sm btn-gray-600 dropdown-toggle">
                  Team/Club{{ selectedTeam ? ': ' + selectedTeam : '' }}
                  <i class="fas fa-caret-down"></i>
                </button>
              </template>
              <a v-for="team in teams" class="dropdown-item" href="" @click.prevent="teamSelected(team)">{{ team }}</a>
            </dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";

export default {
  name: "ResultFilters",
  props: ['tournament'],
  data() {
    return {
      store: useResultsStore(),
    }
  },
  beforeMount() {
    this.selectFilters();
  },
  computed: {
    ...mapWritableState(useResultsStore, [
      'search',
      'overall',
      'round',
      'teams',
      'selectedClass',
      'selectedDivision',
      'selectedGender',
      'selectedGender',
      'selectedTarget',
      'selectedRound',
      'selectedTeam',
    ]),
  },
  methods: {
    cancelClass() {
      this.selectedClass = null;
      this.store.updateUrl();
    },
    cancelDivision() {
      this.selectedDivision = null;
      this.store.updateUrl();
    },
    cancelGender() {
      this.selectedGender = null;
      this.store.updateUrl();
    },
    cancelTarget() {
      this.selectedTarget = null;
      this.store.updateUrl();
    },
    cancelTeam() {
      this.selectedTeam = null;
      this.store.updateUrl();
    },
    classSelected(c) {
      this.selectedClass = c;
      this.store.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    divisionSelected(division) {
      this.selectedDivision = division;
      this.store.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    genderSelected(gender) {
      this.selectedGender = gender;
      this.store.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    overallSelected() {
      this.overall = true;
      this.round = {};
      this.title = this.tournament.name + ' Overall Results';
      this.store.updateUrl();
    },
    roundSelected(round) {
      this.overall = false;
      this.title = round.name + ' Results';
      this.round = round;
      this.selectedRound = round;
      this.store.updateUrl();
    },
    selectFilters() {
      let query = this.$route.query;
      if (query.round) {
        this.tournament.rounds.forEach((round) => {
          if (round.slug === query.round) {
            this.roundSelected(round);
          }
        });
      }
      if (query.division) {
        let options = this.tournament.regform.find(detail => detail.option === 'division');
        if (options && options.enabled) {
          options.multiple.forEach((option) => {
            if (option.label === query.division) {
              this.divisionSelected(option);
            }
          });
        }
      }
      if (query.class) {
        let options = this.tournament.regform.find(detail => detail.option === 'class');
        if (options && options.enabled) {
          options.multiple.forEach((classOption) => {
            if (classOption.label === query.class) {
              this.classSelected(classOption);
            }
          });
        }
      }
      if (query.target) {
        this.tournament.targets.forEach((target) => {
          if (target.name === query.target) {
            this.targetSelected(target);
          }
        });
        this.selectedTarget.name = query.target;
      }
      if (query.gender) {
        let options = this.tournament.regform.find(detail => detail.option === 'gender');
        if (options && options.enabled) {
          options.multiple.forEach((gender) => {
            if (gender.label === query.gender) {
              this.selectedGender = gender;
            }
          })
        }
      }
      if (query.team) {
        this.selectedTeam = query.team;
      }
      if (query.competitor) {
        this.selectedCompetitor = query.competitor;
      }
      if (query.search) {
        this.search = query.search;
      }
      this.store.updateUrl();
    },
    targetSelected(target) {
      this.selectedTarget = target;
      this.store.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
    teamSelected(team) {
      this.selectedTeam = team;
      this.store.updateUrl();
      if (this.overall) {
        this.overallSelected();
      } else {
        this.roundSelected(this.round);
      }
    },
  }
}
</script>
<style scoped>


</style>