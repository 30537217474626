<script setup>
import {computed} from "vue";
import moment from "moment";
import TournamentDescription from "./TournamentDescription.vue";
import HostPostal from "./postal/HostPostal.vue";
import TournamentEdit from "./TournamentEdit.vue";
import {useTournamentStore} from "../../stores/TournamentStore";

const props = defineProps(['tournamentIn', 'user', 'view'])

const date = computed(() => {
  const start = new Date(props.tournamentIn.start_time);
  let momentStart = moment(start);
  const end = new Date(props.tournamentIn.end_time);
  let momentEnd = moment(end);
  if (momentStart.date() === momentEnd.date()) {
    return momentStart.format('MMMM D, YYYY');
  }
  let dateString = momentStart.format('MMMM D');
  if (momentStart.month() === momentEnd.month()) {
    return `${dateString}${momentEnd.format('-D, YYYY')}`;
  } else if (momentStart.year() === momentEnd.year()) {
    return `${dateString}${momentEnd.format(' - MMMM D, YYYY')}`;
  }
  return `${momentStart.format('MMMM D, YYYY')}${momentEnd.format(' - MMMM D, YYYY')}`;
})

const address = computed(() => props.tournamentIn.address);

const started = computed(() => {
  const start = new Date(props.tournamentIn.start_time);
  let momentStart = moment(start);
  return momentStart.isBefore(moment());
})
const store = useTournamentStore();
</script>

<template>
  <div>
    <tournament-edit v-if="props.user?.auth_manage" :tournament-in="props.tournamentIn"/>
    <div v-else class="pageBody mx-auto">
      <div class="bs-card">
        <div class="bs-section-header">{{ tournamentIn.name }}</div>
        <div class="flex detailText">
          <div>
            <i class="fa-regular fa-lg fa-location-arrow"></i> {{ tournamentIn.address.city }},
            {{ tournamentIn.address.state }}
          </div>
          <div class="ml-6">
            <i class="fa-regular fa-lg fa-calendar"></i> {{ date }}
          </div>
        </div>
        <host-postal v-if="tournamentIn.is_master_postal" :master="tournamentIn"/>
        <div v-if="view" @click.self="" class="mt-4 w-full">
          <router-link v-if="started || tournamentIn.is_master_postal" to="/" class="link">View Results</router-link>
          <router-link v-else-if="tournamentIn.userRegistered" to="/" class="link">View my Registration</router-link>
          <router-link v-else to="/" class="link">Register</router-link>
        </div>
        <div v-else class="mt-4 w-full">
          <router-link v-if="started || tournamentIn.is_master_postal" :to="'/results'" class="link">
            View Results
          </router-link>
          <router-link v-else-if="tournamentIn.userRegistered"
                       :to="`/competitors/${tournamentIn.userRegistered.uuid}`"
                       class="link">
            View my Registration
          </router-link>
          <router-link v-else :to="'/register'" class="link">Register</router-link>
        </div>
      
      </div>
      <TournamentDescription :tournament-in="tournamentIn"/>
      <div class="bs-card">
        <div class="bs-card-header">Hosted by</div>
        <div class="infoText">{{ tournamentIn.club.name }}</div>
        <div class="bs-card-header">Sport</div>
        <div class="infoText">{{ tournamentIn.sport.abbreviation }}</div>
        <div class="bs-card-header">Event Address</div>
        <div class="infoText">
          {{ address.address1 }} {{ address.address2 }} {{ address.city }}, {{ address.state }}
        </div>
      </div>
    </div>
  
  </div>
</template>

<style scoped>

.pageBody {
  max-width: 600px;
  width: 600px;
  min-width: 300px;
  margin-top: 40px;
}


@media (max-width: 576px) {
  .bs-card {
    @apply mx-0 border-0;
    box-shadow: none;
  }
  
  .pageBody {
    margin-top: 0;
  }
}

@media (max-width: 600px) {
  .pageBody {
    width: 100%;
    min-width: 100%;
  }
}

@media (min-width: 600px) {
  .pageBody {
    width: 600px !important;
  }
}

.bs-card-header {
  @apply mb-1;
}

.infoText {
  @apply mb-4 text-black;
  font-size: 16px;
  line-height: 19px;
}

.link {
  @apply w-full block bs-input-box font-bold text-black text-center;
}

.detailText {
  @apply text-stone-400;
  font-size: 14px;
  line-height: 17px;
}
</style>