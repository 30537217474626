<script setup>
import {useTournamentStore} from "../../../stores/TournamentStore";
import {computed} from "vue";

const props = defineProps(['sportList', 'targetList'])

const store = useTournamentStore();

const sportTargets = computed(() => {
  let returnArray = [];
  let targets = this.targetList[this.store.sport ? this.store.sport : 'all'];
  let clubTargets = this.targetList['club'];
  targets = {...targets, ...clubTargets};
  Object.keys(targets).forEach(function (id) {
    let target = {};
    target['id'] = id;
    target['name'] = targets[id];
    returnArray.push(target);
  });
  return returnArray;
});

const selected = (sportId) => {
  store.setSport(sportId);
  store.touched();
}
</script>

<template>
  <div class="bs-card">
    <div class="bs-section-header">Sport (select one)</div>
    <div class="flex flex-wrap">
      <div v-for="(sport, i) in sportList">
        <button @click.prevent="selected(i)" class="bs-input-box" :class="i === store.sport ? 'activeBox' : ''">
          {{ sport }}
        </button>
      </div>
    </div>
  
  </div>
</template>

<style scoped>
.activeBox {
  @apply text-white bg-black;
  color: #FFFFFF !important;
}

.bs-input-box {
  @apply text-black font-medium;
  font-size: 14px;
  letter-spacing: .28px;
  line-height: 17px;
  padding-right: 28px;
  padding-left: 28px;
  margin-right: 8px;
  margin-bottom: 8px;
  width: auto;
}

.bs-card {
  padding-bottom: 8px;
}
</style>