<script setup>

import {useCompetitorStore} from "../../../stores/competitorStore";
import {Field} from "vee-validate";

const store = useCompetitorStore();
</script>

<template>
  <div v-if="store.targets.length > 1" class="bs-card">
    <div class="bs-section-header">Targets</div>
    <div v-for="archer of store.selectedArchers" class="flex mb-2">
      <div class="bs-input-box flex-2 mr-4">{{ archer.name }}</div>
      <Field as="select"
             v-model="store.groupTargets[archer.id]"
             :name="`${archer.name} (Archer ID:${archer.id}) Target`"
             class="bs-input-box flex-2">
        <option v-for="target of store.targets"
                :value="target.id"
                :selected="store.groupTargets[archer.id] === target.id">
          {{ target.name }}
        </option>
      </Field>
    </div>
  </div>
</template>

<style scoped>

</style>