<template>
  <div class="flex flex-wrap">
    <div class="formElement md:w-1/3 md:mt-0">
      <label for="sport">Sport</label>
      <select name="sport" id="sport" class="bs-form-box h-10 w-full" v-model="inc_tournament.sport_id"
              v-validate="'required'" v-on:change="changeSport">
        <option v-for="(sportName,sportId) in sportList"
                :selected="inc_tournament && inc_tournament.sport_id === sportId"
                name="sport" :value="sportId">{{ sportName }}
        </option>
      </select>
      <span class="text-sm text-red-600">{{ errors.first('sport') }}</span>
    </div>
    <div class="formElement md:w-1/3 md:mt-0">
      <label for="target">Targets </label>
      <multiselect
        v-model="inc_tournament.targets"
        :options="sportTargets"
        :searchable="true"
        :show-labels="false"
        :hideSelected="true"
        :placeholder="'Select Targets'"
        :multiple="true"
        label="name"
        v-validate="'required'"
        track-by="id"
        :resetAfter="false"
        name="target"
        id="target"
        class="multiselect_blue"
        @remove="targetRemoved"
      ></multiselect>
      <span class="text-sm text-red-600">{{ errors.first('targets') }}</span>
      <div class="text-gray-600 text-sm" v-if="tournament.targets && tournament.targets.length > 1">
        The first selected target will be the default.
      </div>
      <div class="text-gray-600 text-sm" v-else>
        Pick each target type that will be used.
      </div>
    </div>
    <div class="formElement md:w-1/3 md:mt-0">
      <label for="target style">Target Style</label>
      <select @change="selected" name="target style" id="target style" class="bs-form-box w-full"
              v-model="inc_tournament.target_style" v-validate="'required'">
        <option>Stakes</option>
        <option>Bales</option>
      </select>
      <span class="text-sm text-red-600">{{ errors.first('target style') }}</span>
    </div>
    <div v-if="inc_tournament.targets &&  inc_tournament.targets.length > 1" class="formElement">
      <form-switch
        @toggled="tournament.hide_targets = !tournament.hide_targets"
        :model="tournament.hide_targets"
        :id="'hide targets'"
        :label="'Hide Target From Registration'"
        :sub-label="'Competitors will not be able to choose their target when registering. Targets can still be selected when scoring'"/>
    </div>
  </div>
</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "TournamentSportOld",
  components: {FormSwitch},
  props: ['inc_tournament', 'sportTargets', 'sportList'],
  data() {
    return {
      oldSport: this.inc_tournament.sport_id ? this.inc_tournament.sport_id : null,
      tournament: this.inc_tournament,
      oldTargets: this.inc_tournament.targets,
    }
  },
  created() {
    if (this.inc_tournament.target_style) {
      default_locale = this.inc_tournament.target_style === 'Bales' ? 'bow_bale' : 'bow_stake';
      Vue.prototype.trans.locale = default_locale;
    }
  },
  watch: {
    selected_targets(newVal, oldVal) {
      if (this.inc_tournament.slug) {
        if (newVal.length < oldVal.length) {
        
        }
      }
    }
  },
  methods: {
    targetRemoved(target) {
      this.tournament.rounds.forEach(function (round) {
        if (round.target && round.target.id === target.id) {
          round.target = null;
        }
      });
      if (this.oldTargets && this.tournament.id) {
        let exists = this.oldTargets.find(f => f.id === target.id);
        if (exists) {
          Swal.fire({
            titleText: 'Removed existing Target',
            html: 'Any competitors that have previously selected ' + target.name + ' must now select a new target.' +
              ' Any existing scores using ' + target.name + ' may be incorrect.',
            icon: 'warning',
          })
        }
      }
    },
    validate: function () {
      return this.$validator.validate().then(result => {
        return result;
      });
    },
    selected({target}) {
      default_locale = target.value === 'Bales' ? 'bow_bale' : 'bow_stake';
      Vue.prototype.trans.locale = default_locale;
      if (target.value === "Stakes" && !this.tournament.id) {
        this.tournament.bale_competitors = 5;
        this.tournament.locations[0].name = "Range A";
        this.tournament.locations[0].bale_competitors = 5;
        if (this.tournament.rounds[0] && this.tournament.rounds[0].locations[0]) {
          this.tournament.rounds[0].locations[0].name = "Range A";
        }
        this.tournament.rounds[0].arrows_count = 1;
      }
    },
    changeSport() {
      if (this.oldSport && this.tournament.id) {
        Swal.fire({
          titleText: 'Are you sure you want to change the Sport?',
          text: 'Changing the Sport may require existing competitors to select a new target.',
          showCancelButton: true,
          confirmButtonText: 'Change Sport',
          reverseButtons: true,
          icon: 'warning',
        }).then((result) => {
          if (!result.value) { //they cancelled the sport change
            this.tournament.sport_id = this.oldSport; //put back to old sport, otherwise let it change to new sport
          }
          this.$nextTick(() => {
            let acceptedTargets = [];
            this.inc_tournament.targets.forEach((target) => {
              this.sportTargets.forEach((sportTarget) => {
                if ((target.id === sportTarget.id)) {
                  acceptedTargets.push(target);
                }
              })
            });
            this.tournament.targets = acceptedTargets;
          });
        })
      }
    }
  }
}
</script>

<style scoped>
.multiselect_blue :deep(.multiselect__tag) ,
.multiselect_blue :deep(.multiselect__option--highlight) ,
.multiselect_blue :deep(.multiselect__tag-icon:hover)  {
  @apply bg-blue-600;
}

.input-group-prepend .btn, .input-group-append .btn {
  z-index: 0;
}

.formElement {
  @apply flex flex-col w-full mt-2 px-1 mb-2;
}
</style>
