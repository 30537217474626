<template>
  <div class="border border-blue-600 rounded p-2">
    <div class="text-xl">League Helper</div>
    <hr class="mb-2">
    <div>
      <div class="flex mb-1">
        <label :for="'week_count'" style="margin-top: auto;" class="mr-2"># of Weeks</label>
        <input type="number" min="2" max="52" :name="'week_count'" id="week_count"
               class="bs-form-box"
               v-validate="'required|min_value:2|max_value:52'" required
               v-model="weekCount">
        <span class="text-sm text-red-600">{{ errors.first('week_count') }}</span>
      </div>
      <div class="mb-1 flex flex-col w-full" tabindex="0" v-for="(time, index) in times">
        <div class="bs-form-box flex p-0 pl-2 w-full ">
          <div class=" whitespace-nowrap flex-grow">
            <div class="whitespace-nowrap flex w-full pt-2">
              <div class="focus-within:z-100 focus-within:absolute" tabindex="0">
                <flat-pickr
                  class="flex-grow"
                  v-model="times[index]"
                  v-validate="'required'"
                  :name="'helperTime' + (index + 1)"
                  :altInput="true"
                  :config="options"
                  @on-change=""
                ></flat-pickr>
              </div>
              <div class="text-gray-600 flex w-0">
                {{ dayOfTheWeek(time) }}
              </div>
            </div>
          </div>
          <span v-if="times.length > 1">
            <div @click="clearTime(index)" class="btn btn-outline-gray-600">
              <i class="fas fa-trash"></i>
            </div>
          </span>
          <span v-else>
            <div class="">
              <i class=""></i>
            </div>
          </span>
        </div>
        <span class="text-sm text-red-600">{{ errors.first('time ' + (index + 1)) }}</span>
      </div>
      <div class="flex flex-col-reverse md:flex-row md:justify-between">
        <div class="text-sm text-gray-600 mr-3">
          Select the first weeks worth of line times. This will be duplicated for the number of specified
          weeks. Clicking the 'Generate' button will overwrite any existing line times with the newly
          generated times.
        </div>
        <div class="flex justify-end">
          <div>
            <button @click.prevent="addTime()"
                    class="btn-sm btn-outline-blue-600 whitespace-nowrap">
              Add Time
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <button @click.prevent="generate()"
              class="btn btn-blue-600 whitespace-nowrap">
        Generate
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "LineTimeHelper",
  props: ['lineTimeOptions'],
  data() {
    return {
      times: [this.lineTimeOptions.minDate],
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'weekCount'
    ]),
    options() {
      let options = {...this.lineTimeOptions};
      let min = moment(new Date(options.minDate));
      min.add(6, 'days');
      min.hour(23);
      min.minutes(59);
      let formatted = min.format('D MMM YYYY h:mm A');
      options.maxDate = formatted;
      return options;
    }
  },
  methods: {
    addTime() {
      this.times.push(this.lineTimeOptions.minDate);
    },
    clearTime(index) {
      this.times.splice(index, 1);
    },
    generate() {
      let noDuplicates = [...new Set(this.times)];
      let momentTimes = noDuplicates.map(s => moment(new Date(s)));
      let sorted = momentTimes.sort((a, b) => a.diff(b));
      let list = [];
      for (let i = 0; i < this.weekCount; i++) {
        for (let time of [...sorted]) {
          let timeCopy = new moment(time);
          timeCopy.add(7 * i, 'days');
          let formatted = timeCopy.format('D MMM YYYY h:mm A');
          list.push(formatted);
        }
      }
      this.$emit('submit', list);
    },
    dayOfTheWeek(timeString) {
      let time = moment(new Date(timeString));
      return time.format('dddd');
    }
  }
}
</script>

<style scoped>

</style>
