<template>
  <div>
    <dropdown v-if="ladderCounts(ladder).length">
      <template v-slot:trigger>
        <div id="stepSelection"
             class="text-xl sm:text-2xl md:text-3xl lg:text-4xl dropdown-toggle text-gray-800"
             aria-haspopup="true" aria-expanded="false">
          Step: {{ step + 1 }}
          <i class="fas fa-caret-down"></i>
        </div>
      </template>
      <span v-for="(step, index) in ladderCounts(ladder)" class="w-full">
        <span @click="updateStep(index);"
              class="dropdown-item flex justify-between text-xl">
          <span>{{ index + 1 }} {{ ladder.shoot_out ? '' : `(${step} competitors)` }}</span>
          <span class="ml-2 text-center font-bold"
                v-if="!competitor"
                :class="getColorFromPercentage(getLadderCompletion(ladder.id, index))">
            {{ getLadderCompletion(ladder.id, index) }}%
          </span>
        </span>
      </span>
    </dropdown>
    <div v-else class="text-xl sm:text-2xl md:text-3xl lg:text-4xl">
      Step: 1
    </div>
  </div>

</template>

<script>

import mixin from "../../../mixins/scoring_mixins";
import Dropdown from "../../partials/Dropdown.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "RestrictStep",
  mixins: [mixin],
  components: {Dropdown},
  computed: {
    ...mapState(useDisplayStore, ['incCompetitor', 'ladder']),
    competitor() {
      return this.incCompetitor;
    },
    ladders() {
      return this.tournament.ladders;
    },
  }
}
</script>

<style scoped>

</style>
