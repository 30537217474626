<template>
  <div @click="$emit('close')"
       class="flex flex-col mr-2 p-1 max-w-xs lg:text-blue-200 overflow-y-auto">
    <div class="break-words">
      <div>Tournament: <br>{{ tournament.name }}</div>
      <div v-if="device_id">Device Id: {{ device_id }}</div>
    </div>
    <div class="ml-5 flex flex-col">
      <router-link to="/" class="routerLinks" exact>
        <a><span>Home</span></a>
      </router-link>
      <router-link v-if="!tournament.userRegistered && !tournament.is_master_postal" to="/register"
                   class="routerLinks">
        <a>Sign Up!</a>
      </router-link>
      <router-link v-else-if="!tournament.is_master_postal" :to="`/competitors/${tournament.userRegistered.uuid}`"
                   class="routerLinks">
        <a> My Registration</a>
      </router-link>
      <router-link v-if="!tournament.is_master_postal" to="/group" class="routerLinks">
        <a>Register Group</a>
      </router-link>
      <router-link v-if="!tournament.is_master_postal" to="/assignment"
                   class="routerLinks">
        <a>Assignments</a>
      </router-link>
      <router-link v-if="hasLadders" to="/ladder_assignment" class="routerLinks">
        <a>Ladder Assignments</a>
      </router-link>
      <router-link v-if="!tournament.is_master_postal" to="/score" class="routerLinks">
        <a> Score </a>
      </router-link>
      <router-link v-if="hasLadders" to="/score_ladder" class="routerLinks">
        <a> Score Ladder</a>
      </router-link>
      <router-link to="/results" class="routerLinks">
        <a> Results</a>
      </router-link>
      <router-link v-if="!tournament.is_master_postal" to="/progress" class="routerLinks">
        <a>Progress</a>
      </router-link>
      <router-link to="/logs" class="routerLinks">
        <a> Score Logs </a>
      </router-link>
      <a @click.stop.prevent="manageClicked" href="" class="routerLinks">
        Manage
        <span v-show="showManage"><i class="fas fa-caret-up"></i></span>
        <span v-show="!showManage"><i class="fas fa-caret-down"></i></span>
      </a>
      <div v-if="showManage && !tournament.is_master_postal" class="flex flex-col ml-4">
        <router-link to="/manage/edit" class="routerLinks">
          <a> Edit </a>
        </router-link>
        <router-link to="/manage/settings" class="routerLinks">
          <a> Settings </a>
        </router-link>
        <router-link v-if="tournament.is_league" to="/manage/league" class="routerLinks">
          <a> League </a>
        </router-link>
        <router-link to="/manage/competitors" class="routerLinks">
          <a> Competitors </a>
        </router-link>
        <router-link to="/manage/bales" class="routerLinks">
          <a> Bale Settings </a>
        </router-link>
        <router-link to="/manage/ladder" class="routerLinks">
          <a> Ladder Setup </a>
        </router-link>
        <router-link v-if="hasLadders" to="/manage/ladder_board" class="routerLinks">
          <a> Ladder Board </a>
        </router-link>
        <router-link to="/manage/import" class="routerLinks">
          <a> Import </a>
        </router-link>
        <router-link to="/manage/devices" class="routerLinks">
          <a> Devices </a>
        </router-link>
        <router-link to="/manage/assign" class="routerLinks">
          <a> Auto Assign </a>
        </router-link>
        <router-link to="/manage/notifications" class="routerLinks">
          <a> Notifications </a>
        </router-link>
        <router-link to="/manage/messaging" class="routerLinks">
          <a> Messaging </a>
        </router-link>
        <router-link to="/manage/targets" class="routerLinks">
          <a> Custom Targets </a>
        </router-link>
        <router-link to="/manage/restrictions" class="routerLinks">
          <a> Restrictions </a>
        </router-link>
      </div>
      <div v-else-if="showManage && tournament.is_master_postal" class="flex flex-col ml-4">
        <router-link to="/manage/edit" class="routerLinks">
          <a> Edit </a>
        </router-link>
        <router-link to="/manage/postal" class="routerLinks">
          <a> Postal Dashboard</a>
        </router-link>
        <router-link to="/manage/logs" class="routerLinks">
          <a> Score Logs </a>
        </router-link>
        <router-link to="/manage/targets" class="routerLinks">
          <a> Custom Targets </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TournamentNavigation",
  props: {'tournament': Object, 'user': Object},
  data() {
    return {
      uploading: false,
      screenHeight: 300,
      changes: 0,
      showManage: false,
    }
  },
  watch: {
    offlineAssignments() {
      this.changes = this.offlineAssignments.length + this.offlineScores.length + this.offlineCompetitors.length
      
    },
    offlineScores() {
      this.changes = this.offlineAssignments.length + this.offlineScores.length + this.offlineCompetitors.length
    },
    offlineCompetitors() {
      this.changes = this.offlineAssignments.length + this.offlineScores.length + this.offlineCompetitors.length
    },
  },
  computed: {
    hasLadders() {
      return this.tournament.ladders != null && this.tournament.ladders.length;
    },
    device_id() {
      return this.$parent.device_id;
    },
    offlineAssignments() {
      return this.$store.getters['tournament/getOfflineAssignments'];
    },
    offlineScores() {
      return this.$store.getters['tournament/getOfflineScores'];
    },
    offlineCompetitors() {
      return this.$store.getters['tournament/getOfflineCompetitors'];
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    }
  },
  methods: {
    manageClicked() {
      this.showManage = !this.showManage;
    },
    uploadChanges() {
      if (this.changes) {
        if (this.online) {
          this.uploading = true;
          let url = '/tournaments/' + this.tournament.slug + '/sync';
          axios.post(url, {
            'scores': this.offlineScores,
            'assignments': this.offlineAssignments,
            'competitors': this.offlineCompetitors
          })
          .then(({data}) => {
            this.uploading = false;
            this.changes = 0;
            this.$store.dispatch('tournament/resetOffline');
          })
          .catch(({response}) => {
            console.log(response);
          });
        } else {
          this.connectMessage()
        }
      }
    },
    connectMessage() {
      Swal.fire('You cannot sync changes without being connected to the internet.')
    },
  }
}
</script>

<style scoped>
.active {
  @apply text-blue-400;
}

.routerLinks {
  @apply py-1
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 10px;
}
</style>
