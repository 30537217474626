<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Administrators
    </div>
    <div class="card-body">
      <div class="p-2">
        Invite Bowscore users to help you manage this club.
        Administrators can create, edit and manage your tournaments.
      </div>
      <div v-if="message" class="bg-blue-100 p-2">
        {{ message }}
      </div>
      <ul class="">
        <li class="border rounded px-5 py-3 flex-col ">
          <label for="admin_name">New Admin Email: </label>
          <input class="border w-full rounded px-3 py-2" type="text" v-model="email"
                 placeholder="example_email@bowscore.com">
          <div class="flex justify-end">
            <button @click="addAdmin"
                    class="bg-transparent hover:bg-gray-600 text-gray-600 text-xs hover:text-white border-gray-600 hover:border-transparent rounded mt-2 border py-1 px-2">
              Add <span v-show="loading"><i class="far fa-spinner fa-spin"></i></span>
            </button>
          </div>
        
        </li>
        <li v-for="admin in club.admin" class="border rounded px-5 py-3 ">
          <span>
            {{ admin.first_name }} {{ admin.last_name }}: {{ admin.email }}
          </span>
          <button @click="removeAdmin(admin)"
                  class="float-right border border-gray-600 bg-transparent hover:bg-gray-600 text-gray-600 text-xs hover:text-white border-gray-600 rounded px-2 py-1">
            Remove
          </button>
        </li>
        <li v-if="!club.admin.length" class="list-group-item">
          No Admin
        </li>
      
      
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubAdmin",
  props: ['inc_club'],
  data() {
    return {
      club: this.inc_club,
      email: '',
      loading: false,
      message: null
    }
  },
  methods: {
    addAdmin() {
      let vm = this;
      this.loading = true;
      this.$axios.post('/clubs/' + this.club.slug + '/settings/admin', {
        'email': this.email,
      })
      .then(({data}) => {
        if (data['error']) {
          vm.loading = false;
          Swal.fire(data['error'])
        }
        if (data['success']) {
          vm.loading = false;
          if (data['user']) {
            vm.club.admin.push(data['user']);
          }
          vm.message = data['message'];
          vm.email = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    removeAdmin(admin) {
      if (admin.pivot.is_owner) {
        return Swal.fire('The club owner cannot lose admin access.')
      }
      let vm = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to remove " + admin.first_name + ' ' + admin.last_name + " as admin!",
        icon: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove admin!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/clubs/' + vm.club.slug + '/settings/remove_admin', {
            'admin': admin.email,
          })
          .then(({data}) => {
            if (data['error']) {
              vm.loading = false;
              Swal.fire(data['error'])
            }
            if (data['success']) {
              vm.loading = false;
              vm.club = data['club'];
              vm.message = 'Administrative Access has been removed from ' + admin.first_name + ' ' + admin.last_name;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>