<template>
  <div v-if="withdrawCount" class="card card-blue-700">
    <div class="card-header">
      Requests to Withdraw
    </div>
    <div class="card-body py-0">
      <div v-for="competitor in tournament.competitors.filter(f => f.withdraw_request !== null && !f.withdrew)">
        <div class="flex my-3 py-2" :class="withdrawCount > 1 ? '' : ''">
          <div class="flex-grow">
            <div class="leading-none">{{ competitor.name }}</div>
            <small class="text-gray-600">Requested {{ formatDate(competitor.withdraw_request) }}</small>
          </div>
          <div>
            <button @click.prevent="withdraw(competitor)" class="btn-outline-gray-600 btn-sm">
              withdraw
            </button>
          </div>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "WithdrawRequests",
  props: ['tournament'],
  computed: {
    withdrawCount() {
      return this.tournament.competitors.filter(f => f.withdraw_request !== null && !f.withdrew).length;
    }
  },
  methods: {
    formatDate(date) {
      let time = moment(date);
      return time.format('D MMM YYYY');
    },
    withdraw(competitor) {
      let url = '/tournaments/' + this.tournament.slug + '/competitors/' + competitor.uuid + '/status';
      Swal.fire({
        titleText: 'Withdraw ' + competitor.name,
        html: 'Are you sure that you want to withdraw ' + competitor.name + ' from ' + this.tournament.name + '? <br> ' +
          '<small>This will <strong>NOT</strong> permanently delete them from the tournament, and can be undone by simply approving them again.</small><br>',
        input: 'textarea',
        footer: 'Leaving the text field empty will send a default message to the competitor.',
        showCancelButton: true,
        confirmButtonText: 'Withdraw',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: (message) => {
          return this.$axios.post(url, {
            'action': 'withdraw',
            'message': message
          })
          .then(response => {
            if (!response.data.success) {
              throw new Error(response.statusText)
            }
            this.tournament.competitors.find(f => f.id === competitor.id).withdrew = moment();
            return Swal.fire('Withdrawn!', competitor.name + ' has been removed from the tournament.', 'success')
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
    }
    
  }
}
</script>

<style scoped>

</style>