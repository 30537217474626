<template>
  <div class="w-full my-3 flex-grow justify-center"
       v-if="competitor && competitor.id === competitorId">
    <div v-if="competitor.target_id" class="flex flex-wrap p-3">
      <div class="w-1/3 p-0" v-for="t in targetValues">
        <div @click="updateScore(t, baleNumber)"
             :class="valueIsHit(t) ? 'scoreSelected' : ''"
             class="scoreButton text-4xl xs:text-5xl sm:text-6xl md:text-7xl">
          {{ t.name }}
        </div>
      </div>
      <div class="w-full p-0 mt-1 pt-2">
        <div @click="updateScore(null, baleNumber)"
             class="btn-lg xs:text-2xl text-4xl btn-outline-gray-600">
          Clear
        </div>
      </div>
      <div v-if="canRemove" class="w-full p-0 mt-1 pt-2">
        <div @click="removeExtra()"
             class="btn-lg xs:text-2xl text-4xl btn-outline-gray-600">
          Remove Extra Arrow
        </div>
      </div>
      <div class="w-full p-0 mt-1">
        <div @click="updateScore({name: 'EF', value: 0}, baleNumber)"
             class="btn-lg xs:text-2xl text-4xl btn-outline-blue-600">
          Equipment Failure
        </div>
      </div>
      <div v-if="tournament.targets.length > 1" class="flex xs:block sm:hidden w-full p-0 mt-1">
        <target-select/>
      </div>
    </div>
    <div class="flex justify-between pb-2 px-3">
      <div>
        <div @click="closeScore" class="btn-lg xs:text-2xl text-3xl btn-outline-gray-600">close</div>
      </div>
      <div v-if="tournament.targets.length > 1" class="flex xs:hidden sm:block">
        <target-select/>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import TargetSelect from "./TargetSelect.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";
import moment from "moment";

export default {
  name: "ScoreGrid",
  components: {TargetSelect},
  props: ['competitorId', 'baleNumber'],
  data() {
    return {
      close: false,
    }
  },
  mixins: [mixin],
  computed: {
    ...mapWritableState(useDisplayStore, ["notAccepted", "replace", 'ladder', "localExtra"]),
    ...mapState(useDisplayStore, ["competitor", 'lineTime', 'location', 'arrow', 'end', 'verified', 'target']),
    isExtra() {
      return this.arrow > this.roundItem.arrows_count
    },
    canRemove() {
      let key = `${this.tournament.id}_${this.roundIndex}_${this.end}`;
      let local = Object.keys(this.localExtra).includes(key) ? this.localExtra[key] : 0;
      return this.isExtra && this.arrow === (this.roundItem.arrows_count + this.extraArrows + local);
    },
    nextArrowIsAvailable() {
      return !this.roundData.ends[this.end].arrows[this.arrow + 1].target_hit
    },
    existingScore() {
      return this.roundData.ends[this.end].arrows[this.arrow];
    },
    targetValues() {
      return this.target ? this.target.values : this.tournament.targetValues[0].values
    },
    roundData() {
      return this.scoreList[this.competitorId][`${this.roundWord.toLowerCase()}s`][this.roundIndex];
    },
    hit() {
      return this.arrowData.target_hit;
    },
    extraArrows() {
      if (this.isLadder && this.tournament.extra_arrows.length) {
        let copy = [...this.tournament.extra_arrows];
        let vm = this;
        let filtered = copy.filter(a => {
          return a.ladder_id === vm.ladder.id && a.step === vm.step && a.end === this.end;
        })
        return filtered.length;
      }
      return 0;
    },
    arrowData() {
      let endData = this.roundData.ends[this.end];
      if (this.arrow in endData.arrows) {
        return endData.arrows[this.arrow];
      } else {
        return endData.extras[this.arrow];
      }
    },
    versus() {
      let assignments = this.assignedCompetitors.ladder[this.ladder.id].step[this.step];
      if (this.ladder.shoot_out) {
        if (assignments.competitors_count === 2) {
          for (let bale of Object.values(assignments.bales)) {
            if (bale.competitors[0] !== this.competitorId) return bale.competitors[0];
          }
        }
      }
      return null;
    },
  },
  methods: {
    removeExtra() {
      this.close = true;
      
      let data = {
        'tournament_id': this.tournament.id,
        'round_id': this.round === null ? null : this.round.id,
        'ladder_id': this.round === null ? this.roundItem.id : null,
        'step': this.round === null ? this.step : null,
        'end': this.end,
        'arrow': this.arrow,
      };
      
      let score = this.tournament.scoreList[this.competitorId][`${this.roundWord.toLowerCase()}s`][this.roundIndex]
        .ends[this.end];
      if (score.extras != null) {
        delete score.extras[this.arrow];
      }
      
      if (this.versus != null) {
        let vScore = this.tournament.scoreList[this.versus][`${this.roundWord.toLowerCase()}s`][this.roundIndex]
          .ends[this.end];
        if (vScore.extras != null) {
          delete vScore.extras[this.arrow];
        }
      }
      this.$axios.post(`/tournaments/${this.tournament.slug}/remove_arrow`, {
        'data': data,
      })
      this.closeScore();
      this.$emit('forceUpdate')
    },
    
    
    valueIsHit(targetValue) {
      return this.hit === targetValue.name;
    },
    closeScore() {
      this.$emit('closeScore');
    },
    getCompetitorClass() {
      let detail = this.competitor.regform.find(r => r.option === 'class');
      return detail ? detail.name : null
    },
    getCompetitorDivision() {
      let detail = this.competitor.regform.find(r => r.option === 'division');
      return detail ? detail.multiple : null
    },
    newScore(target, bale, extra) {
      let authorized = false;
      if (this.verified) {
        authorized = true;
      } else {
        authorized = this.incCompetitor != null && this.incCompetitor.id === this.competitor.id;
      }
      return {
        tournament_id: this.tournament.id,
        tournament_name: this.tournament.name,
        competitor_id: this.competitorId,
        device: this.$store.getters['device/getDeviceId'],
        uploader_id: this.activeUser ? this.activeUser.id : null,
        competitor_name: this.competitor.name,
        class: this.getCompetitorClass(),
        division: this.getCompetitorDivision(),
        line_time: this.ladder == null ? this.lineTime.time : null,
        location: this.ladder == null ? this.location.name : null,
        arrow: this.arrow,
        extra: extra,
        target_id: this.target ? this.target.id : null,
        target_hit: target ? target.name : null,
        score: target ? target.value : null,
        round: this.round !== null ? this.round.id : 0,
        round_name: this.round !== null ? this.round.name : '',
        end: parseInt(this.end),
        authorized: authorized,
        created_at: moment().utc().format(),
        bale: bale,
        ladder: this.ladder == null ? null : this.ladder.id,
        step: this.ladder == null ? null : this.step,
      };
    },
    
    handleAcceptRequired(newScore) {
      if (this.tournament.accept_button) {
        let existing = this.existingScore;
        let rScore = Object.assign({}, newScore);
        if (existing != null) {
          rScore['score'] = existing.score;
          rScore['target_hit'] = existing.target_hit;
          rScore['target_id'] = existing.target_id;
        } else {
          rScore['score'] = null;
          rScore['target_hit'] = null;
        }
        
        let replaceCopy = [...this.replace];
        replaceCopy.push(rScore);
        this.store.$patch({
          replace: replaceCopy,
        })
        this.store.setNotAccepted(newScore);
      }
    },
    
    getShortScore(score) {
      return {
        arrow: score['arrow'],
        target_hit: score['target_hit'],
        target_id: score['target_id'],
        score: score['score']
      };
    },
    
    getOldScore(score) {
      let endScore = this.roundData.ends[score['end']];
      if (score['arrow'] in endScore.arrows) {
        return endScore.arrows[score['arrow']];
      } else {
        return endScore.extras[score['arrow']];
      }
    },
    
    addTieBreakers(score, oldScore, activeScore) {
      let roundWord = this.ladder == null ? 'round' : 'ladder';
      this.tournament.tieBreakers.forEach(breaker => {
        let change = score['target_hit'] === breaker ? 1 : 0;
        change -= oldScore.target_hit === breaker ? 1 : 0;
        activeScore[breaker] += change;
        activeScore[`${roundWord}s`][this.roundIndex][breaker] += change;
        activeScore[`${roundWord}s`][this.roundIndex].ends[score['end']][breaker] += change;
      });
    },
    
    addChanges(score, oldScore, activeScore) {
      let roundWord = this.ladder == null ? 'round' : 'ladder';
      let pointsChange = score['score'] ? score['score'] : 0;
      let completeChange = score['target_hit'] ? 1 : 0;
      pointsChange -= oldScore.score;
      completeChange -= oldScore.target_hit ? 1 : 0;
      activeScore.points += pointsChange;
      activeScore[`${roundWord}s`][this.roundIndex].points += pointsChange;
      activeScore[`${roundWord}s`][this.roundIndex].ends[score['end']].points += pointsChange;
      activeScore.complete += completeChange;
      activeScore[`${roundWord}s`][this.roundIndex].complete += completeChange;
      activeScore[`${roundWord}s`][this.roundIndex].ends[score['end']].complete += completeChange;
    },
    updateScore(target, bale) {
      let roundWord = this.ladder == null ? 'round' : 'ladder';
      let extra = !(this.arrow in this.roundData.ends[this.end].arrows);
      let newScore = this.newScore(target, bale, extra);
      this.handleAcceptRequired(newScore);
      let score = newScore;
      let shortScore = this.getShortScore(score);
      let oldScore = this.getOldScore(score);
      let activeScore = this.tournament.scoreList[score['competitor_id']];
      this.addTieBreakers(score, oldScore, activeScore);
      this.addChanges(score, oldScore, activeScore);
      this.tournament.scoreList[score['competitor_id']][`${roundWord}s`][this.roundIndex]
        .ends[score['end']][extra ? 'extras' : 'arrows'][score['arrow']] = shortScore;
     
      if (target != null) this.finishedOpenClose();
      this.$store.dispatch('tournament/updateScore', newScore);
    },
    finishedOpenClose() {
      if (this.arrow < (this.roundItem.arrows_count + this.extraArrows)) {
        if (this.extraArrows === 0 && this.nextArrowIsAvailable) {
          this.$emit('openScore', this.competitor.id, this.arrow + 1);
        } else {
          this.closeScore();
        }
      } else {
        this.closeScore();
      }
    },
    
    
  },
  
  
}
</script>

<style scoped>
.scoreButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.scoreButton {
  @apply border-blue-800 border text-blue-800 w-full font-black text-center align-middle flex-1;
  font-family: sans-serif;
}

.scoreSelected {
  @apply bg-blue-800 text-blue-100;
}
</style>
