<template>
  <div class="m-2">
    <div v-for="(board, index) in boards" class="m-4 mb-16">
      <hr v-if="index !== 0">
      <span class="text-2xl mr-4">Board {{ index + 1 }}</span>
      <span v-if="board.id">url: bowscore.com/ladder_board/{{ board.id }}</span>
      <div class="flex flex-col sm:flex-row w-full md:w-3/4 lg:w-1/2 mb-4">
        <div class="flex-1 mr-4">
          <div class="text-xl text-center">Selection 1</div>
          <select
            v-model="selections[board.id][1]"
            class="bs-form-box block w-full"
            :id="`${board.id}_1`">
            <option v-for="ladder in tournamentIn.ladders" :value="ladder.id">{{ ladder.name }}</option>
          </select>
        </div>
        <div class="flex-1 mr-4">
          <div class="text-xl text-center">Selection 2 (optional)</div>
          <select
            v-model="selections[board.id][2]"
            class="bs-form-box block w-full"
            :id="`${board.id}_1`">
            <option v-for="ladder in tournamentIn.ladders" :value="ladder.id">{{ ladder.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="flex justify-between w-full md:w-3/4 lg:w-1/2">
      <button @click=add() class="btn btn-blue-600">Add a board</button>
      <button @click="save()" class="btn btn-blue-600 mr-4">
        Save <span v-if="saving" class="fas fa-spinner fa-spin"></span>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ManageLadderBoard",
  props: ['tournamentIn'],
  data() {
    return {
      boards: [],
      selections: {},
      saving: false,
    }
  },
  methods: {
    add() {
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/add_board`).then(({data}) => {
        if (data.success) {
          this.boards.push(data.board)
          this.selections[data.board.id] = {};
          this.selections[data.board.id][1] = data.board.ladder_ids;
        }
      });
    },
    save() {
      this.saving = true;
      let data = {
        selections: this.selections,
      };
      this.$axios.post(`/tournaments/${this.tournamentIn.slug}/save_ladder_boards`, data).then((_) => {
        this.saving = false;
      }).catch((_) => {
        this.saving = false;
      })
    }
  },
  created() {
    this.$axios.get(`/tournaments/${this.tournamentIn.slug}/get_ladder_boards`).then(({data}) => {
      this.boards = data.boards;
      for (let board of data.boards) {
        this.selections[board.id] = {};
        let ids = board.ladder_ids.split('_');
        for (let i = 0; i < ids.length; i++) {
          this.selections[board.id][i + 1] = parseInt(ids[i]);
        }
      }
    })
  }
}
</script>
<style scoped>

</style>