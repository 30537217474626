<template>
  <div class="card card-blue-700">
    <div class="card-header">
      Scoring Device Authorization
    </div>
    <div class="card-body">
      <div class="m-2">
        <div class="text-center">Manually Add Device By ID</div>
        <div class="mx-auto w-1/2">
          <div class="p-0 max-w-sm my-2 bs-form-box flex">
            <input @keyup="error =  ''" type="text" class="flex-grow pl-2" v-model="addId">
          </div>
          <div class="text-sm text-red-600">
            {{ error }}
          </div>
          <div class="flex justify-end">
            <button @click.prevent="addDevice()" class="btn btn-blue-600">Authorize Device</button>
          </div>
        </div>
      
      </div>
      <hr class="my-3">
      <div class="m-2">
        <div class="text-center">Quick Authorization Code</div>
        <div class="mx-auto w-3/4">
          <Form>
            <div class="p-0 mt-2 mb-1 mx-auto w-48 bs-form-box flex">
              <Field name="club code" rules="max:6|min:6|clean" class="flex-grow pl-2" v-model="club_code"/>
            </div>
            <div class="text-center text-sm text-gray-600">
              This feature is {{ enabled ? 'enabled' : 'disabled' }}
            </div>
            <div class="text-center text-sm text-red-600">
              {{ code_error }}
            </div>
            <span class="text-center text-sm text-red-600">
              <ErrorMessage name="club code"/>
            </span>
          </Form>
          <div class="flex justify-between class mt-1">
            <button @click.prevent="generateCode()" class="btn btn-blue-600">Generate Random Code</button>
            <button @click.prevent="toggleCode()" class="btn"
                    :class="enabled ? 'btn-blue-600' : 'btn-gray-600'">{{ enabled ? 'Disable' : 'Enable' }}
              Feature
            </button>
            <button @click.prevent="saveCode()" class="btn btn-blue-600">Save Code</button>
          </div>
          <div class="text-sm text-gray-600">
            Using this 6-character code on a device will authorize that device to score any tournament
            hosted by this club.
            This is generally only used for club owned devices. It is recommended that you use the
            tournament
            quick setup codes for one time use scoring devices. Authorization can be removed below.
          </div>
        
        </div>
      
      </div>
      <hr class="my-3">
      <div class="flex justify-between">
        <button @click.prevent="show = !show" class="btn btn-gray-600">{{ show ? 'Hide' : 'Show' }} Devices</button>
        <div class="text-gray-600 text-sm h-center">
          Device Id is displayed on the scoring device
        </div>
      </div>
      
      <div v-if="show">
        <div class="m-2">
          <div class="mx-auto w-1/2">
            <div class="max-w-sm bs-form-box flex">
              <span class="h-center"><i class="fas fa-search"></i></span>
              <input type="text" class="flex-grow mx-2 pl-2" v-model="search">
              <div v-if="search !== ''" class="h-center" @click="search = ''">
                <span class=""><i class="fas fa-times"></i></span>
              </div>
            </div>
            <span class="text-center text-sm text-gray-600">filter by device Id</span>
          </div>
        
        </div>
        
        <div class="text-left text-gray-600 text-sm">
          Click a cell to edit.
        </div>
        <div class="table-responsive ">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th class="table-header">Authorized</th>
              <th class="table-header">Device Name</th>
              <th class="table-header">Device Id</th>
              <th class="table-header">Expires</th>
            </tr>
            </thead>
            <tbody>
            <device-details v-for="device in selectedDevices" :inc_device="device" :key="device.id"/>
            <tr v-if="!selectedDevices.length">
              <td colspan="4" class="table-empty text-center">
                No Devices
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    
    
    </div>
  </div>
</template>

<script>
import {Field, Form, ErrorMessage} from 'vee-validate';

export default {
  name: "ClubDevices",
  components: {Form, Field, ErrorMessage},
  props: ['club', 'inc_devices'],
  data() {
    return {
      search: '',
      addId: '',
      devices: this.inc_devices,
      show: false,
      error: '',
      club_code: this.club.auth_code,
      enabled: this.club.allow_code,
      code_error: '',
    }
  },
  computed: {
    selectedDevices() {
      return this.devices ? this.devices.filter(f => f.device_id.toUpperCase().includes(this.search.toUpperCase())) : {};
    }
  },
  methods: {
    addDevice() {
      this.$axios.post('/device/club_add', {device: this.addId, club: this.club})
      .then(({data}) => {
        if (data['error']) {
          this.error = data['error'].message;
        } else {
          this.search = '';
          this.devices = data.devices;
          this.show = true;
        }
      })
    },
    generateCode() {
      this.club_code = Math.random().toString(36).substr(2, 6);
    },
    toggleCode() {
      this.$axios.post('/clubs/' + this.club.slug + '/settings/toggle_code')
      .then(({data}) => {
        if (data.success) {
          this.enabled = !this.enabled;
        }
      })
    },
    saveCode() {
      this.$axios.post('/clubs/' + this.club.slug + '/settings/set_code', {code: this.club_code})
      .then(({data}) => {
        if (data.error) {
          this.code_error = data.error.message;
        } else {
          this.code_error = '';
          Swal.fire('success', 'Quick Authorization Code Updated');
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
