<template>
  <div v-if="sports != null">
    <div v-if="online">
      <div v-if="tournament" class="flex-grow overflow-x-hidden sm:m-2 p-2">
        <div class="bg-blue-200 text-blue-600 p-2 flex flex-col md:flex-row justify-between rounded">
          <div class="w-1/6 hidden md:flex flex-col justify-center text-center">
            <div class="flex justify-center"><i class="fas fa-bow-arrow fa-3x"></i></div>
          </div>
          <div class="flex flex-col flex-grow">
            <div class="text-3xl text-center">Practice Mode</div>
            <div class="md:px-5">
              <div class="flex bs-form-box p-0">
                <input class="ml-2 w-full" type="text" disabled :value="link" id="urlLink">
                <button @click="copyToClipboard"
                        class="h-center bg-blue-600 text-blue-100 py-1 px-2 flex-extralight whitespace-nowrap btn-sm rounded-l-none">
                  <i class="fas fa-paperclip md:hidden"></i>
                  <span class="hidden md:flex pl-1">Copy Link</span>
                </button>
              </div>
              <span class="text-sm text-blue-600">Shareable link to edit/score this tournament.</span>
            </div>
          </div>
          <div class="w-full md:w-1/6 text-center flex flex-col justify-center">
            <div class="flex justify-center">
              <div>
                <div class="text-lg border-b border-solid border-blue-700 px-6">Expires</div>
                <div>{{ expires }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-2xl mt-3">Tournament Details</div>
        <hr>
        <div class="flex flex-wrap">
          <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-1 mb-2">
            <label class="bs-box-label" for="name">Name</label>
            <Field @change="updateTournament" class="bs-form-box w-full" name="name"
                   v-model="tournamentIn.name"
                   rules="required|clean"/>
            <span class="text-sm text-gray-600">Name this tournament, or just put a simple description.</span>
            <ErrorMessage class="errorText" name="name"/>
          </div>
          <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-1 mb-2">
            <label class="bs-box-label" for="sport_id">Sport</label>
            <Field as="select" @change="handleExisting" name="sport_id" class="bs-form-box h-10 w-full"
                   v-model="tournament.sport_id" rules="required">
              <option v-for="sport in sports"
                      :selected="tournament && tournament.sport_id === sport.id"
                      name="sport_id" :value="parseInt(sport.id)">
                {{ sport.name }}
              </option>
            </Field>
            <span class="text-sm text-gray-600">Select your sport.</span>
            <ErrorMessage class="errorText" name="sport_id"/>
          </div>
          <div class="flex flex-col w-full md:w-1/3 mt-2 md:mt-0 px-1 mb-2">
            <label class="bs-box-label" for="target">Targets</label>
            <VueMultiselect
              :modelValue="tournament.targets"
              @update:modelValue="changedTarget"
              :options="sportTargets"
              :searchable="true"
              :show-labels="false"
              :hideSelected="true"
              :multiple="true"
              track-by="id"
              :placeholder="'Select Targets'"
              label="name"
              name="available_targets"
              class="multiselect_blue"/>
            <span class="text-sm text-gray-600">Pick all of the targets you will use this tournament.</span>
          </div>
          <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-1">
            <label class="bs-box-label" for="target_style">Stakes or Bales?</label>
            <div class="flex flex-col lg:flex-row">
              <label class="container">Stakes
                <input @change="updateStyle('Stakes')" type="radio" value="Stakes"
                       v-model="tournament.target_style">
              </label>
              <label class="container">Bales
                <input @change="updateStyle('Bales')" type="radio" value="Bales"
                       v-model="tournament.target_style">
              </label>
            </div>
          </div>
          <div class="flex flex-col w-1/2 md:w-1/4 mt-2 md:mt-0 px-1">
            <label class="bs-box-label" for="bale_competitors"> Competitors per {{ baleWord }} </label>
            <Field type="number" min="1" max="6" name="bale_competitors" class="bs-form-box"
                   rules="required|min_value:1|max_value:6"
                   v-model="tournament.bale_competitors"
                   @change="updateTournament"/>
          </div>
          <div class="flex flex-col w-1/2  md:w-1/4 mt-2 md:mt-0 px-1">
            <label class="bs-box-label" for="ends_count"># of {{ endsWord }}</label>
            <Field class="bs-form-box" type="number" max="20" min="1"
                   @change="updateTournament"
                   v-model="tournamentIn.rounds[0].ends_count"
                   rules="required|min_value:1|max_value:20" name="ends_count"/>
          </div>
          <div class="flex flex-col w-1/2  md:w-1/4 mt-2 md:mt-0 px-1">
            <label class="bs-box-label" for="arrows_count"># of Arrows</label>
            <Field class="bs-form-box" type="number" max="10" min="1"
                   @change="updateTournament"
                   name="arrows_count"
                   rules="required|min_value:1|max_value:10"
                   v-model="tournamentIn.rounds[0].arrows_count"/>
          </div>
        </div>
        <div class="text-2xl">Archers</div>
        <hr>
        <div class="mt-3 border border-gray-600 rounded p-2">
          <div class="w-full flex flex-col">
            <div class="hidden w-full lg:flex mb-0">
              <div class="flex w-full flex-wrap flex-grow">
                <div class="flex flex-col w-full md:w-3/12 mt-2 md:my-0 px-1">
                  <label class="bs-box-label" for="name">Name</label>
                </div>
                <div class="flex flex-col w-full md:w-2/12 mt-2 md:my-0 px-1">
                  <label class="bs-box-label" for="target">Target</label>
                </div>
                <div class="flex flex-col w-full md:w-1/12 mt-2 md:my-0 px-1">
                  <label class="bs-box-label" for="gender">Gender</label>
                </div>
                <div class="flex flex-col w-full md:w-1/4 mt-2 md:my-0 px-1">
                  <label class="bs-box-label" for="division">Division</label>
                </div>
                <div class="flex flex-col w-full md:w-1/4 mt-2 md:my-0 px-1">
                  <label class="bs-box-label" for="class">Class</label>
                </div>
              </div>
              <div class="w-8"></div>
            </div>
            <div class="mb-3">
              <div class="w-full flex flex-col lg:flex-row">
                <div class="flex w-full flex-wrap flex-grow">
                  <div class="flex flex-col w-full sm:w-6/12 lg:w-3/12  md:mt-0 px-1">
                    <label class="lg:hidden bs-box-label" for="name">Name</label>
                    <Field type="text" name="competitor" class="bs-form-box" rules="required|clean"
                           v-model="competitor"/>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/4 lg:w-2/12 md:mt-0 px-1">
                    <label class="lg:hidden bs-box-label" for="target_id">Target</label>
                    <Field as="select" name="target_id" class="bs-form-box  w-full" v-model="target_id"
                           rules="required">
                      <option v-for="target in tournamentIn.targets" :value="target.id">
                        {{ target.name }}
                      </option>
                    </Field>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/4 lg:w-1/12  md:mt-0 px-1">
                    <label class="lg:hidden bs-box-label" for="gender">Gender</label>
                    <Field as="select" name="gender" class="bs-form-box  w-full" v-model="gender" rules="required">
                      <option>Male</option>
                      <option>Female</option>
                    </Field>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 md:mt-0 px-1">
                    <label class="lg:hidden bs-box-label" for="division">Division</label>
                    <Field as="select" name="division" class="bs-form-box  w-full" v-model="division" rules="required">
                      <option>None</option>
                      <option v-for="div in sportDivisions">{{ div }}</option>
                    </Field>
                  </div>
                  <div class="flex flex-col w-full sm:w-1/2 lg:w-1/4 md:mt-0 px-1">
                    <label class="lg:hidden bs-box-label" for="selectedClass">Class</label>
                    <Field as="select" name="selectedClass" class="bs-form-box  w-full" v-model="selectedClass"
                           rules="required">
                      <option>None</option>
                      <option v-for="selectedClass in sportClasses">{{ selectedClass }}</option>
                    </Field>
                  </div>
                </div>
                <div v-if="competitor?.length && target_id != null" class="xs:p-1 lg:p-0" id="competitor">
                  <button @click.prevent="addCompetitor" class="btn btn-outline-green-500 h-full h-center">
                    <span class="lg:hidden flex pr-1">Add Archer </span>
                    <i class="fas fa-plus  xs:ml-1 xs:mt-1 lg:m-0"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-1" v-for="(c, pIndex) in competitors">
          <div class="w-full flex flex-col lg:flex-row">
            <div class="flex w-full flex-wrap flex-grow">
              <div class="flex flex-col w-full sm:w-6/12 lg:w-3/12 mt-2 md:mt-0 px-1">
                <Field
                  type="text"
                  class="bs-form-box"
                  @change="updateCompetitor"
                  rules="required|clean"
                  :name="pIndex + '_name'"
                  v-model="competitors[pIndex].name"/>
              </div>
              <div class="flex flex-col w-full sm:w-1/4 lg:w-2/12 mt-2 md:mt-0 px-1">
                <Field as="select" @change="updateCompetitor" :name="pIndex + '_target'"
                       class="bs-form-box  w-full"
                       v-model="competitors[pIndex].target_id"
                       rules="required|clean">
                  <option v-for="target in tournament.targets" :value="target.id">{{ target.name }}</option>
                </Field>
              </div>
              <div v-if="hasForm(pIndex)" class="flex flex-col w-full sm:w-1/4 lg:w-1/12 mt-2 md:mt-0 px-1">
                <Field as="select" @change="updateCompetitor" class="bs-form-box  w-full"
                       v-model="competitors[pIndex].regform[2].name"
                       :name="pIndex + '_gender'"
                       rules="required">
                  <option>Male</option>
                  <option>Female</option>
                </Field>
              </div>
              <div v-if="hasForm(pIndex)" class="flex flex-col w-full sm:w-1/2 lg:w-1/4 mt-2 md:mt-0 px-1">
                <Field as="select" @change="updateCompetitor" class="bs-form-box  w-full"
                       v-model="competitors[pIndex].regform[1].name"
                       :name="pIndex + '_division'"
                       rules="required">
                  <option>None</option>
                  <option v-for="div in sportDivisions">{{ div }}</option>
                </Field>
              </div>
              <div v-if="hasForm(pIndex)" class="flex flex-col w-full sm:w-1/2 lg:w-1/4 mt-2 md:mt-0 px-1">
                <Field as="select" @change="updateCompetitor" class="bs-form-box w-full"
                       v-model="competitors[pIndex].regform[0].name"
                       :name="pIndex + '_class'"
                       rules="required">
                  <option>None</option>
                  <option v-for="selectedClass in sportClasses">{{ selectedClass }}</option>
                </Field>
              </div>
            </div>
            <div class="xs:p-1 lg:p-0">
              <button @click.prevent="removeCompetitor(pIndex)"
                      class="btn btn-outline-gray-600 h-full h-center">
                <span class="lg:hidden flex pr-1">Delete {{ competitors[pIndex].name }} </span><i
                class="fas fa-trash-alt xs:ml-1 xs:mt-1 lg:m-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>Loading tournament</div>
    </div>
    <div v-else class="text-center text-2xl py-32">
      <div>This page is not available offline</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
import {Form, ErrorMessage, Field} from "vee-validate";

export default {
  name: "PracticeEdit",
  components: {Form, Field, ErrorMessage},
  props: ['tournamentIn', 'sports'],
  data() {
    return {
      competitor: '',
      division: '',
      selectedClass: '',
      gender: '',
      target_id: null,
      sport: null,
      updated: false,
      tournament: this.$store.state.tournament.tournament,
      trans: this.$trans,
    }
  },
  computed: {
    competitors() {
      return this.tournamentIn.competitorList;
    },
    link() {
      return window.location.href
    },
    expires() {
      let created = moment(this.tournament.created_at).add(7, 'd');
      return moment(created).format('D MMM ha');
    },
    sportClasses() {
      return this.sports.find(f => f.id === this.tournament.sport_id).Class
    },
    sportDivisions() {
      return this.sports.find(f => f.id === this.tournament.sport_id).Division
    },
    sportTargets() {
      return this.sports.find(f => f.id === this.tournament.sport_id).targets
    },
    online() {
      return this.$store.getters['tournament/getOnline'];
    },
    baleWord() {
      let word = this.trans.choice('search.bale', 1);
      return this.capitalize(word);
    },
    endsWord() {
      let word = this.trans.choice('search.end', 2);
      return this.capitalize(word);
    },
  },
  methods: {
    capitalize(text) {
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    hasForm(index) {
      return this.competitors[index].regform != null && this.competitors[index].regform.length > 2;
    },
    getModel(id, option) {
      Object.values(this.competitors[id].regform).find(f => f.option === option)
    },
    updateStyle(type) {
      let default_locale = type === 'Bales' ? 'bow_bale' : 'bow_stake';
      this.trans.locale = default_locale;
    },
    copyToClipboard() {
      let url = document.getElementById("urlLink").value;
      if (!navigator.clipboard) {
        //The deprecated way
        document.execCommand('copy');
      } else {
        navigator.clipboard.writeText(url);
      }
      Swal.fire({
        titleText: 'A link to this tournament has been copied to the clipboard',
        html: 'You may now paste this link in an email or message to share the tournament.',
        footer: 'Anyone with the link can score and edit this tournament. Please share it wisely.',
        customClass: {
          footer: 'text-red-600er'
        }
      })
    },
    updateCompetitor({target}) {
      let name = target.name.split('_')[1];
      let index = target.name.split('_')[0];
      let competitor = this.competitors[index];
      let value = target.value;
      let type = (name === 'ends_count' || name === 'arrows_count') ? 'round' : 'competitor';
      this.$axios
      .post('/practice/' + this.tournament.slug + '/update', {
        name: name,
        value: value,
        type: type,
        competitor: competitor
      })
      .then(({data}) => {
        if (data.success) {
          this.updated = true;
        }
      })
    },
    updateTournament({target}) {
      let data = {
        name: target.name,
        value: target.value,
        type: (target.name === 'ends_count' || target.name === 'arrows_count') ? 'round' : 'tournament',
      }
      this.$axios.post(`/practice/${this.tournament.slug}/update`, data)
      .then(({data}) => {
        if (data.success) {
          this.updated = true;
        }
      })
      
    },
    changedTarget(targets) {
      if (targets.length > 0) {
        this.$axios.post(`/practice/${this.tournament.slug}/update`, {
          name: 'targets',
          value: targets,
          type: 'tournament'
        })
        .then(({data}) => {
          if (data.success) {
            this.tournament.targets = targets;
            this.updated = true;
          }
        })
      } else {
        Swal.fire('You must have at least one target', 'You may add the new target then remove the unwanted one afterwards');
      }
      
    },
    addCompetitor() {
      let vm = this;
      let competitor = {
        name: this.competitor,
        target_id: this.target_id,
        gender: this.gender,
        division: this.division,
        class: this.selectedClass
      };
      this.competitor = '';
      this.$axios.post(`/practice/${this.tournament.slug}/update`, {
        name: 'add',
        value: competitor,
        type: 'competitor'
      }).then(({data}) => {
        if (data.success) {
          this.$emit('refresh');
          this.updated = true;
          let container = vm.$el.querySelector("#competitor");
          if (container != null) container.scrollIntoView({behavior: "smooth"});
        }
      })
      
    },
    removeCompetitor(index) {
      let competitor = this.competitors[index];
      delete this.competitors[index];
      this.$axios.post(`/practice/${this.tournament.slug}/update`, {
        name: 'delete',
        value: competitor,
        type: 'competitor'
      })
      .then(({data}) => {
        if (data.success) {
          this.updated = true;
        }
      })
    },
    handleExisting({target}) {
      this.sport = target.value;
      let sport = this.sports.find(f => f.id === parseInt(target.value));
      this.tournament.targets = this.tournament.targets.filter(function (t) {
        let exists = false;
        sport.targets.forEach(function (sportTarget) {
          if (sportTarget.id === t.id) {
            exists = true;
          }
        });
        return exists;
      });
      sport.target = target;
      this.updateTournament(sport);
    },
  },
  beforeRouteLeave(to, from, next) {
    let vm = this;
    if (vm.competitor) {
      Swal.fire({
        titleText: 'The competitor ' + vm.competitor + ' has not been created?',
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: 'Continue without ' + vm.competitor,
        reverseButtons: true,
      }).then(value => {
        if (!value.dismiss) {
          if (vm.updated) {
            if (vm.online) {
              Swal.fire({
                title: 'Updating Tournament',
                showLoaderOnConfirm: true,
                willOpen: () => {
                  Swal.clickConfirm()
                },
                preConfirm: () => {
                  return vm.$axios
                  .get('/practice/' + vm.tournament.slug + '/getTournament')
                  .then(({data}) => {
                    vm.$store.commit('tournament/SET_TOURNAMENT', {
                      'tournament': data.tournament
                    });
                    next();
                  })
                },
                allowOutsideClick: () => !Swal.isLoading(),
              })
            }
          } else {
            next();
          }
        }
      })
    } else {
      if (vm.updated) {
        if (vm.online) {
          Swal.fire({
            title: 'Updating Tournament',
            showLoaderOnConfirm: true,
            willOpen: () => {
              Swal.clickConfirm()
            },
            preConfirm: () => {
              return vm.$axios
              .get('/tournaments/' + vm.tournament.slug + '/suite/tournament')
              .then(({data}) => {
                vm.$store.commit('tournament/SET_TOURNAMENT', {
                  'tournament': data.tournament
                });
                next();
              })
            },
            allowOutsideClick: () => !Swal.isLoading(),
          })
        }
      } else {
        next();
      }
    }
    
  }
}
</script>

<style scoped>

</style>
