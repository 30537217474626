<template>
  <div>
    <div v-for="(message, type) in message_list" class="p-2 text-xl flex justify-between" :class="classes[type]">
      <span v-if="type === 'verify'">
        Your account email has not been verified.
        <a href="/email/resend" class="text-blue-600">
          resend verification email
        </a>
      </span>
      <span v-else v-html="message">
      
      </span>
      <button @click.prevent="delete message_list[type]">
        <span>&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "FlashMessages",
  props: ['session', 'error_messages'],
  data() {
    return {
      message_list: this.session,
      classes: {
        'warning': 'bg-yellow-200 text-yellow-900',
        'success': 'bg-green-200 text-green-900',
        'info': 'bg-blue-200 text-blue-900',
        'danger': 'bg-red-200 text-red-900',
        'message': 'bg-blue-200 text-blue-900',
        'verify': 'bg-yellow-200 text-yellow-900',
      }
    }
  },
  mounted() {
    if (this.error_messages.length) {
      let messages = this.error_messages;
      if (this.message_list['danger']) {
        messages.push(this.message_list['danger'])
      }
      this.message_list['danger'] = messages.join(', ');
    }
  }
}
</script>

<style scoped>

</style>
