<script setup>
import {useDashboardStore} from "../../../stores/DashboardStore";
import {onMounted, ref} from "vue";
import axios from 'axios';
import CardSettings from "./CardSettings.vue";

const store = useDashboardStore();

let tournaments = ref([]);


onMounted(() => {
  axios.get('/dashboard/upcoming').then(({data}) => {
    if (data['success']) {
      tournaments.value = data['list'];
    }
  })
})

</script>

<template>
  <div class="bs-shadow-card cardStyle">
    <card-settings name="upcoming"/>
    <div class="flex justify-between">
      <div class="w-full">
        <div class="bs-card-header">Upcoming Tournaments</div>
        <a :href="`/tournaments/${tournament.slug}#/competitors/${tournament.competitor_uuid}`"
           v-for="tournament of tournaments"
           class="tournamentRow sm:flex-row">
          <div class="label">
            {{ tournament.name }} <br class="hidden sm:block xl:hidden"> ({{ tournament.competitor }})
          </div>
          <div class="text">{{ tournament.division }}</div>
          <div class="text">{{ store.formattedAddress(tournament) }}</div>
          <div class="text">{{ store.formattedDate(tournament) }}</div>
          <div class="text">{{ tournament.status }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.cardStyle {
  @apply w-full relative mb-4;
  padding-top: 32px;
  padding-bottom: 38px;
}
.tournamentRow {
  @apply mb-4 flex flex-col;
  cursor: pointer;
}

.label {
  @apply font-bold text-black pr-1;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: .02rem;
  flex: 2;
}

.text {
  @apply font-medium text-stone-400 pr-1;
  flex: 1;
  font-size: 16px;
  line-height: 19px;
}
</style>