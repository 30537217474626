<template>
  <div class="card card-blue-700 mb-3">
    <div class="card-header">
      Upcoming Tournaments {{ breakdown === null ? '' : ` (${breakdown.length})` }}
    </div>
    <div class="card-body">
      <div v-if="breakdown !== null" class="table-responsive">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th class="table-header">Tournament</th>
            <th class="table-header">Sport</th>
            <th class="table-header">Date</th>
            <th class="table-header">Competitor Count</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="data in breakdown">
            <td><a :href="`/tournaments/${data['Slug']}`">{{ data['Name'] }}</a></td>
            <td>{{ data['Sport'] }}</td>
            <td>{{ start(data['Date']) }}</td>
            <td>{{ data['Count'] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else class="flex justify-center">
        <div class="flex flex-col">
          <div>Loading Breakdown</div>
          <div class="flex justify-center">
            <i class="fas fa-spinner fa-spin fa-3x"></i>
          </div>
        </div>
      
      </div>
    </div>
  
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TournamentBreakdown",
  props: [],
  data() {
    return {
      breakdown: null,
    }
  },
  methods: {
    start(data) {
      return moment(data).format('MM/DD/YY');
    },
  },
  created() {
    this.$axios.get('/godmode/tournament_breakdown')
    .then(({data}) => {
      if (data) {
        this.breakdown = data.data;
      }
    });
  }
}
</script>

<style scoped>

</style>
