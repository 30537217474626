<template>
    <div class="w-full md:w-1/2 mx-auto flex flex-col">
        hi {{$route.params['slug']}}
    </div>
</template>

<script>

    export default {
        name: "AccountDetails",

    }
</script>

<style scoped>

</style>