<template>
  <div class="lg:w-3/4 lg:m-auto max-w-2xl">
    <div v-if="competitor" class="card card-blue-700">
      <div class="card-header">
        <h4>Edit {{ competitor.name }}</h4>
      </div>
      
      <div class="card-body">
        <Form ref="competitorForm"
              :action="'/tournaments/' + tournament.slug + '/competitors/' + competitor.uuid" method="POST"
              id="competitorForm">
          <input type="hidden" name="_token" :value="csrf">
          <input type="hidden" name="_method" value="PUT">
          <competitor-create-edit :tournament="tournament" :inc_competitor="competitor" :user="user"
                                  :restrictions="restrictions"/>
          <button type="submit" class="btn btn-outline-blue-600 float-right">
            Update
            <span v-if="loading"><i class="fas fa-spin fa-spinner"></i></span>
          </button>
        </Form>
      </div>
    </div>
    {{ warning }}
  </div>
</template>

<script>
import CompetitorCreateEdit from "./CompetitorCreateEdit.vue";
import {Form} from "vee-validate";

export default {
  name: "Edit",
  components: {Form, CompetitorCreateEdit},
  props: ['tournamentIn', 'user'],
  computed: {
    tournament() {
      return this.tournamentIn;
    }
  },
  data() {
    return {
      loading: false,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      competitor: null,
      warning: null,
    }
  },
  created() {
    let uuid = this.$route.params.uuid;
    this.$axios.get(`/competitors/${uuid}/edit`).then(({data}) => {
      if (data.warning) {
        this.warning = data.warning;
      } else {
        this.competitor = data.competitor;
      }
    })
  },
  methods: {
    restrictions() {
      let options = [];
      this.tournament.restrictions.forEach((restriction) => {
        if (restriction.type === 'options') {
          options.push(restriction);
        }
      })
      return options;
    },
  }
  
}
</script>

<style scoped>

</style>
