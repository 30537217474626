<template>
  <div v-if="roundItem.arrows_count > 1" class="text-2xl flex flex-wrap">
    <div class="h-center text-4xl order-1 w-full sm:order-2">
      <div>
        <div class="flex justify-between">
          <div class="userName ">{{ position !== 'undefined' ? position : 'FULL' }}&middot;{{ competitorName }}</div>
          <div class="flex justify-end">
            <add-arrow :competitorId="competitorId" :end="end"/>
            <score-image :competitorId="competitorId"/>
          </div>
        </div>
        <div class="flex justify-between">
          <div>
            <span>{{ roundWord === "Ladder" ? 'Step' : roundWord }}:</span>
            <span class="scoreTotal mr-6">
              {{ roundPoints }}
              <span v-for="(breaker,i) in breakers">
                <span v-if="roundBreaker(breaker) > 0 && i === 0">+{{ roundBreaker(breaker) }}-{{ breaker }}</span>
              </span>
            </span>
          </div>
          <div class="text-right">
            <span>End {{ end }}:</span>
            <span class="scoreTotal">
              {{ endPoints }}
              <span v-for="(breaker,i) in breakers">
                <span v-if="endBreaker(breaker) > 0 && i === 0">+{{ endBreaker(breaker) }}-{{ breaker }}</span>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import ScoreAccept from "./ScoreAccept.vue";
import ScoreImage from "./ScoreImage.vue";
import {mapState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";
import AddArrow from "./AddArrow.vue";

export default {
  name: "CompetitorHeader",
  components: {AddArrow, ScoreAccept, ScoreImage},
  mixins: [mixin],
  props: ['competitorId', 'position', 'baleNumber'],
  computed: {
    ...mapState(useDisplayStore, ['end']),
    breakers() {
      return this.tournament.tieBreakers;
    },
    roundData() {
      if (this.isLadder) {
        if (this.roundIndex in this.scoreList[this.competitorId].ladders) {
          return this.scoreList[this.competitorId].ladders[this.roundIndex];
        } else {
          return null;
        }
      }
      return this.scoreList[this.competitorId].rounds[this.round.id];
    },
    endPoints() {
      if (this.roundData == null) return 0;
      return this.roundData.ends[this.end].points;
    },
    roundPoints() {
      if (this.roundData == null) return 0;
      return this.roundData.points;
    },
    competitorName() {
      return this.tournament.competitorList[this.competitorId].name;
    },
    mustAccept() {
      let cId = this.competitorId;
      let rId = this.roundItem.id;
      if (this.notAccepted.length) {
        let exists = this.notAccepted.filter(function (f) {
          return f.competitor_id === cId && f.round === rId;
        });
        return exists.length;
      }
      return false;
    }
  },
  methods: {
    roundBreaker(breaker) {
      if (this.roundData == null) return 0;
      return this.roundData[breaker];
    },
    endBreaker(breaker) {
      if (this.roundData == null) return 0;
      return this.roundData.ends[this.end][breaker];
    },
  }
}
</script>

<style scoped>

.userName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.scoreTotal {
  white-space: nowrap;
}
</style>
