<template>
  <div class="flex flex-col bs-right-margin">
    <div class="flex justify-center mb-4">
      <div class="w-11/12 lg:w-full max-w-xl">
        <div class="bs-form-box p-0 flex rounded-lg">
          <div class="flex flex-col justify-center text-gray-500 p-2 mr-2">
            <i class="fa-2x fas fa-search"></i>
          </div>
          <input class="flex-grow text-xl sm:text-3xl focus:outline-none" ref="searchBar"
                 placeholder=" Find Results"
                 v-model="search">
          <button v-if="search.length" @click="clearSearch"
                  class="flex flex-col justify-center text-gray-500 p-2 mr-2 font-light">
            <i class="fa-2x fas fa-times"></i>
          </button>
          <div v-else>&nbsp</div>
        </div>
        <div class="text-sm" v-text="`Search by ${archersWord}, ${tournamentsWord}, or clubs`"></div>
      </div>
    </div>
    <tournament-list-item v-for="tournament in tournaments" :key="tournament.id"
                          :tournament="tournament" :search="search"/>
    <div v-if="!tournaments.length" class="text-center">
      <span v-if="search.length">No Tournaments match your search "{{ search }}". </span>
      <span v-else>No Tournaments Found</span>
    </div>
  </div>
</template>

<script>
import TournamentListItem from "./TournamentListItem.vue";

export default {
  name: "ResultList",
  components: {TournamentListItem},
  props: ['inc_tournaments'],
  data() {
    return {
      tournaments: this.inc_tournaments,
      search: '',
    }
  },
  watch: {
    search(text, oldText) {
      if (!text.length) {
        this.tournaments = this.inc_tournaments;
      } else if (text.length > 2 || (text.length < oldText.length && text.length > 1)) {
        this.submitSearch();
      }
    }
  },
  computed: {
    archersWord() {
      return this.$trans.choice('search.archer', 2);
    },
    tournamentsWord() {
      return this.$trans.choice('search.tournament', 2);
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.tournaments = this.inc_tournaments;
    },
    submitSearch() {
      this.$axios
      .get('/results', {
        params: {
          'search': this.search
        }
      })
      .then(({data}) => {
        this.tournaments = data.tournaments;
      })
      .catch(({response}) => {
        console.log(response);
      });
    }
  }
}
</script>

<style scoped>

</style>
