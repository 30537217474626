<template>
  <div class="w-full">
    <div v-if="tournament && tournament.hasOwnProperty('unassignedCompetitors') && round && lineTime && location">
      <lock-device
        v-if="showLock"
        @close="showLock = false"
        @lock="lockDevice"
        @unlock="unlockDevice"
        :tournament="tournament"
        :locked="locked"/>
      <div v-else>
        <div class="flex justify-between p-2">
          <div @click="showLock = true" class="mt-1 text-gray-700 float-left">
            <span v-show="locked"><i class="fas fa-lock-alt fa-5x"></i></span>
            <span v-show="!locked"><i class="fas fa-lock-open-alt fa-5x"></i></span>
          </div>
          <div v-if="tournament.club_id" class="bootstrap">
            <div v-if="locked" class="flex justify-center">
              <router-link to="/complete" class="btn-lg btn-outline-blue-600">
                <a>End Scoring</a>
              </router-link>
            </div>
            <div
              v-if="tournament.hasOwnProperty('rounds') && tournament.rounds.length > 1 && !restrictedRounds.length"
              class="dropdown">
              <div id="roundSelection"
                   class="text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl dropdown-toggle"
                   data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">
                {{ capitalize(round.name) }} Scoring
              </div>
              <div class="dropdown-menu" aria-labelledby="roundSelection">
                <span v-for="(r,index) in tournament.rounds" class="w-full">
                  <div @click="updateRound(r)"
                       class="dropdown-item flex justify-between text-xl">
                    <span>{{ r.name }}</span>
                    <span class="ml-2 text-center font-bold"
                          :class="getColorFromPercentage(roundCompletion(r))">
                      {{ roundCompletion(r) }}%
                    </span>
                  </div>
                </span>
              </div>
            </div>
            <div v-else class="text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl">
              {{ capitalize(round.name) }} Scoring
            </div>
            <div v-if="(round.locations.length > 1 || round.line_times.length > 1)"
                 class="dropdown">
              <div
                v-if="!locked || (locked && (restrictedLocations.length > 1 || restrictedTimes.length > 1))">
                <div id="assignmentselection" data-toggle="dropdown" aria-haspopup="true"
                     aria-expanded="false"
                     class="dropdown-toggle text-xl sm:text-2xl md:text-3xl lg:text-4xl">
                  {{ location.name }} - {{ lineTime.time }}
                </div>
                <div class="dropdown-menu w-full" aria-labelledby="assignmentselection">
                  <span v-for="loc in round.locations" class="text-center">
                    <span v-for="time in round.line_times">
                      <div v-if="checkRestricted(time,loc)"
                           @click="updateLocTime(loc, time)"
                           class="dropdown-item text-xl">
                        <span>{{ loc.name }} - {{ time.time }}</span>
                        <span class="ml-2 font-bold"
                              :class="getColorFromPercentage(locationTimeCompletion(round,time,loc))">
                          {{ locationTimeCompletion(round, time, loc) }}%
                        </span>
                      </div>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-4xl text-center">
            {{ capitalize(tournament.name) }} Scoring
          </div>
          <span @click="uploadScores" class="mt-1 text-blue-600 float-right">
            <div v-if="uploadingButton === 'uploading'" key="showUploading">
              <i class="fa-5x fas fa-spin fa-spinner float-right"></i>
            </div>
            <div v-else-if="uploadingButton === 'save'" key="showNotUploading">
              <i class="fa-5x far fa-arrow-alt-circle-up float-right"></i>
            </div>
            <div v-else-if="uploadingButton === 'happy'" key="showHappy">
              <i class="fa-5x far fa-smile float-right"></i>
            </div>
            <div v-else-if="uploadingButton === 'offline'" key="showOffline">
              <i class="fa-5x fas fa-wifi-slash text-gray-500 float-right"></i>
            </div>
            
            <small v-if="offlineScores && offlineScores.length > 0" id='unsaved'
                   class="text-gray-500 whitespace-nowrap">
              {{ offlineScores.length }} unsaved changes
            </small>
          </span>
        </div>
        <div class="endList flex flex-wrap p-2">
          <div class="text-xl sm:text-2xl md:text-3xl lg:text-4xl m-2 h-center leading-none">
            {{ capitalize(this.$trans.choice('search.end', 3)) }}:
          </div>
          <div v-for="e in round.ends_count" @click="updateEnd(e)" class="m-2 btn-lg"
               :class="classForEnd(e)">{{ e }}
          </div>
        </div>
        <div class="text-lg">
          <div class="flex justify-center">
            <div class="h-center">
              Scoring Selection Completion: %{{ filteredCompletion }}
            </div>
            <div v-if="filteredCompletion < 100" class="text-blue-600 ml-6 cursor-pointer"
                 @click="toggleIncomplete">
              <span v-if="!highlight">Hightlight Incomplete</span>
              <span v-else>Remove Hightlights</span>
            </div>
          </div>
        
        </div>
        
        <div v-if="!filteredCount()" class="text-center">
          <p>No Assigned Competitors</p>
        </div>
        
        <div v-for="(bale, baleNumber) in filteredCompetitors" class="lg:w-3/4 mx-auto">
          <div v-if="bale.length" class="flex justify-end text-xl border-b mt-10">
            {{ this.$trans.choice('search.bale', 1) }} {{ baleNumber }}
          </div>
          <div v-for="c in bale" class="flex-col m-3" :ref="'score_block_' + c.id"
               :class="competitor ? competitor.id === c.id ? 'flex ' : 'hidden' : 'flex'">
            <div class="flex flex-col">
              <div v-if="round.arrows_count > 1" class="text-2xl flex justify-between">
                <div v-if="mustAccept(c)" class="flex-grow mr-3">
                  <button @click="denyScores(c,baleNumber)" class="btn btn-gray-600 w-full">Deny
                  </button>
                </div>
                <div class="h-center text-4xl">{{ c.name }}</div>
                <div v-if="mustAccept(c)" class="flex-grow ml-3">
                  <button @click="acceptScores(c,baleNumber)" class="btn btn-blue-600 w-full">Accept
                  </button>
                </div>
              </div>
              <div class="flex flex-wrap">
                <template v-if="round.arrows_count === 1 && mustAccept(c)">
                  <button @click="denyScores(c,baleNumber)"
                          class="btn btn-gray-600 text-3xl flex-grow">
                    Deny
                  </button>
                </template>
                <div @click="openScore(c,a)" v-for="a in round.arrows_count"
                     class="scoreButton text-2xl p-2 flex-1 flex flex-col"
                     :class="(competitor && competitor.id === c.id) && (arrow && arrow === a) ? 'scoreSelected' : ''"
                >
                  <div v-if="round.arrows_count > 1" class="whitespace-nowrap">Arrow {{ a }}</div>
                  <div v-else>{{ c.name }}</div>
                  <div class="text-4xl" :class="(!getScore(c,a)) && highlight ? 'bg-red-300' : ''">
                    &nbsp;{{ getScore(c, a) }}&nbsp;
                  </div>
                </div>
                <template v-if="round.arrows_count === 1 && mustAccept(c)">
                  <button @click="acceptScores(c,baleNumber)"
                          class="btn btn-blue-600 text-3xl flex-grow">Accept
                  </button>
                </template>
              </div>
            </div>
            
            <div class="w-full my-3 flex-grow justify-center" v-if="competitor && competitor.id === c.id">
              <div v-if="target_values" class="flex flex-wrap p-3">
                <div class="w-1/3 p-0" v-for="target in target_values">
                  <div @click="updateScore(target, baleNumber)"
                       :class="getScore(c,arrow) === target.name ? 'scoreSelected' : ''"
                       class="scoreButton text-6xl">
                    {{ target.name }}
                  </div>
                </div>
                <div class="w-full p-0 mt-1">
                  <div @click="clear(arrow, baleNumber)" class="btn-lg text-3xl btn-outline-gray-600">
                    clear
                  </div>
                </div>
              </div>
              <div class="flex justify-between pb-2 px-2">
                <div @click="closeScore" class="btn btn-lg text-3xl btn-outline-gray-600">close</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link :to="tournament.club_id ? '/score' : '/practice/score'" class="mt-10 flex justify-center">
        <a class="text-blue-600 cursor-pointer">switch to original scoring mode</a>
      </router-link>
    </div>
    <div v-else class="text-center py-32">
      <h1 class="mt-4">Loading Tournament Details</h1>
      <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
    </div>
  
  </div>
</template>

<script>
export default {
  name: "ScoringContrast",
  props: ['tournament', 'user', 'verified', 'device_id', 'expired'],
  data() {
    return {
      round: null,
      lineTime: null,
      location: null,
      end: 1,
      uploading: false,
      showLock: false,
      locked: false,
      restrictedBales: [],
      restrictedCompetitors: [],
      restrictedLocations: [],
      restrictedTimes: [],
      restrictedRounds: [],
      notAccepted: [],
      ignoreSize: false,
      competitor: null,
      arrow: null,
      replace: [],
      device: this.verified ? 'logged_in' : 'not_verified',
      highlight: false,
    }
  },
  
  computed: {
    filteredCompletion() {
      let scoresCount = 0;
      let competitorCount = 0;
      let vm = this;
      Object.values(this.filteredCompetitors).forEach(function (bale) {
        Object.values(bale).forEach(function (competitor) {
          competitorCount++;
          scoresCount += competitor.scores.filter(f => f.round === vm.round.id && f.end === vm.end).length
        })
      });
      let scoreMax = competitorCount * vm.round.arrows_count;
      return scoreMax === 0 ? 100 : Math.floor((scoresCount / scoreMax) * 100);
    },
    scores() {
      if (this.notAccepted.length) {
        let scores = [];
        this.competitor.scores.forEach(f => scores.push(f));
        this.notAccepted.forEach(f => scores.push(f));
        return scores;
      }
      return this.competitor.scores;
    },
    uploadingButton() {
      if (this.uploading) {
        return 'uploading';
      }
      if (this.offlineScores.length > 0) {
        if (!this.online) {
          return 'offline';
        }
        return 'save';
      }
      return 'happy';
    },
    filteredCompetitors() {
      let competitors = {};
      let selected = this.tournament.assignedCompetitors.rounds[this.round.id].line_times[this.lineTime.id].locations[this.location.id].bales;
      let restrictedBales = this.restrictedBales.length ? this.restrictedBales : null;
      let restrictedCompetitors = this.restrictedCompetitors.length ? this.restrictedCompetitors : null;
      Object.keys(selected).forEach(function (bale) {
        if (!restrictedBales || restrictedBales.indexOf(parseInt(bale)) !== -1) {
          competitors[bale] = [];
          selected[bale].competitors.forEach(function (competitor) {
            if (!restrictedCompetitors || (restrictedCompetitors && restrictedCompetitors.includes(competitor.name))) {
              competitors[bale].push(competitor)
            }
          })
        }
      });
      return competitors;
    },
    tooMany() {
      return Object.values(this.filteredCompetitors).length > 25 && !this.ignoreSize
    },
    offlineAssignment() {
      return this.$store.getters['tournament/getOfflineAssignment'];
    },
    offlineScores() {
      return this.$store.getters['tournament/getOfflineScores'];
    },
    offlineCompetitors() {
      return this.$store.getters['tournament/getOfflineCompetitors'];
    },
    online() {
      return this.$store.state.tournament.online;
    },
    target_values() {
      let target = this.tournament.targetValues.find(f => f.id === this.competitor.target_id);
      return target.values;
    },
  },
  methods: {
    capitalize(text) {
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    mustAccept(competitor) {
      if (this.notAccepted.length) {
        let exists = this.notAccepted.filter(f => f.competitor_id === competitor.id && f.round === this.round.id);
        if (exists.length) {
          return true;
        }
      }
      return false;
    },
    getScore(competitor, arrow) {
      let score = competitor.scores.find(f => f.arrow === arrow && f.end === this.end && f.round === this.round.id);
      if (this.notAccepted.length) {
        let exists = this.notAccepted.find(f => f.arrow === arrow && f.end === this.end && f.competitor_id === competitor.id && f.round === this.round.id);
        if (exists) {
          score = exists;
        }
      }
      if (score) {
        return score.target_hit
      }
      return null
    },
    updateScore(target, bale) {
      let newScore = {
        tournament_id: this.tournament.id,
        tournament_name: this.tournament.name,
        competitor_id: this.competitor.id,
        device: this.device,
        uploader_id: this.user ? this.user.id : null,
        competitor_name: this.competitor.name,
        class: this.getCompetitorClass(),
        division: this.getCompetitorDivision(),
        line_time: this.lineTime.time,
        location: this.location.name,
        arrow: this.arrow,
        target_hit: target.name,
        score: target.value,
        round: this.round.id,
        round_name: this.round.name,
        end: parseInt(this.end),
        authorized: this.verified,
        created_at: moment().format(),
      };
      if (!this.tournament.accept_button) {
        this.$store.dispatch('tournament/updateScore', {
          'score': newScore,
          'lineTime': this.lineTime,
          'location': this.location,
          'bale': bale
        })
      } else {
        let vm = this;
        //compare against not accepted
        let index = vm.notAccepted.findIndex(f => f.arrow === parseInt(vm.arrow) && f.end === parseInt(vm.end) && f.round === vm.round.id && f.competitor_id === newScore.competitor_id);
        if (index !== -1) {
          vm.notAccepted.splice(index, 1);
        }
        //compare against saved scores
        let i = vm.competitor.scores.findIndex(f => f.arrow === parseInt(vm.arrow) && f.end === parseInt(vm.end) && f.round === vm.round.id && f.competitor_id === newScore.competitor_id);
        if (i !== -1) {
          vm.replace.push(vm.scores[i]);
          vm.competitor.scores.splice(i, 1);
        }
        vm.notAccepted.push(newScore);
      }
      this.closeScore();
    },
    closeScore() {
      let scrollTo = 'score_block_' + this.competitor.id;
      this.competitor = null;
      this.arrow = null;
      let container = this.$refs[scrollTo][0];
      this.$nextTick(() => {
        container.scrollIntoView();
      });
      
    },
    openScore(competitor, arrow) {
      if (this.competitor === competitor && this.arrow === arrow) {
        this.closeScore();
      } else {
        this.competitor = competitor;
        this.arrow = arrow;
        let scrollTo = 'score_block_' + competitor.id;
        let container = this.$refs[scrollTo][0];
        this.$nextTick(() => {
          container.scrollIntoView();
        });
      }
      
    },
    getCompetitorClass() {
      let detail = this.competitor.regform.find(r => r.option === 'class');
      return detail ? detail.name : null
    },
    getCompetitorDivision() {
      let detail = this.competitor.regform.find(r => r.option === 'division');
      return detail ? detail.multiple : null
    },
    checkRestricted(time, loc) {
      let t = this.restrictedTimes.length ? this.restrictedTimes.findIndex(f => f.id === time.id) !== -1 : true;
      let l = this.restrictedLocations.length ? this.restrictedLocations.findIndex(f => f.id === loc.id) !== -1 : true;
      return t && l;
    },
    getColorFromPercentage(percentage) {
      if (percentage > 0) {
        if (percentage >= 100) {
          return 'text-blue-700';
        } else {
          return 'text-blue-500';
        }
      }
      return 'text-gray-600';
    },
    roundCompletion(round) {
      let scoresCount = 0;
      let competitorCount = 0;
      let restricted = this.restrictedBales.length ? this.restrictedBales : null;
      Object.values(this.tournament.assignedCompetitors.rounds[round.id].line_times).forEach(function (lineTime) {
        Object.values(lineTime.locations).forEach(function (location) {
          Object.values(location.bales).forEach(function (bale) {
            if (!restricted || restricted.indexOf(parseInt(bale)) !== -1) {
              Object.values(bale.competitors).forEach(function (competitor) {
                competitorCount++;
                scoresCount += competitor.scores.filter(f => f.round === round.id).length
              })
            }
          })
        })
      });
      let roundScoreMax = competitorCount * round.arrows_count * round.ends_count;
      return roundScoreMax === 0 ? 100 : Math.floor((scoresCount / roundScoreMax) * 100);
    },
    locationTimeCompletion(round, time, loc) {
      let scoresCount = 0;
      let competitorCount = 0;
      let restricted = this.restrictedBales.length ? this.restrictedBales : null;
      let locTime = this.tournament.assignedCompetitors.rounds[round.id].line_times[time.id].locations[loc.id];
      Object.values(locTime.bales).forEach(function (bale) {
        if (!restricted || restricted.indexOf(parseInt(bale)) !== -1) {
          Object.values(bale.competitors).forEach(function (competitor) {
            competitorCount++;
            scoresCount += competitor.scores.filter(f => f.round === round.id).length
          })
        }
      });
      let scoreMax = competitorCount * round.arrows_count * round.ends_count;
      return scoreMax === 0 ? 100 : Math.floor((scoresCount / scoreMax) * 100);
    },
    
    connectMessage() {
      Swal.fire('You cannot upload scores without being connected to the internet.')
    },
    updateEnd(end) {
      if (this.notAccepted.length) {
        Swal.fire('Scores must be accepted or denied before switching end');
      } else {
        this.end = end;
      }
    },
    updateLocTime(location, time) {
      if (this.notAccepted.length) {
        Swal.fire('Scores must be accepted or denied before switching time or location');
      } else {
        this.location = location;
        this.lineTime = time;
      }
    },
    classForEnd(end) {
      
      let btn = 'btn-';
      let selectedEnd = this.end;
      if (selectedEnd !== end) {
        btn += 'outline-';
      }
      let scoresForEnd = 0;
      let round = this.round;
      let competitorCount = 0;
      Object.values(this.filteredCompetitors).forEach(function (bale) {
        bale.forEach(function (competitor) {
          competitorCount++;
          competitor.scores.forEach(function (f) {
            if (f.round === round.id && f.end === end) {
              scoresForEnd++;
            }
          })
        })
      });
      let finished = competitorCount * this.round.arrows_count;
      if (scoresForEnd > 0) {
        if (scoresForEnd >= finished) {
          btn += 'blue-800';
        } else {
          btn += 'gray-400';
        }
      } else {
        btn += 'gray-400';
      }
      return btn;
      
      
      // return end === this.end ? 'endButton scoreSelected' : 'endButton'
    },
    updateRound(round) {
      if (this.notAccepted.length) {
        Swal.fire('Scores must be accepted or denied before switching round');
      } else {
        this.round = round;
        this.location = round.locations[0];
        this.lineTime = round.line_times[0];
      }
    },
    
    lockDevice(bales, competitors, locations, times, rounds, soft) {
      this.restrictedBales = bales;
      this.restrictedCompetitors = competitors;
      this.restrictedLocations = locations;
      this.restrictedTimes = times;
      this.restrictedRounds = rounds;
      
      let vm = this;
      let availableRounds = this.restrictedRounds.length ? this.restrictedRounds : this.tournament.rounds;
      let safeRounds = [];
      let set = false;
      availableRounds.forEach(function (r) {
        let rTime = vm.restrictedTimes.length ? null : vm.lineTime;
        let rLocation = vm.restrictedLocations.length ? null : vm.location;
        vm.restrictedTimes.forEach(function (t) {
          let exists = r.line_times.findIndex(f => f.id === t.id);
          if (exists !== -1) {
            rTime = r.line_times[exists];
          }
        });
        vm.restrictedLocations.forEach(function (l) {
          let exists = r.locations.findIndex(f => f.id === l.id);
          if (exists !== -1) {
            rLocation = r.locations[exists];
          }
        });
        if (rTime && rLocation) {
          if (!set) {
            vm.round = r;
            vm.lineTime = rTime;
            vm.location = rLocation;
            set = true;
          }
          safeRounds.push(r);
        }
      });
      if (this.tournament.rounds.length > safeRounds.length) {
        rounds = safeRounds;
        this.restrictedRounds = rounds;
      }
      this.$localForage.setItem(this.tournament.id + '_lock', true);
      if (bales) {
        this.$localForage.setItem(this.tournament.id + '_locked_bales', bales);
      }
      if (competitors) {
        this.$localForage.setItem(this.tournament.id + '_locked_competitors', competitors);
      }
      if (locations) {
        this.$localForage.setItem(this.tournament.id + '_locked_locations', locations);
      }
      if (times) {
        this.$localForage.setItem(this.tournament.id + '_locked_times', times);
      }
      if (rounds) {
        this.$localForage.setItem(this.tournament.id + '_locked_rounds', rounds);
      }
      if (soft) {
        this.$localForage.setItem(this.tournament.id + '_locked_soft', true);
      }
      
      this.locked = true;
      this.showLock = false;
      this.$emit('toggle-lock');
      
    },
    unlockDevice() {
      this.restrictedBales = [];
      this.restrictedCompetitors = [];
      this.restrictedLocations = [];
      this.restrictedTimes = [];
      this.restrictedRounds = [];
      this.$localForage.removeItem(this.tournament.id + '_lock');
      this.$localForage.removeItem(this.tournament.id + '_locked_bales');
      this.$localForage.removeItem(this.tournament.id + '_locked_competitors');
      this.$localForage.removeItem(this.tournament.id + '_locked_locations');
      this.$localForage.removeItem(this.tournament.id + '_locked_times');
      this.$localForage.removeItem(this.tournament.id + '_locked_rounds');
      this.$localForage.removeItem(this.tournament.id + '_locked_soft');
      this.locked = false;
      this.showLock = false;
      this.$emit('toggle-lock');
    },
    checkEndCompletion(end) {
      let arrowsCount = this.round.arrows_count;
      let round = this.round;
      let complete = Object.values(this.filteredCompetitors).every(function (bale) {
        return bale.every(function (competitor) {
          let foundScores = competitor.scores.filter(f => f.end === end && f.round === round.id);
          return foundScores.length === arrowsCount;
        });
      });
      if (complete) {
        let nextEnd = end + 1;
        while (nextEnd <= this.round.ends_count && complete) {
          complete = Object.values(this.filteredCompetitors).every(function (bale) {
            return bale.every(function (competitor) {
              let foundScores = competitor.scores.filter(f => f.end === nextEnd);
              return foundScores.length === arrowsCount;
            });
          });
          if (!complete) {
            this.end = nextEnd;
            this.toggleIncomplete();
            return true;
          }
          nextEnd++;
        }
      }
    },
    requestAuth() {
      let tournament = this.tournament;
      let vm = this;
      var options = {
        plugins: {excludeIE: true},
        excludes: {
          userAgent: true,
          screenResolution: true,
          hasLiedResolution: true,
          hasLiedLanguages: true,
          sessionStorage: true,
          localStorage: true,
          indexedDb: true,
          webgl: true,
          webglVendorAndRenderer: true,
          availableScreenResolution: true,
          hasLiedOs: true,
          hasLiedBrowser: true,
          plugins: true,
          touchSupport: true,
          audio: true,
          enumerateDevices: true,
          fontsFlash: true,
          fonts: true,
          adBlock: true,
        }
      };
      Fingerprint2.get(options, function (components) {
        let token = Fingerprint2.x64hash128(components.map(function (pair) {
          return pair.value
        }).join(), 31);
        this.$axios.post('/device/request', {
          'tournament': tournament.id,
          'club': tournament.club_id,
          'token': token
        })
        .then(({data}) => {
          vm.$emit('add-device', data.device_id);
          Swal.fire('A request has been made to authorize this device. Please contact the tournament coordinator to expedite this process.');
        })
        .catch(({response}) => {
          console.log(response);
        });
      });
    },
    filteredCount() {
      return Object.values(this.filteredCompetitors).reduce(function (a, c) {
        return a + c.length;
      }, 0);
    },
    acceptScores(competitor, bale) {
      let competitorScores = this.notAccepted.filter(f => f.competitor_id === competitor.id);
      this.$store.dispatch('tournament/updateScores', {
        'scores': competitorScores,
        'lineTime': this.lineTime,
        'location': this.location,
        'bale': bale
      }).then(() => {
        this.notAccepted = this.notAccepted.filter(f => f.competitor_id !== competitor.id);
      });
    },
    denyScores(competitor) {
      this.notAccepted = this.notAccepted.filter(f => f.competitor_id !== competitor.id);
      this.replace.forEach(f => competitor.scores.push(f));
      this.replace = [];
    },
    clear(arrow, bale) {
      if (this.notAccepted.length) {
        let index = this.notAccepted.findIndex(f => f.arrow === arrow && f.competitor_id === this.competitor.id);
        if (index !== -1) {
          this.notAccepted.splice(index, 1);
        }
      }
      this.$store.dispatch('tournament/clearScore', {
        'round': this.round,
        'lineTime': this.lineTime,
        'location': this.location,
        'bale': bale,
        'competitor': this.competitor,
        'arrow': arrow,
        'end': this.end
      });
      this.closeScore();
    },
    toggleIncomplete() {
      this.highlight = !this.highlight;
    }
  },
  created() {
    
    this.$localForage.getItem(this.tournament.id + '_lock').then((locked) => {
      if (locked) {
        this.locked = true;
      }
    });
    let vm = this;
    this.$localForage.getItem(vm.tournament.id + '_locked_rounds').then((rounds) => {
      if (rounds && rounds.length) {
        vm.restrictedRounds = rounds;
      }
      this.$localForage.getItem(vm.tournament.id + '_locked_times').then((times) => {
        if (times && times.length) {
          vm.restrictedTimes = times;
        }
        this.$localForage.getItem(vm.tournament.id + '_locked_locations').then((locations) => {
          if (locations && locations.length) {
            vm.restrictedLocations = locations;
          }
          let rounds = vm.restrictedRounds.length ? vm.restrictedRounds : vm.tournament.rounds;
          let set = false;
          rounds.some(function (r) {
            let rTime = vm.restrictedTimes.length ? null : r.line_times[0];
            let rLocation = vm.restrictedLocations.length ? null : r.locations[0];
            vm.restrictedTimes.forEach(function (t) {
              let exists = r.line_times.findIndex(f => f.id === t.id);
              if (exists !== -1) {
                rTime = r.line_times[exists];
              }
            });
            vm.restrictedLocations.forEach(function (l) {
              let exists = r.locations.findIndex(f => f.id === l.id);
              if (exists !== -1) {
                rLocation = r.locations[exists];
              }
            });
            if (rTime && rLocation) {
              vm.round = r;
              vm.lineTime = rTime;
              vm.location = rLocation;
              set = true;
              return true;
            }
          });
          if (!set) {
            vm.round = rounds[0];
            vm.lineTime = vm.round.line_times[0];
            vm.location = vm.round.locations[0];
          }
          
        });
      });
    });
    
    this.$localForage.getItem(this.tournament.id + '_locked_bales').then((bales) => {
      if (bales) {
        this.restrictedBales = bales;
      }
    });
    this.$localForage.getItem(this.tournament.id + '_locked_competitors').then((competitors) => {
      if (competitors) {
        this.restrictedCompetitors = competitors;
      }
    });
  },
}
</script>

<style scoped>
.scoreButton.scoreSelected,
.scoreButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.scoreButton {
  @apply border-blue-800 border text-blue-800 w-full font-black text-center align-middle;
}

.endButton.scoreSelected,
.endButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.endButton {
  @apply border-blue-800 border text-blue-800 font-black text-center align-middle;
}
</style>
