<template>
  <div class="mt-2">
    <div class="text-xl">{{ `${type[0].toUpperCase()}${type.slice(1)}` }} Cover Image (1280 x 320)
      <span class="text-gray-600 text-sm">The default image will be shown if you skip this option.</span>
    </div>
    <div v-if="!enabled" class="text-red-600">
      The image you have provided was flagged as possibly inappropriate and is currently being
      reviewed. The default image will be shown during the review process.
    </div>
    <div v-if="type === 'tournament'" v-cloak class="mt-3 px-3 py-6 lg:py-10 banner md:h-80"
         :style="'background-image: url(' + imgUrl + ');'">
      <div class="flex justify-between flex-col-reverse sm:flex-row text-xl">Tournament Dates</div>
      <h1 class="uppercase text-4xl leading-none mt-2">
        {{ tournament.name ? tournament.name : 'Tournament Name' }}
      </h1>
      <div class="mb-2">
        <a :href="`/clubs/${club.slug}`" class="text-xl uppercase">hosted by {{ club.name }}</a>
      </div>
      <h5 class="uppercase text-lg ">
        Tournament City, State Country
      </h5>
    </div>
    <div v-if="type === 'club'" v-cloak class="banner mt-3 px-3 py-6 lg:py-10 banner md:h-80"
         :style="'background-image: url(' + imgUrl + ');'">
      <span class="uppercase mb-0">
        Club City, State
      </span>
      <h1 class="uppercase text-center text-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl my-8">
        Club Name
      </h1>
    </div>
    <div class="flex flex-col mt-2">
      <div class="flex justify-center">
        <image-upload
          :title="'Cover Image (1280x320 .jpg)'"
          :option="type"
          :type="type"
          :height="320"
          :width="1280"
          :multiplier="4"
          :inc_url="imgUrl"
          :file_id="typeSlug"
          @update_image="updateImage"
          @remove_image="removeImage">
          <template v-slot:open-modal>
            <button type="button" class="btn btn-outline-blue-600 m-2" data-toggle="modal"
                    data-target="#cropper"
                    data-backdrop="true">
              Upload Image
            </button>
          </template>
        </image-upload>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";
import axios from 'axios';

export default {
  name: "CoverImage",
  components: {ImageUpload},
  props: ['inc_tournament', 'inc_club', 'type'],
  data() {
    return {
      club: this.inc_club,
      tournament: this.inc_tournament,
      imgUrl: '/img/defaultTournamentImage.jpg',
      enabled: true,
    }
  },
  created() {
    this.setImageUrl();
    this.setEnabled();
  },
  computed: {
    typeSlug() {
      if (this.type === 'club') {
        return this.club.slug ? this.club.slug : null;
      }
      return this.tournament.slug ? this.tournament.slug : null;
    },
  },
  methods: {
    setEnabled() {
      if (this.type === 'tournament') {
        this.enabled = (this.tournament.image && this.tournament.image_enabled) || !this.tournament.image;
      } else if (this.type === 'club') {
        this.enabled = (this.club.image && this.club.image_enabled) || !this.club.image;
      }
    },
    setImageUrl() {
      let url = '';
      if (this.type === 'tournament') {
        if (this.tournament.image && this.tournament.image_enabled) {
          url = this.tournament.image
        } else if (this.club.image && this.club.image_enabled) {
          url = this.club.image
        } else {
          url = '/img/defaultTournamentImage.jpg'
        }
      } else if (this.type === 'club') {
        url = this.club.image && this.club.image_enabled ? this.club.image : '/img/defaultTournamentImage.jpg';
      }
      this.imgUrl = url;
    },
    updateImage(details) {
      let [url, enabled, _] = details;
      this.imgUrl = url;
      this.enabled = enabled;
      this.$emit('updateImage', [url, enabled])
    },
    removeImage() {
      return axios({
        method: 'post',
        url: '/image/' + this.type + '/delete/' + this.typeSlug,
      })
      .then(() => {
        if (this.type === 'tournament') {
          this.tournament.image = null;
        } else if (this.type === 'club') {
          this.club.image = null;
        }
        this.setImageUrl();
        this.setEnabled();
      })
    },
  }
  
}
</script>

<style scoped>
.banner {
  background-color: black;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  text-shadow: 0 0 1em #000;
}
</style>
