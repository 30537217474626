<template>
  <div v-cloak class="px-5 lg:px-10 py-6 lg:py-10 md:h-80" id="tournamentBanner"
       :style="'background-image: url(' + imgUrl + ');'">
    <div class="flex justify-between flex-col-reverse sm:flex-row text-xl">{{ throughDate }}</div>
    <h1 class="uppercase text-4xl leading-none mt-2">
      {{ tournament.name }}
    </h1>
    <div class="mb-2">
      <a :href="/clubs/ + tournament.club.slug" class="text-xl uppercase">hosted by {{ tournament.club.name }}</a>
    </div>
    <h5 class="uppercase text-lg ">
      {{ tournament.address.city }}, {{ tournament.address.state }} {{ tournament.address.country }}
    </h5>
  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "TournamentDetails",
  props: ['tournament', 'god'],
  computed: {
    imgUrl() {
      if (this.tournament.image && (this.tournament.image_enabled || this.god)) {
        return this.tournament.image;
      }
      if (this.tournament.club.image && (this.tournament.club.image_enabled || this.god)) {
        return this.tournament.club.image;
      }
      return '/img/defaultTournamentImage.jpg';
    },
    throughDate() {
      let start = moment(new Date(this.tournament.start_time));
      let end = moment(new Date(this.tournament.end_time));
      let dateString = '';
      if (end.year() !== start.year()) {
        dateString += start.format('MMM D, YYYY - ');
      } else {
        dateString += start.format('MMM D-');
      }
      if (end.month() !== start.month()) {
        dateString += end.format('MMM D, YYYY');
      } else {
        dateString += end.format('D, YYYY');
      }
      return dateString
    }
  }
}
</script>

<style scoped>
#tournamentBanner {
  background-color: black;
  background-size: cover;
  background-position: center;
  color: #ffffff;
  text-shadow: 0 0 1em #000;
}

#tournamentDescription {
  /*background-color: rgba(0, 0, 0, 0.77);*/
}
</style>
