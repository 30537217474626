<template>
  <div class="flex flex-wrap">
    <div
      @click="openScore(competitorId,a)" v-for="a in roundItem.arrows_count"
      class="scoreButton text-2xl order-1 sm:order-2"
      :class="arrowIsSelected(a) ? 'scoreSelected' : ''">
      <div v-if="roundItem.arrows_count > 1" class="whitespace-nowrap text-lg mx-1"> Arrow {{ a }}</div>
      <div v-else>{{ position !== 'undefined' ? position : 'FULL' }}
        &middot;{{ tournament.competitorList[competitorId].name }}
      </div>
      <div v-if="hasHit(competitorId, roundItem.id, end, a)"
           class="text-4xl xs:text-5xl sm:text-7xl lg:text-8xl leading-none hitCount mt-2 mb-2">
        {{ arrowValue(a, false) }}
      </div>
      <div v-else class="leading-none bg-blue-600 m-2 text-4xl xs:text-5xl sm:text-7xl lg:text-8xl ">&nbsp;</div>
      <div v-if="roundItem.arrows_count === 1 && !mustAccept" class="flex justify-around">
        <div> {{ roundWord }}: {{ roundPoints }} {{ roundBreakers }}</div>
        <div> End: {{ endPoints }} {{ endBreakers }}</div>
      </div>
    </div>
    <div @click="openScore(competitorId,a.arrow)"
         v-for="a of extraArrows"
         class="scoreButton text-2xl order-1 sm:order-2"
         :class="arrowIsSelected(a.arrow) ? 'scoreSelected' : ''">
      <div v-if="roundItem.arrows_count > 1" class="whitespace-nowrap text-lg mx-1"> Arrow {{ a.arrow }}</div>
      <div v-else>{{ position !== 'undefined' ? position : 'FULL' }}
        &middot;{{ tournament.competitorList[competitorId].name }}
      </div>
      <div v-if="hasHit(competitorId, roundItem.id, end, a.arrow, true)"
           class="text-4xl xs:text-5xl sm:text-7xl lg:text-8xl leading-none hitCount mt-2 mb-2">
        {{ arrowValue(a.arrow, true) }}
      </div>
      <div v-else class="leading-none bg-blue-600 m-2 text-4xl xs:text-5xl sm:text-7xl lg:text-8xl ">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import {mapState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "ArrowSelection",
  mixins: [mixin],
  props: ['competitorId', 'baleNumber', 'position'],
  computed: {
    ...mapState(useDisplayStore, ['end', 'arrow', 'competitor', 'ladder']),
    extraArrows() {
      let list = [];
      if (this.roundData.ends[this.end].extras != null) {
        list = Object.values(this.roundData.ends[this.end].extras)
      }
      return list;
    },
    roundData() {
      if (this.ladder != null) {
        if (`${this.ladder.id}_${this.step}` in this.scoreList[this.competitorId].ladders) {
          return this.scoreList[this.competitorId].ladders[`${this.ladder.id}_${this.step}`];
        } else {
          return null;
        }
      }
      return this.scoreList[this.competitorId].rounds[this.round.id];
    },
    is3d() {
      return this.tournament.target_style === 'Stakes'
    },
    endPoints() {
      if (this.roundData == null) return 0;
      return this.roundData.ends[this.end].points;
    },
    endBreakers() {
      if (this.roundData == null) return '';
      let xCount = this.roundData.ends[this.end]['X'];
      return xCount > 0 ? ' +' + xCount + (this.is3d ? '-12s' : 'X') : '';
    },
    roundPoints() {
      return this.roundData.points
    },
    roundBreakers() {
      if (this.roundData == null) return '';
      let xCount = this.roundData['X'];
      return xCount > 0 ? ' +' + xCount + (this.is3d ? '-12s' : 'X') : '';
    },
    mustAccept() {
      let cId = this.competitorId;
      let rId = this.roundItem.id;
      if (this.notAccepted.length) {
        let exists = this.notAccepted.filter(function (f) {
          return f.competitor_id === cId && f.round === rId;
        });
        return exists.length;
      }
      return false;
    }
  },
  methods: {
    arrowValue(a, extra) {
      if (this.roundData == null) return 0;
      let endData = this.roundData.ends[this.end];
      if (!extra) {
        return endData.arrows[a].target_hit;
      }
      return endData.extras[a].target_hit;
    },
    hasHit(c, rId, end, a, extra) {
      let roundWord = this.ladder == null ? 'rounds' : 'ladders';
      let roundId = this.ladder == null ? rId : `${rId}_${this.step}`;
      if (!(roundId in this.scoreList[c][roundWord])) return false;
      let endScore = this.scoreList[c][roundWord][roundId].ends[end];
      let score = endScore[extra ? 'extras' : 'arrows'][a];
      return score && score.target_hit;
    },
    openScore(competitor, arrow) {
      this.$emit('openScore', competitor, arrow);
    },
    arrowIsSelected(arrow) {
      let isCompetitor = this.competitor && this.competitor.id === this.competitorId;
      let isArrow = this.arrow && this.arrow === arrow;
      return isCompetitor && isArrow;
    }
  }
}
</script>

<style scoped>
.scoreButton:hover {
  @apply bg-blue-800 text-blue-100;
}

.scoreButton {
  @apply border-blue-800 border text-blue-800 w-full font-black text-center align-middle flex-1;
}

.scoreSelected {
  @apply bg-blue-800 text-blue-100;
}

.hitCount {
  font-family: sans-serif;
}
</style>
