<template>
  <div class="h-full">
    <iframe
      width="100%" height="100%"
      :src="mapQuery"
      class="mapFrame"/>
  </div>
</template>

<script>

export default {
  name: "ClubMap",
  props: ['club', 'zoom'],
  computed: {
    mapQuery() {
      let query = 'https://maps.google.com/maps?q=';
      let address = '';
      if (this.club.range_address && this.club.range_address.address1) {
        address += this.club.range_address.address1 + ' ' +
          (this.club.range_address.address2 ? this.club.range_address.address2 + ' ' : '') +
          (this.club.range_address.city ? this.club.range_address.city + ' ' : '') +
          (this.club.range_address.state ? this.club.range_address.state + ' ' : '') +
          (this.club.range_address.zip ? this.club.range_address.zip : '');
      } else if (this.club.mailing_address && this.club.range_address.address1) {
        address += this.club.mailing_address.address1 + ' ' +
          (this.club.mailing_address.address2 ? this.club.mailing_address.address2 + ' ' : '') +
          (this.club.mailing_address.city ? this.club.mailing_address.city + ' ' : '') +
          (this.club.mailing_address.state ? this.club.mailing_address.state + ' ' : '') +
          (this.club.mailing_address.zip ? this.club.mailing_address.zip : '');
      }
      if (address) {
        query += encodeURI(address);
      } else {
        query += encodeURI(this.club.name);
      }
      let fullQuery = query + '&z=' + this.zoom + '&output=embed' + '&embedded=true';
      return fullQuery;
    }
  },
  
}
</script>

<style scoped>
iframe {
  overflow: hidden;
}
</style>
