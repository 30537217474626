<template>
  <div>
    <div v-if="authManage || edit" class="flex flex-wrap sm:mt-3">
      <lock-type
        v-if="!useLadder"
        :header="`Rounds`"
        :allowed="localRounds"
        :label="'name'"
        :options="roundList"
        :track-by="'name'"
        :mutation="'setLocalRounds'"
        :type="'Rounds'"/>
      <lock-type
        v-if="!useLadder"
        :header="`Line Times`"
        :allowed="localTimes"
        :label="'time'"
        :options="timeList"
        :track-by="'time'"
        :mutation="'setLocalTimes'"
        :type="'Times'"/>
      <lock-type
        v-if="!useLadder"
        :header="`${locationsWord}`"
        :allowed="localLocations"
        :label="'name'"
        :options="locationsList"
        :track-by="'name'"
        :mutation="'setLocalLocations'"
        :type="'Locations'"/>
      <lock-type
        v-if="!useLadder"
        :header="`${balesWord}`"
        :allowed="localBales"
        :options="baleList"
        :mutation="'setLocalBales'"
        :type="'Bales'"/>
      <lock-type
        v-if="useLadder"
        :header="`Ladders`"
        :allowed="localLadders"
        :label="'name'"
        :options="ladderList"
        :track-by="'name'"
        :mutation="'setLocalLadders'"
        :type="'Ladders'"/>
      <lock-type
        :header="`Competitors`"
        :allowed="localCompetitors"
        :label="'name'"
        :options="Object.values(tournament.competitorList)"
        :track-by="'id'"
        :mutation="'setLocalCompetitors'"
        :type="'Competitors'"/>
      <div class="mt-5 w-full px-2">
        <div class="float-right">
          <router-link v-if="authManage && hasFailures" to="/reshoots">
            <button class="btn btn-green-500">Score Equipment Failures</button>
          </router-link>
          <button v-if="locked" @click="unlock" class="btn btn-blue-600 ">
            <i class="fas fa-lock-open-alt"></i> Unlock Device
          </button>
          <button v-else @click="lock" class="btn btn-blue-600"><i class="fas fa-lock-alt"></i> Lock Device</button>
        </div>
        <button @click="cancel" class="btn btn-gray-600 float-left">Cancel</button>
      </div>
    </div>
    <div v-else>
      <div class="text-center m-5 px-2">
        <button @click="cancel" class="btn btn-gray-600 ">Cancel</button>
        <button @click="editSettings" v-if="!usedCode" class="btn btn-outline-blue-600">
          <i class="fas fa-user-lock"></i>Edit Restrictions
        </button>
        <button v-if="locked" @click="unlock" class="btn btn-blue-600 ">
          <i class="fas fa-lock-open-alt"></i> Unlock Device
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import LockType from "./LockType.vue";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "LockDevice",
  components: {LockType},
  mixins: [mixin],
  props: ['user', 'useLadder'],
  data() {
    return {
      edit: false,
      usedCode: false,
      lockOptions: ['Rounds', 'Times', 'Locations', 'Bales', 'Competitors', 'Ladders'],
    }
  },
  async created() {
    this.setActiveVariables();
    await this.setUsedCode();
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'locked',
      'localBales',
      'localCompetitors',
      'localLocations',
      'localRounds',
      'localTimes',
      'localLadders',
      'restrictedCompetitors',
      'restrictedRounds',
      'restrictedTimes',
      'restrictedLocations',
      'restrictedBales',
      'restrictedLadders',
      'showLock',
    ]),
    locationsWord() {
      return this.$trans.choice('search.location', 2);
    },
    balesWord() {
      return this.$trans.choice('search.bale', 2)
    },
    authManage() {
      return this.user && this.user.auth_manage;
    },
    locationsList() {
      return this.tournament.locations;
    },
    timeList() {
      return this.tournament.line_times;
    },
    roundList() {
      return this.tournament.rounds;
    },
    ladderList() {
      return this.tournament.ladders;
    },
    hasFailures() {
      return this.tournament.reshoots.length > 0;
    },
    baleList() {
      return [...new Set(Object.values(this.tournament.bales).map(m => m.name))];
    },
  },
  methods: {
    async setUsedCode() {
      let code = await this.$localForage.getItem(this.tournament.id + '_code');
      this.usedCode = code != null;
    },
    clearValues(type) {
      let values = {};
      for (const name of this.lockOptions) {
        values[`${type}${name}`] = [];
      }
      this.store.$patch(values);
    },
    cancel() {
      this.clearValues('local');
      this.close();
    },
    close() {
      this.showLock = false;
    },
    async setExisting() {
      let key = this.tournament.id + '_locked_';
      let values = {};
      for (const name of ['Rounds', 'Times', 'Locations', 'Bales', 'Competitors', 'Ladders']) {
        let value = await this.$localForage.getItem(`${key}${name.toLowerCase()}`);
        values[`local${name}`] = value == null ? [] : value;
      }
      this.store.$patch(values);
      this.edit = true;
    },
    editSettings() {
      this.setExisting()
    },
    roundsHaveTimes(times, rounds) {
      let valid = true;
      if (times.length) {
        times.forEach(function (time) {
          let found = false;
          rounds.forEach(function (round) {
            let exists = round.line_times.find(f => f.id === time.id);
            if (exists) found = true;
          });
          valid = found;
        })
      }
      return valid;
    },
    roundsHaveLocations(locations, rounds) {
      let valid = true;
      if (locations.length) {
        locations.forEach(function (location) {
          let found = false;
          rounds.forEach(function (round) {
            let exists = round.locations.find(f => f.id === location.id);
            if (exists) found = true;
          });
          valid = found;
        })
      }
      return valid;
    },
    verifyCompatability() {
      let rounds = this.localRounds;
      if (!rounds.length) return true;
      let validTimes = this.roundsHaveTimes(this.localTimes, rounds);
      let validLocations = this.roundsHaveLocations(this.localLocations, rounds);
      return validTimes && validLocations;
    },
    setLocalValuesToRestrictedValues() {
      let values = {};
      for (const name of this.lockOptions) {
        values[`restricted${name}`] = this[`local${name}`];
      }
      this.store.$patch(values);
    },
    prepForage(value) {
      return JSON.parse(JSON.stringify(value));
    },
    setLocalValuesToForage() {
      let key = `${this.tournament.id}_lock`;
      this.$localForage.setItem(key, true);
      this.$localForage.setItem(`${key}ed_bales`, this.prepForage(this.localBales));
      this.$localForage.setItem(`${key}ed_competitors`, this.prepForage(this.localCompetitors));
      this.$localForage.setItem(`${key}ed_locations`, this.prepForage(this.localLocations));
      this.$localForage.setItem(`${key}ed_times`, this.prepForage(this.localTimes));
      this.$localForage.setItem(`${key}ed_rounds`, this.prepForage(this.localRounds));
      this.$localForage.setItem(`${key}ed_ladders`, this.prepForage(this.localLadders));
    },
    lock() {
      if (!this.verifyCompatability()) {
        return Swal.fire('The restricted round must have all locations and times that you selected');
      }
      this.setLocalValuesToRestrictedValues();
      this.setLocalValuesToForage();
      if (this.localBales.length) {
        this.shownBales = this.localBales;
      }
      this.$store.dispatch('tournament/setLocked', this.tournament.slug);
      this.store.$patch({
        restrictedRounds: this.localRounds,
        locked: true,
        showLock: false,
      })
      this.setActiveVariables();
      this.clearValues('Local');
      this.close();
    },
    setActiveVariables() {
      let rounds = this.restrictedRounds;
      let times = this.restrictedTimes;
      let locations = this.restrictedLocations;
      let ladders = this.restrictedLadders;
      if (ladders.length && ladders.indexOf(this.ladder === -1)) {
        let ladder = ladders[0];
        this.updateLadder(ladder);
      }
      if (rounds.length && rounds.indexOf(this.round) === -1) {
        let round = rounds[0];
        let roundTimes = round.line_times;
        let roundLocations = round.locations;
        let time;
        let location;
        for (let t of roundTimes) {
          if (!times.length) {
            time = t;
            break;
          }
          if (times.findIndex(x => x.id === t.id) !== -1) {
            time = t;
            break;
          }
        }
        for (let l of roundLocations) {
          if (!locations.length) {
            location = l;
            break;
          }
          if (locations.indexOf(l) !== -1) {
            location = l;
            break;
          }
        }
        if (location && time) {
          this.store.$patch({
            round: round,
            lineTime: time,
            location: location,
          })
        }
      }
    },
    async unlock() {
      this.clearValues('Restricted');
      let usedCode = await this.$localForage.getItem(this.tournament.id + '_code');
      if (usedCode != null) {
        let device = this.$store.state.device;
        let vm = this;
        await this.$axios.post('/device/remove_tournament', {
          'device': device.device.device_id,
          'tournament': this.tournament.id,
        }).then(function (data) {
          let d = data.data.device;
          vm.$store.commit('device/SET', d);
        })
      }
      this.clearForage();
      await this.$store.dispatch('tournament/setUnlocked', this.tournament.slug);
      this.store.$patch({
        locked: false,
        showLock: false,
        verified: false
      })
    },
    
    clearForage() {
      this.$localForage.removeItem(this.tournament.id + '_lock');
      this.$localForage.removeItem(this.tournament.id + '_locked_bales');
      this.$localForage.removeItem(this.tournament.id + '_locked_competitors');
      this.$localForage.removeItem(this.tournament.id + '_locked_locations');
      this.$localForage.removeItem(this.tournament.id + '_locked_times');
      this.$localForage.removeItem(this.tournament.id + '_locked_rounds');
      this.$localForage.removeItem(this.tournament.id + '_locked_soft');
      this.$localForage.removeItem(this.tournament.id + '_locked_ladders');
      this.$localForage.removeItem(this.tournament.id + '_code');
    },
  },
}
</script>

<style scoped>

</style>
