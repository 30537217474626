<template>
  <div>
    <div class="flex justify-center mt-5">
      <img @click="navigateHome()" src="/img/orange_logo_315_wide.png" alt="bowscore logo" class="logo">
    </div>
    <div class="flex justify-center">
      <Form action="/register" method="post" v-slot="{ meta: formMeta}">
        <input type="hidden" name="_token" :value="csrf">
        <div class="flex flex-col formItem">
          <Field rules="required|min:3|max:90|clean|full_name" placeholder="Full Name"
                 type="text" name="name" class="bs-form-box" required v-model="name"/>
          <ErrorMessage name="name" class="errorText"/>
        </div>
        <div class="flex flex-col formItem">
          <Field rules="required|min:3|max:160|email" placeholder="Email"
                 type="email" name="email" class="bs-form-box" required v-model="email"/>
          <ErrorMessage name="email" class="errorText"/>
        </div>
        <div class="flex flex-col formItem relative">
          <Field rules="required|min:6|max:60" v-slot="{ field, meta }" name="password" v-model="password">
            <input v-bind="field" type="password" placeholder="Password" class="bs-form-box"/>
            <i v-if="meta.valid" class="far fa-2x fa-check-circle icon" style="color:#62A82F;"
               aria-hidden="true"></i>
          </Field>
          <ErrorMessage name="password" class="errorText"/>
        </div>
        <div v-if="password.length" class="flex flex-col formItem relative">
          <Field rules="required|confirmed:@password" v-model="confirm" v-slot="{field, meta}"
                 name="password_confirmation">
            <input v-bind="field" type="password" placeholder="Password Confirmation" class="bs-form-box">
            <i v-if="meta.valid" class="far fa-2x fa-check-circle icon" style="color:#62A82F;" aria-hidden="true"></i>
          </Field>
          <ErrorMessage name="password_confirmation" class="errorText"/>
        </div>
        <button :disabled="!formMeta.valid" class="createButton" @click="loading = true"
                :style="`background-color: #${formMeta.valid ? 'FB5202': '9D9D9D'};`">
          Create Account <span v-if="loading"><i class="ml-2 fas fa-spin fa-spinner"></i></span>
        </button>
      </Form>
    </div>
  </div>
</template>

<script>

import {ErrorMessage, Field, Form} from "vee-validate";

export default {
  name: "SignUp",
  components: {Field, ErrorMessage, Form},
  data() {
    return {
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      loading: false,
      password: '',
      confirm: '',
      name: '',
      email: '',
    }
  },
  methods: {
    navigateHome() {
      window.location = '/';
    },
  }
}
</script>

<style scoped>
.icon {
  position: absolute;
  right: 20px;
  top: 17px;
}

.logo {
  width: 120px;
  margin-top: 26px;
  margin-bottom: 60px;
}

.formItem {
  width: 240px;
  margin-bottom: 16px;
}

.bs-form-box {
  padding-left: 20px;
  padding-right: 20px;
  height: 44px;
  font-family: 'Figtree', 'serif';
  font-size: 16px;
  letter-spacing: .32px;
  line-height: 20px;
  font-weight: 500;
}

.createButton {
  width: 240px;
  height: 44px;
  margin-top: 16px;
  border-radius: 3px;
  text-transform: uppercase;
  color: #FFF;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: .32px;
  line-height: 19px;
}

@media (min-width: 576px) {
  .logo {
    width: 240px !important;
    margin-top: 72px;
  }
  
  .bs-form-box {
    height: 60px !important;
  }
  
  .createButton {
    width: 300px !important;
    height: 60px !important;
  }
  
  .formItem {
    width: 300px !important;
  }
}

</style>
