<template>
    <div class="w-full flex flex-wrap">
        <div class="w-full sm:w-1/4 flex sm:justify-end pr-4">
            <span :class="tournament_detail ? 'text-lg' : ''">
                {{name}}:
            </span>
        </div>
        <div class="pl-4 sm:pl-0 w-full sm:w-3/4 h-center">
            <span>
                <img v-if="image" :src="value" width="200" height="200">
                <span v-else>
                    {{ value }}
                </span>
                <slot></slot>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompetitorDetailRow",
        props: ['name', 'value', 'tournament_detail', 'image']
    }
</script>

<style scoped>

</style>
