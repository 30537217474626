<script setup>

const props = defineProps(['text', 'signIn', 'image'])

const navigateTo = ((location) => {
  window.location = location;
})

const linkText = "View Results".toUpperCase();

</script>

<template>
  <div>
    <div class="flex centered flex-col lg:flex-row">
      <div class="flex-1 flex flex-col justify-center mb-5">
        <div class="flex justify-center">
          <img :src="`/img/${image}`" alt="archer and bowscore app on a phone">
        </div>
      </div>
      <div class="flex-1 flex flex-col justify-center topMargin lg:mt-0">
        <div class="text text-center"> {{ text }}</div>
        <div class="flex justify-center">
          <div class="flex flex-col">
            <button @click="navigateTo('/register')" class="button orange">
              Get started
            </button>
            <button v-if="signIn" @click="navigateTo('/login')" class="button white">
              Sign in
            </button>
            <a v-if="signIn" href="/results" class="lg:hidden link">{{ linkText }}</a>
          </div>
        </div>
      </div>
    </div>
    
    <div class="w-full mb-13 flex justify-center text-stone-400">
      <i class="fa-regular fa-2x fa-angle-down"></i>
    </div>
  </div>
</template>

<style scoped>
.topMargin {
  margin-top: 80px;
}

.link {
  @apply font-extrabold flex justify-center;
  color: #696969;
  font-family: 'Figtree', 'serif';
  font-size: 14px;
  letter-spacing: 0.28px;
  margin-top: 24px;
}

.centered {
  margin: 50px 16px 50px;
}

@media (min-width: 1100px) {
  .centered {
    width: 1100px;
    margin: 140px auto 145px;
  }
  
  .topMargin {
    margin-top: 0;
  }
  
  .text {
    font-size: 38px;
  }
  
  .button {
    height: 60px;
    width: 300px;
  }
}


.text {
  @apply font-bold;
  font-family: 'Figtree', 'serif';
  font-size: 28px;
  color: #696969;
  line-height: 1.1;
  margin-bottom: 58px;
}

.orange {
  @apply text-white;
  background-color: #FB5202;
}

.white {
  @apply bg-white;
  text-color: #FB5202;
}

.button {
  font-weight: 700;
  font-family: 'Figtree', serif;
  border-radius: 3px;
  box-shadow: 0 3px 6px -1px rgba(0, 0, 0, 0.16);
  height: 44px;
  width: 338px;
  font-size: 18px;
  letter-spacing: .48px;
  line-height: 25px;
  text-transform: uppercase;
  margin-top: 16px
}


</style>