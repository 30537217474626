<template>
  <div v-if="competitor" class="w-full">
    <competitor-details :competitor="competitor" :tournament="tournament" @getCompetitor="getCompetitor"/>
  </div>
</template>

<script>
import CompetitorDetails from "./CompetitorDetails.vue";

export default {
  name: "CompetitorPage",
  components: {CompetitorDetails},
  props: ['tournamentIn'],
  computed: {
    tournament() {
      return this.tournamentIn;
    }
  },
  data() {
    return {
      competitor: null,
    }
  },
  methods: {
    getCompetitor(uuid) {
      this.$axios.get(`/competitors/${uuid}`).then(({data}) => {
        this.competitor = data.competitor;
      })
    },
  },
  created() {
    let uuid = this.$route.params.uuid;
    this.getCompetitor(uuid);
  }
}
</script>

<style scoped>

</style>
