<template>
  <div>
    <h2 class="text-2xl">Payment</h2>
    <hr>
    <div class="flex justify-center">
      <div class="w-full sm:w-1/2" ref="card-details">
        <div v-if="tournament.payments.pricing_extras && tournament.payments.pricing_extras.length">
          Base Price: ${{ basePrice }}.00 ( ${{ competitorPrice }}.00 per competitor )
          <table class="mt-2 table table-bordered">
            <thead>
            <tr>
              <th class="table-header">Name</th>
              <th class="table-header text-center" v-for="extra in tournament.payments.pricing_extras">
                <div class="">
                  {{ extra.name }} ( {{ extra.type }}${{ extra.amount }}.00 )
                  <modal-component v-if="extra.imageUrl !== undefined && extra.enabled" class="ml-2">
                    <template #open-modal>
                      <span type="button" data-toggle="modal" data-backdrop="true"
                            class="cursor-pointer">
                        <img alt="product image" :src="extra.imageUrl" style="max-width:50px;">
                      </span>
                    </template>
                    <template v-slot:modal-body>
                      <img alt="product image" :src="extra.imageUrl" data-backdrop="true"
                           class="mx-auto">
                    </template>
                  </modal-component>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="archer in archers">
              <td>{{ archer.name }}</td>
              <td v-for="(extra, index) in tournament.payments.pricing_extras" class="text-center">
                <input
                  type="checkbox"
                  @change="updateExtras(archer,extra)"
                  :value="extra"
                  :checked="restrictedPrice(archer, index)"
                  :disabled="restrictedPrice(archer, index)">
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <label>Payment Amount
            <span v-if="processingMessage">( ${{ processingAmount }}.00 processing fee required )</span>
          </label>
          <div class="flex bs-form-box p-0 mb-2">
            <div class=" bg-gray-200 px-3 flex-extralight h-center border-2">$</div>
            <input
              class="pl-2 flex-grow"
              type="number"
              min="0"
              :class="disabled ? 'bg-gray-200' : ''"
              v-validate="'required|min_value:' + minPayment + '|max_value:' + maxPayment"
              name="payment"
              :disabled="disabled"
              ref="payment_amount"
              required>
            <div class=" bg-gray-200 h-center px-2 flex-extralight border-2">.00</div>
          </div>
          <span class="text-sm text-red-600">{{ errors.first('payment') }}</span>
          <span v-if="tournament.payments.require_down_pay_at_register">
            <small class="text-gray-600">Minimum Down Payment: ${{ minPayment }}.00</small>
            <br>
            <small class="text-gray-600">Full Payment: ${{ maxPayment }}.00</small>
          </span>
        </div>
        <div class="w-full mb-2" v-show="!offlinePayment">
          <div v-if="useAuthNet">
            <div class="bs-form-box" id="authNetCard">
              <input
                class="w-1/3 focus:outline-none" maxlength="16" type="text" name="cardNumber"
                id="cardNumber" placeholder="Card number"/>
              <div class="text-right float-right w-2/3">
                <input
                  class="w-1/4 justify-end text-right focus:outline-none" type="text"
                  name="expMonth" id="expMonth" maxlength="2" placeholder="MM"/>{{ ' / ' }}
                <input
                  class="w-1/4 justify-end focus:outline-none" maxlength="4" type="text"
                  name="expYear" id="expYear" placeholder="YYYY"/>
                <input
                  class="w-1/4 justify-end text-right focus:outline-none" maxlength="4" type="text"
                  name="cardCode" id="cardCode" placeholder="CVV"/>
              </div>
            </div>
            <input type="hidden" name="authNetToken" id="authNetToken"/>
            <input type="hidden" name="authNetDescriptor" id="authNetDescriptor"/>
          </div>
          <div v-else>
            <div ref="card" class="bs-form-box flex flex-col justify-center"></div>
          </div>
        </div>
        <div v-if="tournament.payments.allow_offline_payments">
          <form-switch
            class="w-full"
            @toggled="toggleOffline"
            :model="offlinePayment"
            :id="'offlinePayment'"
            :label="'Pay Offline'"
            :subLabel="offlineLabel"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FormSwitch from "../../partials/FormSwitch.vue";
import ModalComponent from "../../partials/ModalComponent.vue";

export default {
  name: "GroupPaymentOld",
  components: {ModalComponent, FormSwitch},
  props: ['tournament', 'archers', 'restrictions'],
  data() {
    return {
      paymentAmount: null,
      offlinePayment: false,
      pricingExtras: {},
      maxPayment: null,
      basePrice: null,
      processingMessage: false,
      processingAmount: 0,
      offlineLabel: 'This tournament accepts payments offline. By selecting this option you are accepting the responsibility of handling payment outside of Bowscore.'
    }
  },
  watch: {
    archers() {
      let count = this.archers.length;
      let processing = this.tournament.payments.processing_fee;
      let priceAndFee = this.tournament.payments.price + processing;
      this.paymentAmount = priceAndFee * count;
      this.maxPayment = priceAndFee * count;
      this.basePrice = priceAndFee * count;
      this.processingAmount = (processing ? processing + 1 : 0) * count;
    },
    restrictions(newVal, oldVal) {
      Object.keys(newVal).forEach((name) => {
        let archer = this.archers.find(f => f.name === name);
        newVal[name]['price'].forEach((index) => {
          let extra = this.tournament.payments.pricing_extras[index];
          if (!Object.values(oldVal).length || !oldVal[name]['price'].includes(index)) {
            this.updateExtras(archer, extra);
          }
        })
        
      })
    }
  },
  computed: {
    competitorPrice() {
      return this.tournament.payments.price + this.tournament.payments.processing_fee;
    },
    useAuthNet() {
      return this.tournament.club.auth_net_key && !this.tournament.payments.use_stripe;
    },
    minPayment() {
      if (this.tournament.payments.require_down_pay_at_register) {
        let singlePrice = this.tournament.payments.down_payment_amount + this.tournament.payments.processing_fee;
        return singlePrice * this.archers.length;
      }
      return this.maxPayment;
    },
    disabled() {
      return this.tournament.payments.require_full_pay_at_register === 1
    }
  },
  methods: {
    restrictedPrice(archer, index) {
      return Object.values(this.restrictions).length && this.restrictions[archer.name]['price'].includes(index);
    },
    updateExtras(archer, extra) {
      let exists = this.pricingExtras[archer.id].indexOf(extra);
      if (exists !== -1) {
        this.pricingExtras[archer.id].splice(exists, 1);
        this.paymentAmount -= extra.type === '+' ? parseInt(extra.amount) : parseInt('-' + extra.amount);
        this.maxPayment -= extra.type === '+' ? parseInt(extra.amount) : parseInt('-' + extra.amount);
      } else {
        this.pricingExtras[archer.id].push(extra);
        this.paymentAmount += extra.type === '+' ? parseInt(extra.amount) : parseInt('-' + extra.amount);
        this.maxPayment += extra.type === '+' ? parseInt(extra.amount) : parseInt('-' + extra.amount);
      }
      this.processingMessage = this.paymentAmount < this.processingAmount;
    },
    toggleOffline() {
      this.offlinePayment = !this.offlinePayment;
      this.$emit('toggleOffline')
    },
  }
}
</script>

<style scoped>
.vue-form-wizard :deep( .wizard-title ){
  @apply text-3xl;
}

a {
  @apply text-blue-600 cursor-pointer;
}
</style>
