<template>
  <div class="w-1/2">
    <div class="card card-blue-700">
      <div class="card-header">
        New Club Requests
      </div>
      <div class="card-body">
        <div v-for="(club, index) in clubs" class="border-b my-4 py-2">
          <div class="flex flex-col">
            <div class="flex justify-between">
              <div class="text-2xl">
                {{ club.name }}
              </div>
              <div>
                created: {{ club.created_at }}
              </div>
            </div>
            <div class="text-blue-600">
              <a :href="'mailto:' + club.email">{{ club.email }}</a>
            </div>
            <div class="mx-auto my-3">{{ club.throttle.message }}</div>
            <div class="flex justify-between">
              <button @click="deleteClub(index)" class="btn btn-red-600">DELETE</button>
              <button @click="acceptClub(index)" class="btn btn-blue-600">Accept</button>
            </div>
          
          </div>
        </div>
        <div v-if="!clubs.length">
          There are no unapproved club requests.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClubRequests",
  props: ['inc_clubs'],
  data() {
    return {
      clubs: this.inc_clubs,
    }
  },
  methods: {
    acceptClub(index) {
      let club = this.clubs[index];
      this.$axios
      .post('/godmode/accept_club', {club: club.slug})
      .then(({data}) => {
        if (data.success) {
          this.clubs.splice(index, 1);
          Swal.fire('Club Accepted!');
        }
      }).catch(({error}) => {
        console.log(error)
      });
    },
    deleteClub(index) {
      let club = this.clubs[index];
      Swal.fire({
        titleText: 'Delete ' + club.name,
        text: 'This is a big deal. Are you sure you want to do delete it?',
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        preConfirm: () => {
          this.$axios
          .post('/godmode/delete_club', {club: club.slug})
          .then(({data}) => {
            if (data.success) {
              this.clubs.splice(index, 1);
              Swal.fire('Club Deleted!');
            }
          }).catch(({error}) => {
            console.log(error)
          });
        },
      })
    }
  }
  
}
</script>

<style scoped>

</style>