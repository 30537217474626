<template>
  <div class="flex flex-col lg:hidden text-gray-600">
    <a class="nav-link" href="/results" :class="active === 'results' ? 'active' : ''">
      <i class="fas fa-fw fa-medal"></i>
      <span v-show="expanded">Results</span>
    </a>
    <dropdown v-if="user && user.managed_clubs.length" key="tournaments_grouped"
              css="bg-white" align="left" class="my-2">
      <template v-slot:trigger>
        <a class="nav-link" href="#" title="Clubs"
           :class="active === 'clubs' ? 'active' : ''">
          <i class="fas fa-fw fa-store-alt"></i>
          <span v-show="expanded">Clubs</span>
          <i class="fas fa-caret-down"></i>
        </a>
      </template>
      <a class="nav-link linkItem" href="/clubs">Find a Club</a>
      <a v-for="org in user.managed_clubs" class="nav-link linkItem" :href="'/clubs/' + org.slug">{{ org.name }}</a>
    </dropdown>
    <a v-else class="nav-link" href="/clubs" title="Clubs"
       :class="active === 'clubs' ? 'active' : ''">
      <i class="fas fa-fw fa-store-alt"></i>
      <span v-show="expanded">Clubs</span>
    </a>
    <dropdown v-if="user && (user.tournaments_grouped.length || user.managed_tournaments.length)"
              key="tournament_main" css="bg-white" align="left" class="my-2">
      <template v-slot:trigger>
        <a class="nav-link whitespace-nowrap" href="#" title="Tournaments" key="main_trigger">
          <i class="fas fa-fw fa-calendar-day"></i>
          <span v-show="expanded">{{ capitalize(this.$trans.choice('search.tournament', 2)) }}</span>
          <i class="fas fa-caret-down"></i>
        </a>
      </template>
      <a class="nav-link linkItem" href="/tournaments">
        Find a {{ capitalize(this.$trans.choice('search.tournament', 1)) }}
      </a>
      <dropdown v-if="user && user.tournaments_grouped.length" key="tournaments_grouped"
                css="bg-white"
                align="left">
        <template v-slot:trigger>
          <a class="nav-link linkItem whitespace-nowrap" href="#" key="grouped_trigger"
             title="Tournaments">
            {{ capitalize(this.$trans.choice('search.tournament', 2)) }} I'm In
            <i class="fas fa-caret-down"></i>
          </a>
        </template>
        <a v-for="tournament in user.tournaments_grouped" class="nav-link linkItem ml-5"
           :href="'/tournaments/' + tournament.slug">
          {{ tournament.name }}
        </a>
      </dropdown>
      <dropdown v-if="user && user.managed_tournaments.length" key="managed_tournaments"
                css="bg-white"
                align="left">
        <template v-slot:trigger>
          <a class="nav-link linkItem whitespace-nowrap" href="#" key="managed_trigger"
             title="Tournaments">
            {{ capitalize(this.$trans.choice('search.tournament', 2)) }} I Manage
            <i class="fas fa-caret-down"></i>
          </a>
        </template>
        <a v-for="tournament in user.managed_tournaments" class="nav-link linkItem ml-5"
           :href="'/tournaments/' + tournament.slug">
          {{ tournament.name }}
        </a>
      </dropdown>
    </dropdown>
    <a v-else class="nav-link" href="/tournaments" :class="active === 'tournaments' ? 'active' : ''">
      <i class="fas fa-fw fa-calendar-day"></i>
      <span v-show="expanded"> {{ capitalize(this.$trans.choice('search.tournament', 2)) }}</span>
    </a>
    <tournament-navigation
      v-if="tournament && tournament.club_id && expanded"
      :tournament="tournament" :user="user"
      :class="'ml-5'"
      @close="close()"/>
    
    <a href="/archers" class="nav-link" title="Archers" :class="active === 'archers' ? 'active' : ''">
      <i class="fas fa-fw fa-bow-arrow"></i>
      <span v-show="expanded"> {{ capitalize(this.$trans.choice('search.archer', 2)) }}</span>
    </a>
    <a v-if="user" class="nav-link" href="/dashboard" title="Dashboard"
       :class="active === 'dashboard' ? 'active' : ''">
      <i class="fas fa-fw fa-tachometer-alt"></i><span v-show="expanded"> Dashboard</span>
    </a>
    <a href="/practice" class="nav-link" title="Practice"
       :class="active === 'practice' ? 'active' : ''">
      <i class="fas fa-fw fa-calendar-exclamation"></i><span v-show="expanded"> Practice</span>
    </a>
    <practice-navigation
      v-if="tournament && !tournament.club_id && expanded"
      :tournament="tournament"
      :user="user"/>
    
    <a class="nav-link" href="/authorize" title="Authorize"
       :class="active === 'authorize' ? 'active' : ''">
      <i class="fas fa-fw fa-user-check"></i><span v-show="expanded"> Authorize</span>
    </a>
    <a class="pt-6 pb-44 xs:pb-32 sm:pb-10" href="/build_notes" title="version">
      <div>Version:</div>
      <div>{{ version }}</div>
    </a>
  </div>
</template>

<script>
import moment from "moment";
import TournamentNavigation from "../tournaments/TournamentNavigation.vue";
import PracticeNavigation from "../practice/PracticeNavigation.vue";

export default {
  name: "SmallNav",
  components: {PracticeNavigation, TournamentNavigation},
  props: ['user'],
  data() {
    return {
      version: '1.0.0',
      verified: true,
      online: true,
      expanded: true,
      navDrop: 'max-h-xs max-w-lg w-56 border overflow-y-auto whitespace-normal',
    }
  },
  created() {
    this.setVersion();
  },
  computed: {
    tournament() {
      return this.$store.getters['tournament/getTournament'];
    },
    active() {
      let path = window.location.pathname.split('/');
      if (path.length > 1) {
        return path[1] ? path[1] : 'home';
      }
      return 'home'
    },
    isTournament() {
      let path = window.location.pathname.split('/');
      if (path.length > 2 && (path[1] === 'tournaments' || path[1] === 'practice')) {
        if (path.length > 3 && path[3] === 'participant') {
          return false;
        }
        return true;
      }
      return false;
    }
  },
  methods: {
    capitalize(text) {
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    async setVersion() {
      await this.$axios.get('/version').then(async ({data}) => {
        if (data.success !== true) return;
        let latest = data.version;
        let time = moment(data.time).local().format('D MMM YY h:mm a');
        let display = `${latest} (${time})`;
        let local = await this.$localForage.getItem('version');
        if (local !== display) {
          await this.$localForage.setItem('version', display);
          store.commit('tournament/CLEAR_TOURNAMENT');
          await this.$localForage.setItem('allTournaments', []);
        }
        this.version = display;
      })
    },
    close() {
      this.$emit('close');
    },
    clone() {
      Swal.fire({
        title: 'Clone Match?',
        text: "This will duplicate the settings and competitors, but no scores will carry over.",
        icon: 'info',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Yes, clone it!'
      }).then((result) => {
        if (result.value) {
          window.location = '/practice/' + this.tournament.slug + '/clone';
        }
      })
    },
    copyLink() {
      var url = document.getElementById("urlHidden");
      url.setAttribute('type', 'text');
      url.select();
      document.execCommand('copy');
      url.setAttribute('type', 'hidden');
      Swal.fire({
        titleText: 'A link to this match has been copied to the clipboard',
        html: 'You may now paste this link in an email or message to share the match.',
        footer: 'Anyone with the link can score and edit this match. Please share it wisely.',
        customClass: {
          footer: 'text-red-600'
        }
      }).then(({dismiss}) => {
        if (!dismiss) {
          this.close();
        }
      })
    },
  },
}
</script>

<style scoped>
.smallLink {
  @apply text-gray-500 flex flex-col text-center w-20 mb-4;
}

.smallLink.active {
  @apply text-blue-500;
}

.nav-link {
  @apply my-2 text-xl;
}

.linkItem {
  @apply pl-6 text-blue-600 cursor-pointer block;
}
</style>
