<template>
  <div>
    <div class="relative" :key="`${ladder.id}_${step}_${numberIndex}`">
      <div class="flex flex-col">
        <div class="flex-grow bs-form-box nameHolder">
          <div class="btn rounded-r-none w-10 flex-none px-0" :class="assignment ? 'btn-green-500' : 'btn-red-500'">
            {{ baleLabel() }}
          </div>
          <input
            class="searchBox h-center boxText"
            :ref="'input_'+ number"
            :id="`${ladder.id}_${step}_${numberIndex}_name`"
            v-model="search"
            tabindex="1"
            :placeholder="'type to filter'"
            v-if="editing">
          <div v-else-if="assignment != null" class="boxText h-center">
            <div class="flex">
              <div>{{ assignment.previous_position }}.</div>
              <div class="mx-2 nameBox w-full " style="text-align: left;">{{ assignment.name }}</div>
              <div>{{ assignment.previous_result }}</div>
            </div>
          </div>
          <div v-else class="h-center boxText text-gray-500">
            <span class="nameBox"> Open </span>
          </div>
<!--          <div-->
<!--            @click="editing ? clear() : editing = true"-->
<!--            title="edit position"-->
<!--            :class="editing ? 'btn border-none' : 'btn btn-gray-400'"-->
<!--            class="editPosition">-->
<!--            <span v-if="!editing"><i class="fas fa-pencil-alt"></i></span>-->
<!--            <span v-else class="h-center h-full"><i class="fas fa-times"></i></span>-->
<!--          </div>-->
        </div>
      </div>
      <div v-if="editing" class="searchListContainer">
        <div class="searchList">
          <button v-if="assignment" class="searchListItem" @click.prevent="remove()">
            <i class="fas fa-trash"></i> REMOVE {{ assignment.name.toUpperCase() }}
          </button>
          <button v-if="!competitors.length" class="searchListItem" @click="editing = !editing">No Competitors</button>
          <button
            v-for="(competitor, index) in competitors"
            @click.prevent="update(competitor)"
            class="searchListItem"
            :tabindex="index+2">
            {{ competitor.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BracketAssignmentSelector",
  props: ['tournamentSlug', 'ladder', 'step', 'numberIndex', 'ladderCompetitors', 'number', 'assignmentIn'],
  data() {
    return {
      search: '',
      editing: false,
      assignment: this.assignmentIn,
    }
  },
  computed: {
    competitors() {
      let list = this.ladderCompetitors;
      if (this.search.length) {
        list = list.filter((f) => {
          return f.name.toLowerCase().includes(this.search.toLowerCase());
        })
      }
      return list;
    }
  },
  methods: {
    baleLabel() {
      let start = this.number;
      let half = this.number / 2.0;
      let up = Math.ceil(half);
      let position = start % 2 === 1 ? 'A' : 'B';
      return `${up}${position}`;
    },
    remove() {
      axios.post(`/tournaments/${this.tournamentSlug}/assign_ladder_remove`, {assignment_id: this.assignment.id});
      this.assignment = null;
      this.editing = false;
      this.$emit('refresh');
    },
    clear() {
      if (this.search.length) {
        this.search = '';
      } else {
        this.editing = false;
      }
    },
    update(competitor) {
      let label = this.baleLabel();
      let position = label.slice(-1);
      let info = {
        'bale': label.slice(0, -1),
        'bale_position': position,
        'tournament_id': this.ladder.tournament_id,
        'ladder_id': this.ladder.id,
        'competitor_id': competitor.id,
        'step': this.step,
      }
      axios.post(`/tournaments/${this.tournamentSlug}/assign_ladder_single`, {data: info});
      info['competitor'] = competitor.name;
      this.assignment = info;
      
      this.search = '';
      this.editing = false;
      this.$emit('refresh');
    },
  }
}
</script>


<style scoped>

.border-none {
  border: none;
}

.boxText {
  @apply text-center flex-grow overflow-x-hidden mx-2;
}

.nameBox {
  @apply overflow-x-hidden;
  text-overflow: ellipsis;
}

.editPosition {
  @apply cursor-pointer rounded-l-none;
}

.nameHolder {
  @apply flex justify-between p-0;
  overflow: hidden;
  white-space: nowrap;
}

.searchBox {
  @apply flex-grow pl-2;
}

.searchBox:focus {
  outline: none;
}

.searchListContainer {
  @apply bg-blue-800 text-blue-200 rounded-b absolute w-full z-10;
}

.searchList {
  @apply m-2 overflow-y-auto max-h-xxs flex flex-col;
}

.searchListItem {
  @apply text-left;
}

.searchListItem:hover,
.searchListItem:focus {
  @apply bg-blue-600 cursor-pointer;
  outline: none;
}

.complete {
  @apply bg-blue-700 text-blue-200 rounded;
}

.incomplete {
  @apply bg-blue-200 text-blue-600 rounded;
}
</style>