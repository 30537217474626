<template>
  <div class="my-2">
    <button v-if="hideShown" @click.prevent="hideShown = false" class="mx-auto btn-lg btn-blue-700 show">
      Show Fewer {{ baleWord }}
    </button>
    <div v-else class="flex flex-wrap">
      <div class="h-center text-lg xs:text-xl sm:text-2xl md:text-3xl lg:text-4xl whitespace-nowrap mr-2">
        Shown {{ baleWord }}:
      </div>
      <div class="h-center min-w-1/2 lg:min-w-1/4">
        <VueMultiselect
          @update:modelValue="update"
          :modelValue="shownBales"
          :options="baleList"
          :searchable="true"
          :hideSelected="true"
          :multiple="true"
          :showLabels="false"
          :placeholder="'Select ' +  this.$trans.choice('search.bale', 2) + ' to show'"/>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/scoring_mixins";
import {mapState, mapWritableState} from "pinia";
import {useDisplayStore} from "../../../stores/DisplayStore";

export default {
  name: "RestrictBales",
  mixins: [mixin],
  watch: {
    shownBales(newValue) {
      if (!newValue.length) {
        this.hideShown = true;
      }
    }
  },
  computed: {
    ...mapState(useDisplayStore, ['location', 'restrictedBales', 'ladder', 'step']),
    ...mapWritableState(useDisplayStore, ['hideShown', 'shownBales']),
    baleWord() {
      let text = this.$trans.choice('search.bale', parseInt(`${this.baleCount}`));
      return `${text[0].toUpperCase()}${text.slice(1)}`;
    },
    baleCount() {
      if (this.isLadder) {
        return this.baleList.length;
      }
      let bales = this.location.bales
      return bales.length;
    },
    activeLocation() {
      return this.location ? this.location.id : this.tournament.rounds[0].locations[0].id;
    },
    baleList() {
      if (this.restrictedBales.length) return this.restrictedBales;
      if (this.isLadder) {
        let bales = this.assignedCompetitors['ladder'][this.ladder.id]['step'][this.step].bales;
        return Object.keys(bales);
      }
      return Object.values(this.tournament.bales).reduce((baleList, bale) => {
        if (bale.location_id === this.activeLocation) baleList.push(bale.name);
        return baleList
      }, []);
    },
  },
  methods: {
    update(value) {
      this.shownBales = value;
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>

</style>
