<template>
  <div class="bg-blue-100 flex flex-wrap">
    <div v-for="detail in regform.filter(f => f.multiple !== null && f.enabled)">
      <form-switch
        @toggled="breakdownToggle(detail)"
        :model="0"
        :id="'show'+detail.option"
        :label="detail.name"/>
    </div>
    <transition name="fade">
      <div v-if='limitBreak' class="w-full mx-5 text-red-600">
        You have reached the maximum number of breakdown fields.
      </div>
    </transition>
  </div>
</template>
<script>
import {mapState, mapWritableState} from "pinia";
import {useResultsStore} from "../../../stores/ResultsStore";
import FormSwitch from "../../partials/FormSwitch.vue";

export default {
  name: "ResultFormSwitches",
  components: {FormSwitch},
  computed: {
    ...mapState(useResultsStore, ['regform']),
    ...mapWritableState(useResultsStore, ['breakdownArray', 'limitBreak']),
  },
  methods: {
    breakdownToggle(detail) {
      if (this.breakdownArray.hasOwnProperty(detail.option)) {
        delete this.breakdownArray[detail.option];
      } else {
        if (Object.keys(this.breakdownArray).length >= 5) {
          document.getElementById('show' + detail.option).click();
          this.limitBreak = true;
          setTimeout(() => {
            this.limitBreak = false;
          }, 6000)
        } else {
          this.breakdownArray[detail.option] = detail.multiple;
        }
      }
    },
  }
}
</script>
<style scoped>
.fade-leave-active, .fade-enter-active {
  transition: opacity 1s;
}

.fade-enter-from, .fade-leave-to, .fade-leave /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  
}

</style>